import PropTypes from "prop-types";
import { FieldWrapper } from "../field-wrapper";
import { retreiveProps } from "../form-utils";
import { useFormStore } from "../../store";
import { makeStyles } from "@material-ui/styles";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles({
    subfieldsWrapper: {
        display: "flex"
    },
    imgButton: {
        marginTop: "6px",
        width: "20px",
        height: "20px",
        verticalAlign: "middle",
        padding: "2px",
        cursor: "pointer",
        zIndex: 1
    }
});

const Fieldset = ({ fields, mainOptions, onChange, indexes }) => {
    const classes = useStyles();
    const onFieldDelete = useFormStore((state) => state.onFieldDelete);

    const allFields = mainOptions.multi ? fields : [fields];

    return allFields.map((fieldList, i) => {
        const isDeleteEnable = i > 0 && mainOptions.viewState !== "view";
        return (
            <div key={i} className="fieldHolder fs_Items">
                <div className="field">
                    <div className="fieldHolder">
                        <div className={classes.subfieldsWrapper}>
                            <div className="field fieldSetField">
                                {fieldList.map((field, j) => {
                                    const props = retreiveProps(field, mainOptions.viewState);
                                    return (
                                        <FieldWrapper
                                            indexes={[...indexes, i, j]}
                                            key={j}
                                            {...props}
                                            onChange={onChange}
                                        />
                                    );
                                })}
                            </div>
                            {isDeleteEnable && (
                                <img
                                    className={classes.imgButton}
                                    src="images/delete.gif"
                                    width="20"
                                    height="20"
                                    onClick={() => onFieldDelete(indexes, i)}
                                    alt={getTranslation("delete-option")}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    });
};

Fieldset.propTypes = {
    fields: PropTypes.array,
    mainOptions: PropTypes.object,
    indexes: PropTypes.array
};

export { Fieldset };
