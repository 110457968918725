import ReactDOM from "react-dom";
import { Fragment } from "react";
import { QueryClientProvider, QueryClient } from "react-query";

const client = new QueryClient({
    staleTime: 15 * 60 * 1000 // 15 minutes
});

export const renderPopup = (props, containerId = "root") => {
    // Creating nested node in order to avoid interfering with other nodes in parent container
    const mountNode = document.createElement("div");

    const parentNode = document.getElementById(containerId);
    parentNode.appendChild(mountNode);

    const unmount = () => ReactDOM.unmountComponentAtNode(mountNode);

    const PopupContainer = () => {
        const { component: Component, onClose, ...componentProps } = props;

        return (
            <Fragment>
                <Component
                    {...componentProps}
                    onClose={(...args) => {
                        onClose?.(...args);
                        unmount();
                    }}
                />
            </Fragment>
        );
    };

    ReactDOM.render(
        <QueryClientProvider client={client}>
            <PopupContainer {...props} />
        </QueryClientProvider>,
        mountNode
    );

    return unmount;
};
