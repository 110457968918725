//this component only for demonstrating and will be removed after integration in Assay
import React, { useState } from "react";
import { copyUploadTemplate, maxStringFieldLength, UploadTemplates } from "@assay/upload-templates";
import { CopyTemplateDialog, getResultDefinitionsQueryParams, useUrl } from "@assay/core";

type CopyState = {
    isOpen: boolean;
    id: string;
};

export const UploadTemplateTab: React.FC = () => {
    const [copyState, setCopyState] = useState<CopyState | null>(null);
    const { params, openItem } = useUrl();
    const id = params?.id ?? null;
    const editMode = (params?.viewState === "edit" || params?.viewState === "create") && !!id;

    const onSubmit = async (name: string) => {
        if (!copyState?.id || !name) {
            return;
        }

        const newTemplate = await copyUploadTemplate(copyState.id, name);

        openItem({ ...params, id: newTemplate.id, viewState: "view" });
    };

    return (
        <>
            <UploadTemplates
                callbacks={{
                    onSave: (id) => openItem({ ...params, id, viewState: "view" }),
                    onDelete: () => openItem({ sandboxtab: 1 }),
                    onChangeMode: (editMode) =>
                        openItem({ ...params, viewState: editMode ? "edit" : "view" }),
                    onInitCopy: (copyId) =>
                        setCopyState({
                            isOpen: true,
                            id: copyId
                        }),
                    onCancel: () => null
                }}
                resultDefinitionsQueryParams={getResultDefinitionsQueryParams()}
                id={id}
                editMode={editMode}
            />
            <CopyTemplateDialog
                open={!!copyState?.isOpen}
                onClose={() => setCopyState(null)}
                formProps={{
                    name: "",
                    onSubmit,
                    maxNameLength: maxStringFieldLength
                }}
            />
        </>
    );
};
