import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@material-ui/core";
import { Clear, Check } from "@material-ui/icons";
import { makeStyles } from "../../theme";

const useStyles = makeStyles((theme) => ({
    content: {
        minHeight: "50px",
        minWidth: "350px"
    },
    button: {
        padding: theme.spacing(1),
        minWidth: 90,
        height: 36
    },
    buttonConfirm: {
        "& svg": {
            color: theme.palette.success.main
        }
    },
    buttonCancel: {
        "& svg": {
            color: theme.palette.warning.main
        }
    },
    actions: {
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: "flex-end"
    }
}));

type Props = {
    titleText: string;
    contentText: string;
    isOpen: boolean;
    onConfirm: () => void;
    onClose: () => void;
    confirmButtonText: string;
    closeButtonText: string;
};

export const ConfirmDialog: React.FC<Props> = ({
    titleText,
    contentText,
    isOpen,
    onConfirm,
    onClose,
    confirmButtonText,
    closeButtonText
}) => {
    const classes = useStyles();

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{titleText}</DialogTitle>

            <DialogContent className={classes.content}>{contentText}</DialogContent>

            <DialogActions className={classes.actions}>
                <Button
                    className={`${classes.button} ${classes.buttonConfirm}`}
                    variant="outlined"
                    color="secondary"
                    onClick={onConfirm}
                    startIcon={<Check />}
                >
                    {confirmButtonText}
                </Button>

                <Button
                    className={`${classes.button} ${classes.buttonCancel}`}
                    variant="outlined"
                    color="secondary"
                    onClick={onClose}
                    startIcon={<Clear />}
                >
                    {closeButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
