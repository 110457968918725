import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import { useTable } from "./use-table";

import { HeaderRow } from "./header-row";
import { TableRow } from "./table-row";
import { NavigationRow } from "./navigation-row";
import React from "react";

const useStyles = makeStyles(() => ({
    table: {
        backgroundColor: "#fff",
        border: "1px solid #C5C5C5",
        borderCollapse: "collapse",
        tableLayout: "fixed"
    }
}));

const ResultTable: React.FC = () => {
    const classes = useStyles();
    const { isLoading, rows, columns, page, totalRowsCount, changePage } = useTable();

    if (isLoading) return null;

    return (
        <Table className={classes.table}>
            <HeaderRow columns={columns} />

            <TableBody>
                {rows.map((row, i) => (
                    <TableRow key={i} row={row} />
                ))}
            </TableBody>

            <NavigationRow
                page={page}
                totalRowsCount={totalRowsCount}
                onNavButtonClick={changePage}
            />
        </Table>
    );
};

export { ResultTable };
