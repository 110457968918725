import { Fragment, useEffect } from "react";

import { makeStyles } from "@assay/ui-kit";
import { useFormStore, useHeatMapStore, useInitOptions } from "../store";
import { FieldWrapper } from "./field-wrapper";
import { beforeShow, retreiveProps } from "./form-utils";

import { Buttons } from "./buttons";
import { Results } from "./results";
import { HeatMap } from "./heatmap";

const useStyles = makeStyles((theme) => ({
    error: {
        padding: theme.spacing(2),
        background: "rgba(230, 230, 230, 1)"
    }
}));

export const Form = ({ viewState, formData, handlers }) => {
    const classes = useStyles();
    // TODO: combine into one hook call
    const fields = useFormStore((state) => state.fields);
    const setFields = useFormStore((state) => state.setFields);
    const resetStore = useFormStore((state) => state.reset);
    const formErrors = useFormStore((state) => state.formErrors);
    const isHeatMap = useHeatMapStore((state) => state.isHeatMapShown && !!state.heatMapProps);

    const resetHeatMap = useHeatMapStore((store) => store.reset);

    useInitOptions();

    useEffect(() => {
        const install = async () => {
            if (formData) {
                await beforeShow(formData, viewState);
                setFields(formData.fields, formData.name);
                resetHeatMap();
            } else {
                resetStore();
            }
        };
        install();
    }, [formData]);

    const modifiedHandlers = {
        ...handlers,
        onSave: () => handlers.onSave(fields)
    };

    const isResultSet = formData?.name === "Result Set" && viewState === "view";

    //for debugging
    window.devFields = fields;

    return (
        formData && (
            <Fragment>
                {formData.name && <h1>{formData.name}</h1>}
                {isHeatMap && <HeatMap formData={formData} />}
                {!isHeatMap && fields.length > 0 && (
                    <>
                        {fields.map((field, i) => {
                            const props = retreiveProps(field, viewState);
                            return (
                                <FieldWrapper
                                    key={i}
                                    {...props}
                                    formData={formData}
                                    setFields={setFields}
                                    indexes={[i]}
                                />
                            );
                        })}

                        {isResultSet && <Results resultSetId={formData.id} />}

                        {formErrors?.map((text) => (
                            <div key={text} className={classes.error}>
                                {text}
                            </div>
                        ))}

                        <Buttons handlers={modifiedHandlers} formData={formData} />
                    </>
                )}
            </Fragment>
        )
    );
};
