import { useState } from "react";
import { Box, Dialog, IconButton } from "@material-ui/core";
import { ConfirmDialog } from "@assay/ui-kit";
import { Close } from "@material-ui/icons";
import { getTranslation } from "@assay/shared";

export const DialogRoot = ({
    children,
    size,
    withConfirmClose,
    confirmTitleText,
    confirmContentText,
    onClose,
    ...restOfProps
}) => {
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const handleClose = () => {
        if (withConfirmClose) {
            setIsConfirmOpen(true);
        } else {
            onClose();
        }
    };

    const onConfirmClose = () => {
        setIsConfirmOpen(false);
        onClose();
    };

    return (
        <Dialog maxWidth={size} fullWidth open onClose={handleClose} {...restOfProps}>
            <Box paddingY={2} paddingX={4}>
                <Box display="flex" justifyContent="flex-end">
                    <IconButton onClick={handleClose} size="small">
                        <Close />
                    </IconButton>
                </Box>

                <ConfirmDialog
                    titleText={confirmTitleText}
                    contentText={confirmContentText}
                    isOpen={isConfirmOpen}
                    onConfirm={onConfirmClose}
                    onClose={() => setIsConfirmOpen(false)}
                    confirmButtonText={getTranslation("yes")}
                    closeButtonText={getTranslation("no")}
                />

                {children}
            </Box>
        </Dialog>
    );
};
