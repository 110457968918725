import React from "react";
import { DebouncedTextField } from "@assay/ui-kit";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { Typography } from "@material-ui/core";
import { useFileTabFlexStyles } from "../hooks";
import { getTranslation } from "@assay/shared";

const selectState = (state: UploadTemplateStore) => ({
    nameError: Boolean(state.name.error && state.name.touched),
    errorText: state.name.error,
    name: state.name.value,
    onTouch: state.actions.touchName,
    onChange: state.actions.changeName
});

export const EditNameField = (): React.ReactElement => {
    const classes = useFileTabFlexStyles();
    const { nameError, errorText, name, onChange, onTouch } =
        useShallowUploadTemplateStore(selectState);
    return (
        <div className={classes.root}>
            <Typography className={classes.title}>{getTranslation("template-name")}</Typography>{" "}
            <DebouncedTextField
                error={nameError}
                helperText={nameError ? errorText : null}
                variant="outlined"
                size={"medium"}
                value={name}
                onChangeValue={onChange}
                onBlur={onTouch}
                className={classes.input}
            />
        </div>
    );
};
