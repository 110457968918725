import { useQueryClient } from "react-query";
import { useTreeStore } from "../store";
import { useEntities } from ".";
import { useUrl } from "../use-url";
import { refetchTreeNode } from "../api-v2";
import { ContentTypes } from "../types";

type AfterSaveArg = {
    name: string;
    id: string | number;
    parentId: string | number;
    entityType?: ContentTypes;
};

type AfterCopyArg = {
    id: string | number;
    name: string;
    parentTree: Array<string | number>;
    entityType?: ContentTypes;
};

type AfterDeleteArg = {
    name: string;
    parentId: string | number;
};

export const useReloads = () => {
    const queryClient = useQueryClient();
    const { openItem } = useUrl();
    const { refetch: refetchEntities } = useEntities();
    const toggleSelectedTreeItem = useTreeStore((state) => state.toggleSelect);
    const expandTreeItems = useTreeStore((state) => state.openNodes);

    const reloadAfterSave = async ({
        name,
        id,
        parentId,
        entityType
    }: AfterSaveArg): Promise<void> => {
        queryClient.invalidateQueries(`table-${parentId}`);
        await refetchTreeNode(parentId, queryClient);
        expandTreeItems([String(parentId)]);
        toggleSelectedTreeItem(String(id));
        refetchEntities(name);

        const type = entityType === ContentTypes.Ut ? ContentTypes.Ut : ContentTypes.Form;
        openItem({ id, type, viewState: "view" });
    };

    const reloadAfterCopy = async ({
        id,
        name,
        parentTree,
        entityType
    }: AfterCopyArg): Promise<void> => {
        await Promise.all(parentTree.map((id) => refetchTreeNode(id, queryClient)));
        expandTreeItems(parentTree.map((el) => String(el)));
        toggleSelectedTreeItem(String(id));
        refetchEntities(name);

        const type = entityType === ContentTypes.Ut ? ContentTypes.Ut : ContentTypes.Form;
        openItem({ id, type, viewState: "view" });
        window.scrollTo(0, 0);
    };

    const reloadAfterDelete = async ({ name, parentId }: AfterDeleteArg): Promise<void> => {
        refetchEntities(name);
        openItem({ viewState: "view" });
        queryClient.invalidateQueries(`table-${parentId}`);
        refetchTreeNode(parentId, queryClient);
    };

    return {
        reloadAfterSave,
        reloadAfterCopy,
        reloadAfterDelete
    };
};
