import { useEffect, useRef, useState } from "react";
import { Button, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { downloadFile, getFileInfo, uploadFile } from "../../api-v2";
import { ErrorDialog } from "@assay/ui-kit";

const useStyles = makeStyles({
    button: {
        padding: "5px 0",
        textDecoration: "underline"
    },
    input: {
        width: "auto !important" // overriding legacy global styles
    }
});

export const File = ({ value, onChange, mainOptions, originField }) => {
    const [fileInfo, setFileInfo] = useState(null);
    const [error, setError] = useState(null);
    const classes = useStyles();
    const fileRef = useRef(null);

    const isOpened = !!error;

    const onSubmit = async () => {
        const file = fileRef.current?.files[0];

        const typeRegex = /^[\w\W]+\.(csv|xlsx|xlsm)$/i;

        if (!file) {
            setError("Please select a file to upload");
            return;
        }

        if (!file.name.match(typeRegex)) {
            setError("Only csv, xlsx and xlsm files are supported");
            return;
        }

        const id = await uploadFile(file);
        onChange(id, undefined);
    };

    useEffect(async () => {
        if (value) {
            setFileInfo(await getFileInfo(parseInt(originField.value)));
        }
    }, [value]);

    if (!fileInfo && value) {
        return <b>Retrieving file info</b>;
    }
    return (
        <>
            {fileInfo && (
                <>
                    <Typography>{fileInfo.filename}</Typography>
                    <Button
                        className={classes.button}
                        onClick={() => downloadFile(fileInfo.filename, value)}
                    >
                        Download
                    </Button>
                </>
            )}

            {(mainOptions.viewState === "edit" || mainOptions.viewState === "create") && (
                <>
                    <Divider />
                    <input
                        className={classes.input}
                        type="file"
                        ref={fileRef}
                        accept=".csv, .xlsx, .xlsm"
                    />
                    <Button className={classes.button} onClick={onSubmit}>
                        Upload
                    </Button>
                </>
            )}

            <ErrorDialog message={error} open={isOpened} onClose={() => setError(null)} />
        </>
    );
};
