import create from "zustand";
import { useUrl } from "../use-url";
import { TreeNode } from "../types";
import shallow from "zustand/shallow";

type TreeStore = {
    expandedItems: string[];
    selectedItem: string;
    toggleExpand: (id: string) => void;
    toggleSelect: (id: string) => void;
    openNodes: (ids: string[]) => void;
};

export const useTreeStore = create<TreeStore>((set, get) => ({
    expandedItems: [],
    selectedItem: "",

    toggleExpand: (id) => {
        const items = get().expandedItems;

        if (items.includes(id)) {
            const expandedItems = items.filter((item) => item !== id);
            set({ expandedItems });
        } else {
            const expandedItems = [...items, id];
            set({ expandedItems });
        }
    },
    openNodes: (ids) => {
        const oldItems = get().expandedItems;
        const newItems = [...new Set([...oldItems, ...ids])];
        set({ expandedItems: newItems });
    },
    toggleSelect: (selectedItem) => set({ selectedItem })
}));

export const useTreeHandlers = () =>
    useTreeStore(
        ({ toggleSelect, toggleExpand, openNodes }) => ({
            toggleSelect,
            toggleExpand,
            openNodes
        }),
        shallow
    );

export const useTreeView = () => {
    const expandedItems = useTreeStore((state) => state.expandedItems);
    const selectedItem = useTreeStore((state) => state.selectedItem);
    const { toggleSelect, toggleExpand } = useTreeHandlers();
    const { openItem } = useUrl();

    const onItemClick = (item: TreeNode) => {
        const { key, type, isEmpty } = item;

        if (!isEmpty) {
            toggleExpand(key);
        }
        toggleSelect(key);
        openItem({ id: key, type, viewState: "view" });
    };

    return { expandedItems, selectedItem, onItemClick };
};
