import React from "react";
import { getTranslation } from "@assay/shared";
import { makeStyles } from "@assay/ui-kit";
import { Typography, Box, IconButton } from "@material-ui/core";
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    pagination: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: theme.spacing(2)
    }
}));

type Props = {
    pageIndex: number;
    setPageIndex: (pageIndex: number) => void;
    totalPages: number;
};

export const ResultsHeader: React.FC<Props> = ({ pageIndex, setPageIndex, totalPages }) => {
    const classes = useStyles();
    const isLastPage = totalPages - 1 === pageIndex;
    const isFirstPage = pageIndex === 0;

    const pageInfo = `${pageIndex + 1}/${totalPages}`;

    return (
        <Box className={classes.header}>
            <Typography variant="h3">{getTranslation("results")}</Typography>

            <Box className={classes.pagination}>
                <IconButton disabled={isFirstPage} onClick={() => setPageIndex(0)}>
                    <FirstPage />
                </IconButton>
                <IconButton disabled={isFirstPage} onClick={() => setPageIndex(pageIndex - 1)}>
                    <ChevronLeft />
                </IconButton>
                <IconButton disabled={isLastPage} onClick={() => setPageIndex(pageIndex + 1)}>
                    <ChevronRight />
                </IconButton>
                <IconButton disabled={isLastPage} onClick={() => setPageIndex(totalPages - 1)}>
                    <LastPage />
                </IconButton>

                <Typography variant="body1">{pageInfo}</Typography>
            </Box>
        </Box>
    );
};
