import React from "react";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { Button, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { DebouncedTextField } from "@assay/ui-kit";
import { SheetDeleteButton } from "./sheet-delete-button";
import { RegularExpressionHint } from "../components";
import { useFileTabGridStyles } from "../hooks";
import { getTranslation } from "@assay/shared";

const selectState = ({
    mainFile,
    sheets,
    sheetsErrors,
    sheetsTouched,
    actions: { changeSheetName, addSheet, touchSheetName, changeSheetUseRegularExpression }
}: UploadTemplateStore) => ({
    sheets,
    sheetsErrors,
    sheetsTouched,
    changeSheetName,
    changeSheetUseRegularExpression,
    touchSheetName,
    isChangingCountEnable: !mainFile,
    addSheet
});

export const SheetsNamesEditor = (): React.ReactElement => {
    const classes = useFileTabGridStyles();
    const {
        sheets,
        changeSheetName,
        isChangingCountEnable,
        addSheet,
        touchSheetName,
        sheetsTouched,
        sheetsErrors,
        changeSheetUseRegularExpression
    } = useShallowUploadTemplateStore(selectState);

    const isSheetDeleteAllowed = isChangingCountEnable && sheets.length > 1;

    const getErrorText = (id: string): string => {
        if (sheetsTouched.get(id)) {
            return sheetsErrors.get(id) ?? "";
        }
        return "";
    };

    return (
        <>
            {sheets.map((sheet, index) => {
                const errorText = getErrorText(sheet.id);
                return (
                    <div className={classes.root} key={sheet.id}>
                        {index === 0 && (
                            <Typography className={classes.title}>{getTranslation("sheet-name")}</Typography>
                        )}

                        <div className={classes.inputWrapper}>
                            <DebouncedTextField
                                helperText={errorText}
                                error={!!errorText}
                                variant="outlined"
                                size={"medium"}
                                value={sheet.name}
                                onChangeValue={(value) => changeSheetName(index, value)}
                                onBlur={() => touchSheetName(sheet.id)}
                                className={classes.input}
                            />

                            {isSheetDeleteAllowed && (
                                <div>
                                    <SheetDeleteButton index={index} />
                                </div>
                            )}
                        </div>
                        <div className={classes.checkbox}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={sheet.useRegexp}
                                        onChange={(_, useRegexp) =>
                                            changeSheetUseRegularExpression(index, useRegexp)
                                        }
                                        name="Regular Expression"
                                    />
                                }
                                label={getTranslation("regular-expression")}
                            />
                            <RegularExpressionHint entity={getTranslation("sheet")} />
                        </div>
                    </div>
                );
            })}
            {isChangingCountEnable && (
                <div className={classes.root}>
                    <div className={classes.inputWrapper}>
                        <Button color="primary" onClick={addSheet}>
                            {`+ ${getTranslation("add-sheet")}`}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
