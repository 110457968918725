import { ensureArray, isDate, isInteger, isNumber, FormError, FormErrorTypes } from "../helpers";
import { getTranslation } from "@assay/shared";

const ERRORS = {
    notNumeric: "errors.field-must-contain-only-numbers",
    notInteger: "errors.field-must-be-integer",
    notDate: "errors.field-must-contain-data",
    fieldRequired: "errors.field-required",
    duplicateOptions: "errors.options-should-be-duplicated",
    emptyOptions: "errors.options-should-not-be-empty",
    longString: "errors.field-must-contain-less-symbols",
    onlySpaces: "errors.field-must-contain-only-symbols"
};

const isEmptySingleValue = (value) => value === null || value === undefined || value === "";

const isEmptyValue = (value) => {
    return (
        isEmptySingleValue(value) ||
        (Array.isArray(value) &&
            (value.length === 0 || (value.length === 1 && isEmptySingleValue(value[0]))))
    );
};

const commonValidations = {
    isNumber: {
        message: ERRORS.notNumeric,
        validator: isNumber
    },
    isInteger: {
        message: ERRORS.notInteger,
        validator: isInteger
    },
    isDate: {
        message: ERRORS.notDate,
        validator: isDate
    }
};

export const getSingleFieldError = (field) => {
    const fieldOptions = field.__pFieldOptions;

    if (fieldOptions.required && isEmptyValue(field.value)) {
        return getTranslation(ERRORS.fieldRequired);
    }

    if (fieldOptions.name === "options" || fieldOptions.type === "text" && fieldOptions.multi) {
        const options = ensureArray(field.value).map((item) => String(item).trim());

        if (options.length > [...new Set(options)].length) {
            return getTranslation(ERRORS.duplicateOptions);
        }
        if (options.length > 1 && options.some(isEmptyValue)) {
            return getTranslation(ERRORS.emptyOptions);
        }
    }

    if (fieldOptions.type === "text" && !fieldOptions.multi) {
        if (field.value?.length > 256) {
            return getTranslation(ERRORS.longString);
        } else if (
            field.value !== "" &&
            !Array.isArray(field.value) &&
            String(field.value).trim() === ""
        ) {
            return getTranslation(ERRORS.onlySpaces);
        }
    }

    if (fieldOptions.validation && field.value !== undefined) {
        const isExcelField =
            field.__pExcelOptions?.dataHeight > 1 || field.__pExcelOptions?.dataWidth > 1;

        for (let validation of fieldOptions.validation) {
            if (
                ["isNumber", "isInteger", "isDate"].includes(validation) &&
                !isEmptyValue(field.value)
            ) {
                let hasError;

                if (isExcelField || fieldOptions.multi) {
                    const { hasHeaders } = field.__pExcelOptions;
                    hasError = ensureArray(field.value).some(
                        (item, i) =>
                            (!hasHeaders || i > 0) && !commonValidations[validation].validator(item)
                    );
                } else {
                    hasError = !commonValidations[validation].validator(field.value);
                }

                if (hasError) {
                    return getTranslation(commonValidations[validation].message);
                }
            }
        }
    }
    return null;
};

/**
 * Throws on error
 */
export const validate = (fields, onError) => {
    for (let field of fields) {
        const { hidden, type, multi } = field.__pFieldOptions;

        if (hidden) {
            continue;
        }

        if (type === "fieldSet") {
            const innerFields = multi
                ? field.fields.reduce((arr, fields) => arr.concat(fields), [])
                : field.fields;
            validate(innerFields, onError);
        } else {
            if (getSingleFieldError(field)) {
                onError();
                throw new FormError(FormErrorTypes.FieldValidation);
            }
        }
    }
};

/**
 * Throws on error
 */
export const validateCopying = (name, parentType) => {
    if (name === "Assay" && parentType !== "Assay Group") {
        throw new FormError(FormErrorTypes.AssayCopying);
    } else if (name === "Protocol" && parentType !== "Assay") {
        throw new FormError(FormErrorTypes.ProtocolCopying);
    }
};
