import React, { useEffect, useMemo, useRef, useState } from "react";
import { useVirtual, VirtualItem } from "react-virtual";
import { sheetSizes } from "./values";
import { useTableCommonStyles, useTableContentStyles } from "./styles";
import { generateAlphabetNumeration } from "../../utils";
import { useMousePosition } from "./use-mouse-position";

type Props = {
    editMode: boolean;
    width: string;
    height: string;
    columnsLength: number;
    rowsLength: number;
    renderCell: (virtualRow: VirtualItem, virtualColumn: VirtualItem) => React.ReactElement;
    selectedColumns: number | undefined;
    selectedRows: number | undefined;
    handleScrollSelect: (direction: "left" | "right" | "top" | "bottom") => void;
};

export const SheetGrid = ({
    editMode,
    width,
    height,
    rowsLength,
    columnsLength,
    renderCell,
    selectedColumns,
    selectedRows,
    handleScrollSelect
}: Props): React.ReactElement => {
    const tableRef = useRef<HTMLTableElement>(null);

    const { clientX, clientY } = useMousePosition();

    const [isMouseDown, setIsMouseDown] = useState(false);

    useEffect(() => {
        const onMouseUp = () => setIsMouseDown(false);
        window.addEventListener("mouseup", onMouseUp);
        return () => {
            window.removeEventListener("mouseup", onMouseUp);
        };
    }, []);

    const [tableRect, setTableRect] = useState<DOMRect | undefined | null>(null);

    const tableWidth = tableRef.current?.clientWidth;
    const tableHeight = tableRef.current?.clientHeight;

    useEffect(() => {
        const rect = tableRef.current?.getBoundingClientRect();
        setTableRect(rect);
    }, [tableWidth, tableHeight]);

    const cellWidth = sheetSizes.width.base;
    const cellHeight = sheetSizes.height.base;

    const scrollingState = {
        left: tableRect ? clientX < tableRect.left + sheetSizes.width.rowTitle : false,
        right: tableRect ? clientX > tableRect.right : false,
        bottom: tableRect ? clientY > tableRect.bottom : false,
        top: tableRect ? clientY < tableRect.top + cellHeight : false
    };

    const isScrolling =
        scrollingState.left || scrollingState.right || scrollingState.top || scrollingState.bottom;

    const scrollTable = () => {
        if (!selectedColumns || !selectedRows || !isMouseDown) {
            return;
        }
        if (scrollingState.left) {
            const scrollSize = (selectedColumns - 2) * cellWidth;

            tableRef?.current?.scroll({ left: scrollSize, behavior: "smooth" });

            handleScrollSelect("left");
        }
        if (scrollingState.right) {
            const scrollSize = (selectedColumns - 1) * cellWidth;

            tableRef?.current?.scroll({ left: scrollSize, behavior: "smooth" });

            handleScrollSelect("right");
        }
        if (scrollingState.bottom) {
            const scrollSize = (selectedRows - 1) * cellHeight;

            tableRef?.current?.scroll({ top: scrollSize, behavior: "smooth" });

            handleScrollSelect("bottom");
        }
        if (scrollingState.top) {
            const scrollSize = (selectedRows - 2) * cellHeight;

            tableRef?.current?.scroll({ top: scrollSize, behavior: "smooth" });

            handleScrollSelect("top");
        }
    };

    useEffect(() => {
        scrollTable();
    }, [selectedColumns, selectedRows, isScrolling]); // eslint-disable-line

    const rowVirtualizer = useVirtual({
        size: rowsLength,
        parentRef: tableRef,
        estimateSize: React.useCallback(() => sheetSizes.height.base, [])
    });

    const columnVirtualizer = useVirtual({
        horizontal: true,
        size: columnsLength,
        parentRef: tableRef,
        estimateSize: React.useCallback(
            (index) => (index === 0 ? sheetSizes.width.rowTitle : sheetSizes.width.base),
            []
        )
    });

    const alphabetHeaders = useMemo(
        () => generateAlphabetNumeration(columnsLength),
        [columnsLength]
    );
    const tableClasses = useTableCommonStyles({ width, height });
    const cellClasses = useTableContentStyles(editMode);

    return (
        <div
            ref={tableRef}
            className={tableClasses.tableRoot}
            onMouseDown={() => setIsMouseDown(true)}
        >
            <div
                className={tableClasses.tableRelativeWrapper}
                style={{
                    height: `${rowVirtualizer.totalSize}px`,
                    width: `${columnVirtualizer.totalSize}px`
                }}
            >
                <div className={tableClasses.crossItemsWrapper}>
                    <div className={tableClasses.crossItemsPlaceholder} />
                </div>
                <div className={tableClasses.stickyRowWrapper}>
                    {rowVirtualizer.virtualItems.map((virtualRow) => (
                        <div
                            key={virtualRow.index}
                            className={cellClasses.titleCellStyle}
                            style={{
                                width: `${sheetSizes.width.rowTitle}px`,
                                transform: `translateX(0px) translateY(${virtualRow.start}px)`
                            }}
                        >
                            {virtualRow.index}
                        </div>
                    ))}
                </div>
                <div className={tableClasses.stickyColumnWrapper}>
                    {columnVirtualizer.virtualItems.map((virtualColumn) => (
                        <div
                            key={virtualColumn.index}
                            className={cellClasses.titleCellStyle}
                            style={{
                                width: `${sheetSizes.width.base}px`,
                                transform: `translateX(${virtualColumn.start}px) translateY(0px)`
                            }}
                        >
                            {virtualColumn.index
                                ? `${alphabetHeaders[virtualColumn.index - 1]}`
                                : ""}
                        </div>
                    ))}
                </div>
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                    <React.Fragment key={virtualRow.index}>
                        {columnVirtualizer.virtualItems.map((virtualColumn) => {
                            return (
                                <React.Fragment key={virtualColumn.index}>
                                    {renderCell(virtualRow, virtualColumn)}
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};
