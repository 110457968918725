import React from "react";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { makeStyles } from "@assay/ui-kit";
import { Field } from "./field";
import { AddParameterButton } from "./add-parameter-button";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(1)
        }
    }
}));

const selectState = (state: UploadTemplateStore) => ({
    fieldsLength: state.fields.length,
    addField: state.actions.addField
});

export const FieldsEditor = (): React.ReactElement => {
    const classes = useStyles();
    const { fieldsLength, addField } = useShallowUploadTemplateStore(selectState);

    const fieldsIterator = new Array(fieldsLength).fill(null);

    return (
        <div className={classes.root}>
            {fieldsIterator.map((_, fieldIndex) => (
                <Field key={fieldIndex} fieldIndex={fieldIndex} />
            ))}
            <AddParameterButton onClick={addField} />
        </div>
    );
};
