import { Color } from "@material-ui/core";
import { blueGrey, grey as greyMui } from "@material-ui/core/colors";
import { CommonColors, SimplePaletteColorOptions } from "@material-ui/core/styles/createPalette";

export const primary: SimplePaletteColorOptions = {
    light: "#DEF1FD",
    main: "#0071BC"
};

export const secondary: SimplePaletteColorOptions = {
    light: "#DEF1FD",
    main: "#2980B9"
};

const info: SimplePaletteColorOptions = {
    light: "#DAF5F2",
    main: "#069697"
};

const error: SimplePaletteColorOptions = {
    light: "#FEE4E2",
    main: "#EA3D2F"
};

const success: SimplePaletteColorOptions = {
    light: "#DCF7E3",
    main: "#2FA84F"
};

export const warning: SimplePaletteColorOptions = {
    light: "#FCF3D7",
    main: "#ED1C24"
};

export const blueGray = {
    light: "#E5EEF1",
    main: blueGrey[400],
    dark: "#78909C"
} as const;

export const common: CommonColors = {
    white: "#ffffff",
    black: "#000000"
};

export const grey: Color = {
    50: greyMui[50],
    100: "#F7F9FA",
    200: "#F1F3F4",
    300: "#E3E5E6",
    400: "#C6CACC",
    500: "#A6ADAF",
    600: "#919699",
    700: greyMui[700],
    800: "#5E6366",
    900: "#3E4345",
    A100: greyMui["A100"],
    A200: greyMui["A200"],
    A400: greyMui["A400"],
    A700: "#263238"
};

export const green = "#4EAE0B";

export const curve = {
    line: "#87B4E7",
    grid: "#bbb",
    dot: "#FFCC00",
    inActiveDot: "#ED1C24"
};

export const disabledButtonColor = "#C3D7FC";

export const palette = {
    primary,
    secondary,
    info,
    error,
    success,
    warning,
    grey,
    blueGray,
    common
};

export const cloningHighlightColors = {
    red: "#B43333",
    blue: "#6B9BF0"
};

export const insertionFragmentsColor = blueGray.light!;

export const searchResultColors = {
    main: palette.warning.main,
    active: "#FEE4E2",
    borderMain: palette.warning.main,
    borderActive: "#1E1E1E"
};

const stopCodonColor = error.main;
const startCodonColor = error.main;

const aminoAcids: Record<string, string> = {
    "M": startCodonColor,
    "*": stopCodonColor
};

const orfLetters = {
    forwardStrand: common.black,
    reverseStrand: blueGray.main,
    aminoAcids
};

const primer = "#7fa38f";

// colors are taken from here: https://en.wikipedia.org/wiki/DNA_codon_table
const nonPolarProtein = "#ffe75f";
const polarProtein = "#b3dec0";
const basicProtein = "#bbbfe0";
const acidicProtein = "#f8b7d3";
const defaultProteinColor = "#B0B0B0";

const translatedProteins: Record<string, string> = {
    "F": nonPolarProtein,
    "L": nonPolarProtein,
    "I": nonPolarProtein,
    "M": nonPolarProtein,
    "V": nonPolarProtein,
    "P": nonPolarProtein,
    "A": nonPolarProtein,
    "W": nonPolarProtein,
    "G": nonPolarProtein,

    "S": polarProtein,
    "T": polarProtein,
    "Y": polarProtein,
    "Q": polarProtein,
    "N": polarProtein,
    "C": polarProtein,

    "H": basicProtein,
    "K": basicProtein,
    "R": basicProtein,

    "D": acidicProtein,
    "E": acidicProtein,

    "*": defaultProteinColor
};

const translatedProteinsProxy = new Proxy(translatedProteins, {
    get(target, property) {
        if (property in target) {
            return target[String(property)];
        }

        return defaultProteinColor;
    }
});

const ORFShape = secondary.main;

const bases = {
    highlight: "rgba(0, 50, 80, 0.3)"
};

export const mapColors = {
    background: grey[100],
    cut: grey[900],
    translatedProteins: translatedProteinsProxy,
    orfLetters,
    ORFShape,
    primer,
    bases
};
