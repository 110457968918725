import React from "react";

import { EditNameField, MainFile, Attachments } from "./index";

import { SettingsTab } from "../components";

export const SettingsFilesTab = () => {
    return (
        <SettingsTab>
            <>
                <EditNameField />
                <MainFile />
                <Attachments />
            </>
        </SettingsTab>
    );
};
