import {
    DataSheetMatrix,
    DocumentSheet,
    Position,
    SelectedSheetMatrix,
    SelectType,
    TemplateElement,
    TemplateRange,
    UploadTemplateField
} from "../types";
import produce from "immer";

export const headersLength = 1;

const checkRegexp = (value?: string, regexpStr?: string | null): boolean => {
    if (!regexpStr || !value) {
        return false;
    }

    try {
        const re = new RegExp(regexpStr);
        return !!re.exec(value);
    } catch (err) {
        return false;
    }
};

type ConvertSimplyRuleParams = {
    matrix: SelectedSheetMatrix[];
    startRow: number;
    startColumn: number;
    sheets: DocumentSheet[];
    sheet: number;
    startRegexpValue?: string;
    endRegexpValue?: string;
    startRegexPositions?: Position;
    endRegexPositions?: Position;
    isMoveDown: boolean;
    isMoveRight: boolean;
    isDisplayOnEmptyCells: boolean;
    rightRegexpStep?: number;
    downRegexpStep?: number;
};

const isEmptyCell = (value?: unknown): boolean =>
    value === undefined || value === null || value === "";

type RegexpOffsetsParams = {
    startRegexPositions: Position | undefined;
    startRow: number;
    endRegexPositions: Position | undefined;
    startColumn: number;
};

const getRegexpOffsets = ({
    startRow,
    startColumn,
    startRegexPositions,
    endRegexPositions
}: RegexpOffsetsParams) => {
    const rowStartOffset =
        startRegexPositions && !isNaN(startRegexPositions.row)
            ? startRegexPositions.row - startRow
            : 0;

    const rowEndOffset =
        endRegexPositions && !isNaN(endRegexPositions.row) ? endRegexPositions.row - startRow : 0;

    const columnStartOffset =
        startRegexPositions && !isNaN(startRegexPositions.column)
            ? startRegexPositions.column - startColumn
            : 0;

    const columnsEndOffset =
        endRegexPositions && !isNaN(endRegexPositions.column)
            ? endRegexPositions.column - startColumn
            : 0;
    return { rowStartOffset, rowEndOffset, columnStartOffset, columnsEndOffset };
};

export const convertSimplyRuleToMatrix = ({
    matrix,
    startColumn,
    isMoveRight,
    isMoveDown,
    startRow,
    sheets,
    sheet,
    startRegexpValue,
    endRegexpValue,
    startRegexPositions,
    endRegexPositions,
    isDisplayOnEmptyCells,
    rightRegexpStep = 0,
    downRegexpStep = 0
}: ConvertSimplyRuleParams) =>
    produce(matrix, (draft) => {
        let row = startRow;
        let column = startColumn;
        let isStartedByRegexp = !startRegexpValue;
        let inProcess = true;

        const { rowStartOffset, rowEndOffset, columnStartOffset, columnsEndOffset } =
            getRegexpOffsets({ startRegexPositions, startRow, endRegexPositions, startColumn });

        if (!isMoveRight && !isMoveDown) {
            return matrix;
        }

        const sheetRawData = sheets[sheet].rawData;

        const { rightAddonBySteps, downAddonBySteps } = findFloatingStartAddons({
            downRegexpStep,
            rightRegexpStep,
            minRow: startRow + headersLength,
            minColumn: startColumn + headersLength,
            startRegexpValue,
            dataMatrix: sheetRawData,
            columnStartOffset,
            rowStartOffset
        });

        row += downAddonBySteps;
        column += rightAddonBySteps;

        const columnCount = findColumnsLength(sheetRawData);

        const isSheetEnd = () =>
            sheet >= sheets.length || row >= sheetRawData.length || column >= columnCount;

        while (inProcess && !isSheetEnd()) {
            const valueInCell = sheetRawData[row][column];

            const valueInCellForStartRegExp =
                sheetRawData[row + rowStartOffset]?.[column + columnStartOffset];

            const valueInCellForEndRegExp =
                sheetRawData[row + rowEndOffset]?.[column + columnsEndOffset];

            if (!isStartedByRegexp) {
                isStartedByRegexp = checkRegexp(valueInCellForStartRegExp, startRegexpValue);
                if (isStartedByRegexp) {
                    draft[sheet][row + rowStartOffset + headersLength][
                        column + columnStartOffset + headersLength
                    ] = SelectType.RegExp;
                }
            }

            const isEndedByRegexp = checkRegexp(valueInCellForEndRegExp, endRegexpValue);

            if (isEndedByRegexp) {
                draft[sheet][row + rowEndOffset + headersLength][
                    column + columnsEndOffset + headersLength
                ] = SelectType.RegExp;
            }

            const isPresentOnThisCell =
                (!isEmptyCell(valueInCell) || isDisplayOnEmptyCells) && !isEndedByRegexp;

            if (isPresentOnThisCell) {
                if (isStartedByRegexp) {
                    draft[sheet][row + headersLength][column + headersLength] =
                        SelectType.DisabledPrimarySelected;
                }

                if (isMoveDown) {
                    row += 1;
                }
                if (isMoveRight) {
                    column += 1;
                }
            } else {
                inProcess = false;
            }
        }
    });

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export function generateAlphabetNumeration(length: number): string[] {
    const result = [];
    for (let i = 0; i < length; i += 1) {
        result.push(numberToLetters(i));
    }
    return result;
}

export function numberToLetters(num: number): string {
    let letters = "";
    while (num >= 0) {
        letters = alphabet[num % 26] + letters;
        num = Math.floor(num / 26) - 1;
    }
    return letters;
}

const lettersToNumber = (letters: string): number => {
    let num = 0;
    const reverseLetters = letters.split("").reverse();
    reverseLetters.forEach((char, i) => {
        num += (alphabet.indexOf(char.toUpperCase()) + 1) * Math.pow(26, i);
    });
    return num;
};

export const stringPositionToPosition = (string: string): Position | undefined => {
    const row = Number(string.match(/^\d+|\d+\b|\d+(?=\w)/g)?.[0]);
    const columnLetters = string.replaceAll(row.toString(), "");
    const column = columnLetters ? lettersToNumber(columnLetters) : NaN;
    if (isNaN(row) && isNaN(column)) {
        return undefined;
    }
    return {
        row: row - 1,
        column: column - 1
    };
};

export const positionToStringPosition = (position: Position): string =>
    `${isNaN(position.column) ? "" : numberToLetters(position.column)}${
        isNaN(position.row) ? "" : position.row + 1
    }`;

export function createEmptySheetMatrix(
    rowsLength: number,
    columnsLength: number
): SelectedSheetMatrix {
    const row = new Array(columnsLength).fill(SelectType.NotSelected);

    return Array.from(Array(rowsLength), () => [...row]) as SelectedSheetMatrix;
}

type MinMaxValues = {
    minRow: number;
    maxRow: number;
    minColumn: number;
    maxColumn: number;
};

export const getMinMaxValuesFromTemplate = (template?: TemplateElement[] | null): MinMaxValues =>
    (template ?? []).reduce(
        (prev: MinMaxValues, element) => ({
            minRow: Math.min(prev.minRow, element.position.row),
            maxRow: Math.max(prev.maxRow, element.position.row),
            minColumn: Math.min(prev.minColumn, element.position.column),
            maxColumn: Math.max(prev.maxColumn, element.position.column)
        }),
        {
            minRow: Number.MAX_SAFE_INTEGER,
            maxRow: 0,
            minColumn: Number.MAX_SAFE_INTEGER,
            maxColumn: 0
        }
    );

type ConvertTemplateToMatrixParams = {
    templateRange: TemplateRange;
    matrixRowsLength?: number;
    matrixColumnLength?: number;
    withHeaders?: boolean;
    collapse?: boolean;
    existMatrix?: SelectedSheetMatrix;
    dataMatrix?: DataSheetMatrix;
    startRegexpValue?: string;
    endRegexpValue?: string;
    startRegexPositions?: Position;
    endRegexPositions?: Position;
    isDisplayOnEmptyCells: boolean;
    rightRegexpStep?: number;
    downRegexpStep?: number;
};

type FindFloatingStartAddonsParams = {
    rightRegexpStep: number;
    downRegexpStep: number;
    minRow: number;
    minColumn: number;
    dataMatrix?: DataSheetMatrix;
    rowStartOffset: number;
    columnStartOffset: number;
    startRegexpValue?: string;
};

const findFloatingStartAddons = ({
    downRegexpStep,
    rightRegexpStep,
    startRegexpValue,
    minRow,
    minColumn,
    dataMatrix,
    columnStartOffset,
    rowStartOffset
}: FindFloatingStartAddonsParams) => {
    let rightAddonBySteps = 0;
    let downAddonBySteps = 0;
    let isFoundBySteps = false;

    if (rightRegexpStep || downRegexpStep) {
        const startRowIndex = minRow + rowStartOffset - headersLength;
        const startColumnIndex = minColumn + columnStartOffset - headersLength;

        let rowIndex = startRowIndex;
        let isRowIterationEnable = true;
        let isColumnIterationEnable = true;

        while (isRowIterationEnable) {
            const row = dataMatrix?.[rowIndex];
            let columnIndex = startColumnIndex;
            while (isColumnIterationEnable) {
                const value = row?.[columnIndex];
                isFoundBySteps = checkRegexp(value, startRegexpValue);
                if (isFoundBySteps) {
                    rightAddonBySteps = columnIndex - startColumnIndex;
                    downAddonBySteps = rowIndex - startRowIndex;
                }

                columnIndex += rightRegexpStep;
                if (
                    columnIndex > (row?.length ?? 0) ||
                    columnIndex < 0 ||
                    isFoundBySteps ||
                    !rightRegexpStep
                ) {
                    isColumnIterationEnable = false;
                }
            }

            rowIndex += downRegexpStep;
            if (
                rowIndex > (dataMatrix?.length ?? 0) ||
                rowIndex < 0 ||
                isFoundBySteps ||
                !downRegexpStep
            ) {
                isRowIterationEnable = false;
            }
        }
    }
    return { rightAddonBySteps, downAddonBySteps };
};

export function convertTemplateToMatrix({
    matrixRowsLength,
    matrixColumnLength,
    templateRange,
    withHeaders = true,
    collapse = false,
    existMatrix,
    dataMatrix,
    startRegexPositions,
    endRegexPositions,
    startRegexpValue,
    endRegexpValue,
    isDisplayOnEmptyCells,
    rightRegexpStep = 0,
    downRegexpStep = 0
}: ConvertTemplateToMatrixParams): SelectedSheetMatrix {
    const templateWithHeaders: TemplateElement[] | null | undefined = withHeaders
        ? templateRange.template?.map((element) => ({
              ...element,
              position: {
                  row: element.position.row + headersLength,
                  column: element.position.column + headersLength
              }
          }))
        : templateRange.template;

    const minMaxValuesWithHeaders = getMinMaxValuesFromTemplate(templateWithHeaders);

    const template: TemplateElement[] | null | undefined = collapse
        ? templateWithHeaders?.map((element) => ({
              ...element,
              position: {
                  row: element.position.row - minMaxValuesWithHeaders.minRow,
                  column: element.position.column - minMaxValuesWithHeaders.minColumn
              }
          }))
        : templateWithHeaders;
    const minMaxValues = collapse ? getMinMaxValuesFromTemplate(template) : minMaxValuesWithHeaders;

    const { rowStartOffset, rowEndOffset, columnStartOffset, columnsEndOffset } = getRegexpOffsets({
        startColumn: minMaxValues.minColumn - headersLength,
        startRow: minMaxValues.minRow - headersLength,
        startRegexPositions,
        endRegexPositions
    });

    const { rightAddonBySteps, downAddonBySteps } = findFloatingStartAddons({
        minRow: minMaxValues.minRow,
        minColumn: minMaxValues.minColumn,
        columnStartOffset,
        dataMatrix,
        rightRegexpStep,
        startRegexpValue,
        downRegexpStep,
        rowStartOffset
    });

    const rowsSize = matrixRowsLength ?? minMaxValues.maxRow - minMaxValues.minRow + 1;

    const columnsSize = matrixColumnLength ?? minMaxValues.maxColumn - minMaxValues.minColumn + 1;

    const matrix = existMatrix ?? createEmptySheetMatrix(rowsSize, columnsSize);

    const rowsInTemplate = minMaxValues.maxRow - minMaxValues.minRow + 1;

    const columnsInTemplate = minMaxValues.maxColumn - minMaxValues.minColumn + 1;

    const columnLimit = columnsSize - minMaxValues.maxColumn - 1;
    const rowLimit = rowsSize - minMaxValues.maxRow - 1;

    const repeatRightLimit =
        templateRange.repeat.right || templateRange.repeat.diagonal
            ? templateRange.repeatAll
                ? columnLimit + columnsInTemplate
                : Math.min(columnsInTemplate * templateRange.repeatCount - 1, columnLimit)
            : 0;

    const repeatDownLimit =
        templateRange.repeat.down || templateRange.repeat.diagonal
            ? templateRange.repeatAll
                ? rowLimit + rowsInTemplate
                : Math.min(rowsInTemplate * templateRange.repeatCount - 1, rowLimit)
            : 0;

    const templateRangeIterator = (callback: (rowAddon: number, columnAddon: number) => void) => {
        if (templateRange.repeat.diagonal) {
            let rowAddon = 0;
            let columnAddon = 0;
            while (rowAddon <= repeatDownLimit && columnAddon <= repeatRightLimit) {
                callback(rowAddon, columnAddon);

                rowAddon += rowsInTemplate;
                columnAddon += columnsInTemplate;
            }
        } else {
            for (let rowAddon = 0; rowAddon <= repeatDownLimit; rowAddon += rowsInTemplate) {
                for (
                    let columnAddon = 0;
                    columnAddon <= repeatRightLimit;
                    columnAddon += columnsInTemplate
                ) {
                    callback(rowAddon, columnAddon);
                }
            }
        }
    };

    const firstRowInTemplate =
        templateWithHeaders?.reduce(
            (prev, item) => Math.min(prev, item.position.row),
            Number.MAX_SAFE_INTEGER
        ) ?? 0;
    const firstColumnInTemplate =
        templateWithHeaders?.reduce(
            (prev, item) => Math.min(prev, item.position.column),
            Number.MAX_SAFE_INTEGER
        ) ?? 0;

    const isFirstElement = (element: TemplateElement) =>
        element.position.row === firstRowInTemplate &&
        element.position.column === firstColumnInTemplate;

    const findEndDataLimits = () => {
        let endRightLimit = columnsSize;
        let downEndLimitInit = false;
        let endDownLimit = rowsSize;

        const updateLimitsHelper = (rowAddon: number, columnAddon: number): void => {
            template?.forEach((element) => {
                const currentRowIndex = element.position.row + rowAddon + downAddonBySteps;
                const currentColumnIndex =
                    element.position.column + columnAddon + rightAddonBySteps;
                const valueInCell =
                    dataMatrix?.[currentRowIndex - headersLength]?.[
                        currentColumnIndex - headersLength
                    ];

                const valueInCellForEndRegexp =
                    dataMatrix?.[currentRowIndex + rowEndOffset - headersLength]?.[
                        currentColumnIndex + columnsEndOffset - headersLength
                    ];

                const isEndedByRegexp =
                    checkRegexp(valueInCellForEndRegexp, endRegexpValue) && isFirstElement(element);

                if (
                    isEndedByRegexp &&
                    matrix[currentRowIndex + rowEndOffset]?.[
                        currentColumnIndex + columnsEndOffset
                    ] !== undefined
                ) {
                    matrix[currentRowIndex + rowEndOffset][currentColumnIndex + columnsEndOffset] =
                        SelectType.RegExp;
                }

                const isLimitFound =
                    dataMatrix &&
                    ((isEmptyCell(valueInCell) && element.isSelected && !isDisplayOnEmptyCells) ||
                        isEndedByRegexp);

                if (isLimitFound) {
                    const isRightLimit =
                        currentColumnIndex !== minMaxValues.minColumn &&
                        !downEndLimitInit &&
                        templateRange.repeat.right;

                    if (isRightLimit) {
                        endRightLimit = Math.min(currentColumnIndex, endRightLimit);
                    } else if (templateRange.repeat.down || templateRange.repeat.diagonal) {
                        endDownLimit = Math.min(currentRowIndex, endDownLimit);
                        downEndLimitInit = true;
                    }
                }
            });
        };

        templateRangeIterator(updateLimitsHelper);

        return { endRightLimit, endDownLimit };
    };

    const { endRightLimit, endDownLimit } = findEndDataLimits();

    let isStartedByRegexp = !startRegexpValue;
    const displayAddonsOnMatrix = (rowAddon: number, columnAddon: number): void => {
        template?.forEach((element) => {
            const currentRowIndex = element.position.row + rowAddon + downAddonBySteps;
            const currentColumnIndex = element.position.column + columnAddon + rightAddonBySteps;
            if (currentRowIndex >= endDownLimit || currentColumnIndex >= endRightLimit) return;

            const startRegexpRowIndex = currentRowIndex + rowStartOffset - headersLength;
            const endRegexpColumnIndex = currentColumnIndex + columnStartOffset - headersLength;

            const valueInCellForStartRegexp =
                dataMatrix?.[startRegexpRowIndex]?.[endRegexpColumnIndex];

            if (!isStartedByRegexp && isFirstElement(element)) {
                isStartedByRegexp = checkRegexp(valueInCellForStartRegexp, startRegexpValue);

                if (
                    isStartedByRegexp &&
                    matrix[currentRowIndex + rowStartOffset]?.[
                        currentColumnIndex + columnStartOffset
                    ] !== undefined
                ) {
                    matrix[currentRowIndex + rowStartOffset][
                        currentColumnIndex + columnStartOffset
                    ] = SelectType.RegExp;
                }
            }

            if (matrix[currentRowIndex]?.[currentColumnIndex] !== undefined && isStartedByRegexp) {
                matrix[currentRowIndex][currentColumnIndex] = element.isSelected
                    ? SelectType.DisabledPrimarySelected
                    : SelectType.SkippedSelected;
            }
        });
    };

    templateRangeIterator(displayAddonsOnMatrix);

    return matrix;
}

export function findColumnsLength(data: unknown[][]): number {
    let maxValue = 0;
    const countArr = (data ?? []).map((row) => (row ?? []).length);
    countArr.forEach((item) => {
        //we need to calculate by this way because Math.max(...array) will be failed with a large arrays
        maxValue = Math.max(item, maxValue);
    });
    return maxValue;
}

export const isCustomField = ({
    headerPosition,
    dataRange: { simpleRule }
}: UploadTemplateField): boolean =>
    !headerPosition ||
    headerPosition.row < 0 ||
    headerPosition.column < 0 ||
    (!simpleRule?.right && !simpleRule?.down && !simpleRule?.diagonal);

export type PrepareSheetsReplacementResult = {
    field: UploadTemplateField;
    isConsistent: boolean;
};

const prepareBaseFieldForSheetReplacement = (
    field: UploadTemplateField,
    existSheets: DocumentSheet[],
    newSheets: DocumentSheet[]
): PrepareSheetsReplacementResult => {
    const result: PrepareSheetsReplacementResult = {
        field: { ...field },
        isConsistent: true
    };
    const newSheet = newSheets.find((sheet) =>
        sheet.useRegexp
            ? checkRegexp(sheet.name, field.sheet.name)
            : sheet.name === field.sheet.name
    );

    if (!newSheet) {
        result.isConsistent = false;
        return result;
    }

    const maxRow = newSheet.rawData.length - 1;
    const maxColumn = findColumnsLength(newSheet.rawData) - 1;

    result.field.sheet = {
        id: newSheet.id,
        number: newSheet.number,
        name: newSheet.name
    };

    const isAggregation = !!field.aggregationFields;

    if (isAggregation) {
        return result;
    }

    const isCustomParameter = isCustomField(field);

    if (!isCustomParameter) {
        const { headerPosition } = field;
        if (!headerPosition || headerPosition.row > maxRow || headerPosition.column > maxColumn) {
            result.isConsistent = false;
        }

        return result;
    }

    const { range } = field.dataRange;

    if (!range || !range.template) {
        result.isConsistent = false;
        return result;
    }

    const hasInConsistentElements = range.template.some(
        (element) => element.position.row > maxRow || element.position.column > maxColumn
    );

    if (hasInConsistentElements) {
        result.isConsistent = false;
        return result;
    }

    return result;
};

const prepareAggregationFieldForSheetReplacement = (
    prevResult: PrepareSheetsReplacementResult,
    baseResults: PrepareSheetsReplacementResult[]
): PrepareSheetsReplacementResult => {
    const result: PrepareSheetsReplacementResult = {
        ...prevResult
    };

    const isAggregation = !!prevResult.field.aggregationFields;

    if (!isAggregation) {
        return result;
    }

    const hasInconsistentBase = baseResults.some(
        (baseResult) =>
            !baseResult.isConsistent &&
            prevResult.field.aggregationFields?.find((option) => option.id === baseResult.field.id)
    );

    if (hasInconsistentBase) {
        result.isConsistent = false;
    }

    return result;
};

export const prepareSheetsReplacement = (
    fields: UploadTemplateField[],
    existSheets: DocumentSheet[],
    newSheets: DocumentSheet[]
): PrepareSheetsReplacementResult[] => {
    const basePrepared = fields.map((field) =>
        prepareBaseFieldForSheetReplacement(field, existSheets, newSheets)
    );

    return basePrepared.map((item) =>
        prepareAggregationFieldForSheetReplacement(item, basePrepared)
    );
};
