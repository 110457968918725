import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { Fragment, useEffect, useState } from "react";
import { useQueries } from "react-query";
import { getAllowedChildren, getForm, getUsers } from "./api-v2";
import { CopyFormLayout, DialogRoot } from "./copy-dialogs";
import { ContentTypes } from "./types";
import { getOriginFieldByName } from "./form/form-utils";
import { getAppConfig, getDateformat, getLocale, getTranslation } from "@assay/shared";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const OptionsSelect = ({ label, options, ...props }) => {
    return (
        <FormControl {...props}>
            <InputLabel>{label}</InputLabel>
            <Select {...props}>
                {options.map(([value, name]) => {
                    return (
                        <MenuItem key={value} value={value}>
                            {name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export const AddEntityForm = ({ onSubmit, addTypesOptions, usersOptions, ...props }) => {
    const [date, setDate] = useState(new Date());

    const [userId, setUserId] = useState(usersOptions[0][0] ?? null);
    const [addType, setAddType] = useState(addTypesOptions[0]?.[0] ?? null);

    return (
        <Box
            component="form"
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit({ date, userId, addType });
            }}
        >
            <CopyFormLayout
                fields={
                    <Fragment>
                        {addTypesOptions.length > 0 && (
                            <OptionsSelect
                                fullWidth
                                value={addType}
                                onChange={(e) => setAddType(e.target.value)}
                                options={addTypesOptions}
                                label={getTranslation("select-type-to-add")}
                                {...props}
                            />
                        )}

                        {getAppConfig().assayConfigHeader.isSupport && (
                            <Fragment>
                                {usersOptions.length > 0 && (
                                    <OptionsSelect
                                        fullWidth
                                        value={userId}
                                        onChange={(e) => setUserId(e.target.value)}
                                        options={usersOptions}
                                        label={getTranslation("select-user")}
                                        {...props}
                                    />
                                )}

                                <Box marginTop={2}>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={getLocale()}
                                    >
                                        <DatePicker
                                            fullWidth
                                            format={getDateformat()}
                                            value={date}
                                            onChange={setDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                            </Fragment>
                        )}
                    </Fragment>
                }
                actions={
                    <Button type="submit" color="primary">
                        OK
                    </Button>
                }
            />
        </Box>
    );
};

const getLoadAddTypeFn = (type) => {
    switch (type) {
        case ContentTypes.Ut:
            //fake permission check till we don't have another
            return async () => new Promise((resolve) => resolve([["ut", "Upload Template"]]));
        default:
            return async (entityId) => {
                const addTypeIds = await getAllowedChildren(entityId);

                if (addTypeIds.length === 0) {
                    return [];
                }

                const addTypeTexts = await Promise.all(
                    addTypeIds.map(async (id) => {
                        const formData = await getForm(id);
                        const addTypeText = getOriginFieldByName(formData, "name").value;
                        return [id, addTypeText];
                    })
                );

                return addTypeTexts;
            };
    }
};

export const AddDialog = ({
    entityId,
    onClose,
    formProps = {},
    open = true,
    type,
    ...dialogProps
}) => {
    const loadTypesFn = getLoadAddTypeFn(type);

    const queries = useQueries([
        {
            queryKey: "addDialogUsers",
            queryFn: getUsers
        },
        {
            queryKey: ["addTypes", entityId],
            queryFn: () => loadTypesFn(entityId)
        }
    ]);

    const [usersQuery, addTypesQuery] = queries;

    const isLoading = Object.values(queries).some((query) => query.isLoading);

    useEffect(() => {
        if (addTypesQuery.data?.length === 0) {
            // Throw up an error message to make sure its obvious when a user isn't allowed to add to an object.
            alert(getTranslation("do-not-have-permission-to-add-to-this-object"));
            onClose();
        }
    }, [addTypesQuery.data]);

    return (
        <DialogRoot onClose={onClose} open={open} {...dialogProps}>
            {isLoading ? (
                <Box height="150px" display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <AddEntityForm
                    addTypesOptions={addTypesQuery.data ?? []}
                    usersOptions={usersQuery.data ?? []}
                    {...formProps}
                    onSubmit={async ({ userId, addType, date }) => {
                        const [id, userName] = usersQuery.data?.find(([id]) => id === userId);
                        const isoDateString = date.toISOString();

                        const payload = {
                            date: isoDateString,
                            user: { id, userName }
                        };

                        //we will delete this check when change all data to new models
                        if (type !== ContentTypes.Ut) {
                            payload.addType = addType;
                        }

                        await formProps.onSubmit(payload);

                        onClose();
                    }}
                />
            )}
        </DialogRoot>
    );
};
