import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
    labelWrapper: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        width: ".65em",
        height: ".65em",
        marginRight: "8px"
    },
    label: {
        width: "150px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
});
