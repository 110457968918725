import { Option, TemplateRange, UploadTemplate, UploadTemplateFieldTouched } from "./types";
import { validateUploadTemplateName } from "./validate";

export const FT_MODE_ONE_VALUE_ID = 1;

export const FT_MODE_OPTIONS: Option[] = [
    { id: 0, name: "Default" },
    { id: FT_MODE_ONE_VALUE_ID, name: "One Value" }
];

export const DEFAULT_DECIMAL_PLACES = 4;

export const EMPTY_UPLOAD_TEMPLATE: UploadTemplate = {
    id: null,
    fileName: "",
    useFileNameRegexp: false,
    name: validateUploadTemplateName({
        value: "",
        error: null
    }),
    sheets: [],
    fields: [],
    attachments: [],
    mainFile: null,
    isDisplayOnEmptyCells: false,
    sheetsErrors: new Map<string, string>(),
    sheetsTouched: new Map<string, boolean>(),
    canEdit: false,
    canDelete: false
};

export const EMPTY_FIELD_TOUCHED: UploadTemplateFieldTouched = {
    resultDefinition: false,
    name: false,
    startRegexPositions: false,
    endRegexPositions: false,
    endRegexpValue: false,
    startRegexpValue: false,
    startRegexStepDown: false,
    startRegexStepRight: false
};

export const FILES_TAB_INDEX = 0;
export const PROPERTIES_TAB_INDEX = 1;

export const NOT_EXIST_COORDINATE = -1;

export const MIN_COLUMNS_COUNT = 26;
export const MIN_ROWS_COUNT = 1000;

export const EMPTY_TEMPLATE_RANGE: TemplateRange = {
    repeatCount: 0,
    template: [],
    repeat: {
        down: true,
        diagonal: false,
        right: false
    },
    reverse: false,
    isMulti: false,
    repeatAll: true
};
