import {
    DocumentSheet,
    UploadTemplateField,
    UploadTemplateFieldErrors,
    UploadTemplateName
} from "./types";
import { isCustomField } from "./utils";
import { getTranslation } from "@assay/shared";

export const getFieldsWithValidConcatenation = (
    fields: UploadTemplateField[]
): UploadTemplateField[] =>
    fields.map((field) => {
        const newField = { ...field };
        if (!newField.aggregationFields) {
            return newField;
        }
        newField.aggregationFields = newField.aggregationFields.filter((aggField) =>
            fields.find((f) => f.id === aggField.id)
        );
        return newField;
    });

export const maxStringFieldLength = 256;

const validateStringField = (value: string, name: string): string | undefined => {
    if (!value) {
        return `${name} is required`;
    }
    if (value.length > maxStringFieldLength) {
        return `${name} is too long`;
    }
};

export const validateUploadTemplateName = (name: UploadTemplateName): UploadTemplateName => ({
    ...name,
    error: validateStringField(name.value, "Template name")
});

export const validateSingleField = (field: UploadTemplateField): UploadTemplateFieldErrors => {
    const { name, dataRange, resultDefinitionId, aggregationFields } = field;
    const { range } = dataRange;

    const isCustomParameter = isCustomField(field);

    const errors: UploadTemplateFieldErrors = {
        name: validateStringField(name, "Name")
    };

    if (aggregationFields) {
        if (aggregationFields.length === 0) {
            errors.aggregationFields = getTranslation("errors.concatenate-is-required");
        } else if (aggregationFields.length !== 2) {
            errors.aggregationFields = getTranslation("errors.should-be-two-fields");
        }
    } else {
        if (!resultDefinitionId) {
            errors.resultDefinition = getTranslation("errors.result-definition-is-required");
        }
        if (isCustomParameter && range === null) {
            errors.dataRange = getTranslation("errors.range-is-required-for-custom-parameter");
        }
    }

    if (field.startRegexpValue) {
        if (!field.startRegexPositions) {
            errors.startRegexPositions = getTranslation("required");
        }

        if (field.startRegexStepRight === undefined) {
            errors.startRegexStepRight = getTranslation("required");
        }

        if (field.startRegexStepDown === undefined) {
            errors.startRegexStepDown = getTranslation("required");
        }

        if (field.startRegexStepRight === 0 && field.startRegexStepDown === 0) {
            errors.startRegexStepRight = getTranslation("both-steps-shouldnt-be-0");
            errors.startRegexStepDown = getTranslation("both-steps-shouldnt-be-0");
        }
    }

    if (field.endRegexpValue && !field.endRegexPositions) {
        errors.endRegexPositions = getTranslation("required");
    }

    if (field.startRegexpValue) {
        try {
            new RegExp(field.startRegexpValue);
        } catch (err) {
            errors.startRegexpValue = getTranslation("invalid-regexp");
        }
    }

    if (field.endRegexpValue) {
        try {
            new RegExp(field.endRegexpValue);
        } catch (err) {
            errors.endRegexpValue = getTranslation("invalid-regexp");
        }
    }

    if (
        field.startRegexPositions &&
        (isNaN(field.startRegexPositions.row) ||
            isNaN(field.startRegexPositions.column) ||
            field.startRegexPositions.row < 0 ||
            field.startRegexPositions.column < 0)
    ) {
        errors.startRegexPositions = getTranslation("invalid");
    }

    if (
        field.endRegexPositions &&
        (isNaN(field.endRegexPositions.row) ||
            isNaN(field.endRegexPositions.column) ||
            field.endRegexPositions.row < 0 ||
            field.endRegexPositions.column < 0)
    ) {
        errors.endRegexPositions = getTranslation("invalid");
    }

    return errors;
};

export const validateMultipleFields = (
    fields: UploadTemplateField[]
): UploadTemplateFieldErrors[] => {
    const nameDuplicates = new Map<string, boolean>();
    const rdDuplicates = new Map<string, boolean>();

    fields.forEach((field) => {
        if (nameDuplicates.has(field.name)) {
            nameDuplicates.set(field.name, true);
        } else {
            nameDuplicates.set(field.name, false);
        }
        if (field.resultDefinitionId) {
            if (rdDuplicates.has(field.resultDefinitionId)) {
                rdDuplicates.set(field.resultDefinitionId, true);
            } else {
                rdDuplicates.set(field.resultDefinitionId, false);
            }
        }
    });

    return fields.map((field) => ({
        name: nameDuplicates.get(field.name)
            ? getTranslation("errors.name-must-be-unique")
            : undefined,
        resultDefinition:
            field.resultDefinitionId && rdDuplicates.get(field.resultDefinitionId)
                ? getTranslation("errors.must-be-unique")
                : undefined
    }));
};

export const getValidatedFields = (fields: UploadTemplateField[]): UploadTemplateField[] => {
    const clearFields = getFieldsWithValidConcatenation(fields);

    const multipleFieldsErrors = validateMultipleFields(clearFields);

    const makeErrorsObject = (index: number) => {
        const field = clearFields[index];
        const singleErrors = validateSingleField(field);
        return {
            ...singleErrors,
            name: multipleFieldsErrors[index].name ?? singleErrors.name,
            resultDefinition:
                multipleFieldsErrors[index].resultDefinition ?? singleErrors.resultDefinition
        };
    };

    return clearFields.map((field, index) => ({
        ...field,
        errors: makeErrorsObject(index)
    }));
};

export const validateSheets = (sheets: DocumentSheet[]): Map<string, string> => {
    const errorsMap = new Map<string, string>();

    const duplicates = new Map<string, boolean>();
    sheets.forEach((sheet) => {
        if (duplicates.has(sheet.name)) {
            duplicates.set(sheet.name, true);
        } else {
            duplicates.set(sheet.name, false);
        }

        if (sheet.name.length === 0) {
            errorsMap.set(sheet.id, getTranslation("errors.name-is-required"));
        }
        if (sheet.name.length > maxStringFieldLength) {
            errorsMap.set(sheet.id, getTranslation("errors.name-is-too-long"));
        }
    });

    sheets.forEach((sheet) => {
        if (duplicates.get(sheet.name) && !errorsMap.has(sheet.name)) {
            errorsMap.set(sheet.id, getTranslation("errors.name-must-be-unique"));
        }
    });

    return errorsMap;
};
