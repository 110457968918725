import PropTypes from "prop-types";

const Select = ({ optionList = [], value, mainOptions, onChange }) => {
    // in some cases optionList is an array of arrays [[id, name], ...]
    // in this case value prop is the option's id. We need to find it's name
    const findOptionName = () => {
        if (!Array.isArray(optionList[0])) {
            return value;
        }

        for (const [id, name] of optionList) {
            if (id == value) {
                return name;
            }
        }

        return value;
    };

    if (mainOptions.viewState == "view") {
        return <span>{findOptionName()}</span>;
    }

    return (
        <select value={value} onChange={(e) => onChange(e.target.value)}>
            <option value="">--SELECT--</option>
            {optionList.map((opt, i) => {
                let val, label;
                if (Array.isArray(opt)) {
                    [val, label] = opt;
                } else {
                    val = opt;
                    label = opt;
                }
                return (
                    <option key={i} value={val}>
                        {label}
                    </option>
                );
            })}
        </select>
    );
};

Select.propTypes = {
    optionList: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mainOptions: PropTypes.object,
    onChange: PropTypes.func
};

export { Select };
