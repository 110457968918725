import React from "react";
import { DotProps } from "./types";
import { curve, theme } from "@assay/ui-kit";

export const ActiveDot: React.FC<DotProps> = ({ x, y }) => (
    <svg
        x={(x ?? 0) - 10}
        y={(y ?? 0) - 10}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_4731_189247)">
            <circle cx="10" cy="10" r="5.5" fill={curve.dot} stroke={theme.palette.common.white} />
        </g>
        <defs>
            <filter
                id="filter0_d_4731_189247"
                x="-2"
                y="-1"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4731_189247"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4731_189247"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
