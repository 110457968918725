import workerJson from "./worker.json";

export const getWorker = () => {
    const blob = new Blob([workerJson?.workerScript ?? ""], {
        type: "text/javascript"
    });

    const url = URL.createObjectURL(blob);

    const worker = new Worker(url);

    const teardownWorker = () => {
        worker.terminate();
        URL.revokeObjectURL(url);
    };

    return { worker, teardownWorker };
};
