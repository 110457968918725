import React, { createContext, useContext, useEffect } from "react";
import { config } from "../config";
import { tokenStorage, useAccessToken } from "./token-storage";
import { Token } from "../types";
import { authActions } from "./auth-actions";

const { IS_AUTH_ENABLED: isAuthEnabled, FAKE_TOKENS: fakeTokens } = config;

type ContextValue = {
    accessToken: Token;
    isAuthEnabled: boolean;
};

const Context = createContext<ContextValue | null>(null);

export const AuthProvider: React.FC = ({ children }) => {
    const accessToken = useAccessToken();

    useEffect(() => {
        if (isAuthEnabled) {
            authActions.refreshTokens();
        } else {
            tokenStorage.setTokens(fakeTokens);
        }
    }, []);

    const contextValue = {
        accessToken,
        isAuthEnabled
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useAuthContext = (): ContextValue => {
    const contextValue = useContext(Context);

    if (contextValue === null) {
        throw new Error("Cannot use auth context outside of AuthProvider");
    }

    return contextValue;
};
