import React, { Fragment, useState } from "react";
import { Typography } from "@material-ui/core";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { ConfirmDialog, FileBar } from "../components";
import { isFileDisplayed } from "../helpers";
import { Dropzone, makeStyles } from "@assay/ui-kit";
import { FileSaved } from "../types";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.grey[600],
        padding: theme.spacing(1, 0, 0),
        display: "block"
    }
}));

type SelectResult = {
    attachments: (File | FileSaved)[];
    addAttachment: (file: File) => void;
    removeAttachment: (index: number) => void;
};

const selectState = ({ attachments, actions }: UploadTemplateStore): SelectResult => ({
    attachments: attachments,
    addAttachment: actions.addAttachment,
    removeAttachment: actions.removeAttachment
});

export const Attachments = (): React.ReactElement => {
    const classes = useStyles();

    const [deleteIndex, setDeleteIndex] = useState<null | number>(null);

    const { attachments, addAttachment, removeAttachment } =
        useShallowUploadTemplateStore(selectState);

    const isDeletePopupOpen = deleteIndex !== null;
    const onDelete = () => {
        if (deleteIndex !== null) {
            removeAttachment(deleteIndex);
            setDeleteIndex(null);
        }
    };

    return (
        <>
            <Typography variant={"subtitle2"}>{getTranslation("attachments")}</Typography>
            <Typography variant={"caption"} className={classes.text}>
                {getTranslation("files-is-been-using-for-personal-goals")}
            </Typography>
            {attachments.map((item, itemIndex) => (
                <Fragment key={itemIndex}>
                    {isFileDisplayed(item) && (
                        <FileBar file={item} onDelete={() => setDeleteIndex(itemIndex)} />
                    )}
                </Fragment>
            ))}
            <div>
                <Dropzone
                    sizeLimitMb={25}
                    onFilesChoose={async ([file]) => {
                        addAttachment(file as File);
                    }}
                />
            </div>
            {isDeletePopupOpen && (
                <ConfirmDialog
                    onClose={() => setDeleteIndex(null)}
                    isOpen={isDeletePopupOpen}
                    onSubmit={onDelete}
                    title={getTranslation("delete-file")}
                />
            )}
        </>
    );
};
