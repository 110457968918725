import create, { StateSelector } from "zustand";
import shallow from "zustand/shallow";

type User = {
    userName: string;
    id: string;
};

type GlobalStore = {
    userAdded: User;
    dateAdded: string;
    setUserAndDate: (userAdded: User, dateAdded: string) => void;
};

const useStore = create<GlobalStore>((set) => ({
    userAdded: { userName: "", id: "" },
    dateAdded: "",

    setUserAndDate: (userAdded, dateAdded) => {
        set({ userAdded, dateAdded });
    }
}));

export const useGlobalStore = <T>(selector: StateSelector<GlobalStore, T>) =>
    useStore(selector, shallow);
