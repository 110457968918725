import React from "react";

import { SettingsTab } from "../components";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { HeadersEditor } from "./headers-editor";
import { FieldsEditor } from "./fields-editor";

const selectState = (state: UploadTemplateStore) => ({
    hasTempHeaders: state.tempHeaders.length > 0,
    hasFields: state.fields.length > 0
});

export const SettingsPropertiesTab = () => {
    const { hasTempHeaders, hasFields } = useShallowUploadTemplateStore(selectState);
    return (
        <SettingsTab>
            <>
                <HeadersEditor />
                {hasFields && !hasTempHeaders && <FieldsEditor />}
            </>
        </SettingsTab>
    );
};
