import { AutocompleteRenderInputParams } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import React from "react";

type TextInputProps = {
    label: string;
    helperText?: string | null;
    error?: boolean;
    onClick: () => void;
} & AutocompleteRenderInputParams;

export const TextInput = ({ label, helperText, error, onClick, ...rest }: TextInputProps) => (
    <TextField
        {...rest}
        label={label}
        variant="outlined"
        onClick={onClick}
        helperText={helperText}
        error={error}
    />
);
