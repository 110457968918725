import { alpha } from "@material-ui/core/styles";
import { Overrides } from "@material-ui/core/styles/overrides";

import { common, disabledButtonColor, primary } from "../colors";
import { config } from "../config";
import { alternativeElevatedShadow, alternativeShadow } from "../shadows";

export const MuiButton: Overrides["MuiButton"] = {
    root: {
        textTransform: "none"
    },
    contained: {
        "&:hover": {
            boxShadow: alternativeShadow
        }
    },

    containedPrimary: {
        boxShadow: alternativeShadow,

        [config.activeSelector]: {
            boxShadow: alternativeShadow
        },

        [config.disabledSelector]: {
            backgroundColor: disabledButtonColor,
            color: common.white
        },

        [config.focusSelector]: {
            color: alpha(common.white, 0.4),
            boxShadow: alternativeShadow
        }
    },

    outlinedPrimary: {
        backgroundColor: primary.main,
        border: "none",
        boxShadow: alternativeShadow,
        color: common.white,

        [config.disabledSelector]: {
            color: disabledButtonColor,
            border: "none",
            boxShadow: "none"
        },

        [config.hoverSelector]: {
            backgroundColor: primary.main,
            border: "none",
            boxShadow: alternativeElevatedShadow
        }
    },
    outlinedSecondary: {
        backgroundColor: common.white,
        border: "none",
        boxShadow: alternativeShadow,
        color: common.black,

        [config.disabledSelector]: {
            color: disabledButtonColor,
            border: "none",
            boxShadow: "none"
        },

        [config.hoverSelector]: {
            backgroundColor: common.white,
            border: "none",
            boxShadow: alternativeElevatedShadow
        }
    },

    textPrimary: {
        fontSize: 14
    }
};
