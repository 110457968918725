import { Overrides } from "@material-ui/core/styles/overrides";

import { blueGray, grey } from "../colors";
import { config } from "../config";
import { quicksandFontFamily } from "../typography";

export const MuiTab: Overrides["MuiTab"] = {
    root: {
        "fontFamily": quicksandFontFamily,
        "letterSpacing": "0.44px",
        "textTransform": "capitalize",
        "fontSize": "16px",
        "fontWeight": 500,
        "padding": "8px 16px",
        "minWidth": 50,
        "height": 40,
        "minHeight": 40,

        "@media (min-width: 600px)": {
            minWidth: 50
        },

        [config.disabledSelector]: {
            color: grey[600]
        }
    },

    textColorPrimary: {
        color: blueGray.main
    }
};
