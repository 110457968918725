import React from "react";
import { Button } from "@material-ui/core";
import { ExtendedTheme, makeStyles, theme } from "../theme";

const useStyles = makeStyles<ExtendedTheme, { size: number; iconColor: string }>({
    root: ({ size, iconColor }) => ({
        minWidth: `${size}px`,
        minHeight: `${size}px`,
        padding: 0,
        color: iconColor
    }),
    startIcon: {
        margin: 0
    }
});

type Props = {
    onClick: () => void;
    size?: number;
    type: "inherit" | "primary" | "secondary" | "default";
    iconColor?: string;
    startIcon: React.ReactElement;
    disabled?: boolean;
};

export const outlinedIconButtonDefaultSize = 34;

export const OutlinedIconButton = ({
    onClick,
    size = outlinedIconButtonDefaultSize,
    type,
    startIcon,
    iconColor,
    disabled
}: Props): React.ReactElement => {
    const classes = useStyles({
        size,
        iconColor:
            iconColor ??
            (type === "primary" ? theme.palette.common.white : theme.palette.primary.main)
    });
    return (
        <Button
            disabled={disabled}
            variant="outlined"
            color={type}
            onClick={onClick}
            classes={classes}
            startIcon={startIcon}
        />
    );
};
