import React from "react";
import { TemplateRange } from "../types";
import { Popover, Typography } from "@material-ui/core";
import { TemplateSettings } from "./sheet/template-settings";
import { getMinMaxValuesFromTemplate } from "../utils";
import { makeStyles } from "@assay/ui-kit";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles({
    templateButton: {
        cursor: "pointer"
    }
});

type Props = {
    range: TemplateRange;
};

export const CustomTemplatePreview = ({ range }: Props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const calculateTemplateSettingsSize = (type: "row" | "column"): number => {
        const minMaxValues = getMinMaxValuesFromTemplate(range.template);

        if (type === "row") return minMaxValues.maxRow - minMaxValues.minRow + 1;

        return minMaxValues.maxColumn - minMaxValues.minColumn + 1;
    };

    const isOpen = !!anchorEl;

    return (
        <>
            <Typography
                color={"primary"}
                className={classes.templateButton}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                {getTranslation("custom")}
            </Typography>
            {isOpen && (
                <Popover
                    open={isOpen}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    <TemplateSettings
                        editMode={false}
                        onClose={() => setAnchorEl(null)}
                        rowsCount={calculateTemplateSettingsSize("row")}
                        columnsCount={calculateTemplateSettingsSize("column")}
                        templateRange={range}
                    />
                </Popover>
            )}
        </>
    );
};
