import React from "react";
import { ExtendedTheme, makeStyles } from "@assay/ui-kit";

type StylesProps = {
    isActive: boolean;
    editMode: boolean;
};

const useStyles = makeStyles<ExtendedTheme, StylesProps>((theme) => ({
    root: ({ isActive, editMode }) => ({
        width: "112px",
        height: "29px",
        borderRadius: "4px",
        padding: "2px",
        border: `2px solid ${isActive ? theme.palette.primary.main : "#fff"}`,
        cursor: editMode ? "pointer" : "default"
    }),
    inner: ({ isActive }) => ({
        width: "100%",
        height: "100%",
        background: `${isActive ? theme.palette.primary.main : theme.palette.grey[500]}`
    })
}));

type Props = {
    isActive: boolean;
    editMode: boolean;
};

export const TemplateElement = ({ isActive, editMode }: Props): React.ReactElement => {
    const classes = useStyles({ isActive, editMode });

    return (
        <div className={classes.root}>
            <div className={classes.inner} />
        </div>
    );
};
