import React, { memo } from "react";

const heatMapColorScale = [
    [0, [5, 10, 172]],
    [0.35, [106, 137, 247]],
    [0.5, [190, 190, 190]],
    [0.6, [220, 170, 132]],
    [0.7, [230, 145, 90]],
    [1, [178, 10, 28]]
];

const getHeatMapColor = (val: number, heatMapMin: number, heatMapMax: number) => {
    const L: unknown[] = [];
    let startIndex = 0;
    let endIndex = 0;
    const percent = (val - heatMapMin) / (heatMapMax - heatMapMin);

    for (let i = 0; i < heatMapColorScale.length; i++) {
        if (i == heatMapColorScale.length - 1) {
            startIndex = i;
            endIndex = i;
            break;
        }
        if (percent >= heatMapColorScale[i][0] && percent <= heatMapColorScale[i + 1][0]) {
            startIndex = i;
            endIndex = i + 1;
            break;
        }
    }

    for (let i = 0; i < 3; i++) {
        const startRangeNumber =
            (heatMapColorScale[startIndex][0] as number) * (heatMapMax - heatMapMin);

        const endRangeNumber =
            (heatMapColorScale[endIndex][0] as number) * (heatMapMax - heatMapMin);

        const startColor = (heatMapColorScale[startIndex][1] as number[])[i];

        const endColor = (heatMapColorScale[endIndex][1] as number[])[i];
        const colorRange = Math.abs(startColor - endColor);
        const addAmount = Math.floor(
            (colorRange * (val - startRangeNumber)) / (endRangeNumber - startRangeNumber)
        );
        let finalColor = 0;
        if (startColor >= endColor) {
            finalColor = startColor - addAmount;
        } else {
            finalColor = startColor + addAmount;
        }
        L.push(finalColor);
    }

    return "rgb(" + L.join(",") + ")";
};

type StyleProps = {
    isBold?: boolean;
    isWhiteText?: boolean;
    backgroundColor?: string;
};

type Props = StyleProps & {
    content: string | number;
    isCalculateColor?: boolean;
    heatMapMin?: number;
    heatMapMax?: number;
    onClick?: () => void;
};
export const Cell: React.FC<Props> = memo(
    ({
        content,
        isBold,
        isCalculateColor,
        heatMapMax = 0,
        heatMapMin = 0,
        backgroundColor,
        onClick
    }) => {
        const color = isCalculateColor
            ? getHeatMapColor(+content, heatMapMin, heatMapMax)
            : backgroundColor;

        //ATTENTION: className will not work correct with "Pop Heat Map Out of Page"
        //changing color after onClick will not work
        return (
            <div
                style={{
                    padding: "6px",
                    fontWeight: isBold ? "bold" : "normal",
                    backgroundColor: color,
                    textAlign: "center",
                    cursor: "pointer",
                    color: isCalculateColor ? "white" : "black",
                    minHeight: "33px",
                    boxSizing: "border-box"
                }}
                onClick={onClick}
            >
                {content}
            </div>
        );
    }
);

Cell.displayName = "Cell";
