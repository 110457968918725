import { useQuery } from "react-query";
import { DocumentSheet, Option, OptionWithNumber } from "./types";
import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "@material-ui/core";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "./store";
import { makeStyles } from "@assay/ui-kit";
import { ResultDefinition } from "@assay/shared";

const fieldsOptionsFieldSelector = (store: UploadTemplateStore): Option[] => {
    return store.fields.reduce((prev, field) => {
        const { aggregationFields, id, name } = field;
        return aggregationFields || !name ? prev : [...prev, { id, name }];
    }, new Array<Option>());
};

export const useFieldsOptions = (): Option[] =>
    useShallowUploadTemplateStore(fieldsOptionsFieldSelector);

const selectGetResultDefinitions = ({ resultDefinitionsQueryParams }: UploadTemplateStore) => ({
    resultDefinitionsQueryParams
});

export const useLoadResultDefinitions = (): void => {
    const { resultDefinitionsQueryParams } = useShallowUploadTemplateStore(
        selectGetResultDefinitions
    );

    const { data } = useQuery<ResultDefinition[]>({ ...resultDefinitionsQueryParams });

    const setResultDefinitions = useShallowUploadTemplateStore(
        (state: UploadTemplateStore) => state.actions.setResultDefinitions
    );

    useEffect(() => {
        if (data) {
            setResultDefinitions(data);
        }
    }, [data, setResultDefinitions]);
};

export const useSheetOptions = (data: DocumentSheet[]): OptionWithNumber[] =>
    useMemo(() => data.map(({ id, name, number }) => ({ id, name, number })), [data]);

export const useRefSize = (ref: React.RefObject<HTMLDivElement>) => {
    const [size, setSize] = useState({
        width: ref.current?.offsetWidth ?? 0,
        height: ref.current?.offsetHeight ?? 0
    });

    useEffect(() => {
        const resizeHandler = debounce(() => {
            setSize({
                width: ref?.current?.offsetWidth ?? 0,
                height: ref?.current?.offsetHeight ?? 0
            });
        }, 300);
        if (ref.current) {
            const el = ref.current;
            setSize({
                width: el.offsetWidth ?? 0,
                height: el.offsetHeight ?? 0
            });
            window.addEventListener("resize", resizeHandler);
            return () => window.removeEventListener("resize", resizeHandler);
        }
    }, [ref]);

    return size;
};

const fileTabInputSize = "220px";

export const useFileTabFlexStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        gap: theme.spacing(1),
        width: "100%",
        alignItems: "center",
        alignContent: "center"
    },
    title: {
        flexGrow: 1
    },
    input: {
        "position": "relative",
        "display": "flex",
        "width": fileTabInputSize,
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0.5)
        }
    }
}));

export const useFileTabGridStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        gap: theme.spacing(1),
        width: "100%",
        gridTemplateColumns: `auto ${fileTabInputSize}`,
        gridTemplateRows: "auto auto",
        alignItems: "center",
        alignContent: "center"
    },
    title: {
        gridRow: "1/2",
        gridColumn: "1/2"
    },
    input: {
        width: "100%"
    },
    inputWrapper: {
        "gridRow": "1/2",
        "gridColumn": "2/3",
        "position": "relative",
        "display": "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0.5)
        }
    },
    checkbox: {
        gridRow: "2/3",
        gridColumn: "2/3",
        display: "flex",
        alignItems: "center"
    }
}));
