import React, { useRef, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { makeStyles } from "../../theme";
import { Link } from "@material-ui/core";
import { DropZoneExtensionOption } from "./constants";
import { ErrorDialog } from "../dialogs";
import { getTranslation } from "../get-translation";

//TODO: return wrong color for .csv, .tsv (problem in library)
const getBorderColor = (props: {
    isDragAccept: boolean;
    isDragReject: boolean;
    isDragActive: boolean;
}) => {
    if (props.isDragAccept) {
        return "#00e676";
    }
    if (props.isDragReject) {
        return "#ff1744";
    }
    if (props.isDragActive) {
        return "#2196f3";
    }
    return "#eeeeee";
};

const useStyles = makeStyles({
    container: {
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        borderWidth: "2px",
        borderRadius: "2px",
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out"
    },
    dropZone: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        paddingTop: "6rem",
        paddingBottom: "5rem",
        width: "100%",
        height: "100%"
    },
    importTitle: {
        textAlign: "center"
    },
    dropTitle: {
        display: "flex"
    }
});

interface Props {
    extensionOption?: DropZoneExtensionOption;
    sizeLimitMb?: number;
    onFilesChoose: (files: FileWithPath[]) => void;
}

export const Dropzone = ({ onFilesChoose, extensionOption, sizeLimitMb }: Props) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const rootRef = useRef<HTMLDivElement>(null);

    const resetFiles = () => {
        if (rootRef.current) {
            const nativeInput = rootRef.current.querySelector("input");
            if (nativeInput) {
                nativeInput.value = "";
            }
        }
    };

    const { getRootProps, getInputProps, open, isDragAccept, isDragActive, isDragReject } =
        useDropzone({
            noClick: true,
            noKeyboard: true,
            accept: extensionOption?.accept,
            onDrop: (acceptedFiles) => {
                if (sizeLimitMb) {
                    const isOverSize = acceptedFiles.some(
                        (file) => file.size > sizeLimitMb * 1024 * 1024
                    );
                    if (isOverSize) {
                        setErrorMessage(getTranslation("errors.file-is-too-big"));
                        return;
                    }
                }
                onFilesChoose(acceptedFiles);

                resetFiles();
            }
        });

    const classes = useStyles();
    const sizeLimitMessage = sizeLimitMb ? ` max ${sizeLimitMb}Mb` : "";
    const importHelperMessage = extensionOption ? `You can import ${extensionOption.name}` : "";

    return (
        <>
            <div
                ref={rootRef}
                className={classes.container}
                style={{
                    borderColor: getBorderColor({
                        isDragAccept,
                        isDragReject,
                        isDragActive
                    })
                }}
            >
                <label {...getRootProps({ className: classes.dropZone })}>
                    <svg
                        width="43"
                        height="28"
                        viewBox="0 0 43 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M34.5785 10.7332C33.3685 4.98317 27.9768 0.666504 21.4997 0.666504C16.3571 0.666504 11.8907 3.39984 9.66646 7.39984C4.31036 7.93317 0.146484 12.1832 0.146484 17.3332C0.146484 22.8498 4.93316 27.3332 10.8231 27.3332H33.9557C38.867 27.3332 42.8529 23.5998 42.8529 18.9998C42.8529 14.5998 39.2051 11.0332 34.5785 10.7332ZM25.0586 15.6665V22.3332H17.9408V15.6665H12.6025L21.4997 7.33317L30.3969 15.6665H25.0586Z"
                            fill="#78909C"
                        />
                    </svg>
                    <input {...getInputProps()} />
                    <div className={classes.dropTitle}>
                        <Link
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                open();
                            }}
                        >
                            Choose file
                        </Link>
                        <p>&nbsp;or Drop file here</p>
                    </div>

                    <p className={classes.importTitle}>{importHelperMessage}</p>
                    <p className={classes.importTitle}>{sizeLimitMessage}</p>
                </label>
            </div>
            <ErrorDialog
                open={!!errorMessage}
                onClose={() => setErrorMessage(null)}
                message={errorMessage ?? ""}
            />
        </>
    );
};
