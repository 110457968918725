import { FileApiAction, UploadTemplateListItem, UploadTemplatePayload } from "./types";
import { getAppConfig, getTranslation } from "@assay/shared";

type UploadTemplateApiParams = {
    method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
    payload?: unknown;
    endPoint?: string;
};

const uploadTemplatesApi = async ({
                                      method,
                                      payload,
                                      endPoint
                                  }: UploadTemplateApiParams): Promise<Response> => {
    const authToken = await getAppConfig().authTokenProvider();

    const request = new Request(
        `${getAppConfig().uploadTemplatesApiUrl}/uploadTemplates/${endPoint ?? ""}`,
        {
            method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`,
                "Custom-ArxLab-ConnectionId": getAppConfig().connectionId
            },
            ...(method !== "GET" ? { body: payload ? JSON.stringify(payload) : "" } : {})
        }
    );

    return await fetch(request);
};

export const createUploadTemplate = (payload: UploadTemplatePayload): Promise<Response> => {
    return uploadTemplatesApi({ method: "POST", payload });
};

export class CopyTemplateError extends Error {

    constructor(message: string) {
        super(message);

    }
}

export const copyUploadTemplate = async (
    sourceId: string,
    newName: string
): Promise<UploadTemplatePayload> => {
    const response = await uploadTemplatesApi({
        method: "POST",
        payload: {
            sourceId,
            newName
        },
        endPoint: "copy"
    });
    if (!response.ok) {
        let errorObject: { message?: string } = {};
        try {
            errorObject = await response.json();

        } catch (e) {
            //not needed any actions
        }

        if (errorObject.message) {
            throw new CopyTemplateError(errorObject.message);
        } else {
            throw new Error(getTranslation("errors.unknown-form-error"));
        }
    }
    return response.json();
};

export const deleteUploadTemplate = (id: string): Promise<Response> => {
    return uploadTemplatesApi({ method: "DELETE", endPoint: id });
};

export const updateUploadTemplate = (
    id: string,
    payload: UploadTemplatePayload
): Promise<Response> => {
    return uploadTemplatesApi({ method: "PUT", payload, endPoint: id });
};

export const getUploadTemplate = async (id?: string): Promise<Response> => {
    return await uploadTemplatesApi({ endPoint: id, method: "GET" });
};

export const getUploadTemplateList = async (): Promise<UploadTemplateListItem[]> => {
    const response = await uploadTemplatesApi({ method: "GET" });
    return await response.json();
};

type UploadTemplateFilesApiParams = {
    action: FileApiAction;
    isMainFile?: boolean;
    file?: File;
    fileId?: string;
    templateId: string;
};

const uploadTemplateFilesApi = async ({
                                          isMainFile = true,
                                          templateId,
                                          action,
                                          file,
                                          fileId
                                      }: UploadTemplateFilesApiParams): Promise<Response> => {
    const createFormData = (): FormData => {
        const formData = new FormData();
        if (!file) return formData;

        formData.append("files", file, file.name);
        return formData;
    };

    const authToken = await getAppConfig().authTokenProvider();

    const getMethod = () => {
        if (action === "create") {
            return "POST";
        } else if (action === "delete") {
            return "DELETE";
        }
        return "GET";
    };

    const needFileSubUrl = !isMainFile && (action === "delete" || action === "download");

    const url = `${getAppConfig().uploadTemplatesApiUrl}/uploadTemplates/${templateId}/${
        isMainFile ? "file" : "attachments"
    }/${needFileSubUrl ? fileId : ""}`;

    const request = new Request(url, {
        method: getMethod(),
        headers: {
            "Authorization": `Bearer ${authToken}`,
            "Custom-ArxLab-ConnectionId": getAppConfig().connectionId
        },
        redirect: "follow",
        ...(action === "create" ? { body: createFormData() } : {})
    });

    return await fetch(request);
};

export const createUploadTemplateMainFile = (templateId: string, file: File): Promise<Response> =>
    uploadTemplateFilesApi({
        action: "create",
        templateId,
        file
    });

export const getUploadTemplateMainFile = (templateId: string): Promise<Response> =>
    uploadTemplateFilesApi({
        action: "download",
        templateId
    });

export const getUploadTemplateAttachmentFile = (
    templateId: string,
    fileId: string
): Promise<Response> =>
    uploadTemplateFilesApi({
        action: "download",
        templateId,
        fileId,
        isMainFile: false
    });

export const deleteUploadTemplateMainFile = (templateId: string): Promise<Response> =>
    uploadTemplateFilesApi({
        action: "delete",
        templateId,
        isMainFile: true
    });

export const createUploadTemplateAttachmentFile = (
    templateId: string,
    file: File
): Promise<Response> =>
    uploadTemplateFilesApi({
        action: "create",
        isMainFile: false,
        templateId,
        file
    });

export const deleteUploadTemplateAttachmentFile = (
    templateId: string,
    fileId: string
): Promise<Response> =>
    uploadTemplateFilesApi({
        action: "delete",
        isMainFile: false,
        templateId,
        fileId
    });
