import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import {
    Checkbox,
    CurveField,
    DateInput,
    Fieldset,
    File,
    HeatMapButton,
    PermGroup,
    Select,
    Textarea,
    TextInput
} from "./forms";
import { ensureArray } from "../helpers";
import { makeStyles } from "@material-ui/styles";
import { useFormStore } from "../store";
import { getSingleFieldError } from "./validate";
import { getTranslation } from "@assay/shared";

const fieldsMap = {
    select: Select,
    text: TextInput,
    checkbox: Checkbox,
    textArea: Textarea,
    date: DateInput,
    permGroupList: PermGroup,
    fieldSet: Fieldset,
    file: File,
    heatMap: HeatMapButton,
    heading: null,
    curve: CurveField
};

const useStyles = makeStyles({
    imgButton: {
        verticalAlign: "middle",
        padding: "2px",
        cursor: "pointer"
    }
});

const additionalFieldTypes = ["hidden"];

const FieldWrapper = ({
    type,
    name,
    value,
    optionList,
    fields,
    mainOptions,
    displayOptions,
    excelOptions,
    formData,
    originField,
    setFields,
    indexes
}) => {
    const classes = useStyles();
    const onFieldAdd = useFormStore((state) => state.onFieldAdd);
    const onFieldChange = useFormStore((state) => state.onFieldChange);
    const onFieldDelete = useFormStore((state) => state.onFieldDelete);
    const siblings = useFormStore((state) => state.fields);
    const fixedOptions = useFormStore((state) => state.selectOptions[name]);
    const isFieldError = useFormStore((state) => state.isFieldError);
    const error = isFieldError ? getSingleFieldError(originField) : null;

    const Field = fieldsMap[type];

    const hideOptionsField = useCallback(
        (isHidden) => {
            const newFields = siblings.map((field) => {
                if (field.name === "options") {
                    return {
                        ...field,
                        __pFieldOptions: {
                            ...field.__pFieldOptions,
                            hidden: isHidden
                        }
                    };
                }
                return field;
            });

            setFields(newFields);
        },
        [setFields, siblings]
    );

    useEffect(() => {
        if (name === "Type" && formData?.name === "Result Definition") {
            hideOptionsField(value !== "drop down");
        }
    }, [value]);

    if (type === "hidden" || mainOptions.hidden || Field === undefined) {
        return null;
    }

    if (type === "heading") {
        return (
            <div>
                <h2>{name}</h2>
                <div className="errorDiv" />
            </div>
        );
    }

    const fieldName = `${name}${mainOptions.required ? "*" : ""}`;

    const onChangeHandler =
        type === "fieldSet"
            ? onFieldChange
            : (newValue, valueOptionIndex) => onFieldChange(indexes, newValue, valueOptionIndex);

    const arrayOfValues = type === "text" && mainOptions.parseExcel ? [value] : ensureArray(value);

    //we need this for ability to change empty value (when value in not exist)
    //and render fieldSet
    if (arrayOfValues.length === 0) {
        arrayOfValues.push("");
    }

    const isAddEnabled = mainOptions.multi && mainOptions.viewState !== "view";

    return (
        <div className={type === "fieldSet" ? "fieldHolder fieldSetHolder" : "fieldHolder"}>
            {type !== "fieldSet" && <div className="label">{fieldName}</div>}
            <div className="field">
                {arrayOfValues.map((valueOption, valueOptionIndex) => {
                    const isDeleteEnabled =
                        valueOptionIndex > 0 && mainOptions.viewState !== "view";
                    return (
                        <div className="fieldValue" key={valueOptionIndex}>
                            <Field
                                indexes={type === "fieldSet" ? indexes : undefined}
                                name={name}
                                value={valueOption}
                                optionList={fixedOptions ?? optionList}
                                fields={fields}
                                mainOptions={mainOptions}
                                excelOptions={excelOptions}
                                displayOptions={displayOptions}
                                formData={formData}
                                onChange={(...args) => onChangeHandler(...args, valueOptionIndex)}
                                originField={originField}
                            />
                            {isDeleteEnabled && (
                                <img
                                    className={classes.imgButton}
                                    src="images/delete.gif"
                                    width="20"
                                    height="20"
                                    onClick={() => onFieldDelete(indexes, valueOptionIndex)}
                                    alt={getTranslation("delete-option")}
                                />
                            )}
                        </div>
                    );
                })}

                {isAddEnabled && (
                    <img
                        className={classes.imgButton}
                        src="images/add.gif"
                        width="20"
                        height="20"
                        onClick={() => {
                            onFieldAdd(indexes);
                        }}
                        alt={getTranslation("add-option")}
                    />
                )}
            </div>
            {error && <div className="errorDiv">{error}</div>}
        </div>
    );
};

FieldWrapper.propTypes = {
    type: PropTypes.oneOf([...Object.keys(fieldsMap), ...additionalFieldTypes]).isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.object,
        PropTypes.array
    ]),
    optionList: PropTypes.array,
    fields: PropTypes.array,
    mainOptions: PropTypes.object,
    displayOptions: PropTypes.object,
    originField: PropTypes.object,
    indexes: PropTypes.array
};

export { FieldWrapper };
