export const saveEnvsToWindow = (): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.env = process.env;
};

export const getEnv = (name: string): string => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return window.env?.[name] ?? "";
};
