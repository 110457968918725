import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import { TreeViewIcon } from "./tree-view-icon";
import React, { Fragment, useState } from "react";
import { useStyles } from "../styles";
import { useTreeQuery } from "../../api-v2";
import { ContentTypes, TreeNode } from "../../types";

type Props = {
    item: TreeNode;
    onClick: (e: unknown, item: TreeNode) => void;
    onContextMenu?: (e: unknown, item: TreeNode) => void;
    parentName?: string;
};

export const TreeViewItem: React.FC<Props> = ({ item, onClick, onContextMenu }) => {
    const classes = useStyles();
    const { key, title, icon } = item;
    const [isTouched, setIsTouched] = useState(false);

    const { data: childrenItems = [], refetch } = useTreeQuery(key ?? "");

    const handleClick = async (event: unknown) => {
        setIsTouched(true);
        const { data: childrenItems } = await refetch();
        const isEmpty = childrenItems?.length === 0;
        onClick(event, { ...item, isEmpty });
    };

    const handleContextMenu = (e: unknown) => {
        onContextMenu?.(e, item);
    };

    return (
        <TreeItem
            nodeId={key}
            label={
                <div className={classes.labelWrapper}>
                    {icon && <TreeViewIcon iconName={icon} className={classes.icon} />}
                    <Typography variant="body2" className={classes.label}>
                        {title}
                    </Typography>
                </div>
            }
            onClick={handleClick}
            // must use capture phase,
            // otherwise the handler will always end up with the topmost item' data
            onContextMenuCapture={handleContextMenu}
        >
            {childrenItems.length
                ? childrenItems.map((child, i) => (
                      <TreeViewItem
                          key={i}
                          item={child}
                          onClick={onClick}
                          onContextMenu={onContextMenu}
                      />
                  ))
                : !isTouched && <Fragment />}
        </TreeItem>
    );
};
