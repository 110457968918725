import { FileWithPath } from "react-dropzone";
import { FileApiError, FileSaved } from "./types";
import {
    createUploadTemplateAttachmentFile,
    createUploadTemplateMainFile,
    deleteUploadTemplateAttachmentFile,
    deleteUploadTemplateMainFile
} from "./api";

export const handleFilesRequests = async (
    templateId: string,
    mainFile: FileWithPath | FileSaved | null,
    attachments: Array<FileWithPath | FileSaved>
): Promise<FileApiError[]> => {
    const errors: FileApiError[] = [];

    if (mainFile instanceof File) {
        try {
            const mainFileResponse = await createUploadTemplateMainFile(templateId, mainFile);
            if (!mainFileResponse.ok) {
                throw new Error();
            }
        } catch {
            errors.push({
                file: mainFile,
                action: "create",
                isMainFile: true,
                fileName: mainFile.name
            });
        }
    } else if (!mainFile) {
        try {
            const mainFileResponse = await deleteUploadTemplateMainFile(templateId);
            if (!mainFileResponse.ok) {
                throw new Error();
            }
        } catch {
            errors.push({
                action: "delete",
                isMainFile: true,
                fileName: "Sample file"
            });
        }
    }

    await Promise.all(
        attachments.map(async (file) => {
            if (file instanceof File) {
                try {
                    const response = await createUploadTemplateAttachmentFile(templateId, file);
                    if (!response.ok) {
                        throw new Error();
                    }
                    return response;
                } catch (e) {
                    errors.push({
                        action: "create",
                        file,
                        fileName: file.name
                    });
                    return null;
                }
            }
            if (!(file as FileSaved).willBeDeleted || !(file as FileSaved).fileId) return;

            const savedFile = file as FileSaved;

            try {
                const response = await deleteUploadTemplateAttachmentFile(
                    templateId,
                    savedFile.fileId
                );
                if (!response.ok) {
                    throw new Error();
                }
                return response;
            } catch (e) {
                errors.push({
                    action: "delete",
                    fileId: savedFile.fileId,
                    fileName: savedFile.fileName
                });
                return null;
            }
        })
    );

    return errors;
};
