import { useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import { CopyFormLayout } from "./copy-form-layout";
import { DialogRoot } from "./dialog-root";
import { PasteButton } from "./paste-button";
import { getTranslation } from "@assay/shared";
import { CopyTemplateError } from "@assay/upload-templates";

export const CopyTemplateForm = ({ onSubmit, name: initialValue = "", maxNameLength = 0 }) => {
    const [name, setName] = useState(initialValue);
    const [errorText, setErrorText] = useState("");
    const [inProgress, setInProgress] = useState(false);

    return (
        <Box
            component="form"
            onSubmit={async (e) => {
                e.preventDefault();
                if (!name) {
                    setErrorText(getTranslation("template-name-is-required"));
                } else if (name.length > maxNameLength && maxNameLength !== 0) {
                    setErrorText(getTranslation("errors.name-is-too-long"));
                } else {
                    setInProgress(true);
                    try {
                        await onSubmit(name);
                    } catch (e) {
                        if (e instanceof CopyTemplateError) {
                            setErrorText(e.message);
                        } else {
                            console.warn(e);
                            alert(e.message);
                        }
                    }

                    setInProgress(false);
                }
            }}
        >
            <CopyFormLayout
                title={
                    <Typography variant="h6" gutterBottom>
                        {getTranslation("copy-template")}
                    </Typography>
                }
                fields={
                    <TextField
                        error={!!errorText}
                        helperText={errorText}
                        variant="outlined"
                        name="assayName"
                        label={getTranslation("new-template-name")}
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                        fullWidth
                    />
                }
                actions={<PasteButton inProgress={inProgress} />}
            />
        </Box>
    );
};

export const CopyTemplateDialog = ({ onClose, open = true, formProps = {}, ...popupProps }) => {
    return (
        <DialogRoot
            {...popupProps}
            onClose={onClose}
            open={open}
            withConfirmClose
            confirmTitleText={getTranslation("cancel-template-copying")}
            confirmContentText={getTranslation("are-you-sure")}
        >
            <CopyTemplateForm
                {...formProps}
                onSubmit={async (...args) => {
                    await formProps.onSubmit(...args);
                    onClose?.();
                }}
            />
        </DialogRoot>
    );
};
