import { getForm, getForms } from "../../api-v2";
import { ContentTypes } from "../../types";

const findFormFields = (form) => {
    for (let f of form.fields) {
        if (f.__pFieldOptions.name === "fields") {
            return f.fields;
        }
    }
};

const findFieldValue = (field, name) => {
    for (let prop of field) {
        if (prop.__pFieldOptions.name === name) {
            return prop.value;
        }
    }
};

const isInTable = (field) => findFieldValue(field, "inTable");
const isTableLink = (field) => findFieldValue(field, "isTableLink");
const findName = (field) => findFieldValue(field, "name");

// each field in the fields array is an array of field's properties
// we need to find out if a property with the name "inTable" exists in a field
// and return an array of such fields' names
const findInTableFieldNames = (fields) =>
    fields.reduce(
        (acc, field) => {
            if (isInTable(field)) {
                acc.columns.push(findName(field));
            }
            if (isTableLink(field)) {
                acc.tableLinkName = findName(field);
            }
            return acc;
        },
        { columns: [], tableLinkName: "" }
    );

const generateRows = (forms, columns, tableLinkName) =>
    forms.map((form) =>
        columns.map((col) => {
            const formField = form.fields.find((f) => f.name === col);

            const cell = {
                label: formField?.value ?? ""
            };

            if (col === tableLinkName) {
                cell.icon = form.icon;
                cell.id = form.id;
                cell.type = form.showTable ? ContentTypes.Table : ContentTypes.Form;
            }

            return cell;
        })
    );

export const generateTable = async (formIds) => {
    const forms = await getForms(formIds);

    if (!forms.length) {
        return {
            rows: [],
            columns: []
        };
    }

    const f2 = await getForm(forms[0].typeId);
    const f2Fields = findFormFields(f2);

    const { columns, tableLinkName } = findInTableFieldNames(f2Fields);
    const rows = generateRows(forms, columns, tableLinkName);

    return { rows, columns };
};
