import { ResultTableCell } from "../types";

const sortingFn = (rowA: ResultTableCell[], rowB: ResultTableCell[]) => {
    const cellA = rowA.find((cell) => cell.id);
    const cellB = rowB.find((cell) => cell.id);

    if (!cellA) return -1;
    if (!cellB) return 1;

    const labelA = cellA.label.trim().toLowerCase();
    const labelB = cellB.label.trim().toLowerCase();

    return labelA > labelB ? 1 : -1;
};

export const sortRows = (rows: ResultTableCell[][]) => {
    rows.sort(sortingFn);

    const folders = [];
    const permittedFolders = [];
    const rest = [];

    for (const row of rows) {
        if (row.some((cell) => cell.icon === "folder.gif")) {
            folders.push(row);
        } else if (row.some((cell) => cell.icon === "lock.gif")) {
            permittedFolders.push(row);
        } else {
            rest.push(row);
        }
    }

    return [...folders, ...permittedFolders, ...rest];
};
