import { signIn, refreshTokens } from "./auth-api";
import { tokenStorage } from "./token-storage";

/**
 * Refreshes tokens before they expire by itself
 * Provides a set of action functions to manage auth
 */
class AuthActions {
    private refreshTimeoutId?: ReturnType<typeof setTimeout>;

    signIn = async (email: string, password: string, apiKey: string): Promise<void> => {
        const authData = await signIn(email, password, apiKey);
        const { expiresInSeconds, ...tokens } = authData;
        tokenStorage.setTokens(tokens);
        this.setRefreshTimeout(expiresInSeconds);
    };

    signOut = (): void => {
        this.clearRefreshTimeout();
        tokenStorage.clearTokens();
    };

    refreshTokens = async (): Promise<void> => {
        try {
            this.clearRefreshTimeout();
            const tokens = tokenStorage.getTokens();
            const { accessToken, refreshToken } = tokens;

            if (!accessToken || !refreshToken) {
                throw new Error("Invalid tokens");
            }

            const { expiresInSeconds, ...refreshedTokens } = await refreshTokens(tokens);
            tokenStorage.setTokens(refreshedTokens);
            this.setRefreshTimeout(expiresInSeconds);
        } catch {
            this.signOut();
        }
    };

    private setRefreshTimeout = (expiresInSeconds: number) => {
        const refreshTime = expiresInSeconds * 1000 - 10000; // 10 secs before expiration
        this.refreshTimeoutId = setTimeout(this.refreshTokens, refreshTime);
    };

    private clearRefreshTimeout = () => {
        if (this.refreshTimeoutId !== undefined) {
            clearTimeout(this.refreshTimeoutId);
        }
    };
}

export const authActions = new AuthActions();
