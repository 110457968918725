import React from "react";
import { ExcelIcon } from "./excel-icon";
import ImageIcon from "@material-ui/icons/Image";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AttachFileIcon from "@material-ui/icons/AttachFile";

type Props = {
    fileName: string;
};

export const AttachmentIcon: React.FC<Props> = ({ fileName }) => {
    const name = fileName.toLowerCase().trim();

    if (
        name.endsWith(".xls") ||
        name.endsWith(".xlsm") ||
        name.endsWith(".xlsx") ||
        name.endsWith(".csv") ||
        name.endsWith(".tsv")
    ) {
        return <ExcelIcon />;
    }

    const size = 24;

    if (name.endsWith(".png") || name.endsWith(".jpg") || name.endsWith(".jpeg")) {
        return <ImageIcon style={{ fontSize: size }} color="secondary" />;
    }

    if (name.endsWith(".pdf")) {
        return <PictureAsPdfIcon style={{ fontSize: size }} color="secondary" />;
    }

    return <AttachFileIcon style={{ fontSize: size }} color="secondary" />;
};
