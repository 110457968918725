import { makeStyles } from "@material-ui/styles";
import { useUrl } from "../use-url";
import { ContentTypes } from "../types";
import { FormContainer } from "../form/form-container";
import { ResultTable } from "../result-table";
import { UploadTemplatesContent } from "./upload-templates-content";

const useStyles = makeStyles({
    oldRoot: {
        width: "100%"
    },
    wrapper: {
        position: "relative",
        flexGrow: 1,
        flexBasis: "100%",
        padding: "20px 0 20px 20px",
        boxSizing: "border-box"
    }
});

export const Content = () => {
    const classes = useStyles();
    const { params, openItem } = useUrl();
    const { id, typeId, parentId, type, viewState } = params;

    //because ut styles crashes by it
    const wrapperId = type !== ContentTypes.Ut ? "arxOneContainer" : undefined;

    return (
        // TODO: form's styling depends on this id.
        // can be removed once form's styling is migrated to mui
        <main className={classes.wrapper} id={wrapperId}>
            <div className={classes.oldRoot}>
                {type === ContentTypes.Table && <ResultTable />}
                {type === ContentTypes.Ut && <UploadTemplatesContent />}
                {type === ContentTypes.Form && (
                    <FormContainer
                        key={id}
                        id={id}
                        typeId={typeId}
                        parentId={parentId}
                        viewState={viewState}
                        openItem={openItem}
                    />
                )}

                <div id="arxTwoContainer" />
            </div>
        </main>
    );
};
