import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const SpaceIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="m14.92534,11.4707l0,1.40625l-5.625,0l0,-1.40625c0,-0.25781 -0.21094,-0.46875 -0.46875,-0.46875c-0.25781,0 -0.46875,0.21094 -0.46875,0.46875l0,1.875c0,0.25785 0.21094,0.46875 0.46875,0.46875l6.56245,0c0.2579,0 0.4688,-0.2109 0.4688,-0.46875l0,-1.875c0,-0.25781 -0.2109,-0.46875 -0.4688,-0.46875c-0.2578,0 -0.4687,0.21094 -0.4687,0.46875z"
                fill={props.color}
            />
        </SvgIcon>
    );
};
