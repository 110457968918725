import React, { useState } from "react";
import { Collapse, Typography } from "@material-ui/core";
import { DebouncedTextField, makeStyles, theme } from "@assay/ui-kit";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { UploadTemplateField, UploadTemplateFieldTouched } from "../../types";
import { normalizeStringToNumber, stringPositionToPosition } from "../../utils";
import { getTranslation } from "@assay/shared";
import { getRegExpFieldValues } from "./helpers";

const useStyles = makeStyles({
    root: {
        width: `calc(100% + ${theme.spacing(4)}px)`,
        marginLeft: theme.spacing(-2), // we have default spacing(2) padding into MuiAccordionDetails
        marginRight: theme.spacing(-2), // and it's pretty hard to keep it all except here
        paddingLeft: theme.spacing(2), // it is dirty way, but works
        paddingRight: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    titleWrapper: {
        paddingTop: theme.spacing(1),
        cursor: "pointer",
        display: "flex",
        width: "100%"
    },
    title: {
        fontWeight: "bold"
    },
    icon: {
        marginRight: 0,
        marginLeft: "auto"
    },
    fields: {
        "marginTop": theme.spacing(1),
        "display": "flex",
        "flexDirection": "column",
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(2)
        },
        "& > *:last-child": {
            marginBottom: theme.spacing(1)
        }
    },
    fieldRow: {
        "display": "flex",
        "width": "100%",
        "alignItems": "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    },
    fieldTitle: {
        width: "115px",
        flexShrink: 0
    },
    fieldInput: {
        flexGrow: 1
    },
    openIcon: {
        opacity: 0.54
    }
});

type Props = {
    onChange: (value: Partial<UploadTemplateField>) => void;
    onTouch: (subField: keyof UploadTemplateFieldTouched) => void;
    field: UploadTemplateField;
};

export const FieldRegexp: React.FC<Props> = ({ field, onChange, onTouch }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const {
        endRegexpValue,
        endRegexpValueError,
        startRegexpValueError,
        endRegexPositionsError,
        endRegexPositionsStr,
        startRegexPositionsStr,
        startRegexPositionsError,
        startRegexpValue,
        startRegexStepDown,
        startRegexStepRight,
        startRegexStepRightError,
        startRegexStepDownError
    } = getRegExpFieldValues(field);

    //we have onClick handler into sheet,
    //but we don't know will we click on sheet or not
    //and by default onBlur calls first and onClick then,
    //so we need to have some delay between onClick and resetting focus
    const resetFocusedDelay = 300;

    const onBlurStartPosition = () => {
        onTouch("startRegexPositions");

        setTimeout(() => {
            onChange({
                isStartRegexPositionsFocused: false
            });
        }, resetFocusedDelay);
    };

    const onBlurEndPosition = () => {
        onTouch("endRegexPositions");

        setTimeout(() => {
            onChange({
                isEndRegexPositionsFocused: false
            });
        }, resetFocusedDelay);
    };

    return (
        <div className={classes.root}>
            <div
                className={classes.titleWrapper}
                onClick={() => setIsOpen((prevState) => !prevState)}
            >
                <Typography
                    className={classes.title}
                    color={isOpen ? "textPrimary" : "textSecondary"}
                >
                    {getTranslation("specify-regular-expression")}
                </Typography>
                <div className={classes.icon}>
                    {isOpen ? <RemoveIcon /> : <AddIcon className={classes.openIcon} />}
                </div>
            </div>

            <Collapse in={isOpen}>
                <div className={classes.fields}>
                    <Typography color="textSecondary">{getTranslation("start")}</Typography>
                    <div className={classes.fieldRow}>
                        <Typography className={classes.fieldTitle}>
                            {getTranslation("start-value")}
                        </Typography>
                        <DebouncedTextField
                            onBlur={() => onTouch("startRegexpValue")}
                            helperText={startRegexpValueError}
                            error={!!startRegexpValueError}
                            placeholder={getTranslation("optional")}
                            variant="outlined"
                            value={startRegexpValue ?? ""}
                            className={classes.fieldInput}
                            onChangeValue={(startRegexpValue) =>
                                onChange({
                                    startRegexpValue
                                })
                            }
                        />
                    </div>
                    <div className={classes.fieldRow}>
                        <Typography className={classes.fieldTitle}>
                            {getTranslation("start-cell")}
                        </Typography>
                        <DebouncedTextField
                            onClick={() => {
                                onChange({
                                    isStartRegexPositionsFocused: true
                                });
                            }}
                            helperText={startRegexPositionsError}
                            error={!!startRegexPositionsError}
                            onBlur={onBlurStartPosition}
                            placeholder={startRegexpValue ? "" : getTranslation("optional")}
                            variant="outlined"
                            value={startRegexPositionsStr}
                            className={classes.fieldInput}
                            onChangeValue={(stringPosition) =>
                                onChange({
                                    startRegexPositions: stringPositionToPosition(stringPosition)
                                })
                            }
                        />
                    </div>
                    <div className={classes.fieldRow}>
                        <Typography className={classes.fieldTitle}>
                            {getTranslation("down-step")}
                        </Typography>
                        <DebouncedTextField
                            type="number"
                            variant="outlined"
                            helperText={startRegexStepDownError}
                            error={!!startRegexStepDownError}
                            onBlur={() => onTouch("startRegexStepDown")}
                            placeholder={startRegexpValue ? "" : getTranslation("optional")}
                            value={startRegexStepDown?.toString() ?? ""}
                            className={classes.fieldInput}
                            onChangeValue={(downRegexpStep) =>
                                onChange({
                                    startRegexStepDown: normalizeStringToNumber(downRegexpStep)
                                })
                            }
                        />
                    </div>
                    <div className={classes.fieldRow}>
                        <Typography className={classes.fieldTitle}>
                            {getTranslation("right-step")}
                        </Typography>
                        <DebouncedTextField
                            type="number"
                            variant="outlined"
                            helperText={startRegexStepRightError}
                            error={!!startRegexStepRightError}
                            onBlur={() => onTouch("startRegexStepRight")}
                            placeholder={startRegexpValue ? "" : getTranslation("optional")}
                            value={startRegexStepRight?.toString() ?? ""}
                            className={classes.fieldInput}
                            onChangeValue={(rightRegexpStep) =>
                                onChange({
                                    startRegexStepRight: normalizeStringToNumber(rightRegexpStep)
                                })
                            }
                        />
                    </div>
                    <Typography color="textSecondary">{getTranslation("end")}</Typography>
                    <div className={classes.fieldRow}>
                        <Typography className={classes.fieldTitle}>
                            {getTranslation("end-value")}
                        </Typography>
                        <DebouncedTextField
                            helperText={endRegexpValueError}
                            error={!!endRegexpValueError}
                            onBlur={() => onTouch("endRegexpValue")}
                            placeholder={getTranslation("optional")}
                            variant="outlined"
                            value={endRegexpValue ?? ""}
                            className={classes.fieldInput}
                            onChangeValue={(endRegexpValue) => onChange({ endRegexpValue })}
                        />
                    </div>
                    <div className={classes.fieldRow}>
                        <Typography className={classes.fieldTitle}>
                            {getTranslation("end-cell")}
                        </Typography>
                        <DebouncedTextField
                            onClick={() => {
                                onChange({
                                    isEndRegexPositionsFocused: true
                                });
                            }}
                            helperText={endRegexPositionsError}
                            error={!!endRegexPositionsError}
                            onBlur={onBlurEndPosition}
                            placeholder={endRegexpValue ? "" : getTranslation("optional")}
                            variant="outlined"
                            value={endRegexPositionsStr}
                            className={classes.fieldInput}
                            onChangeValue={(stringPosition) =>
                                onChange({
                                    endRegexPositions: stringPositionToPosition(stringPosition)
                                })
                            }
                        />
                    </div>
                </div>
            </Collapse>
        </div>
    );
};