export const getTranslation = (key: string, replacements?: string[]): string => {
    const dictionaryAsString = window.localStorage.getItem("dictionary");
    const dictionaryAsObject = JSON.parse(dictionaryAsString ?? "{}");

    let value = dictionaryAsObject[key];

    replacements?.forEach((item, i) => {
        const reg = new RegExp("\\{" + i + "\\}", "gm");
        value = value.replace(reg, item);
    });

    return value;
};
