import React from "react";
import { addMongoDBCodeDependenciesToGlobalScope } from "./stringified-code-eval-api";
import { makeStyles } from "@material-ui/styles";
import { Sidebar } from "./sidebar";
import { Content } from "./content";
import "./index.css";

import { theme, ThemeProvider } from "@assay/ui-kit";

addMongoDBCodeDependenciesToGlobalScope();

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        width: "100%",
        height: "100%"
    }
});

export const Assay: React.FC = () => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.wrapper}>
                <Sidebar />
                <Content />
            </div>
        </ThemeProvider>
    );
};
