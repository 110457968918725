import React from "react";
import { colors, makeStyles } from "@material-ui/core";

type Cell = number[] | string[];

type Props = {
    rows: Cell[][];
    headers?: string[];
};

const useStyles = makeStyles({
    table: {
        textAlign: "center",
        fontSize: "14px",
        borderSpacing: 0
    },
    header: {
        fontSize: "16px",
        fontWeight: "bold",
        backgroundColor: colors.grey[400]
    },
    oddRow: {
        "backgroundColor": colors.grey[200],
        "&:hover": {
            backgroundColor: colors.grey[300]
        }
    },
    evenRow: {
        "backgroundColor": "white",
        "&:hover": {
            backgroundColor: colors.grey[300]
        }
    },
    cell: {
        padding: "0 10px",
        border: `1px solid ${colors.grey[400]}`
    }
});

export const Table: React.FC<Props> = ({ rows, headers }) => {
    const classes = useStyles();
    return (
        <table className={classes.table}>
            <tbody>
                {!!headers?.length && (
                    <tr className={classes.header}>
                        {headers.map((header, i) => (
                            <th key={i} className={classes.cell}>
                                {header}
                            </th>
                        ))}
                    </tr>
                )}
                {rows.map((row, i) => {
                    return (
                        <tr key={i} className={i % 2 ? classes.oddRow : classes.evenRow}>
                            {row.map((cell) => (
                                (Array.isArray(cell) ? cell : [cell]).map((value, j) => (
                                    <td key={j} className={classes.cell}>
                                        {value ?? ""}
                                    </td>
                                ))
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
