import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ArrowDiagonal = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="m19.328538,10.009047l-1.994902,0l0,6.860971l-11.560454,-12.066563l-1.406406,1.467977l11.560454,12.066563l-6.573201,0l0,2.082237l9.974508,0l0,-10.411184z"
                fill={props.color}
            />
        </SvgIcon>
    );
};
