// TODO: Being used only in legacy form.js, needs to be deleted.
export function fixNumbers(object) {
    //convert field values that should be numbers to actual numbers in the JSON instead of strings of numbers

    //recursively iterate through all objects
    for (var property in object) {
        if (object.hasOwnProperty(property)) {
            if (typeof object[property] == "object" && object[property] != null) {
                fixNumbers(object[property]);
            }
        }
    }

    //in a field
    if (object.hasOwnProperty("value") && object.constructor.name == "Object") {
        if (object.hasOwnProperty("__pFieldOptions")) {
            if (object["__pFieldOptions"].hasOwnProperty("validation")) {
                if (
                    object["__pFieldOptions"]["validation"].indexOf("isNumber") != -1 ||
                    object["__pFieldOptions"]["validation"].indexOf("isInteger") != -1
                ) {
                    //in a field that should be a number

                    if ($.isArray(object["value"])) {
                        if ($.isArray(object["value"][0])) {
                            for (var i = 0; i < object["value"].length; i++) {
                                for (var j = 0; j < object["value"][i].length; j++) {
                                    if (object.hasOwnProperty("__pExcelOptions")) {
                                        if (
                                            object["__pExcelOptions"].hasOwnProperty("hasHeaders")
                                        ) {
                                            if (object["__pExcelOptions"]["hasHeaders"] && i == 0) {
                                                //skip items that have excel headers which don't need to be numbers
                                                continue;
                                            }
                                        }
                                    }
                                    //convert to number for each item in 2D array
                                    try {
                                        object["value"][i][j] = parseFloat(object["value"][i][j]);
                                    } catch (err) {}
                                }
                            }
                        } else {
                            for (var i = 0; i < object["value"].length; i++) {
                                if (object.hasOwnProperty("__pExcelOptions")) {
                                    if (object["__pExcelOptions"].hasOwnProperty("hasHeaders")) {
                                        if (object["__pExcelOptions"]["hasHeaders"] && i == 0) {
                                            //skip items that have excel headers which don't need to be numbers
                                            continue;
                                        }
                                    }
                                }
                                //convert to number for each item in 2D array
                                try {
                                    object["value"][i] = parseFloat(object["value"][i]);
                                } catch (err) {}
                            }
                        }
                    } else {
                        //convert single value field to number
                        try {
                            object["value"] = parseFloat(values);
                        } catch (err) {}
                    }
                }
            }
        }
    }
}

// TODO: Being used only in legacy form.js, needs to be deleted.
export function fixSort(form) {
    //create an object called _sort at the root of the form
    //{form....
    // _sort:{"fieldName":"fieldVal","fieldName2","fieldVal2"}
    //the purpose of this is to make a sort of view that is easier to sort than the more complicated queries of drilling down into the actual fields would be
    //key names have spaces replaced with _ and are lower cased
    var b = {};
    $.each(form.fields, function (i, field) {
        if (field["__pFieldOptions"]["type"] != "fieldSet") {
            try {
                b[
                    field["__pFieldOptions"]["name"]
                        .toLowerCase()
                        .replace(/\s+/gi, "_")
                        .replace(/\./gi, "")
                ] = field["value"];
            } catch (err) {}
        }
    });
    form._sort = b;
}

export function zeroPad(num, places) {
    //left pad with zeros
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}

export function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isInteger(n) {
    var int_regex = /^[0-9]+$/;
    return int_regex.test(n);
}

export function isDate(testdate) {
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)*\d{2}$/;
    return date_regex.test(testdate);
}

export function ensureArray(theVal) {
    if (Array.isArray(theVal)) {
        return theVal;
    } else {
        if (
            typeof theVal === "string" ||
            typeof theVal === "number" ||
            typeof theVal === "boolean" ||
            isObject(theVal)
        ) {
            return [theVal];
        } else {
            return [];
        }
    }
}

export const noop = () => {};

export const isObject = (value) =>
    typeof value === "object" && value !== null && !Array.isArray(value);

/**
 * If the argument is a number or number-like string
 * @param {String | Number} value 
 * @returns {Boolean}
 */
export const isNumeric = (value) => {
    return (typeof value === "number" || typeof value === "string" && value !== "") && !isNaN(value);
};
