import { getAppConfig } from "@assay/shared";

export const apiCall = async <T>(
    path: string,
    method: string,
    formData = {},
    headers = {}
): Promise<T> => {
    const emptyResponse = {} as T;
    const { dotnetApiUrl, authTokenProvider, connectionId } = getAppConfig();
    const authToken = await authTokenProvider();

    let url = `${dotnetApiUrl}/${path}`;
    let body: Record<string, string> | null = {
        connectionId,
        ...formData
    };

    try {
        if (method === "GET") {
            url += `?${new URLSearchParams(body).toString()}`;
            body = null;
        }

        const response = await fetch(url, {
            method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`,
                "Custom-ArxLab-ConnectionId": connectionId,
                ...headers
            },
            ...(body ? { body: JSON.stringify(body) } : {})
        });
        try {
            return await response.json();
        } catch (e) {
            return emptyResponse;
        }
    } catch (e) {
        console.warn(`Error on request ${path}`, e);
        return emptyResponse;
    }
};

export const downloadFile = async (fileName: string, fileId: string): Promise<void> => {
    const { dotnetApiUrl, authTokenProvider, connectionId } = getAppConfig();
    const authToken = await authTokenProvider();

    const body = JSON.stringify({
        connectionId,
        fileId
    });

    const url = `${dotnetApiUrl}/getFile`;

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
            "Custom-ArxLab-ConnectionId": connectionId
        },
        body
    });

    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
};

export const uploadFile = async (file: File): Promise<number> => {
    const { dotnetApiUrl, authTokenProvider, connectionId } = getAppConfig();
    const authToken = await authTokenProvider();

    const body = new FormData();
    body.append("connectionId", connectionId);
    body.append("file", file);

    const url = `${dotnetApiUrl}/files`;

    const response = await fetch(url, {
        method: "POST",
        redirect: "follow",
        headers: {
            "Authorization": `Bearer ${authToken}`,
            "Custom-ArxLab-ConnectionId": connectionId
        },
        body
    });

    const json = await response.json();
    return json.fileId;
};
