import { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { EditPermsPopup } from "./edit-perms-popup";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles({
    root: {
        padding: "4px",
        fontSize: "0.75rem",
        fontWeight: "bold",
        color: "blue"
    }
});

export const EditPerms = ({ fieldValue, onSubmit }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <Button classes={classes} onClick={() => setOpen(true)}>
                {getTranslation("edit")}
            </Button>
            <EditPermsPopup
                open={open}
                fieldValue={fieldValue}
                onSubmit={onSubmit}
                onClose={() => setOpen(false)}
            />
        </Fragment>
    );
};
