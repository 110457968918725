import React, { ReactElement, useEffect } from "react";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "./store";
import { UploadTemplateEditScreen, UploadTemplateViewScreen } from "./screens";
import { makeStyles } from "@assay/ui-kit";
import { CircularProgress } from "@material-ui/core";
import { CreatedInformation, ResultDefinitionsQueryParams, UploadTemplateCallbacks } from "./types";
import { FilesRetryDialog, RequestsResultAlert } from "./containers";
import { useLoadResultDefinitions } from "./hooks";
import { getGlobalPermissions } from "@assay/shared";

export const useStyles = makeStyles((theme) => ({
    root: {
        "background": theme.palette.grey[200],
        "position": "absolute",
        "display": "flex",
        "flexDirection": "column",
        "left": 0,
        "right": 0,
        "top": 0,
        "bottom": 0,
        "& > *:not(:last-child)": {
            marginBottom: "1.5rem"
        }
    },
    loader: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));

const stateSelector = ({
    id,
    editMode,
    actions,
    loadingDataInProcess
}: UploadTemplateStore) => ({
    existId: id,
    loadingDataInProcess,
    editMode: editMode,
    initExistedTemplate: actions.loadTemplate,
    initExternals: actions.initExternals,
    reset: actions.reset
});

//those props for integration in assay
type Props = {
    id?: string;
    editMode?: boolean;
    created?: CreatedInformation;
    callbacks: UploadTemplateCallbacks;
    resultDefinitionsQueryParams: ResultDefinitionsQueryParams;
};

export const UploadTemplates = ({
    id,
    editMode: initEditMode = false,
    created,
    callbacks,
    resultDefinitionsQueryParams
}: Props): ReactElement => {
    const classes = useStyles();
    const {
        editMode,
        initExistedTemplate,
        loadingDataInProcess,
        initExternals,
        existId,
        reset
    } = useShallowUploadTemplateStore(stateSelector);

    useLoadResultDefinitions();

    useEffect(() => {
        initExternals({
            callbacks,
            created,
            resultDefinitionsQueryParams
        });
    }, [callbacks, created, initExternals, resultDefinitionsQueryParams]);

    useEffect(() => {
        if (id && (id !== existId || initEditMode !== editMode)) {
            initExistedTemplate(id, initEditMode);
        } else if (!id) {
            reset(true);
        }
    }, [id, existId, initEditMode, editMode, initExistedTemplate, reset]);

    const safeEditMode = editMode && getGlobalPermissions().canEdit;

    return (
        <div className={classes.root}>
            {loadingDataInProcess && (
                <div className={classes.loader}>
                    <CircularProgress />
                </div>
            )}
            {safeEditMode && !loadingDataInProcess && <UploadTemplateEditScreen />}
            {!safeEditMode && !loadingDataInProcess && <UploadTemplateViewScreen />}
            <RequestsResultAlert />
            <FilesRetryDialog />
        </div>
    );
};
