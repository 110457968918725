import { Tokens } from "../types";
import { config } from "../config";
import { getEnv } from "@assay/shared";

const { APP_NAME: appName } = config;

type SignInData = {
    accessToken: string;
    refreshToken: string;
    expiresInSeconds: number;
    tokenType: "bearer";
};

type RefreshData = {
    accessToken: string;
    refreshToken: string;
    expiresInSeconds: number;
};

type SignIn = (userName: string, password: string, apiKey: string) => Promise<SignInData>;
type Refresh = (tokens: NonNullable<Tokens>) => Promise<RefreshData>;

const request = async (...args: Parameters<typeof fetch>) => {
    const response = await fetch(...args);
    const json = await response.json();
    return JSON.parse(json.data);
};

export const signIn: SignIn = (userName, password, apiKey) => {
    return request(`${getEnv("REACT_APP_AUTH_API_URL")}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            appName,
            user: userName,
            password,
            apiKey
        })
    });
};

export const refreshTokens: Refresh = (tokens) => {
    return request(`${getEnv("REACT_APP_AUTH_API_URL")}/auth/refresh`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...tokens, appName })
    });
};
