import { useQuery } from "react-query";
import { Entities, Entity } from "../types";
import { selectOfType, useGetRdsAsEntities } from "../api-v2";
import { getUploadTemplateList } from "@assay/upload-templates";
import { Features, featureService } from "@assay/shared";

const getNewUts = async () => {
    const uts = await getUploadTemplateList();
    return uts.map((ut) => [ut.id, ut.name]);
};

const getOldUts = () => selectOfType(1902);

const useGetUtsAsEntities = () => {
    const queryFn = featureService[Features.IsNewUploadTemplatesOn] ? getNewUts : getOldUts;
    return useQuery("uploadTemplates", queryFn);
};

type UseEntitiesResult = {
    isLoading: boolean;
    refetch: (entitiesName: string) => void;
    data: {
        [key in Entities]: Entity[];
    };
};

export const useEntities = (): UseEntitiesResult => {
    const rds = useGetRdsAsEntities();
    const uts = useGetUtsAsEntities();
    const recipes = useQuery("recipes", () => selectOfType(1807));
    const afs = useQuery("analysisFunctions", () => selectOfType(4641));

    const isLoading = !rds.data || !uts.data || !recipes.data || !afs.data;

    const refetch = (entitiesName: string): void => {
        if (entitiesName === "Upload Template" || entitiesName === "Upload Templates") {
            uts.refetch();
        } else if (entitiesName === "Recipe" || entitiesName === "Recipes") {
            recipes.refetch();
        } else if (entitiesName === "Analysis Function" || entitiesName === "Analysis Functions") {
            afs.refetch();
        } else if (entitiesName === "Result Definition" || entitiesName === "Result Definitions") {
            rds.refetch();
        }
    };

    return {
        isLoading,
        refetch,
        data: {
            [Entities.Rd]: rds.data,
            [Entities.Ut]: uts.data,
            [Entities.Recipes]: recipes.data,
            [Entities.Af]: afs.data
        }
    };
};
