import { ExtendedTheme, makeStyles, OutlinedAutocomplete } from "@assay/ui-kit";
import React, { useRef } from "react";
import { useRefSize } from "../hooks";
import { DataSheetMatrix, Option, SelectedSheetMatrix, TemplateRange } from "../types";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { Sheet } from "./sheet";
import { getTranslation } from "@assay/shared";

type StyleProps = {
    height: number;
    width: number;
};

const useStyles = makeStyles<ExtendedTheme, StyleProps>((theme) => ({
    root: {
        "paddingTop": theme.spacing(1),
        "paddingLeft": theme.spacing(1),
        "paddingBottom": theme.spacing(1),
        "paddingRight": theme.spacing(0.25),
        "display": "flex",
        "flexDirection": "column",
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(2)
        },
        "width": "100%",
        "height": "100%",
        "boxSizing": "border-box"
    },
    header: {
        "display": "flex",
        "alignItems": "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(2)
        }
    },
    sheetSelect: {
        width: 300
    },
    sheetRoot: {
        position: "relative",
        flexGrow: 1,
        width: "100%",
        overflow: "hidden"
    },
    sheetInner: ({ height, width }) => ({
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        flex: "1 1 0%",
        overflow: "hidden",
        height: `${height}px`,
        width: `${width}px`
    })
}));

type Props<T extends Option> = {
    sheetChangeDisabled?: boolean;
    currentSheet: T | null;
    sheetsOptions: T[];
    onChangeSheet: (sheet: T | null) => void;
    editMode: boolean;
    dataSheetMatrix: DataSheetMatrix;
    selectedSheetMatrix: SelectedSheetMatrix;
    onChange: (matrix: SelectedSheetMatrix, template?: TemplateRange) => void;
    templateMode?: boolean;
    isDisplayOnEmptyCells: boolean;
    isDisplayOnEmptyCellsDisabled: boolean;
    onChangeIsDisplayOnEmptyCells: (checked: boolean) => void;
};

export const SheetEditor = <T extends Option>({
    onChangeSheet,
    currentSheet,
    sheetsOptions,
    dataSheetMatrix,
    selectedSheetMatrix,
    editMode,
    onChange,
    sheetChangeDisabled,
    templateMode,
    isDisplayOnEmptyCells,
    isDisplayOnEmptyCellsDisabled,
    onChangeIsDisplayOnEmptyCells
}: Props<T>): React.ReactElement => {
    const ref = useRef<HTMLDivElement>(null);
    const { height, width } = useRefSize(ref);
    const classes = useStyles({ height, width });

    const isSheetExist = !!(dataSheetMatrix && selectedSheetMatrix && currentSheet);
    const isSheetOptionExist = !!sheetsOptions.find((option) => option.name === currentSheet?.name);

    return (
        <div className={classes.root}>
            <Typography variant={"h3"}>{getTranslation("upload-template")}</Typography>

            <>
                <div className={classes.header}>
                    <OutlinedAutocomplete<T>
                        disableClearable={true}
                        disabled={sheetChangeDisabled}
                        getOptionSelected={(option, value) => option.name === value.name}
                        size={"medium"}
                        options={sheetsOptions}
                        value={isSheetOptionExist ? currentSheet : null}
                        onChange={(_, value) => onChangeSheet(value)}
                        getOptionLabel={(option) => option.name}
                        className={classes.sheetSelect}
                        label={getTranslation("tab")}
                    />
                    <FormControlLabel
                        disabled={isDisplayOnEmptyCellsDisabled}
                        checked={isDisplayOnEmptyCells}
                        control={
                            <Checkbox
                                color={"primary"}
                                onChange={(_, checked) => onChangeIsDisplayOnEmptyCells(checked)}
                                name="isDisplayOnEmptyCells"
                            />
                        }
                        label={getTranslation("display-mapping-preview-on-empty-cells")}
                    />
                </div>

                <div className={classes.sheetRoot} ref={ref}>
                    <div className={classes.sheetInner}>
                        {isSheetExist && (
                            <Sheet
                                templateMode={templateMode}
                                editMode={editMode}
                                width={`${width}px`}
                                height={`${height}px`}
                                dataSheetMatrix={dataSheetMatrix}
                                selectedSheetMatrix={selectedSheetMatrix}
                                onChange={onChange}
                                isDisplayOnEmptyCells={isDisplayOnEmptyCells}
                            />
                        )}
                    </div>
                </div>
            </>
        </div>
    );
};
