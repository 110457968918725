import React, { useCallback } from "react";
import { Typography } from "@material-ui/core";
import { useShallowUploadTemplateStore } from "../store";
import { MergeType } from "../types";
import {
    CommaIcon,
    DashIcon,
    DeleteIcon,
    makeStyles,
    OutlinedIconButton,
    SpaceIcon
} from "@assay/ui-kit";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles((theme) => ({
    root: {
        "margin": theme.spacing(1),
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(1)
        }
    },
    buttons: {
        "display": "flex",
        "width": "100%",
        "justifyContent": "flex-start",
        "alignItems": "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    }
}));

type Props = {
    fieldIndex: number;
};

export const ConcatenationMergeType: React.FC<Props> = ({ fieldIndex }) => {
    const classes = useStyles();
    const { mergeType, changeMergeType } = useShallowUploadTemplateStore(
        useCallback(
            ({ fields, actions: { changeField } }) => ({
                mergeType: fields[fieldIndex].mergeType,
                changeMergeType: (mergeType: MergeType) =>
                    changeField(fieldIndex, {
                        ...fields[fieldIndex],
                        mergeType
                    })
            }),
            [fieldIndex]
        )
    );
    return (
        <div className={classes.root}>
            <Typography color="textSecondary" variant={"body2"}>
                {getTranslation("concatenation-merge")}
            </Typography>
            <div className={classes.buttons}>
                <OutlinedIconButton
                    onClick={() => changeMergeType("empty")}
                    startIcon={<DeleteIcon />}
                    type={mergeType === "empty" ? "primary" : "secondary"}
                />
                <OutlinedIconButton
                    onClick={() => changeMergeType("comma")}
                    startIcon={<CommaIcon />}
                    type={mergeType === "comma" ? "primary" : "secondary"}
                />
                <OutlinedIconButton
                    onClick={() => changeMergeType("dash")}
                    startIcon={<DashIcon />}
                    type={mergeType === "dash" ? "primary" : "secondary"}
                />
                <OutlinedIconButton
                    onClick={() => changeMergeType("space")}
                    startIcon={<SpaceIcon />}
                    type={mergeType === "space" ? "primary" : "secondary"}
                />
            </div>
        </div>
    );
};
