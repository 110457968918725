import { apiCall } from "./common";

type User = {
    name: string;
    id: number;
};

type UserGroup = User & {
    users: User[];
};

type FileInfo = {
    id: number;
    typeId: number;
    filename: string;
    actualFileName: string;
    fileExtension: string;
    path: string;
};

export const getUserGroups = async (): Promise<UserGroup[]> => {
    const userGroups: UserGroup[] = await apiCall("getUserGroups", "POST");
    return userGroups;
};

export const getUsers = async (): Promise<User[]> => {
    return await apiCall("getUserList2", "POST", {});
};

export const getHistory = async (hash: string): Promise<{ functionData: any }> => {
    return await apiCall("getHistory", "POST", { hash });
};

export const selectOfType = async (typeId: number): Promise<any> => {
    //used for admin creates the options object necessary to show a select box of object types
    const { ops } = await apiCall("selectOfType", "POST", { typeIds: [typeId] });
    return ops;
};

export const getAllowedChildren = async (id: number): Promise<any> => {
    return await apiCall("getAllowedChildren", "POST", { id });
};

export const getNextId = async (): Promise<number> => {
    const { id } = await apiCall<{ id: number }>("getNextId", "POST", {});
    return id;
};

export const getNextSequence = async (seq: number): Promise<number> => {
    const { seq: nextSeq } = await apiCall<{ seq: number }>("nextSequence", "POST", { seq });
    return nextSeq;
};

export const getParentType = async (key: string): Promise<string> => {
    const { name } = await apiCall<{ name: string }>("getParentType", "POST", { key });
    return name;
};

export const getFileInfo = async (id: number): Promise<FileInfo> => {
    const fileInfo = await apiCall<FileInfo>(`files/${id}/info`, "GET");
    return fileInfo;
};
