import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { initSheets, readSheets } from "../helpers";
import { Dropzone, DROPZONE_EXTENSIONS_OPTIONS, makeStyles } from "@assay/ui-kit";
import { getTranslation } from "@assay/shared";
import { ConfirmDialog } from "../components";

const useStyles = makeStyles((theme) => ({
    root: {
        "padding": theme.spacing(1),
        "display": "flex",
        "flexDirection": "column",
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(4)
        }
    },
    buttons: {
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    }
}));

const selectState = ({ actions, callbacks: { onCancel } }: UploadTemplateStore) => ({
    setFileParsingInProgress: actions.setFileParsingInProgress,
    setFileParsingError: actions.setFileParsingError,
    initMainFile: actions.initMainFile,
    initWithoutFile: actions.initWithoutFile,
    onCancel
});

export const UploadTemplateNew = () => {
    const classes = useStyles();
    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
    const {
        initMainFile,
        setFileParsingInProgress,
        setFileParsingError,
        initWithoutFile,
        onCancel
    } = useShallowUploadTemplateStore(selectState);

    const handleFileChoose = async ([file]: File[]) => {
        setFileParsingInProgress(true);
        const { error, sheets } = await readSheets(file);

        error ? setFileParsingError(error) : initMainFile(initSheets(sheets), file);

        setFileParsingInProgress(false);
    };

    const onSubmitPopup = () => {
        onCancel();
        setIsCancelPopupOpen(false)
    }

    return (
        <>
            <div className={classes.root}>
                <Typography variant={"h3"}>{getTranslation("upload-template")}</Typography>

                <Dropzone
                    sizeLimitMb={25}
                    onFilesChoose={(files) => handleFileChoose(files as File[])}
                    extensionOption={DROPZONE_EXTENSIONS_OPTIONS.table}
                />
                <div className={classes.buttons}>
                    <Button variant="outlined" color="secondary" onClick={initWithoutFile}>
                        {getTranslation("continue-without-file")}
                    </Button>
                    <Button color="secondary" onClick={() => setIsCancelPopupOpen(true)}>
                        {getTranslation("cancel")}
                    </Button>
                </div>
            </div>
            <ConfirmDialog
                onClose={() => setIsCancelPopupOpen(false)}
                isOpen={isCancelPopupOpen}
                onSubmit={onSubmitPopup}
                title={getTranslation("cancel-template-creation")}
            />
        </>
    );
};
