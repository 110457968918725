import { useState, useEffect } from "react";
import { Token, Tokens } from "../types";

type Subscriber = (tokens: Tokens) => void;
type Unsubscribe = () => void;

interface ITokenStorage {
    getTokens: () => Tokens;
    setTokens(tokens: Tokens): void;
    clearTokens(): void;
    subscribe(subscriber: Subscriber): Unsubscribe;
}

const EMPTY_TOKENS = {
    accessToken: null,
    refreshToken: null
};

const LS_TOKENS_KEY = "__TOKENS__";

/**
 * stores and manages tokens in local storage
 */
class TokenStorage implements ITokenStorage {
    private tokens: Tokens;
    private subscribers: Subscriber[] = [];

    constructor() {
        this.tokens = this.retrieveTokensFromLocalStorage();
    }

    private retrieveTokensFromLocalStorage = (): Tokens => {
        const valueFromLs = localStorage.getItem(LS_TOKENS_KEY);

        if (typeof valueFromLs === "string") {
            return JSON.parse(valueFromLs);
        }

        return EMPTY_TOKENS;
    };

    private saveToLocalStorage = (tokens: Tokens) => {
        const areExistentTokens = Object.values(tokens).every((token) => token !== null);

        if (areExistentTokens) {
            localStorage.setItem(LS_TOKENS_KEY, JSON.stringify(tokens));
        } else {
            localStorage.removeItem(LS_TOKENS_KEY);
        }
    };

    getTokens = () => {
        return this.tokens;
    };

    subscribe = (subscriber: Subscriber) => {
        this.subscribers.push(subscriber);

        return () => {
            this.subscribers = this.subscribers.filter((sub) => sub !== subscriber);
        };
    };

    private notifySubscribers(tokens: Tokens) {
        this.subscribers.forEach((subscriber) => {
            subscriber(tokens);
        });
    }

    clearTokens() {
        this.setTokens(EMPTY_TOKENS);
    }

    setTokens(tokens: Tokens) {
        this.tokens = tokens;
        this.saveToLocalStorage(tokens);
        this.notifySubscribers(tokens);
    }
}

export const tokenStorage = new TokenStorage();

/**
 * Subscribes a caller to accessToken updates
 * @returns {Token} access token from local storage
 */
export const useAccessToken = (): Token => {
    const [token, setToken] = useState(tokenStorage.getTokens().accessToken);

    useEffect(() => {
        return tokenStorage.subscribe((tokens) => setToken(tokens.accessToken));
    }, []);

    return token;
};
