import { AnyObj, FormData } from "../../../types";
import { isDate, isInteger, isNumber } from "../../../helpers";
import { getTranslation } from "@assay/shared";

const generateErrorMessage = (
    field: AnyObj,
    sheet = "",
    recordNumber: number,
    i: number,
    j: number,
    theVal: any,
    errorText: string
): string => {
    const { __pExcelOptions: excelOpts, __pFieldOptions: fieldOpts } = field;

    let leftOffset = parseInt(excelOpts.leftOffset);
    let topOffset = parseInt(excelOpts.topOffset);
    const leftOffsetOffset = parseInt(excelOpts.repeatWidth);
    const topOffsetOffset = parseInt(excelOpts.repeatHeight);
    leftOffset = leftOffset + leftOffsetOffset * recordNumber;
    topOffset = topOffset + topOffsetOffset * recordNumber;

    if (excelOpts.hasHeaders) {
        i = i + 1;
    }
    return `
        Sheet: ${sheet},
        Record: ${recordNumber + 1},
        Field: ${fieldOpts.name},
        Cell: ${colNumberToString(leftOffset + j)}:${topOffset + i},
        Value: ${theVal},
        Error: ${errorText}`;
};

/**
 *  Converts column number to name. e.g 28 = AB
 */
const colNumberToString = (n: number): string => {
    n = n - 1;
    const ordA = "a".charCodeAt(0);
    const ordZ = "z".charCodeAt(0);
    const len = ordZ - ordA + 1;

    let s = "";
    while (n >= 0) {
        s = String.fromCharCode((n % len) + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
};

type DataSize = {
    width: number;
    height: number;
};

const calcDataSize = (field: any): DataSize => {
    const { __pExcelOptions: excelOpts } = field;

    const reverse = excelOpts.reverse || false;
    let width;
    let height;

    if (reverse) {
        width = parseInt(excelOpts.dataHeight);
        height = parseInt(excelOpts.dataWidth);
    } else {
        width = parseInt(excelOpts.dataWidth);
        height = parseInt(excelOpts.dataHeight);
    }

    if (!Array.isArray(field.value)) {
        width = 1;
        height = 1;
    } else {
        if (Array.isArray(field.value[0])) {
            height = field.value[0].length;
            width = field.value.length;
        } else {
            height = field.value.length;
            width = 1;
        }
    }

    return { width, height };
};

export const validateExcel = (recordNumber: number, form: FormData): string[] => {
    const errors: string[] = [];
    const { sheet } = form;

    form.fields.forEach((field) => {
        const { __pExcelOptions: excelOpts, __pFieldOptions: fieldOpts } = field;

        if (!excelOpts) {
            return;
        }

        const { width, height } = calcDataSize(field);

        let fieldVal: any = field.value;
        if (width === 1) {
            fieldVal = height === 1 ? [[fieldVal]] : [fieldVal];
        }

        const start = excelOpts.hasHeaders ? 1 : 0;

        for (let i = start; i < height; i++) {
            for (let j = 0; j < width; j++) {
                const val = fieldVal[j][i];

                if (excelOpts.leftOffset === 0) {
                    continue;
                }

                fieldOpts?.validation?.forEach((validation: string) => {
                    if (val === "" || val === null) {
                        return;
                    }

                    if (validation === "isNumber" && !isNumber(val)) {
                        const text = getTranslation("errors.field-must-contain-only-numbers");
                        const msg = generateErrorMessage(
                            field,
                            sheet,
                            recordNumber,
                            i,
                            j,
                            val,
                            text
                        );
                        errors.push(msg);
                    }

                    if (validation === "isInteger" && !isInteger(val)) {
                        const text = getTranslation("errors.field-must-be-integer");
                        const msg = generateErrorMessage(
                            field,
                            sheet,
                            recordNumber,
                            i,
                            j,
                            val,
                            text
                        );
                        errors.push(msg);
                    }

                    if (validation === "isDate" && !isDate(val)) {
                        const text = getTranslation("errors.field-must-contain-data");
                        const msg = generateErrorMessage(
                            field,
                            sheet,
                            recordNumber,
                            i,
                            j,
                            val,
                            text
                        );
                        errors.push(msg);
                    }
                });

                if (fieldOpts?.required && (val === "" || val === null)) {
                    const text = getTranslation("errors.field-required");
                    errors.push(generateErrorMessage(field, sheet, recordNumber, i, j, val, text));
                }

                if (fieldOpts?.type === "select" && !fieldOpts.options.contains(val)) {
                    const text = getTranslation("errors.field-no-valid-option", [
                        fieldOpts.options.join(", ")
                    ]);
                    errors.push(generateErrorMessage(field, sheet, recordNumber, i, j, val, text));
                }
            }
        }
    });
    return errors;
};
