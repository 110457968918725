import { TreeView as MuiTreeView } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "react-query";
import { TreeViewItem } from "./components";

const useStyles = makeStyles({
    tree: {
        padding: "5px",
        height: "100%",
        boxSizing: "border-box",
        overflow: "hidden auto"
    }
});

export const TreeView = ({ expandedItems, selectedItem, onItemClick, onContextMenu, query }) => {
    const classes = useStyles();
    const { data } = useQuery(query);

    if (!data) {
        return null;
    }

    return (
        <MuiTreeView
            className={classes.tree}
            expanded={expandedItems}
            selected={selectedItem}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {data.map((item, i) => (
                <TreeViewItem
                    key={i}
                    item={item}
                    onClick={onItemClick}
                    onContextMenu={onContextMenu}
                />
            ))}
        </MuiTreeView>
    );
};
