import { Overrides } from "@material-ui/core/styles/overrides";

import { grey } from "../colors";

export const MuiInputBase: Overrides["MuiInputBase"] = {
    input: {
        backgroundColor: grey[100]
    },

    inputMarginDense: {
        paddingTop: "4.5px",
        paddingBottom: "4.5px"
    }
};

export const MuiInputLabel: Overrides["MuiInputLabel"] = {
    outlined: {
        transform: "translate(14px, 12px) scale(1)"
    }
};
