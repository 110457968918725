import "react";
import { getFieldByName } from "../form-utils";
import { Table } from "@assay/shared";

type Props = {
    fields: any[];
    groupFieldNames?: string[];
    width?: number;
    height?: number;
};

export const FieldTable: React.FC<Props> = ({ fields, groupFieldNames, height, width }) => {
    let rows = [];
    let headers: string[] = [];
    if (groupFieldNames) {
        const columns = groupFieldNames.map(header => getFieldByName(fields, header)?.value || []);
        const height = Math.max(...columns.map(column => column?.length || 0));
        if (height === 0) {
            return null;
        }
        for (let i = 0; i < height; i++) {
            rows.push(groupFieldNames.map((_, j) => columns[j]?.[i]));
        }
        headers = groupFieldNames;
    } else if (height && width) {
        for (let i = 0; i < height; i++) {
            const row = [];
            for (let j = 0; j < width; j++) {
                row.push(fields[j][i]);
            }
            rows.push(row)
        }
    }
    
    return (
        <Table headers={headers} rows={rows}  />
    );
};
