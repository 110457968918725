import { Overrides } from "@material-ui/core/styles/overrides";

export const MuiTabs: Overrides["MuiTabs"] = {
    indicator: {
        height: 2,
        borderRadius: "3px"
    },

    root: {
        height: 40,
        minHeight: 40
    }
};
