import { useQuery, UseQueryResult, QueryClient } from "react-query";
import { apiCall } from "./common";
import { sortTreeItems } from "../helpers";
import { TreeNode } from "../types";

type ParentTreeResponse = {
    parentTree: number[];
};

export const getParentTree = async (id: number): Promise<number[]> => {
    const response = await apiCall<ParentTreeResponse>("getParentTree", "POST", {
        id
    });
    return response.parentTree;
};

const getTree = async (itemId: number | string): Promise<TreeNode[]> => {
    return await apiCall<TreeNode[]>("getTree", "POST", {
        key: itemId,
        mode: "all"
    });
};

const getAssayTree = async (): Promise<TreeNode[]> => {
    return await apiCall<TreeNode[]>("getAssayTree", "POST", {
        key: "350",
        mode: "all"
    });
};

const getProtocolTree = async (): Promise<TreeNode[]> => {
    return await apiCall<TreeNode[]>("getProtocolTree", "POST", {
        key: "350",
        mode: "all"
    });
};

const getTreeQueryKey = (id: number | string): string => `treeItems-${id}`;

type TreeQuery = {
    queryKey: string;
    queryFn: (id?: string | number) => Promise<TreeNode[]>;
};

export const assayTreeQuery: TreeQuery = {
    queryKey: getTreeQueryKey("assays"),
    queryFn: getAssayTree
};

export const protocolTreeQuery: TreeQuery = {
    queryKey: getTreeQueryKey("protocols"),
    queryFn: getProtocolTree
};

export const rootTreeQuery: TreeQuery = {
    queryKey: getTreeQueryKey("root"),
    queryFn: () => getTree("root")
};

const fetchTree = async (id: number | string): Promise<TreeNode[]> => {
    const items = await getTree(Number(id));
    return sortTreeItems(items);
};

export const useTreeQuery = (id: number | string): UseQueryResult<TreeNode[]> => {
    return useQuery({
        queryKey: getTreeQueryKey(id),
        queryFn: () => fetchTree(id),
        enabled: false
    });
};

export const refetchTreeNode = (id: number | string, queryClient: QueryClient): Promise<void> => {
    const key = getTreeQueryKey(id);
    const isExist = !!queryClient.getQueryData(key);

    if (isExist) {
        return queryClient.refetchQueries(key);
    } else {
        return queryClient.fetchQuery(key, () => fetchTree(id));
    }
};
