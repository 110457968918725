import { ensureArray, isObject } from "./helpers";

/**
 * Mutates passed object by attaching dunder properties to it.
 * @param object - mutable object to which dunder properties will be added
 * @param propertyName - purpose of this arg is unclear. Seems like it is used to handle some exceptional cases
 *
 * @refactor Function is only being used as a part of Form constructor
 */
export function attachOptions(object, propertyName="") {
    //attach default dunder objects

    //recursively iterate through all objects
    for (var property in object) {
        if (object.hasOwnProperty(property)) {
            if (typeof object[property] == "object" && object[property] != null) {
                if (property != "_links") {
                    attachOptions(object[property], property);
                }
            }
        }
    }

    //make sure we are in a field
    if (
        propertyName.substring(0, 2) != "__" &&
        object &&
        object.constructor.name == "Object" &&
        propertyName != "userUpdated" &&
        propertyName != "userAdded"
    ) {
        //add empty dunders if they do not already exist
        object["__pFieldOptions"] = object["__pFieldOptions"] || {};
        object["__pDefOptions"] = object["__pDefOptions"] || {};
        object["__pFunctions"] = object["__pFunctions"] || {};
        object["__pExcelOptions"] = object["__pExcelOptions"] || {};
        object["__pDisplayOptions"] = object["__pDisplayOptions"] || {};
    }
}

export function removeParentField(object) {
    //remove parentField Property from all fields and fieldsets

    //recursively iterate through all objects
    for (var property in object) {
        if (object.hasOwnProperty(property)) {
            if (typeof object[property] == "object" && object[property] != null) {
                removeParentField(object[property]);
            }
        }
    }

    //delete parentField property if it exists
    if (object.hasOwnProperty("parentField")) {
        delete object["parentField"];
    }
}

export function attachFieldByName(object) {
    //attaches getFieldByName function to field objects and getFieldNames to form/fieldSet

    //recursively iterate through all nested objects
    for (var property in object) {
        if (object.hasOwnProperty(property)) {
            if (typeof object[property] == "object" && object[property] != null) {
                if (property != "_links") {
                    attachFieldByName(object[property]);
                }
            }
        }
    }

    //add function for objects that have fields
    if (object && object.hasOwnProperty("fields")) {
        if (!object["__pFieldOptions"]["multi"]) {
            //if the field is not multi i.e. not a multi fieldSet
            //attach a function that iterates through each field in the objects field array
            //and return field that matches the name provided

            object.getFieldByName = (function (ob) {
                return function (theName) {
                    var result = false;

                    $.each(ob.fields, function (i, field) {
                        if (
                            field?.["__pFieldOptions"]?.["name"].toLowerCase() ==
                            theName.toLowerCase()
                        ) {
                            result = field;
                            return false;
                        }
                    });
                    return result;
                };
            })(object);
            //attacgh function that returns an array of the names of each field
            object.getFieldNames = (function (ob) {
                return function () {
                    var result = [];
                    $.each(ob.fields, function (i, field) {
                        result.push(field["__pFieldOptions"]["name"]);
                    });
                    return result;
                };
            })(object);
        } else {
            //is a multi fieldSet

            //in this case we need to go one level deeper.  the structure looks like this {someField...,fields[[{field1},{field2},{field3}],[{field1},{field2},{field3}]]}
            //loop through outside array of multi fieldSet and attach a function to return the matching field for each fieldSet of the multi fieldSet
            $.each(object.fields, function (i, fieldArrayItem) {
                fieldArrayItem.getFieldByName = (function (ob) {
                    return function (theName) {
                        var result = false;
                        $.each(ob, function (i, field) {
                            if (
                                field?.["__pFieldOptions"]?.["name"].toLowerCase() ==
                                theName.toLowerCase()
                            ) {
                                result = field;
                                return false;
                            }
                        });
                        return result;
                    };
                })(fieldArrayItem);
            });

            //loop through outside array of multi fieldSet and attach a function to return an array of the field names for each fieldSet of the multi fieldSet
            $.each(object.fields, function (i, fieldArrayItem) {
                fieldArrayItem.getFieldNames = (function (ob) {
                    return function () {
                        var result = [];
                        $.each(ob, function (i, field) {
                            result.push(field["__pFieldOptions"]["name"]);
                        });
                        return result;
                    };
                })(fieldArrayItem);
            });
        }
    }
}

export function attachGetValArray(object) {
    //reformats value data for a field or fieldset
    //for a field returns {"fieldName":"value"}
    //for a fieldSet returns {"fieldSetName.fieldName":"value"}
    //for a multi fieldSet returns {"fieldSetName.fieldName1":[val1,val2,val3....],"fieldSetName.fieldName2":[val1,val2,val3....]}

    //iterate through nested objects
    for (var property in object) {
        if (object.hasOwnProperty(property)) {
            if (typeof object[property] == "object" && object[property] != null) {
                if (property != "_links") {
                    attachGetValArray(object[property]);
                }
            }
        }
    }

    //only for fields (items with __pFieldOptions)
    if (object && object.hasOwnProperty("__pFieldOptions")) {
        if (object["__pFieldOptions"]["type"] == "fieldSet") {
            object.getValArray = (function (ob) {
                return function () {
                    if (ob["__pFieldOptions"]["multi"]) {
                        r = {};
                        //loop through each fieldSet in a multi fieldSet if our field name (fieldSetName.fieldName) does not yet exist, add it
                        //then push values onto field names for each fieldSet
                        $.each(ob.fields, function (i, fieldItemArray) {
                            $.each(fieldItemArray, function (i, field) {
                                if (field["__pFieldOptions"]["type"] != "fieldSet") {
                                    if (
                                        !r.hasOwnProperty(
                                            ob["__pFieldOptions"]["name"] +
                                                "." +
                                                field["__pFieldOptions"]["name"]
                                        )
                                    ) {
                                        r[
                                            ob["__pFieldOptions"]["name"] +
                                                "." +
                                                field["__pFieldOptions"]["name"]
                                        ] = [];
                                    }
                                    if (field["__pFieldOptions"]["multi"]) {
                                        r[
                                            ob["__pFieldOptions"]["name"] +
                                                "." +
                                                field["__pFieldOptions"]["name"]
                                        ].push(field["value"] || []);
                                    } else {
                                        r[
                                            ob["__pFieldOptions"]["name"] +
                                                "." +
                                                field["__pFieldOptions"]["name"]
                                        ].push(field["value"] || "");
                                    }
                                }
                            });
                        });
                        return r;
                    } else {
                        //add value by appropriate name for each field in the fieldset
                        r = {};
                        $.each(ob.fields, function (i, field) {
                            if (field["__pFieldOptions"]["type"] != "fieldSet") {
                                if (field["__pFieldOptions"]["multi"]) {
                                    r[
                                        ob["__pFieldOptions"]["name"] +
                                            "." +
                                            field["__pFieldOptions"]["name"]
                                    ] = field["value"] || [];
                                } else {
                                    r[
                                        ob["__pFieldOptions"]["name"] +
                                            "." +
                                            field["__pFieldOptions"]["name"]
                                    ] = field.hasOwnProperty("value") ? [field["value"]] : [];
                                }
                            }
                        });
                        return r;
                    }
                };
            })(object);
        } else {
            //return {fieldName:value}
            object.getValArray = (function (ob) {
                return function () {
                    if (ob["__pFieldOptions"]["multi"]) {
                        r = {};
                        r[ob["__pFieldOptions"]["name"]] = ob["value"] || [];
                        return r;
                    } else {
                        r = {};
                        r[ob["__pFieldOptions"]["name"]] = ob.hasOwnProperty("value")
                            ? ob["value"]
                            : [];
                        return r;
                    }
                };
            })(object);
        }
    }
}

export function removeDunder(object) {
    //remove dunder objects from all fields recursively

    //recursively iterate through all objects
    for (var property in object) {
        if (object.hasOwnProperty(property)) {
            if (typeof object[property] == "object" && object[property] != null) {
                if (property.substring(0, 2) != "__") {
                    //if we are not on a dunder object continue to next object
                    removeDunder(object[property]);
                } else {
                    //if we are on a dunder object, delete ourselves
                    delete object[property];
                }
            }
        }
    }
}

export function addDefaultValues(object) {
    //add user defined default values

    //recursively iterate through all objects
    for (var property in object) {
        if (object.hasOwnProperty(property)) {
            if (typeof object[property] == "object" && object[property] != null) {
                addDefaultValues(object[property]);
            }
        }
    }

    //if the field has a default value option set the fields value equal to the default value option
    if (object.hasOwnProperty("__pFieldOptions")) {
        if (object["__pFieldOptions"].hasOwnProperty("defaultValue")) {
            object.value = object["__pFieldOptions"]["defaultValue"];
        }
    }
}

export function applyDisplayOptions(field, theVal) {
    //apply scientific notation and num decimal places options to field
    try {
        showScientificNotation = field["__pDisplayOptions"]["scientificNotation"];
        noDecimals = false;
        numDecimals = field["__pDisplayOptions"]["decimalPlaces"];
        if (isNumber(numDecimals)) {
            numDecimals = parseInt(numDecimals);
        } else {
            if (showScientificNotation) {
                numDecimals = 1;
            } else {
                noDecimals = true;
            }
        }

        if (showScientificNotation) {
            theVal = theVal.toExponential(numDecimals);
        } else {
            if (!noDecimals) {
                theVal = theVal.toFixed(numDecimals);
            }
        }
    } catch (err) {}
    return theVal;
}

//get the first value in the array if field value is array.  For fields with multiple values this
//function will be called multiple times and use the first value each time.
// _NOTE: From the usage we can tell that this code was used to extract an id that would later be passed to getCacheAsync
export function getFirstValue(field) {
    fieldVal = field["value"];
    if (field["__pFieldOptions"]["multi"]) {
        fieldVal = ensureArray(fieldVal).slice(0);
        if (fieldVal.length > 0) {
            firstVal = fieldVal[0];
        } else {
            firstVal = "";
        }
    } else {
        firstVal = fieldVal;
    }

    return firstVal;
}

export function getPlusText(field) {
    //when a green plus button is added the text returned by this function is added to it
    thisName = "";
    if (field.hasOwnProperty("__pFieldOptions")) {
        if (field["__pFieldOptions"].hasOwnProperty("name")) {
            thisName = field["__pFieldOptions"]["name"];
        }
    }
    switch (thisName) {
        case "Measurement Labels":
            return "Add Measurement Label";
        case "Assay Condition Fieldset":
            return "Add Condition";
        case "Assay Component Role":
            return "Add Component Role";
        case "Assay Component Fields":
            return "Add Assay Component";
        case "Assay Component Type Fields":
            return "Add additional type for this component";
        case "Assay Component Role Fieldset":
            return "Add additional role for this component";
        case "ext references fieldset":
            return "Add another external reference";
        case "Repeat Fixed Fields":
            return "Add Repeat Fixed";
        case "Update Offset Fields":
            return "Add Field Offset Update";

        default:
            return "";
    }
}

export function setDefaultValues(object, propertyName) {
    //set system default values for all fields in a form object

    //recursively iterate through all objects
    propertyName = propertyName || "";
    for (var property in object) {
        if (object.hasOwnProperty(property)) {
            if (typeof object[property] == "object" && object[property] != null) {
                if (property != "_links") {
                    setDefaultValues(object[property], property);
                }
            }
        }
    }

    //make sure we are really in an actual field, not a fieldSet or a dunder object, or a date object etc...
    if (
        object &&
        !object.hasOwnProperty("fields") &&
        !object.hasOwnProperty("value") &&
        propertyName.substring(0, 2) != "__" &&
        object.constructor.name == "Object" &&
        propertyName != "userUpdated" &&
        propertyName != "userAdded"
    ) {
        //default value will be an empty string for all fields
        var theVal = "";
        //except for checkboxes default value for checkboxes will be false
        if (object["__pFieldOptions"]["type"] == "checkbox") {
            theVal = false;
        }
        if (!object["__pFieldOptions"]["multi"]) {
            //if we are not a multi field and our value is undefined replace with default value
            object["value"] = object["value"] || theVal;
        } else {
            //if we are a multi field and our value is undefined replace with default value as 0th item in an array
            object["value"] = object["value"] || [theVal];
        }
    }
}

export function attachParentField(object) {
    //attaches parent field to field and fieldSet objects

    //recursively iterate through objects
    for (var property in object) {
        if (object.hasOwnProperty(property)) {
            if (typeof object[property] == "object" && object[property] != null) {
                if (property != "_links") {
                    attachParentField(object[property]);
                }
            }
        }
    }

    //if the object has a fields attribute (is a fieldSet) add self as the parent object of field fieldSet's children
    if (
        object &&
        object.hasOwnProperty("fields") &&
        object.fields &&
        !object[property].parentField
    ) {
        if (!object["__pFieldOptions"]["multi"]) {
            //if not multi loop through all of the fields and add a parentField property set equal to the parent object
            $.each(object.fields, function (i, field) {
                if (!field.hasOwnProperty("parentField")) {
                    Object.defineProperty(field, "parentField", {
                        value: object,
                        enumerable: false
                    });
                }
            });
        } else {
            //if is multi do double loop, loop through all of the fields and add a parentField property set equal to the parent object
            $.each(object.fields, function (i, fieldArrayItem) {
                Object.defineProperty(fieldArrayItem, "parentField", {
                    value: object,
                    enumerable: false
                });
                $.each(fieldArrayItem, function (i, field) {
                    if (!field.hasOwnProperty("parentField") && isObject(field)) {
                        Object.defineProperty(field, "parentField", {
                            value: fieldArrayItem,
                            enumerable: false
                        });
                    }
                });
            });
        }
    }
}
