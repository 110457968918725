import { useEffect, useState } from "react";
import { attachDunderAsync, getForm } from "../api-v2";
import { attachFieldByName, attachOptions, attachParentField } from "../form-helpers";
import { getFieldByName } from "./form-utils";
import { useFormHandlers } from "./use-form-handlers";
import { Form } from "./form";
import { useGlobalStore } from "../store";

export const FormContainer = ({ id, parentId, typeId, viewState, openItem }) => {
    const [formData, setFormData] = useState(null);
    const { userAdded, dateAdded } = useGlobalStore(({ dateAdded, userAdded }) => ({
        dateAdded,
        userAdded
    }));

    const fetch = async () => {
        let fetchedFormData;
        if (viewState === "create") {
            const form = await getForm(typeId);
            fetchedFormData = JSON.parse(getFieldByName(form, "JSON").value);
            fetchedFormData.typeId = Number(typeId);
            fetchedFormData.parentId = parentId;
            fetchedFormData.userAdded = userAdded;
            fetchedFormData.dateAdded = dateAdded;
        } else {
            fetchedFormData = await getForm(Number(id));
        }

        if (!fetchedFormData) {
            return;
        }

        // TODO: seems unnecessary, but requires some testing to make sure
        attachOptions(fetchedFormData);
        // TODO: replace with attachDunders from helpers/dunders
        await attachDunderAsync(fetchedFormData);
        attachParentField(fetchedFormData);
        attachFieldByName(fetchedFormData);

        setFormData(fetchedFormData);
    };

    useEffect(() => {
        fetch();
    }, [id, viewState]);

    const handlers = useFormHandlers(formData, viewState, openItem);

    return (
        <Form
            viewState={viewState}
            handlers={handlers}
            formData={formData}
            onFormDataChange={setFormData}
        />
    );
};
