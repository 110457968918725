import { Overrides } from "@material-ui/core/styles/overrides";

import { MuiBackdrop } from "./backdrop";
import { MuiButton } from "./button";
import { MuiDialogActions } from "./dialog-actions";
import { MuiDialogContent } from "./dialog-content";
import { MuiIconButton } from "./icon-button";
import { MuiInputBase, MuiInputLabel } from "./input-base";
import { MuiListItem } from "./list-item";
import { MuiMenu } from "./menu";
import { MuiMenuItem } from "./menu-item";
import { MuiOutlinedInput } from "./outlined-input";
import { MuiTab } from "./tab";
import { MuiTabs } from "./tabs";
import { MuiFormHelperText } from "./form-helper-text";
import { MuiChip } from "./chip";
import { MuiTooltip } from "./tooltip";
import { MuiAccordion } from "./accordion";

export const overrides: Overrides = {
    MuiOutlinedInput,
    MuiButton,
    MuiIconButton,
    MuiInputBase,
    MuiInputLabel,
    MuiListItem,
    MuiTabs,
    MuiTab,
    MuiMenu,
    MuiDialogActions,
    MuiDialogContent,
    MuiBackdrop,
    MuiMenuItem,
    MuiFormHelperText,
    MuiChip,
    MuiTooltip,
    MuiAccordion
};
