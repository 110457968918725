import { Overrides } from "@material-ui/core/styles/overrides";
import { common, warning } from "../colors";
import { alternativeShadow } from "../shadows";

export const MuiChip: Overrides["MuiChip"] = {
    root: {
        backgroundColor: common.white,
        boxShadow: alternativeShadow
    },
    label: {
        maxWidth: "90px"
    },
    deleteIcon: {
        "color": warning.main,
        "&:hover": {
            color: warning.main
        }
    }
};
