import create from "zustand";
import { useEffect } from "react";
import { uploadTemplateLogicInteractive } from "../form/form-utils";
import { Entities, Entity } from "../types";
import { useEntities } from "../hooks";
import { ensureArray } from "../helpers";

type SelectOptions = {
    [key in Entities]: Entity[];
};

type Store = {
    type: string;
    fields: any[];
    formErrors?: string[];
    setFormErrors: (formErrors: string[]) => void;
    isFieldError: boolean;
    setIsFieldError: (isFieldError: boolean) => void;
    selectOptions: SelectOptions;
    setOptions: (options: SelectOptions) => void;
    setFields: (fields: any[], type: string) => void;
    reset: () => void;
    onFieldChange: (indexes: number[], value: any, optionIndex: number) => void;
    onFieldAdd: (indexes: number[]) => void;
    onFieldDelete: (indexes: number[], optionIndex: number) => void;
};

const getFieldWithClearValues = (object: any) => {
    if (object.length) {
        object = object.map(getFieldWithClearValues);
    } else {
        if (object.value) {
            object.value = "";
        }
        if (object.fields) {
            object.fields = object.fields.map(getFieldWithClearValues);
        }
    }

    return object;
};

const getFieldsWithParentProperties = (object: any[]) => {
    if (object.length) {
        object = object.map((item) => {
            //don't change it without total checking of fields adding
            Object.defineProperty(item, "parentField", { value: object, enumerable: false });
            return item;
        });
    }
    return object;
};

const setRecursively = (fields: any[], indexes: number[], fieldSetCallback: any, type?: string) => {
    const newFields = [...fields];
    const i = indexes.shift() as number;

    let field = newFields[i];

    if (indexes.length) {
        if (Array.isArray(field)) {
            field = setRecursively(field, indexes, fieldSetCallback, type);
        } else {
            field.fields = setRecursively(field.fields, indexes, fieldSetCallback, type);
        }
    } else {
        fieldSetCallback(field);
    }

    if (type === Entities.Ut) {
        uploadTemplateLogicInteractive(field);
    }

    newFields[i] = field;

    return newFields;
};

const normalizeFields = (fields: any[]) => {
    fields.forEach((field) => {
        if (
            field.__pFieldOptions?.type === "checkbox" &&
            typeof field.value !== "boolean" &&
            field.value !== "true"
        ) {
            field.value = false;
        } else if (Array.isArray(field)) {
            normalizeFields(field);
        } else if (field.fields) {
            normalizeFields(field.fields);
        }
    });
};

export const useFormStore = create<Store>((set, get) => ({
    type: "",
    fields: [],
    isFieldError: false,
    selectOptions: {
        [Entities.Ut]: [],
        [Entities.Rd]: [],
        [Entities.Recipes]: [],
        [Entities.Af]: []
    },

    setFormErrors: (formErrors) => set({ formErrors }),

    setOptions: (selectOptions) => {
        set({ selectOptions });
    },

    setFields: (fields, type) => {
        normalizeFields(fields);
        set({ fields, type });
    },

    setIsFieldError: (isFieldError) => {
        set({ isFieldError });
    },

    reset: () =>
        set({
            fields: [],
            formErrors: undefined,
            isFieldError: false
        }),

    onFieldChange: (indexes, value, optionIndex) => {
        const { fields, type } = get();

        const changeCallback = (field: any) => {
            if (field.__pFieldOptions.multi) {
                if (field.__pFieldOptions.type === "fieldSet") return;

                field.value = field.value ? ensureArray(field.value) : [];

                field.value[optionIndex] = value;
            } else {
                field.value = value;
            }
        };

        const newFields = setRecursively(fields, indexes, changeCallback, type);

        set({ fields: newFields });
    },

    onFieldAdd: (indexes) => {
        const { fields, type } = get();

        const changeCallback = (field: any) => {
            if (!field.__pFieldOptions.multi) return;

            if (field.__pFieldOptions.type === "fieldSet") {
                //other ways doesn't work correct
                const [baseField] = field.fields;
                let newField = JSON.parse(JSON.stringify(baseField));

                newField = getFieldWithClearValues(newField);
                newField = getFieldsWithParentProperties(newField);
                if (type === "Upload Template") {
                    uploadTemplateLogicInteractive(newField);
                }

                field.fields.push(newField);
            } else {
                field.value = field.value ? ensureArray(field.value) : [""];

                field.value.push("");
            }
        };

        const newFields = setRecursively(fields, indexes, changeCallback);

        set({ fields: newFields });
    },

    onFieldDelete: (indexes, optionIndex) => {
        const { fields } = get();

        const changeCallback = (field: any) => {
            if (!field.__pFieldOptions.multi) return;
            if (field.__pFieldOptions.type === "fieldSet") {
                field.fields.splice(optionIndex, 1);
            } else {
                field.value = field.value ? ensureArray(field.value) : [];
                field.value.splice(optionIndex, 1);
            }
        };

        const newFields = setRecursively(fields, indexes, changeCallback);

        set({ fields: newFields });
    }
}));

export const useInitOptions = (): void => {
    const setOptions = useFormStore((state) => state.setOptions);
    const { isLoading, data } = useEntities();

    useEffect(() => {
        if (!isLoading) {
            setOptions(data);
        }
    }, [data, isLoading, setOptions]);
};
