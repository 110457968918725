import { makeStyles } from "@material-ui/styles";
import { Checkbox, FormControlLabel, List, ListItem } from "@material-ui/core";

const useStyles = makeStyles({
    list: {
        padding: 0
    },

    listItem: {
        padding: "2px"
    },

    itemLabel: {
        marginTop: 0
    },

    checkbox: {
        padding: 0,
        marginRight: "10px"
    },

    itemText: {
        cursor: "pointer",
        margin: 0,
        paddingTop: "8px"
    }
});

export const UsersList = ({ group, selected, onUserChange }) => {
    const classes = useStyles();

    return (
        <List className={classes.list} dense>
            {group.users.map(({ id, name }) => (
                <ListItem key={id} className={classes.listItem}>
                    <FormControlLabel
                        className={classes.itemLabel}
                        label={name}
                        control={
                            <Checkbox
                                className={classes.checkbox}
                                checked={selected.userIds.includes(id)}
                                onChange={() => onUserChange(id, name, group)}
                            />
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
};
