import { Box, Button } from "@material-ui/core";
import { CopyFormLayout, CopyFormTitle, DialogRoot } from "../../../copy-dialogs";
import { useSelected } from "./use-selected";
import { GroupsList } from "./groups-list";
import { getTranslation } from "@assay/shared";

export const EditPermsPopup = ({ onClose, fieldValue, onSubmit, open }) => {
    const { selected, onSelectedUsersChange, onSelectedGroupsChange } = useSelected(fieldValue);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(selected);
        onClose();
    };

    return (
        <DialogRoot open={open} onClose={onClose}>
            <Box component="form" onSubmit={handleSubmit}>
                <CopyFormLayout
                    title={<CopyFormTitle>{getTranslation("users")}</CopyFormTitle>}
                    fields={
                        <GroupsList
                            selected={selected}
                            onGroupChange={onSelectedGroupsChange}
                            onUserChange={onSelectedUsersChange}
                        />
                    }
                    actions={
                        <Button type="submit" color="primary">
                            {getTranslation("ok")}
                        </Button>
                    }
                />
            </Box>
        </DialogRoot>
    );
};
