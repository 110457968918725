import { makeStyles } from "@assay/ui-kit";

export const useStyles = makeStyles((theme) => ({
    root: {
        "position": "relative",
        "padding": theme.spacing(2),
        "minWidth": "250px",
        "display": "flex",
        "flexDirection": "column",
        "& > *:not(:last-child)": {
            marginBottom: "0.5rem"
        }
    },
    tableWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    row: {
        "display": "flex",
        "alignContent": "center",
        "alignItems": "center",
        "& > *:not(:last-child)": {
            marginRight: "0.5rem"
        }
    },

    directionRow: {
        "marginLeft": theme.spacing(4),
        "display": "flex",
        "alignContent": "center",
        "alignItems": "center",
        "& > *:not(:last-child)": {
            marginRight: "0.5rem"
        }
    },
    title: {
        width: "150px"
    },
    count: {
        width: "75px"
    },
    actions: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    mainActions: {
        "display": "flex",
        "& > *:not(:last-child)": {
            marginRight: "0.5rem"
        }
    },
    clearAction: {
        marginRight: "auto",
        marginLeft: "0"
    },
    closeIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: "auto",
        left: "auto"
    }
}));
