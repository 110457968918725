import { IconButton } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { makeStyles } from "@material-ui/core/styles";
import { getTranslation } from "@assay/shared";

const useActionButtonStyles = makeStyles({
    container: {
        display: "flex"
    }
});

const useIconStyles = makeStyles({
    root: {
        "color": "white",
        // the "disabled" rule does not override the default styles for some reason
        "&:disabled": {
            color: "#ababab"
        }
    }
});

export const ActionButtons = ({ count, page, rowsPerPage, onPageChange }) => {
    const classes = useActionButtonStyles();
    const iconClasses = useIconStyles();

    const lastPageIndex = Math.ceil(count / rowsPerPage) - 1;
    const isLastPage = page >= lastPageIndex;

    return (
        <div className={classes.container}>
            <IconButton
                classes={iconClasses}
                onClick={() => onPageChange("first")}
                disabled={page === 0}
                aria-label={getTranslation("first-page")}
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                classes={iconClasses}
                onClick={() => onPageChange("prev")}
                disabled={page === 0}
                aria-label={getTranslation("previous-page")}
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                classes={iconClasses}
                onClick={() => onPageChange("next")}
                disabled={isLastPage}
                aria-label={getTranslation("next-page")}
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                classes={iconClasses}
                onClick={() => onPageChange("last")}
                disabled={isLastPage}
                aria-label={getTranslation("last-page")}
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
};
