import React, { useCallback, useState } from "react";
import { DotParams, DotProps } from "./types";
import { ActiveDot } from "./active-dot";
import { ActiveHoveredDot } from "./active-hovered-dot";
import { InactiveHoveredDot } from "./inactive-hovered-dot";
import { InactiveDot } from "./inactive-dot";

type Props = DotProps & {
    isInteractive: boolean;
    allDots: DotParams[];
    onChange: (x: number, y: number, isActive: boolean) => void;
};

export const Dot: React.FC<Props> = ({ allDots, isInteractive, onChange, ...rest }) => {
    const [isHovered, setIsHovered] = useState(false);

    const datumX = rest.datum?.x ?? Number.MAX_SAFE_INTEGER;
    const datumY = rest.datum?.y ?? Number.MAX_SAFE_INTEGER;

    const isActive =
        allDots.find((item) => item.x === datumX && item.y === datumY)?.isActive ?? false;

    const handleSetHovered = useCallback(() => {
        if (isInteractive) {
            setIsHovered(true);
        }
    }, [isInteractive]);

    const handleSetUnHovered = useCallback(() => setIsHovered(false), []);

    return (
        <g
            x={rest.x}
            y={rest.y}
            onMouseOut={handleSetUnHovered}
            onMouseEnter={handleSetHovered}
            onClick={() => onChange(datumX, datumY, !isActive)}
        >
            {isActive && isHovered && <ActiveHoveredDot {...rest} />}
            {isActive && !isHovered && <ActiveDot {...rest} />}
            {!isActive && isHovered && <InactiveHoveredDot {...rest} />}
            {!isActive && !isHovered && <InactiveDot {...rest} />}
        </g>
    );
};

