import { getTranslation } from "@assay/shared";

export enum FormErrorTypes {
    FieldValidation,
    ResultSetFile,
    AnalysisFunction,
    AssayCopying,
    ProtocolCopying,
    Unknown
}

export class FormError extends Error {
    type: FormErrorTypes;
    messages?: string[];

    constructor(type: FormErrorTypes, messages?: string[]) {
        super();
        this.type = type;
        if (messages) {
            this.messages = messages;
        }
    }
}

const ERROR_MESSAGES = {
    [FormErrorTypes.FieldValidation]: "errors.form-fields-validation",
    [FormErrorTypes.ResultSetFile]: "errors.result-set-file",
    [FormErrorTypes.AnalysisFunction]: "errors.analysis-function",
    [FormErrorTypes.AssayCopying]: "errors.must-paste-assay-under-assay-group",
    [FormErrorTypes.ProtocolCopying]: "errors.must-paste-protocol-under-assay",
    [FormErrorTypes.Unknown]: "errors.unknown-form-error"
};

export const handleFormError = (error: Error): void => {
    const type = error instanceof FormError ? error.type : FormErrorTypes.Unknown;
    alert(getTranslation(ERROR_MESSAGES[type]));
    console.error(error);
};
