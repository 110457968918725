import { useState } from "react";
import {
    createNewForm,
    deleteFormRefactored,
    getNextId,
    getNextSequence,
    getParentTree,
    getParentType,
    sendSaveFormRequest
} from "../api-v2";

import { afterSave, beforeSave } from "./form-utils";
import { renderPopup } from "../popup";
import { useFormStore } from "../store";

import { CopyAssayDialog, CopyProtocolDialog, CopyTemplateDialog } from "../copy-dialogs";
import { validate, validateCopying } from "./validate";
import { useReloads } from "../hooks";
import { getTranslation } from "@assay/shared";
import { handleFormError, FormError, FormErrorTypes } from "../helpers";
import { useQueryClient } from "react-query";

export const useFormHandlers = (formData, viewState, openItem) => {
    const [isLoading, setIsLoading] = useState(false);
    const queryClient = useQueryClient();
    const { reloadAfterSave, reloadAfterCopy, reloadAfterDelete } = useReloads();
    const setIsFieldError = useFormStore((state) => state.setIsFieldError);
    const setFormErrors = useFormStore((state) => state.setFormErrors);

    if (!formData) return;

    const onSave = async (fields) => {
        setIsLoading(true);
        const payload = { ...formData, fields };

        try {
            const onError = () => setIsFieldError(true);
            validate(fields, onError);
            setIsFieldError(false);

            await beforeSave(payload);

            if (payload.id) {
                await sendSaveFormRequest(payload);
            } else {
                payload.id = await createNewForm(payload);
            }
            await afterSave(payload, queryClient);
            await reloadAfterSave(payload);
        } catch (error) {
            if (error instanceof FormError && error.type === FormErrorTypes.ResultSetFile) {
                setFormErrors(error.messages);
            }
            return handleFormError(error);
        } finally {
            setIsLoading(false);
        }
        
    };

    const onCopyTemplate = async (templateName) => {
        let newFormData;

        try {
            const newId = await getNextId();
            const seq = await getNextSequence(formData.parentId);

            newFormData = JSON.parse(JSON.stringify(formData));

            newFormData._sort.index = seq;
            newFormData.fields[0].value = templateName;
            newFormData._sort.name = templateName;

            if (formData.typeId != 1) {
                //todo: investigate do we need to remove the excess props from formData object
                //form.removeDunder();
            }

            newFormData.dateAdded = new Date();
            newFormData.id = newId;
            newFormData.locked = true;

            if (newFormData.locked) {
                delete newFormData.locked;
            }

            await sendSaveFormRequest(newFormData);
        } catch (error) {
            return handleFormError(error);
        }

        await reloadAfterCopy(newFormData);
    };

    const onCopyAssay = async ({ currentNodeKey, name }) => {
        let newFormData;

        try {
            const parentType = await getParentType(currentNodeKey);

            validateCopying(formData.name, parentType);

            const newId = await getNextId();
            const parentTree = await getParentTree(Number(currentNodeKey));

            if (formData.typeId != 1) {
                //todo: investigate do we need to remove the excess props from formData object
                //form.removeDunder();
            }

            newFormData = JSON.parse(JSON.stringify(formData));

            newFormData.dateAdded = new Date();
            newFormData.fields[0].value = name;
            newFormData.id = newId;
            newFormData.parentTree = parentTree;
            newFormData.parentId = Number(currentNodeKey);

            await sendSaveFormRequest(newFormData);
        } catch (error) {
            return handleFormError(error);
        }

        reloadAfterCopy(newFormData);
    };

    const onCopy = () => {
        let popupData;
        switch (formData.name) {
            case "Upload Template":
                popupData = {
                    component: CopyTemplateDialog,
                    formProps: {
                        onSubmit: onCopyTemplate,
                        name: `${getTranslation("copy-of")} ${formData._sort.name}`
                    }
                };
                break;

            case "Protocol":
                popupData = {
                    component: CopyProtocolDialog,
                    formProps: {
                        onSubmit: onCopyAssay,
                        initialValues: {
                            protocolName: `${getTranslation("copy-of")} ${formData.fields[0].value}`
                        }
                    }
                };
                break;

            case "Assay":
                popupData = {
                    id: "assayPopup",
                    component: CopyAssayDialog,
                    formProps: {
                        onSubmit: onCopyAssay,
                        initialValues: {
                            assayName: `${getTranslation("copy-of")} ${formData.fields[0].value}`
                        }
                    }
                };
        }

        if (popupData) renderPopup(popupData);
    };

    const onDelete = async () => {
        if (confirm(getTranslation("are-you-sure"))) {
            try {
                await deleteFormRefactored(Number(formData.id));
            } catch (error) {
                return handleFormError(error);
            }
            reloadAfterDelete(formData);
        }
    };

    const onEdit = () => {
        openItem({ id: formData.id, viewState: "edit", type: "form" });
    };

    const showSaveButton = viewState === "edit" || viewState === "create";
    const showEditButton = viewState === "view" && formData.canEdit && !formData.locked;
    const showDeleteButton = viewState === "view" && formData.canDelete2 && !formData.locked;
    const showCopyButton = viewState === "view" && formData.canCopy;

    return {
        isLoading,

        showSaveButton,
        showCopyButton,
        showEditButton,
        showDeleteButton,

        onSave,
        onDelete,
        onCopy,
        onEdit
    };
};
