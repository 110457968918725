import {
    DocumentSheet,
    FilePayload,
    FileSaved,
    Option,
    OptionWithNumber,
    Sheet,
    UploadTemplate,
    UploadTemplatePayload
} from "./types";
import { getWorker, XlsxParserWorkerRequest, XlsxParserWorkerResponse } from "./xlsx-parser-worker";
import { v4 as uuidv4 } from "uuid";
import { FT_MODE_ONE_VALUE_ID, FT_MODE_OPTIONS, MIN_COLUMNS_COUNT, MIN_ROWS_COUNT } from "./values";
import { getValidatedFields, validateUploadTemplateName } from "./validate";
import { ResultDefinition, ResultDefinitionTypes } from "@assay/shared";

const { worker } = getWorker();

function makeCompareConverting(value: string): string {
    return value.toString().toLowerCase().replaceAll(" ", "").replaceAll("_", "");
}

export function isSameResultDefinition(name: string, header: string): boolean {
    return makeCompareConverting(name) === makeCompareConverting(header);
}

export const isDecimalPlacesDisabled = (rd: ResultDefinition | null): boolean =>
    rd?.type !== ResultDefinitionTypes.RealNumber && rd?.type !== ResultDefinitionTypes.Percentage;

export const isScientificNotationDisabled = (rd: ResultDefinition | null): boolean =>
    rd?.type !== ResultDefinitionTypes.RealNumber && rd?.type !== ResultDefinitionTypes.Integer;

type ReadSheetsResult = {
    sheets: Sheet[];
    error?: string;
};

export async function readSheets(file: File): Promise<ReadSheetsResult> {
    return new Promise<ReadSheetsResult>((resolve) => {
        worker.onmessage = (ev: MessageEvent<XlsxParserWorkerResponse>) => {
            resolve({
                sheets: ev.data.sheets,
                error: ev.data.error
            });
        };
        const request: XlsxParserWorkerRequest = {
            file,
            minRowCount: MIN_ROWS_COUNT,
            minColumnCount: MIN_COLUMNS_COUNT
        };
        worker.postMessage(request);
    });
}

export const initSheets = (data: Sheet[]): DocumentSheet[] =>
    data.map((sheet, index) => ({
        id: uuidv4(),
        name: sheet.name,
        useRegexp: false,
        number: index,
        rawData: sheet.rows
    }));

export function isOptionEqualValue(option: Option, value: Option): boolean {
    return option.id === value.id;
}

export const convertUploadTemplateToPayload = (data: UploadTemplate): UploadTemplatePayload => ({
    name: data.name.value,
    fileName: data.fileName,
    useFileNameRegexp: data.useFileNameRegexp,
    isDisplayOnEmptyCells: data.isDisplayOnEmptyCells,
    fields: data.fields.map((field) => ({
        id: field.id,
        sheetId: field.sheet.id as string,
        headerPosition: field.headerPosition,
        aggregationFields: field.aggregationFields?.map((item) => item.id as string) ?? null,
        resultDefinition: field.resultDefinitionId ?? "",
        mergeType: field.mergeType,
        name: field.name,
        dataRange: field.dataRange,
        required: field.required,
        decimalPlaces: field.decimalPlaces,
        hideInResult: field.hideInResult,
        startRegexpValue: field.startRegexpValue,
        startRegexPositions: field.startRegexPositions,
        endRegexpValue: field.endRegexpValue,
        endRegexPositions: field.endRegexPositions,
        showInFT: field.showInFT,
        scientificNotation: field.scientificNotation,
        useFTOneValueMode: field.FTMode?.id === FT_MODE_ONE_VALUE_ID,
        startRegexStepDown: field.startRegexStepDown,
        startRegexStepRight: field.startRegexStepRight
    })),
    sheets: data.sheets,
    created: data.created,
    canDelete: data.canDelete,
    canEdit: data.canEdit
});

export const convertPayloadToUploadTemplate = (payload: UploadTemplatePayload): UploadTemplate => ({
    id: payload.id ?? "",
    name: validateUploadTemplateName({ value: payload.name }),
    fileName: payload.fileName ?? payload.file?.fileName ?? "",
    useFileNameRegexp: payload.useFileNameRegexp ?? false,
    isDisplayOnEmptyCells: payload.isDisplayOnEmptyCells ?? false,
    fields: getValidatedFields(
        payload.fields.map((field) => {
            const createAggregationFieldOption = (aggFieldId: string) => {
                const aggField = payload.fields.find((field) => field.id === aggFieldId);
                return {
                    id: aggField?.id ?? "",
                    name: aggField?.name ?? ""
                };
            };

            const aggregationFields =
                field.aggregationFields && field.aggregationFields.length > 0
                    ? field.aggregationFields.map(createAggregationFieldOption)
                    : null;

            const FTMode = field.showInFT
                ? field.useFTOneValueMode
                    ? FT_MODE_OPTIONS.find((option) => option.id === FT_MODE_ONE_VALUE_ID)
                    : FT_MODE_OPTIONS.find((option) => option.id !== FT_MODE_ONE_VALUE_ID)
                : undefined;

            const createSheet = (sheet?: DocumentSheet): OptionWithNumber => ({
                id: sheet?.id ?? "",
                number: sheet?.number ?? 0,
                name: sheet?.name ?? ""
            });

            const payloadSheet = payload.sheets.find((sheet) => sheet.id === field.sheetId);

            return {
                id: field.id,
                sheet: createSheet(payloadSheet),
                headerPosition: field.headerPosition,
                endRegexpValue: field.endRegexpValue,
                startRegexPositions: field.startRegexPositions,
                endRegexPositions: field.endRegexPositions,
                startRegexpValue: field.startRegexpValue,
                aggregationFields,
                mergeType: field.mergeType,
                resultDefinitionId: field.resultDefinition,
                name: field.name,
                dataRange: field.dataRange,
                required: field.required,
                decimalPlaces: field.decimalPlaces,
                hideInResult: field.hideInResult,
                showInFT: field.showInFT,
                scientificNotation: field.scientificNotation,
                FTMode,
                startRegexStepDown: field.startRegexStepDown,
                startRegexStepRight: field.startRegexStepRight
            };
        })
    ),
    mainFile: payload.file ? { ...payload.file, isMainFile: true } : null,
    attachments: payload.attachments ?? [],
    sheets: payload.sheets,
    sheetsTouched: new Map<string, boolean>(),
    sheetsErrors: new Map<string, string>(),
    created: payload.created,
    canDelete: payload.canDelete,
    canEdit: payload.canEdit
});

export const getFileName = (file: File | FilePayload): string => {
    if (file instanceof File) {
        return file.name;
    }
    return file.fileName;
};

export const getFileUrl = (file: File | Blob): string => URL.createObjectURL(file);

export const errorToString = (e: any): string => {
    try {
        return JSON.stringify(e);
    } catch {
        try {
            return e.toString();
        } catch {
            return "Unknown error";
        }
    }
};

export const isFileDisplayed = (file: File | FileSaved): boolean =>
    file && !(file as FileSaved).willBeDeleted;

export const createSheet = (number: number): DocumentSheet => {
    const column = Array(26).fill("");
    return {
        id: uuidv4(),
        name: `Sheet${number + 1}`,
        useRegexp: false,
        number: number,
        rawData: Array.from(Array(1000), () => [...column])
    };
};

export const deleteFileExtension = (fileName: string): string => {
    const parts = fileName.split(".");
    if (parts.length > 1) {
        parts.splice(parts.length - 1, 1);
        return parts.join(".");
    }
    return fileName;
};
