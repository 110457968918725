import { Overrides } from "@material-ui/core/styles/overrides";

import { common, grey } from "../colors";

export const MuiOutlinedInput: Overrides["MuiOutlinedInput"] = {
    root: {
        "padding": "0 !important", // TODO: bad idea but I couldn't styled .MuiAutocomplete-inputRoot (not exist in Overrides)
        "& > input": {
            paddingLeft: "10.5px !important"
        }
    },
    input: {
        "backgroundColor": common.white,
        "padding": "10.5px",
        "&$disabled": {
            backgroundColor: grey[100]
        }
    },
    inputMarginDense: {
        paddingTop: "4.5px",
        paddingBottom: "4.5px"
    },

    notchedOutline: {
        borderColor: grey[400]
    }
};
