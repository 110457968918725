import { Button, Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { UploadTemplateTable } from "../containers";
import { ConfirmDialog, FileBar } from "../components";
import { green, makeStyles } from "@assay/ui-kit";
import ClearIcon from "@material-ui/icons/Clear";
import { isFileDisplayed } from "../helpers";
import { getGlobalPermissions, getTranslation } from "@assay/shared";

export const useStyles = makeStyles((theme) => ({
    root: {
        "padding": "0.5rem",
        "display": "flex",
        "flexDirection": "column",
        "& > *:not(:last-child)": {
            marginBottom: "1.5rem"
        }
    },
    files: {
        display: "flex",
        flexDirection: "column"
    },
    file: {
        maxWidth: "350px"
    },

    primaryIcon: {
        color: green
    },
    deleteIcon: {
        color: theme.palette.warning.main
    },

    viewActionsButtons: {
        "display": "flex",
        "marginLeft": 0,
        "marginRight": "auto",
        "& > *:not(:last-child)": {
            marginRight: "1.5rem"
        }
    }
}));

const stateSelector = ({
    id,
    name,
    actions,
    mainFile,
    attachments,
    callbacks,
    canDelete,
    canEdit
}: UploadTemplateStore) => ({
    name: name.value,
    changeEditMode: actions.changeEditMode,
    onDelete: actions.removeUploadTemplate,
    files: [...(mainFile ? [mainFile] : []), ...(attachments ?? [])],
    onCopy: () => {
        if (id) {
            callbacks.onInitCopy(id);
        }
    },
    canDelete,
    canEdit
});

export const UploadTemplateViewScreen = (): React.ReactElement => {
    const classes = useStyles();
    const { name, changeEditMode, onDelete, files, onCopy, canDelete, canEdit } =
        useShallowUploadTemplateStore(stateSelector);

    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

    const handleDelete = () => {
        setIsDeletePopupOpen(false);
        onDelete();
    };

    const { canAdd } = getGlobalPermissions();

    return (
        <div className={classes.root}>
            <Typography variant={"h3"}>{name}</Typography>
            <Typography variant={"subtitle1"}>{getTranslation("template-fields")}</Typography>
            <UploadTemplateTable />

            <div className={classes.viewActionsButtons}>
                {canAdd && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<FileCopyIcon />}
                        classes={{
                            startIcon: classes.primaryIcon
                        }}
                        onClick={onCopy}
                    >
                        {getTranslation("copy")}
                    </Button>
                )}
                {canEdit && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => changeEditMode(true)}
                        startIcon={<EditIcon />}
                        classes={{
                            startIcon: classes.primaryIcon
                        }}
                    >
                        {getTranslation("edit")}
                    </Button>
                )}
                {canDelete && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setIsDeletePopupOpen(true)}
                        startIcon={<ClearIcon />}
                        classes={{
                            startIcon: classes.deleteIcon
                        }}
                    >
                        {getTranslation("delete")}
                    </Button>
                )}
            </div>
            <div className={classes.files}>
                <Typography variant={"subtitle2"}>{`${getTranslation("files")}:`}</Typography>
                {files.map((file, fileIndex) => (
                    <Fragment key={fileIndex}>
                        {isFileDisplayed(file) && (
                            <div className={classes.file}>
                                <FileBar file={file} />
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>

            {isDeletePopupOpen && (
                <ConfirmDialog
                    onClose={() => setIsDeletePopupOpen(false)}
                    isOpen={isDeletePopupOpen}
                    onSubmit={handleDelete}
                    title={getTranslation("delete-template")}
                />
            )}
        </div>
    );
};
