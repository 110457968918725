import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { getTranslation } from "@assay/shared";

type Props = {
    inProgress: boolean;
    disabled: boolean;
};

export const PasteButton: React.FC<Props> = ({ inProgress, disabled }) => {
    if (inProgress) {
        return <CircularProgress />;
    }

    return (
        <Button type="submit" color="primary" disabled={disabled}>
            {getTranslation("paste")}
        </Button>
    );
};
