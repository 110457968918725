import React from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "../../theme";

const useStyles = makeStyles((theme) => {
    return {
        contentRoot: {
            padding: theme.spacing(2)
        },
        titleRoot: {
            margin: 0,
            padding: theme.spacing(2)
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        actionsRoot: {
            margin: 0,
            padding: theme.spacing(1)
        }
    };
});

type Props = {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    content: React.ReactElement;
    actions?: React.ReactElement;
};

export const StyledDialog = ({
    isOpen,
    content,
    actions,
    title,
    onClose
}: Props): React.ReactElement => {
    const styles = useStyles();
    return (
        <Dialog onClose={onClose} open={isOpen} maxWidth={"xl"}>
            <MuiDialogTitle disableTypography className={styles.titleRoot}>
                {title && <Typography variant="h6">{title}</Typography>}

                {onClose ? (
                    <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
            <MuiDialogContent dividers={Boolean(title && actions)} className={styles.contentRoot}>
                {content}
            </MuiDialogContent>
            {actions && (
                <MuiDialogActions className={styles.actionsRoot}>{actions}</MuiDialogActions>
            )}
        </Dialog>
    );
};
