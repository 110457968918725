import React from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { makeStyles, WithPopover } from "@assay/ui-kit";
import { Typography } from "@material-ui/core";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles({
    text: {
        maxWidth: "230px",
        textAlign: "center"
    }
});

type Props = {
    entity: string;
};

export const RegularExpressionHint: React.FC<Props> = ({ entity }) => {
    const classes = useStyles();

    return (
        <WithPopover
            popoverContent={
                <Typography className={classes.text}>
                    {getTranslation("by-clicking-regular-expression", [entity])}
                </Typography>
            }
            position="left"
        >
            <HelpOutlineIcon color="primary" style={{ fontSize: 18 }} />
        </WithPopover>
    );
};
