export enum ResultDefinitionTypes {
    RealNumber = "real number",
    Percentage = "percentage",
    Integer = "integer",
    Text = "text",
    Date = "date",
    Bool = "bool",
    DropDown = "drop down"
}

export type ResultDefinition = {
    id: string;
    name: string;
    type: ResultDefinitionTypes;
    options?: string[];
};
