import React, { useCallback, useMemo, useState } from "react";
import { SheetGrid } from "./sheet-grid";
import { SheetCell } from "./sheet-cell";
import {
    convertTemplateToMatrix,
    createEmptySheetMatrix,
    findColumnsLength,
    getMinMaxValuesFromTemplate,
    headersLength
} from "../../utils";
import { Popover } from "@material-ui/core";
import { TemplateSettings } from "./template-settings";
import { VirtualItem } from "react-virtual/types";
import {
    DataSheetMatrix,
    SelectedSheetMatrix,
    SelectType,
    TemplateElement,
    TemplateRange
} from "../../types";
import debounce from "lodash.debounce";
import { EMPTY_TEMPLATE_RANGE } from "../../values";

interface Props {
    editMode: boolean;
    width: string;
    height: string;
    dataSheetMatrix: DataSheetMatrix;
    selectedSheetMatrix: SelectedSheetMatrix;
    onChange: (matrix: SelectedSheetMatrix, template?: TemplateRange) => void;
    templateMode?: boolean;
    templateRange?: TemplateRange | null;
    isDisplayOnEmptyCells: boolean;
}

type Position = {
    row: number;
    column: number;
};

type MultiSelectState = {
    startPosition: Position;
    endPosition: Position;
};

const notExistIndex = -1;

export const Sheet = ({
    dataSheetMatrix,
    onChange,
    selectedSheetMatrix,
    templateMode = false,
    templateRange,
    width,
    height,
    editMode,
    isDisplayOnEmptyCells
}: Props): React.ReactElement => {
    const columnsLength = useMemo(
        () => findColumnsLength(dataSheetMatrix) + headersLength,
        [dataSheetMatrix]
    );
    const viewData = useMemo(() => {
        return [new Array(columnsLength).fill(""), ...dataSheetMatrix].map((row, rowIndex) => {
            const typedRow = row as string[];
            return [rowIndex || "", ...typedRow];
        });
    }, [dataSheetMatrix]);

    const rowsLength = useMemo(() => viewData.length, [viewData]);

    const [multiSelectState, setMultiSelectState] = useState<MultiSelectState | null>(null);

    const [templateSettingsAnchorPosition, setTemplateSettingsAnchorPosition] =
        useState<Position | null>(null);
    const [templateSettingsAnchorEl, setTemplateSettingsAnchorEl] = useState<HTMLDivElement | null>(
        null
    );

    const selectedColumns = multiSelectState?.endPosition.column;
    const selectedRows = multiSelectState?.endPosition.row;

    const changeMatrixValue = (rowCurrent: number, columnCurrent: number) => {
        if (!editMode) return;

        const newCurrentSheetMatrix = templateMode
            ? [...createEmptySheetMatrix(rowsLength, columnsLength)]
            : [...selectedSheetMatrix];

        const newRow = [...newCurrentSheetMatrix[rowCurrent]];
        newRow[columnCurrent] =
            newRow[columnCurrent] !== SelectType.ActiveSelected
                ? SelectType.ActiveSelected
                : SelectType.NotSelected;
        newCurrentSheetMatrix[rowCurrent] = newRow;

        onChange(newCurrentSheetMatrix);
    };

    function getMinMaxTempRange() {
        const tempRowMin = Math.min(
            multiSelectState?.startPosition.row ?? notExistIndex,
            multiSelectState?.endPosition.row ?? notExistIndex
        );

        const tempRowMax = Math.max(
            multiSelectState?.startPosition.row ?? notExistIndex,
            multiSelectState?.endPosition.row ?? notExistIndex
        );

        const tempColumnMin = Math.min(
            multiSelectState?.startPosition.column ?? notExistIndex,
            multiSelectState?.endPosition.column ?? notExistIndex
        );

        const tempColumnMax = Math.max(
            multiSelectState?.startPosition.column ?? notExistIndex,
            multiSelectState?.endPosition.column ?? notExistIndex
        );
        return { tempRowMin, tempRowMax, tempColumnMin, tempColumnMax };
    }

    function saveTempSelection() {
        if (!editMode) return;

        const newCurrentSheetMatrix = [...selectedSheetMatrix];
        const { tempRowMin, tempRowMax, tempColumnMin, tempColumnMax } = getMinMaxTempRange();

        if (tempRowMin === tempRowMax && tempColumnMin === tempColumnMax) {
            setMultiSelectState(null);
            return;
        }

        for (let row = tempRowMin; row <= tempRowMax; row += 1) {
            const newRow = [...newCurrentSheetMatrix[row]];
            for (let column = tempColumnMin; column <= tempColumnMax; column += 1) {
                newRow[column] = SelectType.ActiveSelected;
            }
            newCurrentSheetMatrix[row] = newRow;
        }

        onChange(newCurrentSheetMatrix);
        setMultiSelectState(null);
    }

    function calculateTemplateAnchorState() {
        if (!editMode) return;

        const { tempRowMin, tempRowMax, tempColumnMax } = getMinMaxTempRange();
        const anchorRow = Math.floor((tempRowMin + tempRowMax) / 2);

        setTemplateSettingsAnchorPosition({
            row: anchorRow,
            column: tempColumnMax
        });
    }

    function handleSelectEnd(): void {
        if (!editMode) return;
        if (templateMode) {
            calculateTemplateAnchorState();
        } else {
            saveTempSelection();
        }
    }

    function getCellSelectType(virtualRowIndex: number, virtualColumnIndex: number): SelectType {
        const findRow = selectedSheetMatrix[virtualRowIndex];

        const { tempRowMin, tempRowMax, tempColumnMin, tempColumnMax } = getMinMaxTempRange();

        const isSelectedTemp =
            virtualRowIndex >= tempRowMin &&
            virtualRowIndex <= tempRowMax &&
            virtualColumnIndex >= tempColumnMin &&
            virtualColumnIndex <= tempColumnMax;

        if (isSelectedTemp) {
            return SelectType.ActiveSelected;
        }

        return findRow?.[virtualColumnIndex] ?? SelectType.NotSelected;
    }

    function findTemplateAnchorEl(
        instance: HTMLDivElement | null,
        virtualRow: VirtualItem,
        virtualColumn: VirtualItem
    ) {
        if (
            virtualRow.index === templateSettingsAnchorPosition?.row &&
            virtualColumn.index === templateSettingsAnchorPosition?.column
        ) {
            setTemplateSettingsAnchorEl(instance);
        }
    }

    function handleCloseTemplateSettings() {
        setTemplateSettingsAnchorEl(null);
        setTemplateSettingsAnchorPosition(null);
        setMultiSelectState(null);
    }

    const hasSelected = useMemo(
        () =>
            selectedSheetMatrix.some((row) =>
                row.some((column) => column === SelectType.ActiveSelected)
            ),
        [selectedSheetMatrix]
    );

    function calculateTemplateSettingsSize(type: "row" | "column"): number {
        if (!multiSelectState) {
            return 0;
        }
        return (
            Math.abs(
                (multiSelectState.endPosition[type] ?? 0) -
                    (multiSelectState.startPosition[type] ?? 0)
            ) + 1
        );
    }

    const saveTemplate = (value: { range?: TemplateRange | null; matrix: SelectedSheetMatrix }) => {
        const { tempRowMin, tempColumnMin } = getMinMaxTempRange();
        const minMaxFromTemplate = getMinMaxValuesFromTemplate(templateRange?.template);
        const templateElements: TemplateElement[] = [];
        value.matrix.forEach((row, rowIndex) => {
            row.forEach((column, columnIndex) => {
                templateElements.push({
                    position: {
                        row:
                            rowIndex +
                            (tempRowMin !== notExistIndex
                                ? tempRowMin - headersLength
                                : minMaxFromTemplate.minRow),
                        column:
                            columnIndex +
                            (tempColumnMin !== notExistIndex
                                ? tempColumnMin - headersLength
                                : minMaxFromTemplate.minColumn)
                    },
                    isSelected: column === SelectType.ActiveSelected
                });
            });
        });
        const newTemplateRange: TemplateRange = {
            ...EMPTY_TEMPLATE_RANGE,
            ...value.range,
            template: templateElements
        };
        const newCurrentSheetMatrix = convertTemplateToMatrix({
            templateRange: newTemplateRange,
            matrixRowsLength: rowsLength,
            matrixColumnLength: columnsLength,
            withHeaders: true,
            isDisplayOnEmptyCells
        });

        onChange(newCurrentSheetMatrix, newTemplateRange);
        handleCloseTemplateSettings();
    };

    function openExistTemplateSettings(
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        isSelected: boolean
    ) {
        const target = event.currentTarget;
        const timeOut = setTimeout(() => {
            if (templateRange && isSelected) {
                setTemplateSettingsAnchorEl(target);
            }
        }, 500);
        const clearFn = () => {
            clearTimeout(timeOut);
            target.removeEventListener("mouseout", clearFn);
        };
        target.addEventListener("mouseout", clearFn);
    }

    const scrollSelectPosition = useMemo(
        () => ({
            left: {
                row: selectedRows,
                column: selectedColumns ? selectedColumns - 1 : selectedColumns
            },
            right: {
                row: selectedRows,
                column: selectedColumns ? selectedColumns + 1 : selectedColumns
            },
            top: {
                row: selectedRows ? selectedRows - 1 : selectedRows,
                column: selectedColumns
            },
            bottom: {
                row: selectedRows ? selectedRows + 1 : selectedRows,
                column: selectedColumns
            }
        }),
        [selectedRows, selectedColumns]
    );

    const debounceSelect = useCallback(
        (position) => {
            debounce(() => {
                if (!multiSelectState) {
                    return;
                }
                return setMultiSelectState({
                    startPosition: multiSelectState.startPosition,
                    endPosition: position
                });
            }, 250)();
        },
        [multiSelectState]
    );

    const handleScrollSelect = (direction: "left" | "right" | "top" | "bottom") => {
        if (!templateSettingsAnchorEl) {
            debounceSelect(scrollSelectPosition[direction]);
        }
    };

    return (
        <>
            <SheetGrid
                editMode={editMode}
                height={height}
                width={width}
                columnsLength={columnsLength}
                rowsLength={rowsLength}
                selectedColumns={selectedColumns}
                selectedRows={selectedRows}
                handleScrollSelect={handleScrollSelect}
                renderCell={(virtualRow, virtualColumn) => {
                    const content = (
                        viewData[virtualRow.index][virtualColumn.index] ?? ""
                    ).toString();
                    const selectType = getCellSelectType(virtualRow.index, virtualColumn.index);
                    return (
                        <SheetCell
                            editMode={editMode}
                            ref={(instance) =>
                                findTemplateAnchorEl(instance, virtualRow, virtualColumn)
                            }
                            onMouseOver={(event) =>
                                openExistTemplateSettings(
                                    event,
                                    selectType === SelectType.ActiveSelected
                                )
                            }
                            virtualRow={virtualRow}
                            virtualColumn={virtualColumn}
                            selectType={selectType}
                            hasSelected={hasSelected}
                            content={content ?? ""}
                            onSelectStart={() => {
                                if (!editMode) return;
                                setMultiSelectState({
                                    startPosition: {
                                        column: virtualColumn.index,
                                        row: virtualRow.index
                                    },
                                    endPosition: {
                                        column: virtualColumn.index,
                                        row: virtualRow.index
                                    }
                                });
                                changeMatrixValue(virtualRow.index, virtualColumn.index);
                            }}
                            onSelectEnd={handleSelectEnd}
                            onSelect={() => {
                                if (!editMode) return;
                                if (multiSelectState) {
                                    setMultiSelectState((prevState) => {
                                        return {
                                            ...(prevState ?? {
                                                startPosition: {
                                                    row: notExistIndex,
                                                    column: notExistIndex
                                                }
                                            }),
                                            endPosition: {
                                                row: virtualRow.index,
                                                column: virtualColumn.index
                                            }
                                        };
                                    });
                                }
                            }}
                        />
                    );
                }}
            />
            {!!templateSettingsAnchorEl && editMode && (
                <Popover
                    open={!!templateSettingsAnchorEl}
                    anchorEl={templateSettingsAnchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    onClose={handleCloseTemplateSettings}
                >
                    <TemplateSettings
                        onClose={handleCloseTemplateSettings}
                        rowsCount={calculateTemplateSettingsSize("row")}
                        columnsCount={calculateTemplateSettingsSize("column")}
                        templateRange={templateRange}
                        onSave={saveTemplate}
                    />
                </Popover>
            )}
        </>
    );
};
