import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import { ExtendedTheme } from "@assay/ui-kit";
import React from "react";

const useStyles = makeStyles<ExtendedTheme, { length: number }>({
    row: {
        "borderBottom": "1px solid #D3D3D3",
        "&:hover": {
            backgroundColor: "#E9E9E9"
        }
    },
    cell: {
        paddingLeft: "5px",
        fontSize: "14px",
        textAlign: "left",
        backgroundColor: "#99A1AF",
        borderBottom: "1px solid #afafaf",
        color: "#333",
        width: ({ length }) => `${100 / length}%`,
        padding: "3px",
        height: "30px"
    }
});

type Props = {
    columns: string[];
};

export const HeaderRow: React.FC<Props> = ({ columns }) => {
    const classes = useStyles({ length: columns.length });

    return (
        <TableHead>
            <TableRow className={classes.row}>
                {columns.map((column) => (
                    <TableCell key={column} className={classes.cell}>
                        {column}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
