import { useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { generateTable, paginateRows, sortRows } from "./utils";
import { ResultTableRow } from "./types";
import { useUrl } from "../use-url";
import { getFormIdsAndCursorData } from "../api-v2";
import { UT_FOLDER_ID } from "../constants";
import { featureService, Features } from "@assay/shared";
import { getUploadTemplateList } from "@assay/upload-templates";
import { ContentTypes } from "../types";

type ChangePageAction = "next" | "prev" | "first" | "last";

type Table = {
    rows: ResultTableRow[];
    columns: string[];
};

type TableData = Table & {
    isLoading: boolean;
    page: number;
    totalRowsCount: number;
    changePage: (action: ChangePageAction) => void;
};

const TABLE_QUERY_OPTIONS = {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
};

const getTable = async (id: string): Promise<Table> => {
    const query = { parentId: Number(id) };
    const formIds = await getFormIdsAndCursorData({ query });
    const { rows: unsortedRows, columns } = await generateTable(formIds);
    const rows = sortRows(unsortedRows);

    return { rows, columns };
};

const getUtTable = async (): Promise<Table> => {
    const utList = await getUploadTemplateList();
    const rows = sortRows(
        utList.map((item) => [
            {
                id: item.id,
                label: item.name,
                icon: "",
                type: ContentTypes.Ut
            }
        ])
    );

    return { rows, columns: ["Name"] };
};

const useTableQuery = (): UseQueryResult<Table> => {
    const { params } = useUrl();
    const isNewUtsOn = featureService[Features.IsNewUploadTemplatesOn];
    const isUtsFolder = Number(params.id) === UT_FOLDER_ID;
    let queryFn;

    if (isNewUtsOn && isUtsFolder) {
        queryFn = getUtTable;
    } else {
        queryFn = () => getTable(params.id);
    }

    return useQuery({
        queryFn,
        queryKey: `table-${params.id}`,
        ...TABLE_QUERY_OPTIONS
    });
};

type TablePage = ResultTableRow[];

export const useTable = (): TableData => {
    const [page, setPage] = useState(0);
    const [paginatedRows, setPaginatedRows] = useState<TablePage[]>([]);

    const { data, isLoading, isStale } = useTableQuery();

    useEffect(() => {
        if (data) {
            setPage(0);
            setPaginatedRows(paginateRows(data.rows as never[]));
        }
    }, [data]);

    const changePage = (action: ChangePageAction) => {
        switch (action) {
            case "next":
                return setPage(page + 1);
            case "prev":
                return setPage(page - 1);
            case "first":
                return setPage(0);
            case "last":
                return setPage(paginatedRows.length - 1);
        }
    };

    return {
        isLoading: isLoading || isStale,
        rows: paginatedRows[page] ?? [],
        columns: data?.columns ?? [],
        page,
        totalRowsCount: data?.rows?.length ?? 0,
        changePage
    };
};
