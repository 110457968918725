import { TableFooter, TableRow, TableCell, TablePagination } from "@material-ui/core";

import { RESULTS_PER_PAGE } from "../utils";

import { makeStyles } from "@material-ui/core/styles";
import { ActionButtons } from "./action-buttons";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles({
    navigationRow: {
        textAlign: "right",
        backgroundColor: "#4D5158",
        color: "white",
        border: "1px solid #E8E8E8"
    },
    cell: {
        color: "white"
    }
});

const usePaginationStyles = makeStyles({
    root: {
        color: "white",
        overflow: "hidden"
    },
    toolbar: {
        justifyContent: "flex-end"
    },
    selectIcon: {
        color: "white"
    }
});

export const NavigationRow = ({ page, totalRowsCount, onNavButtonClick }) => {
    const classes = useStyles();
    const paginationClasses = usePaginationStyles();

    const isStalePage = page > Math.ceil(totalRowsCount / RESULTS_PER_PAGE);
    const currentPage = isStalePage ? 0 : page;

    return (
        <TableFooter>
            <TableRow className={classes.navigationRow}>
                {totalRowsCount ? (
                    <TablePagination
                        classes={paginationClasses}
                        labelRowsPerPage={getTranslation("results-per-page")}
                        count={totalRowsCount}
                        page={currentPage}
                        onPageChange={onNavButtonClick}
                        rowsPerPageOptions={[RESULTS_PER_PAGE]} // disabled
                        rowsPerPage={RESULTS_PER_PAGE}
                        ActionsComponent={ActionButtons}
                    />
                ) : (
                    <TableCell className={classes.cell}>
                        <span>{getTranslation("search-returned-no-results")}</span>
                    </TableCell>
                )}
            </TableRow>
        </TableFooter>
    );
};
