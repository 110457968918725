import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMonth, getYear } from "date-fns";
import { convertDateToString, convertStringToDate } from "../../helpers";
import { makeStyles } from "@assay/ui-kit";
import { getRangeNumbers } from "@assay/shared";

const years = getRangeNumbers(1990, getYear(new Date()) + 100);

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const useStyles = makeStyles((theme) => ({
    headerRoot: {
        "margin": theme.spacing(1),
        "display": "flex",
        "justifyContent": "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0.25)
        }
    },
    button: {
        minWidth: "24px"
    }
}));

type Props = {
    value: string;
    mainOptions: { viewState: "view" | "edit" | "create" };
    onChange: (value: string) => void;
};

export const DateInput: React.FC<Props> = ({ value, mainOptions, onChange }) => {
    const classes = useStyles();

    if (mainOptions.viewState === "view") {
        return <span>{value ?? ""}</span>;
    }

    const date = value ? convertStringToDate(value) : null;

    const onChangeHandler = (newDate: Date) => {
        onChange(convertDateToString(newDate));
    };

    return (
        <DatePicker
            portalId="root-portal"
            showPopperArrow={false}
            selected={date}
            onChange={onChangeHandler}
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                increaseYear,
                decreaseYear,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
            }) => (
                <div className={classes.headerRoot}>
                    <button
                        onClick={decreaseYear}
                        disabled={prevMonthButtonDisabled}
                        className={classes.button}
                    >
                        {"<<"}
                    </button>
                    <button
                        onClick={() => changeMonth(getMonth(date) - 1)}
                        disabled={prevMonthButtonDisabled}
                        className={classes.button}
                    >
                        {"<"}
                    </button>
                    <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(Number(value))}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <button
                        onClick={() => changeMonth(getMonth(date) + 1)}
                        disabled={nextMonthButtonDisabled}
                        className={classes.button}
                    >
                        {">"}
                    </button>
                    <button
                        onClick={increaseYear}
                        disabled={nextMonthButtonDisabled}
                        className={classes.button}
                    >
                        {">>"}
                    </button>
                </div>
            )}
            todayButton="Today"
            showWeekNumbers
        />
    );
};
