import React from "react";
import { ErrorDialog } from "@assay/ui-kit";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";

const selectState = ({ fileParsingError, actions }: UploadTemplateStore) => ({
    errorText: fileParsingError,
    onClose: () => actions.setFileParsingError(null)
});

export const ErrorFileLoadingDialog = (): React.ReactElement => {
    const { errorText, onClose } = useShallowUploadTemplateStore(selectState);
    return <ErrorDialog message={errorText ?? ""} open={!!errorText} onClose={onClose} />;
};
