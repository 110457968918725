import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { ICONS, TreeViewIcon } from "./tree-view-icon";
import React from "react";
import { PopoverPosition } from "@material-ui/core";

const useStyles = makeStyles({
    item: {
        display: "flex",
        alignItems: "center",
        padding: "0 10px"
    },
    icon: {
        width: ".6em",
        height: ".6em",
        marginRight: "8px"
    }
});

type Props = {
    coords: PopoverPosition;
    onClose: (e: React.MouseEvent) => void;
    menuOptions: Array<{
        text: keyof typeof ICONS;
        disabled: boolean;
        onClick: (e: React.MouseEvent) => void;
    }>;
};

export const ContextMenu: React.FC<Props> = ({ coords, onClose, menuOptions }) => {
    const classes = useStyles();
    const open = coords !== null;

    return (
        <Menu
            onClose={onClose}
            open={open}
            onContextMenu={onClose}
            anchorReference="anchorPosition"
            anchorPosition={coords}
        >
            {menuOptions.map(({ text, disabled, onClick }) => (
                <MenuItem
                    key={text}
                    disabled={disabled}
                    onClick={(e) => {
                        onClick(e);
                        onClose(e);
                    }}
                >
                    <div className={classes.item}>
                        <TreeViewIcon iconName={text} className={classes.icon} />
                        {text}
                    </div>
                </MenuItem>
            ))}
        </Menu>
    );
};
