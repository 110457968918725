import { getAppConfig } from "./appConfig";

type Permissions = {
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
};

export const getGlobalPermissions = (): Permissions => {
    const { permissions } = getAppConfig().assayConfigHeader;

    return {
        canAdd: permissions.canAdd,
        canEdit: permissions.canEdit,
        canDelete: permissions.canDelete
    };
};
