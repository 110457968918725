import React, { useEffect, useMemo, useState } from "react";
import RemoveIcon from "@material-ui/icons/Remove";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { prepareSheetsReplacement, PrepareSheetsReplacementResult } from "../utils";
import { InconsistentFieldsDialog } from "./inconsistent-fields-dialog";
import { IconButton, Typography } from "@material-ui/core";
import { makeStyles, WithPopover } from "@assay/ui-kit";

const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.warning.main
    }
}));

type Props = {
    index: number;
};

const selectState = ({
    sheets,
    fields,
    actions: { changeSheets, replaceFields }
}: UploadTemplateStore) => ({
    fields,
    sheets,
    changeSheets,
    replaceFields
});

export const SheetDeleteButton: React.FC<Props> = ({ index }) => {
    const classes = useStyles();

    const [isDeleteInit, setIsDeleteInit] = useState(false);
    const [preparedFields, setPreparedFields] = useState<PrepareSheetsReplacementResult[] | null>(
        null
    );
    const { changeSheets, sheets, fields, replaceFields } =
        useShallowUploadTemplateStore(selectState);

    const newSheets = useMemo(() => {
        const tempSheets = [...sheets];
        tempSheets.splice(index, 1);
        return tempSheets.map((sheet, sheetIndex) => ({
            ...sheet,
            number: sheetIndex
        }));
    }, [sheets, index]);

    useEffect(() => {
        if (isDeleteInit) {
            const newPreparedFields = prepareSheetsReplacement(fields, sheets, newSheets);
            const hasInconsistent = newPreparedFields.some((item) => !item.isConsistent);

            if (hasInconsistent) {
                setPreparedFields(newPreparedFields);
            } else {
                changeSheets(newSheets);
                replaceFields(newPreparedFields.map((item) => item.field));
                setIsDeleteInit(false);
            }
        } else {
            setPreparedFields(null);
        }
    }, [isDeleteInit, sheets, index, fields, newSheets]);

    const handleEraseOnlyInconsistent = () => {
        if (!preparedFields) {
            return;
        }
        replaceFields(preparedFields.filter((item) => item.isConsistent).map((item) => item.field));
        changeSheets(newSheets);
        setIsDeleteInit(false);
        setPreparedFields(null);
    };

    const handleEraseAll = () => {
        if (!preparedFields) {
            return;
        }
        replaceFields([]);
        changeSheets(newSheets);
        setIsDeleteInit(false);
        setPreparedFields(null);
    };

    const isDialogOpen = !!preparedFields;

    const inconsistentFields = preparedFields?.filter((item) => !item.isConsistent) ?? [];

    return (
        <>
            <WithPopover popoverContent={<Typography>Remove</Typography>} position="bottom">
                <IconButton
                    className={classes.button}
                    aria-label="delete"
                    size="small"
                    onClick={() => setIsDeleteInit(true)}
                >
                    <RemoveIcon fontSize="inherit" />
                </IconButton>
            </WithPopover>

            <InconsistentFieldsDialog
                isOpen={isDialogOpen}
                onClose={() => {
                    setPreparedFields(null);
                    setIsDeleteInit(false);
                }}
                fields={inconsistentFields}
                onEraseInconsistent={handleEraseOnlyInconsistent}
                onEraseAll={handleEraseAll}
            />
        </>
    );
};
