import { Dispatch, useEffect, useState } from "react";
import { getHistory } from "./api-v2";

const urlParamsSerializer = {
    serialize: (params: any) => {
        const entries = Object.entries(params);

        if (entries.length === 0) {
            return window.location.origin + window.location.pathname;
        }

        const arr = entries.map(([key, val]) => {
            return `${key}=${JSON.stringify(val)}`;
        });

        return `?${arr.join("&")}`;
    },

    parse: () => {
        const urlParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            [...urlParams.entries()].map(([key, val]) => {
                return [key, JSON.parse(val)];
            })
        );

        return params;
    },

    parseHash: async () => {
        const hash = location.hash.replace("#", "");
        if (!hash) return null;

        const response = await getHistory(hash);

        const func = JSON.parse(response.functionData);

        const params: {
            [key: string]: string;
        } = {};

        if (func.f === "getList") {
            // eslint-disable-next-line prefer-destructuring
            params.query = func.a[1];
            params.type = "table";
        }

        if (func.f === "makeForm") {
            // func.a[1] is containerId. Seems to be useless now
            [params.id, , params.viewState] = func.a;
            params.type = "form";
        }

        for (const key in params) {
            if (params[key] === undefined) delete params[key];
        }

        return params;
    }
};

class UrlSearchParamsController {
    private subscribers: Array<(value: any) => void | Dispatch<any>> = [];

    constructor() {
        this.init();
    }

    setUrlParams = (urlParams: any) => {
        history.pushState(urlParams, "", urlParamsSerializer.serialize(urlParams));
        this.notify();
    };

    subscribe(callback: (() => void) | Dispatch<any>) {
        this.subscribers.push(callback);
        return () => {
            this.subscribers = this.subscribers.filter((sub) => sub !== callback);
        };
    }

    private notify() {
        const params = urlParamsSerializer.parse();
        this.subscribers.forEach((sub) => sub(params));
    }

    private async init() {
        // handle back/forward browser buttons
        window.addEventListener("popstate", () => this.notify());

        const params = await urlParamsSerializer.parseHash();

        if (params) {
            history.replaceState(params, "", urlParamsSerializer.serialize(params));
        }
    }
}

export const urlSearchParamsController = new UrlSearchParamsController();

export const useUrl = () => {
    const [params, setParams] = useState(urlParamsSerializer.parse);

    useEffect(() => {
        return urlSearchParamsController.subscribe(setParams);
    }, []);

    return {
        params,
        openItem: urlSearchParamsController.setUrlParams
    };
};
