import PropTypes from "prop-types";

const Checkbox = ({ value, mainOptions, onChange }) => {
    return mainOptions.viewState == "view" ? (
        <span>{`${!!value}`}</span>
    ) : (
        <input type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} />
    );
};

Checkbox.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    mainOptions: PropTypes.object
};

export { Checkbox };
