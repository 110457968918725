import React from "react";
import { SvgIcon } from "@material-ui/core";

export const ExcelIcon = () => {
    return (
        <SvgIcon
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="20px"
            height="19px"
            viewBox="0 0 20 19"
            enableBackground="new 0 0 20 19"
            xmlSpace="preserve"
        >
            <image
                id="image0"
                width="20"
                height="19"
                x="0"
                y="0"
                href="
                    data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAMAAACnUt2HAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABpFBMVEUAAAAfo2Uio2YzxYIh
                    o2Qzw4EhoGQXh08Xhk4XhU0VhUsWhk0st3cXhk0ho2YWhUsVhEoUgkgTgUYUhksSgEUQfEARfkMQ
                    fEEQeUAReD8NdTsQdToNdToNcjgNVi0YXDcWSSsYXDYQYDAXWjcYWzcYWzcho2Yqs3MzxIEgn2MW
                    h04Whk0WhkwVhUsVhEsWdUUaklcjpmcst3cWhUwWhUsVhEoUg0kUgkgUgkcTgUYSgEYSf0UNYjQQ
                    ekAYj1MjilSKwaQqjlkTgUdrsIw9l2cRf0QRfkMNYTOn0LqhzbY7lWXh7+cWgUcRfUIQfEEhiFHq
                    9O/X6d9lq4UPeT8NYDISgEXK49X4+/kfg0wPdz4PdTwQUy0VYzgVdUIUhkpssIvK4dWHvJ+gybIP
                    dj0OdDsOcjoQTiwYWjYUbDwujFnm8us7kGEef0nw9vNKlmwOcTkNbzgQTSsTekEWe0MOczsRczwR
                    cToNbjcMbDUQTCoNcDgMbDYMazQLaTMQSyoVajsReEANRyYNRiYNRSUNRSQMQSMRQSYYWzYYXDcU
                    TC0WVDH///92wXFPAAAAJnRSTlMAOvLSXv1er8vLy+X9/v3+/v7+/f79/v70/T1gYGCg/V5eEJCf
                    a7QVYFkAAAABYktHRIvyb0fgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5QgYEAsfXVpj
                    bgAAAN9JREFUGNNjYAACRiY1MFDXAANmkBgDixqKICtuQTZNMEAIsnNwcnFraevo6ukbGBqBAQ8D
                    r7GJqZm5haWVtY2tHcQUPgZ+U3sHRydnF1c3d4SggJm5h6eXt4+vH5KgoJOlf0BgkJ9tcAhCUMgq
                    1DUsPCI4Mio6JjYODIQZRFzd4hMSk5JTUtPSM/zAQJRBzDczKzsnNy+/oBAhKG5bVBxVUlpWXlGJ
                    EJSIjEpJrSqvrqmtS69vAANJBilpGVm5xqbmlta29g4IkAf7XaETBLo6UAQVO1AAHkElZRVkoAoA
                    CY5gQwwG+UkAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDgtMjRUMTY6MTE6MzArMDA6MDCPMU5B
                    AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTA4LTI0VDE2OjExOjMwKzAwOjAw/mz2/QAAAABJRU5E
                    rkJggg==
                "
            />
        </SvgIcon>
    );
};
