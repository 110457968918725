import React from "react";
import { Curve } from "@assay/shared";
import { extractCurveParameters } from "../../helpers";
import { FormData } from "../../types";
import { getFieldByName } from "../form-utils";

type Props = {
    formData: FormData;
    value: string;
    originField: {
        value: string;
    };
};

export const CurveField: React.FC<Props> = ({ formData, value }) => {
    if (!value) {
        return null;
    }
    const curveValue = JSON.parse(value);
    const { curveDataField } = JSON.parse(getFieldByName(formData, "heatMapProperties")?.value);

    const { xDomain, dots, curveParameters } = extractCurveParameters(
        curveValue,
        JSON.parse(getFieldByName(formData, curveDataField)?.value).enabled,
        formData
    );
    return (
        <Curve
            isInteractive={false}
            height={350}
            width={650}
            yDomain={[0, 120]}
            xDomain={xDomain}
            dots={dots}
            onDotsChange={() => null}
            onChangeCurveParameters={() => null}
            curveParameters={curveParameters}
        />
    );
};