import React, { ReactElement, useEffect, useState } from "react";
import { initApp } from "./api-v2";
import { Assay } from "./assay";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress } from "@material-ui/core";
import { AssayProps, QueryProvider } from "@assay/shared";

const useStyles = makeStyles({
    wrapper: {
        height: "100%",
        display: "flex"
    },
    spinner: {
        alignSelf: "center",
        margin: "0 auto"
    }
});

export const App: React.FC<AssayProps> = (props): ReactElement => {
    const classes = useStyles();
    const [isAppReady, setIsAppReady] = useState(false);

    useEffect(() => {
        const init = async () => {
            await initApp(props);
            setIsAppReady(true);
        };
        init();
    }, []);

    return (
        <QueryProvider>
            <div className={classes.wrapper}>
                {isAppReady ? <Assay /> : <CircularProgress className={classes.spinner} />}
            </div>
        </QueryProvider>
    );
};
