import React from "react";

import { makeStyles } from "../theme";

const useStyles = makeStyles((theme) => ({
    disabledPlaceholder: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.palette.common.white,
        opacity: 0.65
    }
}));

export const DisabledPlaceholder: React.FC = () => {
    const classes = useStyles();

    return <div className={classes.disabledPlaceholder} />;
};
