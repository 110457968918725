import { getFormattedTextValue } from "../form-utils";
import { FieldTable } from "./field-table";
import { AnyObj, FormData } from "../../types";
import React from "react";
import { makeStyles } from "@assay/ui-kit";

const useStyles = makeStyles({
    text: {
        whiteSpace: "pre-wrap",
        lineBreak: "anywhere"
    }
});

type Props = {
    formData: FormData;
    value: string | number;
    excelOptions: AnyObj;
    mainOptions: {
        viewState: "view" | "edit" | "create";
        groupFieldNames?: string[];
        parseExcel?: boolean;
    };
    onChange: (value: string) => void;
    displayOptions: AnyObj;
};

export const TextInput: React.FC<Props> = ({
    formData,
    value,
    mainOptions,
    excelOptions: {
        dataWidth,
        dataHeight,
        reverse
    } = {},
    displayOptions,
    onChange
}) => {
    const classes = useStyles();
    const formatedValue = getFormattedTextValue(value, displayOptions);
    if (Array.isArray(mainOptions.groupFieldNames) && mainOptions.groupFieldNames.some(name => !!name)) {
        return (
            <FieldTable fields={formData.fields} groupFieldNames={mainOptions.groupFieldNames} />
        );
    }
    if (mainOptions.parseExcel && dataWidth > 0 && Array.isArray(value)) {
        const width = reverse ? dataHeight : dataWidth;
        const height = reverse ? dataWidth : dataHeight;
        return (
            <FieldTable fields={value} width={width} height={height} />
        );
    }
    if (mainOptions.viewState === "view") {
        return <span className={classes.text}>{formatedValue}</span>;
    }
    return <input type="text" value={value ?? ""} onChange={(e) => onChange(e.target.value)} />;
};


