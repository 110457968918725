import { getAppConfig } from "../appConfig";
import parse from "html-react-parser";
import { Locales } from "../types";
import enLocale from "date-fns/locale/en-US";
import jaLocale from "date-fns/locale/ja";
import zhLocale from "date-fns/locale/zh-CN";
import gbLocale from "date-fns/locale/en-GB";
import { Locale } from "date-fns";

const shortDateFormats: Record<string, string> = {
    [Locales.EnUs]: "MM/dd/yyyy",
    [Locales.EnGB]: "dd/MM/yyyy",
    [Locales.JaJp]: "yyyy/MM/dd",
    [Locales.ZhCn]: "yyyy/MM/dd"
};

const longDateFormats: Record<string, string> = {
    [Locales.EnUs]: "MMMM dd yyyy",
    [Locales.EnGB]: "dd MMMM yyyy",
    [Locales.JaJp]: "yyyy&#24180;MM&#26376;dd&#26085;",
    [Locales.ZhCn]: "yyyy&#24180;MM&#26376;dd&#26085;"
};

const localeMap: Record<string, Locale> = {
    [Locales.EnUs]: enLocale,
    [Locales.EnGB]: gbLocale,
    [Locales.JaJp]: jaLocale,
    [Locales.ZhCn]: zhLocale
};

export const getFormattedDate = (date?: string): string => {
    if (!date) {
        return "";
    }

    const { defaultLangCode, useLongDate } = getAppConfig();
    const dateFormat = useLongDate ? "full" : "short";

    return new Intl.DateTimeFormat(defaultLangCode, {
        dateStyle: dateFormat,
        timeStyle: dateFormat
    }).format(new Date(date));
};

export const getDateformat = (): string | JSX.Element | JSX.Element[] => {
    const { defaultLangCode, useLongDate } = getAppConfig();

    return useLongDate
        ? parse(longDateFormats[defaultLangCode])
        : shortDateFormats[defaultLangCode];
};

export const getLocale = (): Locale => {
    const { defaultLangCode } = getAppConfig();

    return localeMap[defaultLangCode];
};
