import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { CurveParameter, CurveParameters } from "./types";
import { theme } from "@assay/ui-kit";
import { TableInput } from "./table-input";

const useStyles = makeStyles({
    table: {
        "background": "white",
        "width": "700px",
        "borderSpacing": 0,
        "& tbody": {
            "& tr:nth-of-type(odd)": {
                background: theme.palette.grey[200]
            },
            "& tr:first-of-type": {
                background: "transparent !important"
            },
            "& tr": {
                "& td": {
                    "padding": "7px 12px",
                    "textAlign": "center",
                    '& input[type="number"]': {
                        padding: "4px 5px",
                        fontSize: "12px",
                        width: "126px !important",
                        margin: 0
                    },
                    '& input[type="checkbox"]': {
                        width: "auto !important",
                        margin: "3px !important"
                    },
                    "& td:first-of-type": {
                        textAlign: "left"
                    }
                }
            }
        }
    },
    refitCurveButton: {
        "padding": "0 28px",
        "& button": {
            "border": "1px solid rgb(0, 0, 0)",
            "padding": "4px 10px",
            "fontSize": "14px",
            "marginTop": "9px",
            "marginBottom": "9px",
            "borderRadius": "2px",
            "cursor": "pointer",
            "background": theme.palette.common.white,
            "&:hover": {
                background: theme.palette.grey[200]
            }
        }
    }
});

type Props = {
    isInteractive: boolean;
    width: number;
    parameters: CurveParameters;
    onChangeParameters: (parameters: CurveParameters) => void;
};

export const SettingsTable: React.FC<Props> = ({
    parameters,
    onChangeParameters,
    isInteractive,
    width
}) => {
    const classes = useStyles();

    const [localParameters, setLocalParameters] = useState(parameters);

    useEffect(() => {
        setLocalParameters(parameters);
    }, [parameters]);

    const onChangeHandler = (key: keyof CurveParameters, value: Partial<CurveParameter>) => {
        setLocalParameters({ ...localParameters, [key]: { ...localParameters[key], ...value } });
    };

    const prepareValue = (value: string) => +value;

    return (
        <>
            <table className={classes.table} style={{ width }}>
                <tbody>
                    <tr>
                        <th></th>
                        <th>Initial Value</th>
                        <th>Fixed</th>
                        <th>Min Value</th>
                        <th>Max Value</th>
                        <th>Calculated Outputs</th>
                    </tr>

                    <tr>
                        <td>Min Asymptote</td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.minAsymptote.initialValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("minAsymptote", {
                                        initialValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="checkbox"
                                checked={localParameters.minAsymptote.fixed ?? false}
                                onChange={(event) =>
                                    onChangeHandler("minAsymptote", {
                                        fixed: event.target.checked
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.minAsymptote.minValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("minAsymptote", {
                                        minValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.minAsymptote.maxValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("minAsymptote", {
                                        maxValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <p>{localParameters.minAsymptote.calculatedValue}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Hill Slope</td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.hillSlope.initialValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("hillSlope", {
                                        initialValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="checkbox"
                                checked={localParameters.hillSlope.fixed ?? false}
                                onChange={(event) =>
                                    onChangeHandler("hillSlope", {
                                        fixed: event.target.checked
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.hillSlope.minValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("hillSlope", {
                                        minValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.hillSlope.maxValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("hillSlope", {
                                        maxValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <p>{localParameters.hillSlope.calculatedValue}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>EC50 (uM)</td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.EC50.initialValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("EC50", {
                                        initialValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="checkbox"
                                checked={localParameters.EC50.fixed ?? false}
                                onChange={(event) =>
                                    onChangeHandler("EC50", {
                                        fixed: event.target.checked
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.EC50.minValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("EC50", {
                                        minValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.EC50.maxValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("EC50", {
                                        maxValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <p>{localParameters.EC50.calculatedValue}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Max Asymptote</td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.maxAsymptote.initialValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("maxAsymptote", {
                                        initialValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="checkbox"
                                checked={localParameters.maxAsymptote.fixed ?? false}
                                onChange={(event) =>
                                    onChangeHandler("maxAsymptote", {
                                        fixed: event.target.checked
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.maxAsymptote.minValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("maxAsymptote", {
                                        minValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <TableInput
                                isInteractive={isInteractive}
                                type="number"
                                value={localParameters.maxAsymptote.maxValue ?? ""}
                                onChange={(event) =>
                                    onChangeHandler("maxAsymptote", {
                                        maxValue: prepareValue(event.target.value)
                                    })
                                }
                            />
                        </td>
                        <td>
                            <p>{localParameters.maxAsymptote.calculatedValue}</p>
                        </td>
                    </tr>
                    {isInteractive && (
                        <tr>
                            <td colSpan={5} align="right">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td
                                                className={classes.refitCurveButton}
                                                onClick={() => onChangeParameters(localParameters)}
                                            >
                                                <button>Refit Curve</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
};
