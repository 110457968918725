import moment from "moment";

export const convertDateToString = (date: Date): string => moment(date).format("MM/DD/YYYY");

export const convertStringToDate = (str: string): Date | null => {
    const parts = str.split("/");
    const newStr = `${parts[2]}-${parts[0]}-${parts[1]}`;
    if (newStr.length === 10) {
        return new Date(newStr);
    }
    return null;
};
