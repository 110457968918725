import React, { useMemo } from "react";
import { useSheetOptions } from "../../hooks";

import { SheetEditor } from "../../components/sheet-editor";
import {
    useCommonEditorData,
    useFieldsEditorData,
    useHeadersEditorData,
    useRegexpEditorData
} from "./hooks";
import {
    OptionWithNumber,
    Position,
    SelectedSheetMatrix,
    SelectType,
    TemplateRange
} from "../../types";
import { headersLength } from "../../utils";

export const SheetEditorWorkplace = (): React.ReactElement => {
    const {
        isFileExist,
        sheets,
        filePreview,
        fileParsingInProcess,
        isDisplayOnEmptyCells,
        changeIsDisplayOnEmptyCells,
        changeCurrentSheetIndex,
        currentSheetIndex
    } = useCommonEditorData();

    const { setRegexpPosition } = useRegexpEditorData();

    const sheetsOptions = useSheetOptions(sheets);
    const tableData = useMemo(() => sheets.map((item) => item.rawData), [sheets]);

    const { headersMatrix, handleChangeHeaders, editHeadersMode, currentHeadersSheet } =
        useHeadersEditorData(sheets, tableData);

    const {
        editFieldMode,
        fieldsMatrix,
        fieldSheetNumber,
        onChangeFieldTemplate,
        onChangeFieldSheet
    } = useFieldsEditorData(sheets);

    const findFieldSheetOption = () =>
        sheetsOptions.find((option) => option.number === fieldSheetNumber);

    const sheetChangeDisabled =
        (!editHeadersMode && !editFieldMode && !filePreview) || fileParsingInProcess;

    const selectedSheetMatrix =
        editHeadersMode || filePreview
            ? headersMatrix[currentSheetIndex]
            : fieldsMatrix[fieldSheetNumber];

    const dataSheetMatrix =
        tableData[editHeadersMode || filePreview ? currentSheetIndex : fieldSheetNumber];

    const getOnChangeHandler = () => {
        if (editHeadersMode) {
            return handleChangeHeaders;
        } else if (editFieldMode) {
            return (_: unknown, template?: TemplateRange) => {
                if (template) onChangeFieldTemplate(template);
            };
        } else if (setRegexpPosition) {
            return (matrix: SelectedSheetMatrix) => {
                const position: Position = { row: 0, column: 0 };
                matrix.forEach((row, rowIndex) => {
                    row.forEach((column, columnsIndex) => {
                        if (column === SelectType.ActiveSelected) {
                            position.row = rowIndex - headersLength;
                            position.column = columnsIndex - headersLength;
                        }
                    });
                });
                setRegexpPosition(position);
            };
        }
        return () => null;
    };

    const onChangeSheetHandler = (sheet: OptionWithNumber | null) => {
        if (editHeadersMode || filePreview) {
            changeCurrentSheetIndex(sheet?.number ?? 0);
        } else if (sheet) {
            onChangeFieldSheet(sheet);
        }
        if (sheet) {
            changeCurrentSheetIndex(sheet.number);
        }
    };

    const currentSheet =
        editHeadersMode || filePreview ? currentHeadersSheet : findFieldSheetOption() ?? null;

    const editMode = editHeadersMode || editFieldMode || !!setRegexpPosition;

    return (
        <SheetEditor<OptionWithNumber>
            templateMode={editFieldMode}
            sheetChangeDisabled={sheetChangeDisabled}
            selectedSheetMatrix={selectedSheetMatrix}
            dataSheetMatrix={dataSheetMatrix}
            onChange={getOnChangeHandler()}
            onChangeSheet={onChangeSheetHandler}
            editMode={editMode}
            currentSheet={currentSheet}
            sheetsOptions={sheetsOptions}
            isDisplayOnEmptyCells={isDisplayOnEmptyCells}
            isDisplayOnEmptyCellsDisabled={!isFileExist}
            onChangeIsDisplayOnEmptyCells={changeIsDisplayOnEmptyCells}
        />
    );
};
