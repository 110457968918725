import React, { useRef } from "react";
import { useUrl } from "@assay/core";
import { useAuthContext, authActions } from "./auth";
import { config } from "./config";

const SANDBOX_URL_PARAM = "sandboxtab";

type ChangeTabIndex = (event: React.ChangeEvent<unknown>, newValue: number) => void;

type UseTabs = () => {
    tabIndex: number;
    changeTabIndex: ChangeTabIndex;
};

export const useTabs: UseTabs = () => {
    const { params, openItem } = useUrl();
    const tabIndex = +(params[SANDBOX_URL_PARAM] ?? 0);

    const setTabIndex = (value: number) =>
        openItem({
            [SANDBOX_URL_PARAM]: value
        });

    const changeTabIndex: ChangeTabIndex = (event, newValue) => {
        setTabIndex(newValue);
    };

    return { tabIndex, changeTabIndex };
};

type TokenProvider = (refresh?: boolean) => Promise<string>;

/**
 * returns the function that must be passed to our deliverable components (Assay, UTs, etc)
 */
export const useAuthTokenProvider = (): TokenProvider => {
    const auth = useAuthContext();

    // make sure authTokenProvider always has access to the most up-to-date accessToken
    const authRef = useRef(auth);
    authRef.current = auth;

    const authTokenProvider = async (refresh?: boolean): Promise<string> => {
        if (refresh) {
            await authActions.refreshTokens();
        }

        return authRef.current.accessToken || config.FAKE_TOKENS.accessToken;
    };

    return authTokenProvider;
};
