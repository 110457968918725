export const config = {
    IS_AUTH_ENABLED: true,
    AUTH_API_URL: "https://beta.arxspan.com/webservices/auth/api/v1",
    APP_NAME: "Workflow",
    FAKE_TOKENS: {
        accessToken:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyODk3Iiwic2Vzc2lvbklkIjoiMmE2OTAxMTEtYzk1MS00ODAwLTg5NWUtYjUyMjA0ZjkzMDgwIiwidG9rZW5UeXBlIjoiYWNjZXNzIiwibmJmIjoxNjEzNjUzNzcyLCJleHAiOjI5MTM2NTQ2NzIsImlzcyI6IkFyeHNwYW4vQnJ1a2VyIiwiYXVkIjoiQWxsIn0.KINgB8areSaNU1_NCNrC162lDUzZh9ciB5g_vqlKCjo",
        refreshToken:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyODk3IiwidG9rZW5UeXBlIjoicmVmcmVzaCIsIm5iZiI6MTYxMzY1Mzc3MiwiZXhwIjoxNjEzNjYwOTcyLCJpc3MiOiJBcnhzcGFuL0JydWtlciIsImF1ZCI6IkFsbCJ9.ggkXPzKP33Rd7QgQ0MmU3frKBuK5XACWty6qeknfUTQ"
    }
};
