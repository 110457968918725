import { useState } from "react";
import { Box, Checkbox, Collapse, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { UsersList } from "./users-list";

const useStyles = makeStyles({
    listItem: {
        "padding": 0,
        "flexDirection": "column",
        "&:not(:last-child)": {
            borderBottom: "1px solid #e0e0e0"
        }
    },

    box: {
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        flexDirection: "row"
    },

    itemText: {
        cursor: "pointer",
        margin: 0,
        paddingTop: "8px"
    },

    collapse: {
        alignSelf: "flex-start",
        paddingLeft: "50px"
    }
});

export const GroupItem = ({ group, selected, onGroupChange, onUserChange }) => {
    const classes = useStyles();
    const { name, id, users } = group;

    const isInitiallyOpen = () => {
        const isAnyUserSelected = users.some((user) => selected.userIds.includes(user.id));

        return isAnyUserSelected;
    };

    const [open, setOpen] = useState(isInitiallyOpen);

    return (
        <ListItem className={classes.listItem}>
            <Box className={classes.box}>
                <Checkbox
                    checked={selected.groupIds.includes(id)}
                    onChange={() => onGroupChange(group)}
                />
                <ListItemText
                    primary={name}
                    className={classes.itemText}
                    onClick={() => setOpen(!open)}
                />
            </Box>

            <Collapse in={open} className={classes.collapse}>
                <UsersList group={group} selected={selected} onUserChange={onUserChange} />
            </Collapse>
        </ListItem>
    );
};
