import React from "react";
import { makeStyles } from "@assay/ui-kit";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    addButtonWrapper: {
        display: "flex",
        justifyContent: "center",
        paddingTop: theme.spacing(2)
    }
}));

type Props = {
    onClick: () => void;
};

export const AddParameterButton: React.FC<Props> = ({ onClick }) => {
    const classes = useStyles();

    return (
        <div className={classes.addButtonWrapper}>
            <Button color="primary" onClick={onClick}>
                + Add New Parameter
            </Button>
        </div>
    );
};
