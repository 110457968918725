// context menu icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";

// Tree item icons
import CheckIcon from "@material-ui/icons/Check";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import BlurCircularIcon from "@material-ui/icons/BlurCircular";
import DeleteIcon from "@material-ui/icons/Delete";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import FolderIcon from "@material-ui/icons/Folder";
import KitchenIcon from "@material-ui/icons/Kitchen";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import LockIcon from "@material-ui/icons/Lock";
import PaletteIcon from "@material-ui/icons/Palette";
import StorageIcon from "@material-ui/icons/Storage";
import DescriptionIcon from "@material-ui/icons/Description";
import React from "react";

export const ICONS = {
    "Add": AddIcon,
    "Edit": EditIcon,
    "View": VisibilityIcon,
    "check.gif": CheckIcon,
    "checkedout.gif": AssignmentTurnedInIcon,
    "cylinder.gif": BlurCircularIcon,
    "disposed.gif": DeleteIcon,
    "door.gif": MeetingRoomIcon,
    "flask.gif": LocalDrinkIcon,
    "folder.gif": FolderIcon,
    "freezer.gif": KitchenIcon,
    "hierarchy.gif": AccountTreeIcon,
    "lab.gif": GroupWorkIcon,
    "lock.gif": LockIcon,
    "plate.gif": PaletteIcon,
    "rack.gif": StorageIcon,
    "text_dock.gif": DescriptionIcon
};

type Props = {
    iconName: keyof typeof ICONS;
    className?: string;
} & React.ComponentProps<typeof AddIcon>;

export const TreeViewIcon: React.FC<Props> = ({ iconName, ...rest }) => {
    const Icon = ICONS[iconName];
    if (!Icon) return null;
    return <Icon {...rest} />;
};
