import { getFormDunders } from "../../api-v2";
import { isObject } from "../../helpers";
import { FormData, Dunders, AnyObj } from "../../types";

/**
 * Recursively extracts dunder ids from formData
 */
const extractDunderIds = (object: AnyObj, dunderIds: number[] = []): number[] => {
    if (!isObject(object) && !Array.isArray(object)) {
        return dunderIds;
    }

    for (const key in object) {
        const val = object[key];

        if (key === "_dunderSource") {
            dunderIds.push(val);
        }

        if (Array.isArray(val)) {
            val.forEach((el) => extractDunderIds(el, dunderIds));
        } else if (isObject(val) && val._dunderSource) {
            extractDunderIds(val, dunderIds);
        }
    }

    return dunderIds;
};

type DunderMap = Record<string, Dunders>;

const attachDunderData = (object: AnyObj, dunderDataMap: DunderMap): void => {
    for (const val of Object.values(object)) {
        if (isObject(val)) {
            attachDunderData(val, dunderDataMap);
        } else if (Array.isArray(val)) {
            val.map((el) => attachDunderData(el, dunderDataMap));
        }
    }

    if (!object._dunderSource) {
        return;
    }

    const dunderSource = dunderDataMap[object._dunderSource];

    for (const dunderKey in dunderSource) {
        if (!dunderKey.startsWith("__")) {
            continue;
        }

        const dunder = dunderSource[dunderKey as keyof Dunders] as AnyObj;

        for (const key in dunder) {
            if (dunderKey === "__parent") {
                object[key] = dunder[key];
            } else {
                if (object[dunderKey]) {
                    object[dunderKey][key] = dunder[key];
                } else {
                    object[dunderKey] = dunder;
                }
            }
        }
    }
};

/**
 * Fetches and attaches dunder properties to a form, it's fields and fieldsets.
 */
export const attachDunders = async (formData: FormData): Promise<void> => {
    const dunderIds = extractDunderIds(formData);

    if (!dunderIds.length) {
        return;
    }

    const dunderDataMap = await getFormDunders(dunderIds);

    attachDunderData(formData, dunderDataMap);
};
