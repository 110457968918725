import create, { StateSelector } from "zustand";
import shallow from "zustand/shallow";
import { DotParams, ExternalCurveParameters } from "@assay/shared";
import { FormData } from "../types";

type HeatMapProps = {
    noTrtIndex: number;
    DMSOIndex: number;
    columnLabels: string[];
    emptyBorderCells: {
        top: number[];
        bottom: number[];
        left: number[];
        right: number[];
    };
    DMSOPercentActivity: number[];
    percentActivityFields: number[][];
};

export type StoreCurveParameters = {
    dots: DotParams[];
    xDomain: [number, number];
    curveParameters: ExternalCurveParameters;
    compoundId: string;
    cellLine: string;
    registrationId: string;
};

type Store = {
    isHeatMapShown: boolean;
    forms: FormData[];
    heatMapProps: HeatMapProps | null;
    curvesParams: StoreCurveParameters[];
    setHeatmapProps: (props: HeatMapProps) => void;
    setIsHeatMapShown: (value: boolean) => void;
    selectedCells: Array<boolean[] | undefined>;
    setSelectedCells: (selectedCells: Array<boolean[] | undefined>) => void;
    setCurveParameters: (value: StoreCurveParameters[]) => void;
    reset: () => void;
    setForms: (forms: FormData[]) => void;
};

const initStore = {
    forms: [],
    isHeatMapShown: false,
    heatMapProps: null,
    selectedCells: [],
    curvesParams: []
};

const useStore = create<Store>((set) => ({
    ...initStore,
    setIsHeatMapShown: (isHeatMapShown) => {
        set({ isHeatMapShown });
    },
    setHeatmapProps: (heatMapProps) => set({ heatMapProps }),
    setSelectedCells: (selectedCells) => set({ selectedCells }),
    reset: () => set({ ...initStore }),
    setCurveParameters: (curvesParams) => set({ curvesParams }),
    setForms: (forms) => set({ forms })
}));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.getHeatMapState = () => useStore.getState();

export const useHeatMapStore = <T>(selector: StateSelector<Store, T>) =>
    useStore(selector, shallow);