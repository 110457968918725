import { useState } from "react";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { makeStyles } from "@material-ui/core/styles";
import { testId, getAppConfig } from "@assay/shared";

import { TreeViewContainer } from "../tree-view";
import { Search } from "./search";

const useStyles = makeStyles({
    sidebarContainer: {
        height: "100%",
        boxShadow: "6px 0 8px -5px #ccc",
        display: "flex"
    },

    sidebar: {
        display: "flex",
        flexDirection: "column",
        transition: ".2s",
        overflowX: "hidden",
        width: (open) => (open ? "240px" : "0")
    },

    button: {
        borderRadius: 0,
        height: "100%",
        minWidth: "36px",
        padding: 0
    },

    icon: {
        width: "20px",
        height: "20px",
        transform: (open) => `rotateZ(${open ? "180" : "0"}deg)`
    }
});

export const Sidebar = () => {
    const [open, setOpen] = useState(true);
    const classes = useStyles(open);
    const { hasFTLiteAssay } = getAppConfig()
    const handleSetOpen = (value) => {
        setOpen(value);
        //for recalculation some styles in new upload template sheet
        setTimeout(() => window.dispatchEvent(new Event("resize")), 0);
    };

    return (
        <Box className={classes.sidebarContainer}>
            <Box className={classes.sidebar}>
                {hasFTLiteAssay && <Search />}
                <Divider />
                <TreeViewContainer />
            </Box>
            <Button className={classes.button} onClick={() => handleSetOpen(!open)}
                {...testId("toggle-sidebar-button")}>
                <DoubleArrowIcon className={classes.icon} />
            </Button>
        </Box>
    );
};
