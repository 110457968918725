import React from "react";
import { DebouncedTextField } from "@assay/ui-kit";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { useFileTabGridStyles } from "../hooks";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { RegularExpressionHint } from "../components";
import { getTranslation } from "@assay/shared";

const selectState = ({
    fileName,
    useFileNameRegexp,
    actions: { changeFileName, changeFileUseRegularExpression }
}: UploadTemplateStore) => ({
    fileName,
    useFileNameRegexp,
    changeFileUseRegularExpression,
    changeFileName
});

export const FileNameField = (): React.ReactElement => {
    const classes = useFileTabGridStyles();
    const { changeFileName, fileName, useFileNameRegexp, changeFileUseRegularExpression } =
        useShallowUploadTemplateStore(selectState);
    return (
        <div className={classes.root}>
            <Typography className={classes.title}>{getTranslation("file-name")}</Typography>{" "}
            <div className={classes.inputWrapper}>
                <DebouncedTextField
                    variant="outlined"
                    size={"medium"}
                    value={fileName}
                    onChangeValue={changeFileName}
                    className={classes.input}
                />
            </div>
            <div className={classes.checkbox}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={useFileNameRegexp}
                            onChange={(_, useRegexp) => changeFileUseRegularExpression(useRegexp)}
                            name="Regular Expression"
                        />
                    }
                    label={getTranslation("regular-expression")}
                />
                <RegularExpressionHint entity={getTranslation("file")} />
            </div>
        </div>
    );
};
