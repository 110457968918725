import React, { useEffect } from "react";
import { useHeatMapStore } from "../../store";
import { getResults } from "../../api-v2";
import { convertFormsToHeatmap } from "../../helpers";
import { FormData } from "../../types";
import { getTranslation } from "@assay/shared";

type Props = {
    formData: FormData;
    value: string;
    mainOptions: { viewState: "view" | "edit" | "create" };
    onChange: (value: string) => void;
};

export const HeatMapButton: React.FC<Props> = ({ formData, mainOptions }) => {
    const isHeatMapShown = useHeatMapStore((state) => state.isHeatMapShown);
    const setIsHeatMapShown = useHeatMapStore((state) => state.setIsHeatMapShown);
    const heatMapProps = useHeatMapStore((state) => state.heatMapProps);
    const setSelectedCells = useHeatMapStore((state) => state.setSelectedCells);
    const setHeatmapProps = useHeatMapStore((state) => state.setHeatmapProps);
    const setCurveParameters = useHeatMapStore((state) => state.setCurveParameters);
    const setForms = useHeatMapStore((state) => state.setForms);

    useEffect(() => {
        const createHeatMap = async () => {
            const resultsForms = await getResults(formData.id);

            if (resultsForms.length < 1) {
                return;
            }

            setForms(resultsForms);

            const {
                curveParameters,
                columnLabels,
                noTrtIndex,
                DMSOIndex,
                emptyBorderCells,
                DMSOPercentActivity,
                percentActivityFields,
                selectedCells
            } = convertFormsToHeatmap(resultsForms);

            setCurveParameters(curveParameters);
            setSelectedCells(selectedCells);
            setHeatmapProps({
                DMSOIndex,
                noTrtIndex,
                columnLabels,
                emptyBorderCells,
                DMSOPercentActivity,
                percentActivityFields
            });
        };
        if (isHeatMapShown) {
            createHeatMap();
        }
    }, [isHeatMapShown]);

    if (mainOptions.viewState !== "view") {
        return null;
    }

    if (!isHeatMapShown || !heatMapProps) {
        return (
            <button onClick={() => setIsHeatMapShown(true)}>
                {getTranslation("show-heat-map")}
            </button>
        );
    }
    return null;
};