import React from "react";
import { saveEnvsToWindow } from "@assay/shared";
import { AuthProvider } from "./auth";
import { AppRaw } from "./app-raw";
import { theme, ThemeProvider } from "@assay/ui-kit";

saveEnvsToWindow();

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <AppRaw />
            </AuthProvider>
        </ThemeProvider>
    );
};

export default App;
