import React, { useState } from "react";
import { Heatmap } from "@assay/shared";

export const HeatmapTab = () => {
    const [selectedCells, setSelectedCells] = useState<Array<boolean[] | undefined>>([
        undefined,

        [true, true, true, true, true, true, true, true]
    ]);
    const columnLabels=[
        "",
        "0.022",
        "0.068",
        "0.205",
        "0.617",
        "No Trt",
        "DMSO",
        "1.851",
        "5.555",
        "16.66",
        "50",
        ""
    ];
    return (
        <div style={{ padding: "10px", background: "lightgray" }}>
            <Heatmap
                noTrtIndex={columnLabels.findIndex((item) => item === "No Trt") - 1}
                DMSOIndex={columnLabels.findIndex((item) => item === "DMSO") - 1}
                columnLabels={columnLabels}
                emptyBorderCells={{
                    top: [
                        18410, 34790, 38570, 39410, 37330, 39860, 36510, 29780, 26900, 21160, 15480,
                        8600
                    ],
                    bottom: [
                        9400, 13780, 15460, 15620, 16170, 18260, 17210, 14910, 12130, 9590, 7480,
                        4250
                    ],
                    left: [33080, 37890, 31520, 25400, 19540, 15550],
                    right: [13120, 13970, 11490, 8890, 7800, 7440]
                }}
                DMSOPercentActivity={[85.93, 86.7]}
                percentActivityFields={[
                    [94.18, 82.43, 61.2, 42.42, 35.53, 40.01, 38.96, 28.21],
                    [98.58, 85.16, 64.48, 44.05, 32.92, 41.85, 40.69, 29.2]
                ]}
                selectedCells={selectedCells}
                onChangeSelectedCells={setSelectedCells}
            />
        </div>
    );
};
