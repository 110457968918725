import React from "react";
import { DotProps } from "./types";
import { curve, theme } from "@assay/ui-kit";

export const ActiveHoveredDot: React.FC<DotProps> = ({ x, y }) => (
    <svg
        x={(x ?? 0) - 10}
        y={(y ?? 0) - 10}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="10" cy="10" r="10" fill={theme.palette.common.black} fillOpacity="0.3" />
        <circle cx="10" cy="10" r="5.5" fill={curve.dot} stroke={theme.palette.common.white} />
    </svg>
);
