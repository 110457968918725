import React from "react";
import { TemplateElement } from "./template-element";
import { SelectedSheetMatrix, SelectType } from "../../types";

type Props = {
    editMode: boolean;
    matrix: SelectedSheetMatrix;
    onChange: (rowIndex: number, columnIndex: number) => void;
};

const isActive = (value: SelectType) =>
    value === SelectType.ActiveSelected || value === SelectType.DisabledPrimarySelected;

export const TemplateSettingsMatrix = ({
    matrix,
    onChange,
    editMode
}: Props): React.ReactElement => {
    return (
        <table>
            <tbody>
                {matrix.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((column, columnIndex) => (
                            <td
                                key={columnIndex}
                                onClick={() => {
                                    onChange(rowIndex, columnIndex);
                                }}
                            >
                                <TemplateElement isActive={isActive(column)} editMode={editMode} />
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
