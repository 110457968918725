import { useQueryClient } from "react-query";
import { getParentTree, refetchTreeNode } from "../api-v2";
import { useTreeHandlers } from "../store";
import { useUrl } from "../use-url";
import { ContentTypes } from "../types";
import { useEffect } from "react";
import { UT_FOLDER_ID } from "../constants";

export const useInitTreeState = (): void => {
    const { toggleSelect, openNodes } = useTreeHandlers();
    const { params } = useUrl();
    const queryClient = useQueryClient();

    const initTreeState = async () => {
        const { id, type, parentId } = params;
        const selectedItemId = id ?? parentId;

        if (!selectedItemId) {
            return;
        }

        const parentTree =
            type === ContentTypes.Ut ? [UT_FOLDER_ID] : await getParentTree(selectedItemId);

        await Promise.all(parentTree.map((node) => refetchTreeNode(node, queryClient)));
        openNodes(parentTree.map((key) => String(key)));
        toggleSelect(String(selectedItemId));
    };

    const shouldInit = Object.values(params).length > 0;

    useEffect(() => {
        if (shouldInit) {
            initTreeState();
        }
    }, []);
};
