import { useQuery, UseQueryResult } from "react-query";
import { apiCall } from "./common";
import { getNextId } from "./other";
import { attachDunder, attachDunders, extractDunderIds } from "../form/form-utils";
import { attachOptions } from "../form-helpers.js";
import { Dunders, FormData } from "../types";

export const sendSaveFormRequest = async (formData: FormData): Promise<void> => {
    await apiCall("saveForm", "POST", { form: formData });
};

export const createNewForm = async (formData: FormData): Promise<number> => {
    const id = await getNextId();
    formData.id = id;
    await sendSaveFormRequest(formData);
    return id;
};

export const getForm = async (id: number): Promise<FormData> => {
    const { forms } = await apiCall("loadForms", "POST", { ids: [id] });
    return forms[0];
};

export const getForms = async (ids: number[]): Promise<FormData[]> => {
    const { forms } = await apiCall("loadForms", "POST", { ids });
    return forms;
};

export const getFormQueryKey = (id?: number) => `form-${id}`;

export const useFormQuery = (id?: number): UseQueryResult<FormData> => {
    return useQuery<FormData>({
        queryKey: getFormQueryKey(id),
        queryFn: async () => {
            const form = await getForm(id as number);
            attachOptions(form);
            await attachDunders(form);
            return form;
        },
        enabled: !!id,
        keepPreviousData: true
    });
};

export const getFormIdsAndCursorData = async (payload: {
    query: { parentId: number };
}): Promise<number[]> => {
    const defaultBody = {
        rpp: 10000, // results per page
        query: {},
        action: "next",
        list: true
    };
    const { forms: ids } = await apiCall("getList", "POST", { ...defaultBody, ...payload });

    return ids;
};

export const deleteFormRefactored = async (id: number): Promise<void> => {
    await apiCall("deleteForm", "POST", { id });
};

// TODO: remove and replace use cases with attachDunders from helpers/dunders
/**
 * Populates dunder fields
 */
export const attachDunderAsync = async (formData: FormData): Promise<void> => {
    //gets dunder properties for fields/fieldSets

    const dunderIds = extractDunderIds(formData);

    let dunderDataMap;

    if (dunderIds.length) {
        dunderDataMap = await getFormDunders(dunderIds);
    }

    attachDunder(formData, dunderDataMap);
};

type DunderMap = Record<string, Dunders>;

export const getFormDunders = async (ids: number[]): Promise<DunderMap> => {
    const idsWithoutDuplicates = [...new Set(ids)];

    const dunderData: Dunders[] = await apiCall("loadDunders", "POST", {
        ids: idsWithoutDuplicates
    });

    const dunderDataMap: DunderMap = {};

    if (dunderData) {
        dunderData.forEach((dataObj) => {
            dunderDataMap[dataObj.id] = dataObj;
        });
    }

    return dunderDataMap;
};
