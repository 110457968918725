import React, { forwardRef, memo } from "react";
import { VirtualItem } from "react-virtual";
import { useTableContentStyles } from "./styles";
import { SelectType } from "../../types";

type Props = {
    editMode: boolean;
    virtualRow: VirtualItem;
    virtualColumn: VirtualItem;
    selectType: SelectType;
    hasSelected: boolean;
    content: string;
    onSelectStart: () => void;
    onSelectEnd: () => void;
    onSelect: () => void;
    onMouseOver?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const SheetCell = memo(
    forwardRef<HTMLDivElement, Props>(
        (
            {
                editMode,
                virtualRow,
                virtualColumn,
                selectType,
                content,
                onSelectStart,
                onSelect,
                onSelectEnd,
                hasSelected,
                onMouseOver
            },
            ref
        ): React.ReactElement => {
            const classes = useTableContentStyles(editMode);

            const getClassName = () => {
                if (selectType === SelectType.ActiveSelected) {
                    return classes.contentCellActiveSelectedStyle;
                }
                if (selectType === SelectType.DisabledPrimarySelected) {
                    return classes.contentCellDisabledPrimarySelectedStyle;
                }
                if (selectType === SelectType.DisabledSecondarySelected) {
                    return classes.contentCellDisabledSecondarySelectedStyle;
                }
                if (selectType === SelectType.SkippedSelected) {
                    return classes.contentCellSkippedSelectedStyle;
                }
                if (selectType === SelectType.RegExp) {
                    return classes.contentCellRegexpStyle;
                }
                if (hasSelected) {
                    return classes.contentCellDisabledStyle;
                }
                return classes.contentCellStyle;
            };

            return (
                <div
                    ref={ref}
                    className={getClassName()}
                    style={{
                        width: `${virtualColumn.size}px`,
                        transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`
                    }}
                    onMouseDown={() => {
                        onSelectStart();
                    }}
                    onMouseEnter={() => {
                        onSelect();
                    }}
                    onMouseUp={onSelectEnd}
                    onMouseOver={onMouseOver}
                >
                    {content}
                </div>
            );
        }
    )
);

SheetCell.displayName = "SheetCell";
