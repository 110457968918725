import { ExternalCurveParameters, InitialCurveParams } from "../types";
import { DotParams } from "../dot/types";
import { curveFunction, fitCurve } from "./legacy/arxFit";

export const calculateCurve = (
    xVals: number[],
    yVals: number[],
    userParams: ExternalCurveParameters
): { dots: DotParams[]; params?: InitialCurveParams; isError?: boolean } => {
    try {
        const params = fitCurve(
            xVals,
            yVals,
            [
                userParams.minAsymptote.initialValue,
                userParams.hillSlope.initialValue,
                userParams.EC50.initialValue,
                userParams.maxAsymptote.initialValue
            ],
            [
                {
                    name: "minAsymptote",
                    fixed: userParams.minAsymptote.fixed,
                    minVal: userParams.minAsymptote.minValue,
                    maxVal: userParams.minAsymptote.maxValue
                },
                {
                    name: "hillSlope",
                    fixed: userParams.hillSlope.fixed,
                    minVal: userParams.hillSlope.minValue,
                    maxVal: userParams.hillSlope.maxValue
                },
                {
                    name: "inflectionPoint",
                    fixed: userParams.EC50.fixed,
                    minVal: userParams.EC50.minValue,
                    maxVal: userParams.EC50.maxValue
                },
                {
                    name: "maxAsymptote",
                    fixed: userParams.maxAsymptote.fixed,
                    minVal: userParams.maxAsymptote.minValue,
                    maxVal: userParams.maxAsymptote.maxValue
                }
            ]
        );

        if (params?.error) {
            return {
                dots: [],
                isError: true
            };
        }

        const resolution = 500;
        const xHigh = Math.max(...xVals);
        const xLow = Math.min(...xVals);
        const dots: DotParams[] = [];
        const step = (xHigh - xLow) / resolution;
        for (let i = xLow; i < xHigh; i += step) {
            dots.push({
                x: i,
                y: curveFunction(i, params.params),
                isActive: true
            });
        }

        return {
            dots,
            params: {
                minAsymptote: (+params.params[0]).toFixed(2),
                hillSlope: (+params.params[1]).toFixed(2),
                inflectionPoint: (+Math.pow(10, Number(+params.params[2]))).toPrecision(3),
                maxAsymptote: (+params.params[3]).toFixed(2)
            }
        };
    } catch (e) {
        return {
            dots: [],
            isError: true
        };
    }
};
