import React from "react";

import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { Typography } from "@material-ui/core";
import { MainFileViewBar } from "./main-file-view-bar";
import { MainFileDropzone } from "./main-file-dropzone";
import { makeStyles } from "@assay/ui-kit";
import { FileNameField } from "./file-name-field";
import { SheetsNamesEditor } from "./sheets-names-editor";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles((theme) => ({
    root: {
        "position": "relative",
        "display": "flex",
        "flexDirection": "column",
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(2)
        }
    }
}));

const selectState = ({ mainFile }: UploadTemplateStore) => ({
    isMainFileExist: !!mainFile
});

export const MainFile: React.FC = () => {
    const classes = useStyles();
    const { isMainFileExist } = useShallowUploadTemplateStore(selectState);

    return (
        <div className={classes.root}>
            <Typography variant={"subtitle2"}>{`${getTranslation("sample-file")}:`}</Typography>
            {isMainFileExist && <MainFileViewBar />}
            {!isMainFileExist && <MainFileDropzone />}
            <FileNameField />
            <SheetsNamesEditor />
        </div>
    );
};
