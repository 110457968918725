import React, { useState } from "react";
import { Box, Button, CircularProgress, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@assay/ui-kit";
import { authActions } from "./auth-actions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(3)
    },
    textField: {
        marginBottom: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(3)
    },
    spinner: {
        marginRight: theme.spacing(1.5)
    },
    errorMessage: {
        height: 20,
        marginBottom: theme.spacing(1)
    },
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    }
}));

type Props = {
    onSuccess: () => void;
};

export const SignInForm: React.FC<Props> = ({ onSuccess }) => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            await authActions.signIn(userName, password, apiKey);
            onSuccess();
        } catch (error) {
            setSubmitError("Something went wrong");
            console.error(error);
            setIsLoading(false);
        }
    };

    const classes = useStyles();

    return (
        <Box component="form" className={classes.root} onSubmit={handleSubmit}>
            <Typography gutterBottom className={classes.title} variant="h3">
                {"Please, sign in"}
            </Typography>

            <TextField
                required
                fullWidth
                autoComplete="username"
                label="User name"
                type="text"
                name="userName"
                variant="outlined"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className={classes.textField}
                autoFocus
            />

            <TextField
                required
                fullWidth
                autoComplete="current-password"
                label="Password"
                type="password"
                name="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={classes.textField}
            />

            <TextField
                required
                fullWidth
                label="API key"
                type="text"
                name="apiKey"
                variant="outlined"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                className={classes.textField}
            />

            <Typography variant="body2" color="error" className={classes.errorMessage}>
                {submitError}
            </Typography>

            <Box className={classes.buttonBox}>
                {isLoading && <CircularProgress className={classes.spinner} size={18} />}

                <Button color="primary" variant="outlined" type="submit">
                    Sign in
                </Button>
            </Box>
        </Box>
    );
};
