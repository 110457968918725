import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles, StyledDialog } from "@assay/ui-kit";
import { PrepareSheetsReplacementResult } from "../utils";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles({
    dialogRoot: {
        minWidth: "350px"
    }
});

type Props = {
    isOpen: boolean;
    onClose: () => void;
    fields: PrepareSheetsReplacementResult[];
    onEraseInconsistent: () => void;
    onEraseAll: () => void;
};

export const InconsistentFieldsDialog: React.FC<Props> = ({
    fields,
    onEraseInconsistent,
    onEraseAll,
    onClose,
    isOpen
}) => {
    const classes = useStyles();
    return (
        <StyledDialog
            title={getTranslation("some-mapping-rules-are-inconsistent")}
            isOpen={isOpen}
            onClose={onClose}
            content={
                <div className={classes.dialogRoot}>
                    {fields?.map((item) => (
                        <Typography variant="subtitle2" key={item.field.id}>
                            {item.field.name}
                        </Typography>
                    ))}
                </div>
            }
            actions={
                <>
                    <Button color="secondary" variant="outlined" onClick={onEraseInconsistent}>
                        {getTranslation("erase-only-inconsistent-mapping-rules")}
                    </Button>
                    <Button color="primary" onClick={onEraseAll}>
                        {getTranslation("erase-all-mapping-rules")}
                    </Button>
                </>
            }
        />
    );
};
