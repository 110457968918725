import { UploadTemplateField } from "../../types";
import { positionToStringPosition } from "../../utils";

export const getRegExpFieldValues = (field: UploadTemplateField) => {
    const {
        startRegexPositions,
        startRegexpValue,
        endRegexPositions,
        endRegexpValue,
        errors,
        touched,
        startRegexStepRight,
        startRegexStepDown
    } = field;

    const startRegexPositionsStr = startRegexPositions
        ? positionToStringPosition(startRegexPositions)
        : "";

    const endRegexPositionsStr = endRegexPositions
        ? positionToStringPosition(endRegexPositions)
        : "";

    const startRegexpValueError = touched?.startRegexpValue ? errors?.startRegexpValue : "";
    const endRegexpValueError = touched?.endRegexpValue ? errors?.endRegexpValue : "";
    const startRegexPositionsError = touched?.startRegexPositions
        ? errors?.startRegexPositions
        : "";
    const endRegexPositionsError = touched?.endRegexPositions ? errors?.endRegexPositions : "";

    const startRegexStepRightError = touched?.startRegexStepRight
        ? errors?.startRegexStepRight
        : "";

    const startRegexStepDownError = touched?.startRegexStepDown ? errors?.startRegexStepDown : "";

    return {
        startRegexpValue,
        endRegexpValue,
        startRegexPositionsStr,
        startRegexStepRight,
        endRegexPositionsStr,
        startRegexpValueError,
        endRegexpValueError,
        startRegexPositionsError,
        endRegexPositionsError,
        startRegexStepDown,
        startRegexStepRightError,
        startRegexStepDownError
    };
};