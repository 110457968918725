import React from "react";
import { DisabledPlaceholder, makeStyles, SpinnerPlaceholder, TabPanel } from "@assay/ui-kit";
import { Tab, Tabs } from "@material-ui/core";
import { SettingsFilesTab } from "./settings-files-tab";
import { SettingsPropertiesTab } from "./settings-properties-tab";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { FILES_TAB_INDEX, PROPERTIES_TAB_INDEX } from "../values";
import { SaveButton } from "./save-button";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        width: "100%",
        height: "100%",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        boxSizing: "border-box"
    },
    tabs: {
        marginTop: theme.spacing(2),
        flexGrow: 1
    },
    saveButtons: {
        margin: theme.spacing(1)
    }
}));

const selectState = ({
    customTemplateModeActive,
    fileParsingInProcess,
    settingsTabIndex,
    sendingDataInProcess,
    actions
}: UploadTemplateStore) => ({
    disabled: customTemplateModeActive || fileParsingInProcess || sendingDataInProcess,
    loading: fileParsingInProcess || sendingDataInProcess,
    tabIndex: settingsTabIndex,
    setTabIndex: actions.setSettingsTabIndex
});

export const SettingsPanel = (): React.ReactElement => {
    const classes = useStyles();

    const { tabIndex, setTabIndex, disabled, loading } = useShallowUploadTemplateStore(selectState);

    const handleChange = (_: unknown, newValue: number) => setTabIndex(newValue);

    return (
        <div className={classes.root}>
            <Tabs
                value={tabIndex}
                onChange={handleChange}
                aria-labelledby="upload-template-properties-bar-tabs"
            >
                <Tab label={getTranslation("files")} />
                <Tab label={getTranslation("properties")} />
            </Tabs>
            <div className={classes.tabs}>
                <TabPanel selectedIndex={tabIndex} currentIndex={FILES_TAB_INDEX}>
                    <SettingsFilesTab />
                </TabPanel>
                <TabPanel selectedIndex={tabIndex} currentIndex={PROPERTIES_TAB_INDEX}>
                    <SettingsPropertiesTab />
                </TabPanel>
            </div>
            {disabled && <DisabledPlaceholder />}
            {loading && <SpinnerPlaceholder />}
            <div className={classes.saveButtons}>
                <SaveButton />
            </div>
        </div>
    );
};
