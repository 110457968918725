import { alpha } from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";

import { grey, primary, secondary } from "../colors";
import { config } from "../config";

export const MuiIconButton: Overrides["MuiIconButton"] = {
    root: {
        borderRadius: config.borderRadius
    },

    colorPrimary: {
        color: secondary.main,

        [config.hoverSelector]: {
            backgroundColor: alpha(primary.main, 0.1)
        },

        [config.disabledSelector]: {
            backgroundColor: grey[100],
            color: secondary.light!
        }
    },

    sizeSmall: {
        padding: 8,
        width: "auto",
        height: "auto"
    }
};
