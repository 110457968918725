import React, { CSSProperties, useMemo } from "react";
import { VictoryAxis, VictoryLine, VictoryScatter } from "victory";
import { Dot, DotParams } from "./dot";
import produce from "immer";
import { Legend } from "./legend";
import { ExternalCurveParameters } from "./types";
import { curve, theme } from "@assay/ui-kit";
import { SettingsTable } from "./settings-table";
import { calculateCurve } from "./fit/fit";
import { getRangeNumbers } from "../../helpers";

type StyleParams = {
    width: number;
    height: number;
};

const createStyles = ({ width, height }: StyleParams) => ({
    wrapper: {
        display: "flex",
        width: "fit-content",
        flexDirection: "column"
    },
    root: {
        background: theme.palette.common.white,
        width,
        height
    },
    hiddenLabels: {
        display: "none"
    },
    grid: {
        stroke: curve.grid,
        strokeWidth: 1
    },
    tickLabels: {
        fontSize: 11
    },
    line: {
        stroke: curve.line,
        strokeWidth: 2
    }
});

type Props = {
    width?: number;
    height?: number;
    xDomain: [number, number];
    yDomain: [number, number];
    dots: DotParams[];
    onDotsChange: (dots: DotParams[]) => void;
    curveParameters: ExternalCurveParameters;
    onChangeCurveParameters: (parameters: ExternalCurveParameters) => void;
    isInteractive?: boolean;
};

export const Curve: React.FC<Props> = ({
    width = 700,
    height = 350,
    xDomain,
    yDomain,
    dots,
    onDotsChange,
    curveParameters,
    onChangeCurveParameters,
    isInteractive = true
}) => {
    const styles = createStyles({ height, width });

    const onDotChange = (x: number, y: number, isActive: boolean) => {
        const newDots = produce(dots, (draft) => {
            const dot = draft.find((item) => item.x === x && item.y === y);
            if (dot) {
                dot.isActive = isActive;
            }
        });
        onDotsChange(newDots);
    };

    const xTicks = getRangeNumbers(xDomain[0], xDomain[1], 0.5);

    const filteredLineDots = useMemo(() => dots.filter((item) => item.isActive), [dots]);

    const {
        params: calculatedCurveParams,
        dots: curveDots,
        isError: isErrorInLine
    } = useMemo(
        () =>
            calculateCurve(
                filteredLineDots.map((item) => item.x),
                filteredLineDots.map((item) => item.y),
                curveParameters
            ),
        [filteredLineDots, curveParameters]
    );

    const tableParameters = useMemo(
        () => ({
            minAsymptote: {
                ...curveParameters.minAsymptote,
                calculatedValue: calculatedCurveParams?.minAsymptote ?? ""
            },
            maxAsymptote: {
                ...curveParameters.maxAsymptote,
                calculatedValue: calculatedCurveParams?.maxAsymptote ?? ""
            },
            hillSlope: {
                ...curveParameters.hillSlope,
                calculatedValue: calculatedCurveParams?.hillSlope ?? ""
            },
            EC50: {
                ...curveParameters.EC50,
                calculatedValue: calculatedCurveParams?.inflectionPoint ?? ""
            }
        }),
        [curveParameters, calculatedCurveParams]
    );

    return (
        <div style={styles.wrapper as CSSProperties}>
            <svg style={styles.root}>
                <VictoryAxis
                    crossAxis={false}
                    style={{
                        grid: styles.grid,
                        tickLabels: styles.tickLabels
                    }}
                    height={height}
                    width={width}
                    dependentAxis
                    domain={yDomain}
                    orientation="left"
                    standalone={false}
                />
                <VictoryAxis
                    height={height}
                    width={width}
                    style={{
                        tickLabels: styles.hiddenLabels
                    }}
                    dependentAxis
                    orientation="top"
                    standalone={false}
                />
                <VictoryAxis
                    crossAxis={false}
                    style={{
                        grid: styles.grid,
                        tickLabels: styles.tickLabels
                    }}
                    height={height}
                    width={width}
                    dependentAxis
                    tickValues={xTicks}
                    domain={xDomain}
                    orientation="bottom"
                    standalone={false}
                />
                <VictoryAxis
                    height={height}
                    width={width}
                    style={{
                        tickLabels: styles.hiddenLabels
                    }}
                    dependentAxis
                    orientation="right"
                    standalone={false}
                />
                <Legend x={width - 143} y={52} />
                {!isErrorInLine && (
                    <VictoryLine
                        height={height}
                        width={width}
                        domain={{
                            x: xDomain,
                            y: yDomain
                        }}
                        style={{
                            data: styles.line
                        }}
                        standalone={false}
                        data={curveDots}
                    />
                )}

                <VictoryScatter
                    height={height}
                    width={width}
                    domain={{
                        x: xDomain,
                        y: yDomain
                    }}
                    standalone={false}
                    size={6}
                    dataComponent={
                        <Dot isInteractive={isInteractive} allDots={dots} onChange={onDotChange} />
                    }
                    data={dots}
                />
            </svg>
            <SettingsTable
                isInteractive={isInteractive}
                width={width}
                onChangeParameters={(newParameters) => onChangeCurveParameters(newParameters)}
                parameters={tableParameters}
            />
        </div>
    );
};
