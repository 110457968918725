import React from "react";
import { Button, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { green, StyledDialog } from "@assay/ui-kit";
import { getTranslation } from "@assay/shared";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) =>
    createStyles({
        text: {
            minHeight: "50px",
            minWidth: "350px"
        },
        applyButton: {
            "padding": theme.spacing(1),
            "minWidth": 90,
            "height": 36,
            "& svg": {
                color: green
            }
        },
        rejectButton: {
            "padding": theme.spacing(1),
            "minWidth": 90,
            "height": 36,
            "& svg": {
                color: theme.palette.warning.main
            }
        },

        flex: {
            display: "flex",
            gap: theme.spacing(1),
            justifyContent: "flex-end"
        }
    })
);

interface Props {
    onClose: () => void;
    onSubmit: () => void;
    isOpen: boolean;
    title: string;
}

export const ConfirmDialog: React.FC<Props> = ({ onClose, onSubmit, isOpen, title }: Props) => {
    const classes = useStyles();

    return (
        <StyledDialog
            onClose={onClose}
            title={title}
            content={
                <Typography variant="subtitle2" className={classes.text}>
                    {getTranslation("are-you-sure")}
                </Typography>
            }
            actions={
                <div className={classes.flex}>
                    <Button
                        classes={{ root: classes.applyButton }}
                        variant="outlined"
                        color="secondary"
                        onClick={() => onSubmit()} //for avoid transferring event into callback
                        startIcon={<CheckIcon />}
                    >
                        {getTranslation("yes")}
                    </Button>
                    <Button
                        classes={{ root: classes.rejectButton }}
                        color="secondary"
                        variant="outlined"
                        onClick={() => onClose()}
                        startIcon={<ClearIcon />}
                    >
                        {getTranslation("no")}
                    </Button>
                </div>
            }
            isOpen={isOpen}
        />
    );
};
