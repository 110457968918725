import { Fragment, useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import { DialogRoot } from "./dialog-root";
import { CopyFormLayout } from "./copy-form-layout";
import { CopyFormTitle } from "./copy-form-title";
import { TreeView } from "../tree-view";
import { assayTreeQuery } from "../api-v2";
import { PasteButton } from "./paste-button";
import { getTranslation } from "@assay/shared";

const CopyAssayForm = ({ onSubmit, initialValues = {} }) => {
    const [name, setName] = useState(initialValues.assayName ?? "");
    const [currentNodeKey, setCurrentNodeKey] = useState(initialValues.currentNodeKey ?? null);
    const [inProgress, setInProgress] = useState(false);

    return (
        <Box
            component="form"
            onSubmit={async (e) => {
                setInProgress(true);
                e.preventDefault();
                await onSubmit({ name, currentNodeKey });
                setInProgress(false);
            }}
        >
            <CopyFormLayout
                title={<CopyFormTitle>{getTranslation("copy-assay")}</CopyFormTitle>}
                fields={
                    <Fragment>
                        <TextField
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            name="name"
                            label={getTranslation("new-assay-name")}
                            variant="outlined"
                            fullWidth
                        />

                        <Box marginTop={2}>
                            <Typography>Select A New Location: </Typography>
                            <Box height="400px">
                                <TreeView
                                    query={assayTreeQuery}
                                    onItemClick={(_, item) =>
                                        setCurrentNodeKey(item.key.replace("_", ""))
                                    }
                                />
                            </Box>
                        </Box>
                    </Fragment>
                }
                actions={<PasteButton inProgress={inProgress} disabled={!currentNodeKey} />}
            />
        </Box>
    );
};

export const CopyAssayDialog = ({ onClose, formProps = {}, open = true, ...popupProps }) => {
    return (
        <DialogRoot {...popupProps} onClose={onClose} open={open}>
            <CopyAssayForm
                {...formProps}
                onSubmit={async (...args) => {
                    try {
                        await formProps.onSubmit(...args);
                        onClose?.();
                    } catch (e) {
                        alert(e.message);
                        console.warn(e);
                    }
                }}
            />
        </DialogRoot>
    );
};
