import React, { useState } from "react";
import { Curve, ExternalCurveParameters } from "@assay/shared";
//https://model.arxspan.com/arxlab/assay2/index.asp#F25732B9BA7EB1BDD651D9748DA5659B
export const CurveTab = () => {
    const [dots, setDots] = useState([
        { x: -0.6, y: 98.58, isActive: true },
        { x: -0.16376009240711664, y: 85.16, isActive: true },
        { x: 0.31336156334225695, y: 64.48, isActive: true },
        { x: 0.7904832613048535, y: 44.05, isActive: true },
        { x: 1.2676049358150259, y: 33.92, isActive: true },
        { x: 1.7447266259594252, y: 41.85, isActive: true },
        { x: 2.2218483153216573, y: 40.69, isActive: true },
        { x: 2.6989700043360187, y: 29.2, isActive: true }
    ]);
    const [curveParameters, setCurveParameters] = useState<ExternalCurveParameters>({
        EC50: {
            fixed: false
        },
        maxAsymptote: {
            fixed: false,
            maxValue: 120
        },
        hillSlope: {
            fixed: false
        },
        minAsymptote: {
            initialValue: 0,
            fixed: true
        }
    });
    return (
        <Curve
            xDomain={[-1, 3]}
            yDomain={[0, 120]}
            dots={dots}
            onDotsChange={setDots}
            curveParameters={curveParameters}
            onChangeCurveParameters={(parameters) => setCurveParameters(parameters)}
        />
    );
};
