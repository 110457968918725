import React, { useRef } from "react";
import { ExtendedTheme, makeStyles } from "@assay/ui-kit";

import { useRefSize } from "../hooks";

type StyleProps = {
    height: number;
};

const useStyles = makeStyles<ExtendedTheme, StyleProps>((theme) => ({
    root: {
        position: "relative",
        height: "100%",
        width: "100%"
    },
    inner: ({ height }) => ({
        paddingTop: theme.spacing(1),
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        flex: "1 1 0%",
        overflow: "auto",
        height: `${height}px`,
        width: "100%",
        paddingRight: theme.spacing(0.5)
    })
}));

type Props = {
    children: React.ReactNode;
};

export const SettingsTab = ({ children }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const { height } = useRefSize(ref);
    const classes = useStyles({ height });
    return (
        <div className={classes.root} ref={ref}>
            <div className={classes.inner}>{children}</div>
        </div>
    );
};
