import { useState } from "react";
import { useUrl } from "../use-url";
import { copyUploadTemplate, maxStringFieldLength, UploadTemplates } from "@assay/upload-templates";
import { CopyTemplateDialog } from "../copy-dialogs";
import { useGlobalStore } from "../store";
import { ContentTypes } from "../types";
import { getResultDefinitionsQueryParams } from "../api-v2";
import { UT_FOLDER_ID } from "../constants";
import { useReloads } from "../hooks";

type CopyState = {
    isOpen: boolean;
    id: string;
};

export const UploadTemplatesContent = () => {
    const [copyState, setCopyState] = useState<CopyState | null>(null);
    const { params, openItem } = useUrl();
    const { id, viewState } = params;
    const { userAdded, dateAdded } = useGlobalStore(({ dateAdded, userAdded }) => ({
        dateAdded,
        userAdded
    }));
    const { reloadAfterSave, reloadAfterCopy, reloadAfterDelete } = useReloads();

    const editMode = viewState === "edit" || viewState === "create";

    const onSubmitCopy = async (name: string) => {
        if (copyState?.id && name) {
            const newTemplate = await copyUploadTemplate(copyState.id, name);

            reloadAfterCopy({
                id: newTemplate.id as string,
                name: "Upload Template",
                entityType: ContentTypes.Ut,
                parentTree: [UT_FOLDER_ID]
            });
        }
    };

    const onSave = async (id: string) => {
        reloadAfterSave({
            id,
            name: "Upload Template",
            entityType: ContentTypes.Ut,
            parentId: UT_FOLDER_ID
        });
    };

    const onDelete = () => {
        reloadAfterDelete({
            name: "Upload Template",
            parentId: UT_FOLDER_ID
        });
    };

    const onCancel = () => {
        openItem({});
    };

    const onChangeMode = (editMode: boolean) => {
        openItem({ ...params, viewState: editMode ? "edit" : "view" });
    };

    const onInitCopy = (copyId: string) =>
        setCopyState({
            isOpen: true,
            id: copyId
        });

    return (
        <>
            <UploadTemplates
                callbacks={{
                    onSave,
                    onDelete,
                    onChangeMode,
                    onInitCopy,
                    onCancel
                }}
                resultDefinitionsQueryParams={getResultDefinitionsQueryParams()}
                id={id}
                editMode={editMode}
                created={{
                    userId: userAdded.id,
                    userName: userAdded.userName,
                    date: dateAdded
                }}
            />
            <CopyTemplateDialog
                open={!!copyState?.isOpen}
                onClose={() => setCopyState(null)}
                formProps={{
                    name: "",
                    onSubmit: onSubmitCopy,
                    maxNameLength: maxStringFieldLength
                }}
            />
        </>
    );
};
