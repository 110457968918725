import React from "react";
import { Popover, PopoverOrigin } from "@material-ui/core";
import { makeStyles } from "../theme";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: "none"
    },
    paper: {
        padding: theme.spacing(1),
        color: theme.palette.common.white,
        background: theme.palette.primary.main
    }
}));

type PopoverPosition = "left" | "right" | "top" | "bottom";

const positionsMap = {
    left: {
        anchorOrigin: {
            vertical: "center",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "center",
            horizontal: "right"
        }
    },
    right: {
        anchorOrigin: {
            vertical: "center",
            horizontal: "right"
        },
        transformOrigin: {
            vertical: "center",
            horizontal: "left"
        }
    },
    top: {
        anchorOrigin: {
            vertical: "top",
            horizontal: "center"
        },
        transformOrigin: {
            vertical: "bottom",
            horizontal: "center"
        }
    },
    bottom: {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center"
        }
    }
};

type Props = {
    popoverContent: React.ReactNode;
    position?: PopoverPosition;
};

export const WithPopover: React.FC<Props> = ({ popoverContent, position, children }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const positionParameters = positionsMap[position ?? "left"];

    return (
        <>
            <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                {children}
            </div>
            <Popover
                className={classes.popover}
                classes={{
                    paper: classes.paper
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={positionParameters.anchorOrigin as PopoverOrigin}
                transformOrigin={positionParameters.transformOrigin as PopoverOrigin}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {popoverContent}
            </Popover>
        </>
    );
};
