import { Option, OptionWithNumber } from "./common";
import { ResultDefinition } from "@assay/shared";

export type TemplateDirection = {
    down: boolean;
    right: boolean;
    diagonal: boolean;
};

export type Position = {
    row: number;
    column: number;
};

export type TemplateElement = {
    position: Position;
    isSelected: boolean;
};

export type TemplateRange = {
    repeatCount: number;
    repeatAll: boolean;
    repeat: TemplateDirection;
    template: TemplateElement[] | null;
    isMulti: boolean;
    reverse: boolean;
    relatedField?: unknown; //for legacy
    leftOffset?: unknown; //for legacy
    topOffset?: unknown; //for legacy
};

export enum SelectType {
    ActiveSelected,
    DisabledPrimarySelected,
    DisabledSecondarySelected,
    SkippedSelected,
    NotSelected,
    RegExp
}

export type DocumentSheet = {
    id: string;
    name: string;
    number: number;
    useRegexp: boolean;
    rawData: string[][];
};

export type Sheet = { name: string; rows: string[][] };

export type SelectedSheetMatrix = SelectType[][];
export type SelectedMatrix = SelectedSheetMatrix[];
export type DataSheetMatrix = string[][];
export type DataMatrix = DataSheetMatrix[];

export type FileApiAction = "create" | "download" | "delete";

export type DataRange = {
    simpleRule: TemplateDirection | null;
    range: TemplateRange | null;
    additionalRanges?: unknown; //for legacy
};

export type UploadTemplateFieldTouched = {
    resultDefinition: boolean;
    name: boolean;
    startRegexPositions: boolean;
    endRegexPositions: boolean;
    startRegexpValue: boolean;
    endRegexpValue: boolean;
    startRegexStepRight: boolean;
    startRegexStepDown: boolean;
};

export type MergeType = "empty" | "comma" | "dash" | "space" | null;

export type UploadTemplateField = {
    id: string;
    name: string;
    sheet: OptionWithNumber;
    resultDefinitionId?: string;
    headerPosition: Position | null;
    aggregationFields: Option[] | null;
    mergeType: MergeType;
    dataRange: DataRange;
    required: boolean;
    decimalPlaces: number | null;
    startRegexPositions?: Position;
    isStartRegexPositionsFocused?: boolean;
    endRegexPositions?: Position;
    isEndRegexPositionsFocused?: boolean;
    startRegexpValue?: string;
    endRegexpValue?: string;
    startRegexStepRight?: number;
    startRegexStepDown?: number;
    scientificNotation: boolean;
    showInFT: boolean;
    FTMode?: Option;
    hideInResult: boolean;
    errors?: UploadTemplateFieldErrors;
    touched?: UploadTemplateFieldTouched;
};

export type UploadTemplateFieldErrors = {
    resultDefinition?: string;
    name?: string;
    dataRange?: string;
    aggregationFields?: string;
    startRegexPositions?: string;
    endRegexPositions?: string;
    startRegexpValue?: string;
    endRegexpValue?: string;
    startRegexStepRight?: string;
    startRegexStepDown?: string;
};

export type UploadTemplateName = {
    value: string;
    error?: string | null;
    touched?: boolean;
};

export type FilePayload = {
    fileId: string;
    fileName: string;
};

export type FileSaved = FilePayload & {
    isMainFile?: boolean;
    willBeDeleted?: boolean;
};

export type CreatedInformation = {
    userId: string;
    userName: string;
    date: string;
};

export type UploadTemplate = {
    id: string | null;
    name: UploadTemplateName;
    fileName: string;
    useFileNameRegexp: boolean;
    isDisplayOnEmptyCells: boolean;
    fields: UploadTemplateField[];
    sheets: DocumentSheet[];
    sheetsErrors: Map<string, string>;
    sheetsTouched: Map<string, boolean>;
    mainFile: File | FileSaved | null;
    attachments: Array<File | FileSaved>;
    created?: CreatedInformation;
    canDelete: boolean;
    canEdit: boolean;
};

export type Header = {
    id: string;
    name: string;
    sheet: OptionWithNumber | null;
    savedField?: UploadTemplateField;
} & Position;

export type UploadTemplatePayload = {
    id?: string;
    name: string;
    fileName: string;
    useFileNameRegexp: boolean;
    isDisplayOnEmptyCells: boolean;
    fields: UploadTemplateFieldPayload[];
    sheets: DocumentSheet[];
    file?: FilePayload;
    attachments?: FilePayload[];
    created?: CreatedInformation;
    canDelete: boolean;
    canEdit: boolean;
};

export type UploadTemplateFieldPayload = {
    id: string;
    name: string;
    sheetId: string;
    resultDefinition: string;
    headerPosition: Position | null;
    aggregationFields: string[] | null;
    mergeType: MergeType;
    startRegexPositions?: Position;
    endRegexPositions?: Position;
    startRegexpValue?: string;
    endRegexpValue?: string;
    scientificNotation: boolean;
    dataRange: DataRange;
    required: boolean;
    showInFT: boolean;
    useFTOneValueMode: boolean;
    decimalPlaces: number | null;
    hideInResult: boolean;
    startRegexStepRight?: number;
    startRegexStepDown?: number;
};

export type ResultDefinitionsQueryParams = {
    queryFn: () => Promise<ResultDefinition[]>;
    queryKey: string;
};

export type UploadTemplateCallbacks = {
    onSave: (id: string) => void;
    onChangeMode: (editMode: boolean) => void;
    onDelete: (id: string) => void;
    onInitCopy: (id: string) => void;
    onCancel: () => void;
};

type InitExternalsParams = {
    callbacks: UploadTemplateCallbacks;
    created?: CreatedInformation;
    resultDefinitionsQueryParams: ResultDefinitionsQueryParams;
};

export type UploadTemplateActions = {
    loadTemplate: (id: string, editMode: boolean, replaceConditions?: boolean) => void;
    initMainFile: (data: DocumentSheet[], file: File) => void;
    removeMainFile: () => void;
    setResultDefinitions: (resultDefinitions: ResultDefinition[]) => void;
    removeRow: (fieldIndex: number) => void;
    changeField: (fieldIndex: number, field: UploadTemplateField) => void;
    addField: () => void;
    replaceFields: (fields: UploadTemplateField[]) => void;
    touchField: (fieldIndex: number, subField: keyof UploadTemplateFieldTouched) => void;
    handleConfirmHeaders: () => void;
    handleEditHeaders: () => void;
    changeName: (name: string) => void;
    changeFileName: (name: string) => void;
    changeFileUseRegularExpression: (isUse: boolean) => void;
    changeSheetName: (index: number, name: string) => void;
    changeSheetUseRegularExpression: (index: number, isUse: boolean) => void;
    touchName: () => void;
    addAttachment: (file: File) => void;
    removeAttachment: (index: number) => void;
    reset: (hard?: boolean) => void;
    changeEditMode: (value: boolean) => void;
    setSettingsTabIndex: (index: number) => void;
    setTempHeaders: (headers: Header[]) => void;
    setEditableFieldIndex: (index: number | null) => void;
    setCustomTemplateModeActive: (value: boolean) => void;
    setFileParsingInProgress: (value: boolean) => void;
    setFileParsingError: (value: string | null) => void;
    saveUploadTemplate: () => void;
    retrySaveFiles: () => void;
    deleteFilesErrors: () => void;
    removeUploadTemplate: () => void;
    initExternals: (value: InitExternalsParams) => void;
    setMessages: (value: { succeedMessage?: string; errorMessage?: string }) => void;
    initWithoutFile: () => void;
    addSheet: () => void;
    changeSheets: (sheets: DocumentSheet[]) => void;
    changeIsDisplayOnEmptyCells: (checked: boolean) => void;
    touchSheetName: (id: string) => void;
    changeCurrentSheetIndex: (index: number) => void;
};

export type FileApiError = {
    action: FileApiAction;
    isMainFile?: boolean;
    file?: File;
    fileId?: string;
    fileName: string;
};

export type UploadTemplateListItem = {
    id: string;
    name: string;
};
