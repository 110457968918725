import { ContentTypes, TreeNode } from "../../../types";
import { getGlobalPermissions } from "@assay/shared";

const disabledPermissions = {
    isAddDisabled: true,
    isViewDisabled: true,
    isEditDisabled: true
};

export const calcPermissions = (item: TreeNode) => {
    const isGlobalCanAdd = getGlobalPermissions().canAdd;

    if (!item) {
        return disabledPermissions;
    }

    const isAddDisabled = !item.canAdd || !isGlobalCanAdd;

    const isViewDisabled = item.type === ContentTypes.Ut && item.showTable;
    const isEditDisabled = !item.canEdit;

    return {
        isAddDisabled,
        isViewDisabled,
        isEditDisabled
    };
};
