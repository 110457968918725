import { afterSaveResultSet } from "./result-set";
import { createNewForm, getForm, getNextSequence } from "../../api-v2";
import { attachDunders } from "./dunders";
import { ensureArray, isNumber } from "../../helpers";
import { getUploadTemplate } from "@assay/upload-templates";
import { Features, featureService, queryClient } from "@assay/shared";

/**
 * Get props for <FieldWrapper> from field object
 * @param {Object} field
 */
export const retreiveProps = (field, viewState) => {
    const {
        value,
        fields,
        __pFieldOptions: {
            name,
            type,
            options: optionList,
            required,
            multi,
            inTable,
            isTableLink,
            hidden,
            parseExcel
        },
        __pFunctions: { afterShow, beforeShow } = {},
        __pDisplayOptions: displayOptions,
        __pDefOptions: { groupFieldNames } = {},
        __pExcelOptions: excelOptions
    } = field;

    const mainOptions = {
        required,
        parseExcel,
        hidden,
        multi,
        inTable,
        isTableLink,
        groupFieldNames,
        viewState,
        afterShow,
        beforeShow
    };

    const onChange = (value) => (field.value = value);
    const originField = field;

    return {
        type,
        name,
        value,
        fields,
        optionList,
        mainOptions,
        displayOptions,
        excelOptions,
        originField,
        onChange
    };
};

const removeUnusedProps = (field) => {
    if (typeof field !== "object" || Array.isArray(field) || field === null) {
        return;
    }
    const unusedProps = [
        "__pFieldOptions",
        "__pDefOptions",
        "__pExcelOptions",
        "__pDisplayOptions",
        "__ftOptions",
        "__pFunctions"
    ];
    for (let prop of unusedProps) {
        delete field[prop];
    }
};

const recursiveRemoveProps = (formData) => {
    removeUnusedProps(formData);
    for (let prop in formData) {
        if (typeof formData[prop] === "object") {
            recursiveRemoveProps(formData[prop]);
        }
    }
};

export const formDataToSave = (formData, fields) => {
    const formClone = { ...formData, fields: null };
    recursiveRemoveProps(formClone);
    const fieldsClone = JSON.parse(JSON.stringify(fields));
    recursiveRemoveProps(fieldsClone);
    return { ...formClone, fields: fieldsClone };
};

export function getFormattedTextValue(value, displayOptions) {
    //apply scientific notation and num decimal places options to field
    try {
        const showScientificNotation = displayOptions.scientificNotation;
        let noDecimals = false;
        let numDecimals = displayOptions.decimalPlaces;
        if (isNumber(numDecimals)) {
            numDecimals = parseInt(numDecimals);
        } else {
            if (showScientificNotation) {
                numDecimals = 1;
            } else {
                noDecimals = true;
            }
        }

        if (showScientificNotation) {
            return value.toExponential(numDecimals);
        } else if (!noDecimals) {
            return value.toFixed(numDecimals);
        }
    } catch (err) {}
    return value;
}

export const getChildrenAsync = async (formId) => {
    const { childIds } = await restCallAsync("/getChildIds/", "POST", {
        id: formId
    });
    childIds.sort();
    //add all children to cache
    //add all children as forms to array from cache
    return childIds.map(async (id) => await getForm(id));
};

export const getFieldsByType = (field, type) => {
    const fields = Array.isArray(field) ? field : field.fields;
    if (!fields) {
        return null;
    }
    return fields.filter(
        (field) => type.toLowerCase() === field.__pFieldOptions.type?.toLowerCase()
    );
};

export const getFieldByName = (field, name) => {
    const fields = Array.isArray(field) ? field : field.fields ?? field.fd.fields;
    if (!fields) {
        return [];
    }
    return fields.find((field) => name.toLowerCase() === field.__pFieldOptions.name.toLowerCase());
};

export const getOriginFieldByName = (field, name) => {
    const fields = Array.isArray(field) ? field : field.fields;
    if (!fields) {
        return null;
    }
    return fields.find((field) => name.toLowerCase() === field.name.toLowerCase());
};

export const extractDunderIds = (formObject, dunderSourceIds = []) => {
    // recursively get dunder ids from formData and nested objects

    if ((typeof formObject !== "object" || formObject === null) && !Array.isArray(formObject)) {
        return dunderSourceIds;
    }
    for (let key in formObject) {
        const val = formObject[key];
        const isObject = typeof val === "object" && val !== null;

        if (key === "_dunderSource") {
            dunderSourceIds.push(val);
        }

        if (Array.isArray(val)) {
            val.forEach((el) => {
                extractDunderIds(el, dunderSourceIds);
            });
        }

        if (isObject && val._dunderSource) {
            extractDunderIds(val, dunderSourceIds);
        }
    }

    return dunderSourceIds;
};

export const attachDunder = (object, dunderDataMap) => {
    //recursively iterate through all objects
    for (const val of Object.values(object)) {
        const isValueObject = typeof val === "object" && val !== null;

        if (isValueObject) {
            attachDunder(val, dunderDataMap);
        }
    }

    /**
     // _NOTE: This is only used on the admin page
     if (object && object.hasOwnProperty("linkFunction")) {
        const tempF = new AsyncifiedFunction("field", object["linkFunction"]);
        await tempF(object);
    }
     */

    //if there is a _dunderSource property {_dunderSource:2323) load the dunder object with that id and merge fields
    if (object && object.hasOwnProperty("_dunderSource")) {
        //get the dunder object

        const lO = dunderDataMap[object["_dunderSource"]];

        //iterate through each item in the dunder object
        for (var property in lO) {
            if (lO.hasOwnProperty(property)) {
                if (property == "__parent") {
                    //for each item in the __parent object attach that item to the root of this field
                    for (var property2 in lO["__parent"]) {
                        object[property2] = lO["__parent"][property2];
                    }
                } else {
                    if (object.hasOwnProperty(property)) {
                        //if the dunder object already exists on the field
                        //overwrite each item currently in that dunder object with each item existing in the new dunder object
                        if (property.substring(0, 2) == "__") {
                            for (var property2 in lO[property]) {
                                // dunders sometimes have the property fields in them
                                // that is why there is a check for __ The check for __ may be desired anyways
                                // but the dunders should not have a fields property.
                                if (lO[property].hasOwnProperty(property2)) {
                                    object[property][property2] = lO[property][property2];
                                }
                            }
                        }
                    } else {
                        //if the dunder object is not on the field add it
                        if (property.substring(0, 2) == "__") {
                            object[property] = lO[property];
                        }
                    }
                }
            }
        }
    }
};

const getValidationName = (type) => {
    switch (type) {
        case "real number":
        case "percentage": {
            return "isNumber";
        }
        case "integer":
            return "isInteger";
        case "date":
            return "isDate";
    }
};

const getFieldType = (type) => {
    switch (type) {
        case "text":
        case "real number":
        case "integer":
        case "percentage": {
            return "text";
        }
        case "bool":
            return "checkbox";
        case "curve":
            return "curve";
        case "heat map":
            return "heatMap";
        case "drop down":
            return "select";
        case "date":
            return "date";
    }
};

const createFileField = (name) => ({
    name,
    __ftOptions: {
        mode: null,
        send: false
    },
    __pFieldOptions: {
        multi: false,
        required: true,
        type: "file",
        name
    }
});

export const beforeShow = async (formData, viewState) => {
    if (formData.name === "Upload Template") {
        const { fields } = getFieldByName(formData, "Fields");
        for (let i = 0, len = fields.length; i < len; i++) {
            uploadTemplateLogic(fields[i]);
        }
    } else if (formData.name === "Result Definition") {
        const type = getFieldByName(formData, "type")?.value;
        if (type !== "drop down") {
            const optionsField = getFieldByName(formData, "options");
            if (optionsField) {
                optionsField.__pFieldOptions.hidden = true;
                optionsField.__pFieldOptions.required = false;
            }
        }
    } else if (formData.name === "Result Set") {
        const fileField = getFieldByName(formData, "File");
        let filesAdded = true;

        if (viewState === "create") {
            const protocol = await getForm(formData.parentId);
            const uploadTemplateId = getOriginFieldByName(protocol, "upload template").value;
            const isNewUtsOn = featureService[Features.IsNewUploadTemplatesOn];

            if (isNewUtsOn) {
                const response = await getUploadTemplate(uploadTemplateId);
                const uploadTemplate = await response.json();
                formData.fields.push(createFileField(uploadTemplate.fileName));
            } else {
                const uploadTemplate = await getForm(uploadTemplateId);
                const uploadFilesExist = !!getOriginFieldByName(uploadTemplate, "File Fields");

                if (uploadFilesExist) {
                    const fileJson = getOriginFieldByName(uploadTemplate, "FILEJSON").value;
                    if (fileJson) {
                        const fields = JSON.parse(fileJson);
                        formData.fields.push(...fields);
                        if (!fields.length) {
                            filesAdded = false;
                        }
                    }
                }
            }

            const recipesIds = ensureArray(getOriginFieldByName(protocol, "recipes").value);
            for (let recipeId of recipesIds) {
                if (recipeId) {
                    const recipe = await getForm(recipeId);
                    const json = getOriginFieldByName(recipe, "JSON").value;
                    const items = getOriginFieldByName(recipe, "Items");
                    if (json) {
                        const fields = JSON.parse(json);
                        fields.forEach((field, index) => {
                            const defaultValue = getOriginFieldByName(
                                items.fields[index],
                                "default value"
                            ).value;
                            field.value = defaultValue;
                            formData.fields.push(field);
                        });
                    }
                }
            }

            await attachDunders(formData);

            fileField.__pFieldOptions.hidden = filesAdded;
        } else {
            if (getFieldsByType(formData, "file").length > 1) {
                fileField.__pFieldOptions.hidden = true;
            }
            const heatMapProps = getFieldByName(formData, "heatMapProperties");
            if (!heatMapProps?.value) {
                const heatMapField = getFieldsByType(formData, "heatMapProperties")[0];
                if (heatMapField) {
                    heatMapField.__pFieldOptions.hidden = true;
                }
            }
        }
    } else if (formData.name === "Result") {
        const fieldsToHide = ["Heat Map"];
        const curves = getFieldsByType(formData, "curve");
        const curvesOptions = curves
            .filter((field) => !!field.value)
            .map((field) => JSON.parse(field.value));
        fieldsToHide.push(...curves.filter(curve => !curve.value).map(curve => curve.__pFieldOptions.name));
        if (curvesOptions.length) {
            for (let option of curvesOptions) {
                fieldsToHide.push(
                    ...option.fitType.displayParameters.map(
                        (param) => option.fitType.parameterDisplayConfig[param].label
                    )
                );
                for (let key in option.fitType.parameterDisplayConfig) {
                    fieldsToHide.push(...option.fitType.parameterDisplayConfig[key].conditions);
                }
            }
        }
        for (let field of formData.fields) {
            if (field.__pDefOptions.groupFieldNames) {
                fieldsToHide.push(...field.__pDefOptions.groupFieldNames);
            }
        }

        fieldsToHide.forEach((fieldName) => {
            const field = getFieldByName(formData, fieldName);
            if (field) {
                field.__pFieldOptions.hidden = true;
            }
        });
    }

    formData.fields = formData.fields.filter((field) => !field.hidden);
};

const beforeSaveRecipe = async (formData) => {
    const { fields } = getFieldByName(formData, "items");
    const L = await Promise.all(
        fields.map(async (field) => {
            const recipeItem = {};
            recipeItem.__parent = {};
            recipeItem.__pFieldOptions = {};
            recipeItem.__pDefOptions = {};
            const rd = await getForm(getFieldByName(field, "result definition").value);
            const resultDefinitionName = getOriginFieldByName(rd, "name").value;
            recipeItem.__pFieldOptions.name = resultDefinitionName;
            recipeItem.__parent.name = resultDefinitionName;
            const theType = getOriginFieldByName(rd, "type").value;
            recipeItem.__pDefOptions.type = theType;
            recipeItem.__pFieldOptions.multi = getFieldByName(field, "multi").value;
            recipeItem.__pFieldOptions.required = getFieldByName(field, "required").value;
            if (getFieldByName(field, "default value").value?.replace(/\s+/gi, "") != "") {
                recipeItem.__pFieldOptions.defaultValue = getFieldByName(
                    field,
                    "default value"
                ).value;
            }

            if (theType === "drop down") {
                recipeItem.__pFieldOptions.options = getOriginFieldByName(rd, "options").value;
            }
            recipeItem.__pFieldOptions.type = getFieldType(theType);
            const validation = getValidationName(theType);
            if (validation) {
                recipeItem.__pFieldOptions.validation = [validation];
            }

            const newId = await createNewForm(recipeItem);
            return { _dunderSource: newId };
        })
    );
    getFieldByName(formData, "JSON").value = JSON.stringify(L);
};

const beforeSaveUt = async (formData) => {
    formData._sort = formData._sort ?? {};
    if (!formData._sort.index) {
        const { seq } = await getNextSequence(formData.parentId);
        formData._sort.index = seq;
    }

    formData._sort.name = getFieldByName(formData, "Name")?.value ?? "";
    const fileFields = getFieldByName(formData, "File Fields")?.fields ?? [];
    const fileFieldsFormatted = await Promise.all(
        fileFields.map(async (subfields) => {
            const dForm = {
                __parent: {
                    name: getFieldByName(subfields, "File Name").value
                },
                __pFieldOptions: {
                    name: getFieldByName(subfields, "File Name").value,
                    type: "file",
                    multi: false,
                    required: true
                },
                __pDefOptions: {},
                __pExcelOptions: {
                    tabName: getFieldByName(subfields, "Tab Name").value,
                    regExFlag: getFieldByName(subfields, "Regular Expression").value,
                    parseRepeats: getFieldByName(subfields, "Parse Repeats").value
                },
                __pDisplayOptions: {},
                __ftOptions: {}
            };

            const newId = await createNewForm(dForm);
            return { _dunderSource: newId };
        })
    );
    getFieldByName(formData, "FILEJSON").value = JSON.stringify(fileFieldsFormatted);

    const allFields = getFieldByName(formData, "fields")?.fields ?? [];
    const allFieldsFormatted = await Promise.all(
        allFields.map(async (field) => {
            const utField = {
                __parent: {},
                __pFieldOptions: {},
                __pDefOptions: {},
                __pExcelOptions: {},
                __pDisplayOptions: {},
                __ftOptions: {}
            };
            if (getFieldByName(field, "result definition").value) {
                const rd = await getForm(getFieldByName(field, "result definition").value);
                const rdName = getOriginFieldByName(rd, "name").value;
                /**
                 * We set field name to result definition name. It's how it works on stage/model
                 * and we need to provide the same functionality according to backwards compatability.
                 * But it's wrong and should be don this way:
                 * const fieldName = getFieldByName(field, "name").value;
                 * utField.__pFieldOptions.name = fieldName;
                 */
                //
                utField.__pFieldOptions.name = rdName;
                utField.__parent.name = rdName;
                utField.__pFieldOptions.multi = getFieldByName(field, "multi")?.value;

                const theType = getOriginFieldByName(rd, "type").value;
                utField.__pDefOptions.type = theType;
                if (theType === "drop down") {
                    utField.__pFieldOptions.options = getOriginFieldByName(rd, "options").value;
                }
                utField.__pFieldOptions.type = getFieldType(theType);
                const validation = getValidationName(theType);
                if (validation) {
                    utField.__pFieldOptions.validation = [validation];
                }
            } else {
                const fieldName = getFieldByName(field, "name").value;
                utField.__pFieldOptions.name = fieldName;
                utField.__parent.name = fieldName;
                utField.__pFieldOptions.multi =
                    getFieldByName(field, "data height").value > 1 ||
                    getFieldByName(field, "data width").value > 1;
                utField.__pDefOptions.type = "text";
                utField.__pFieldOptions.type = "text";
            }
            utField.__pFieldOptions.required = getFieldByName(field, "required").value;
            if (getFieldByName(field, "Hide in Result")?.value) {
                utField.__parent.hidden = true;
                utField.__pFieldOptions.hidden = true;
            }
            if (getFieldByName(field, "Show in FT")) {
                if (getFieldByName(field, "Show in FT").value) {
                    utField.__ftOptions.mode = getOriginFieldByName(field, "FT Mode").value;
                    utField.__ftOptions.send = true;
                } else {
                    utField.__ftOptions.send = false;
                }
            } else {
                utField.__ftOptions.mode = "default";
                utField.__ftOptions.send = true;
            }
            if (getFieldByName(field, "H Skips")) {
                const hSkipsValue = getFieldByName(field, "H Skips")?.value;
                const hSkipsFiltered = hSkipsValue
                    ? hSkipsValue.filter((skip) => !!skip).map((skip) => parseInt(skip))
                    : [];
                utField.__pExcelOptions.hSkips = JSON.stringify(hSkipsFiltered);
            }
            if (getFieldByName(field, "W Skips")) {
                const wSkipsValue = getFieldByName(field, "W Skips")?.value;
                const wSkipsFiltered = wSkipsValue
                    ? wSkipsValue.filter((skip) => !!skip).map((skip) => parseInt(skip))
                    : [];
                utField.__pExcelOptions.wSkips = JSON.stringify(wSkipsFiltered);
            }
            const fieldType = getFieldByName(field, "Field Type")?.value;
            if (fieldType) {
                utField.__pExcelOptions.uploadTemplateType = fieldType;
                if (fieldType.toLowerCase() === "aggregate") {
                    utField.__pFieldOptions.parseExcel = true;
                    utField.__pFieldOptions.aggregate = true;
                    utField.__pFieldOptions.aggregateFields = getFieldByName(
                        field,
                        "Field Names"
                    ).value;
                    utField.__pFieldOptions.aggregateOperation = getFieldByName(
                        field,
                        "Operation"
                    ).value;
                } else {
                    utField.__pFieldOptions.aggregate = false;
                    utField.__pFieldOptions.parseExcel = fieldType.toLowerCase() === "excel";
                }
            } else {
                utField.__pFieldOptions.parseExcel = true;
            }

            if (getFieldByName(field, "Group Field Names")) {
                utField.__pDefOptions.groupFieldNames = getFieldByName(
                    field,
                    "Group Field Names"
                ).value;
            }

            //Excel
            if (getFieldByName(field, "Start Special")) {
                utField.__pExcelOptions.startSpecial = getFieldByName(field, "Start Special").value;
                utField.__pExcelOptions.startRightStep = getFieldByName(
                    field,
                    "Start Right Step"
                ).value;
                utField.__pExcelOptions.startDownStep = getFieldByName(
                    field,
                    "Start Down Step"
                ).value;
                utField.__pExcelOptions.startTopOffset = getFieldByName(
                    field,
                    "Start Top Offset"
                ).value;
                utField.__pExcelOptions.startLeftOffset = getFieldByName(
                    field,
                    "Start Left Offset"
                ).value;
                utField.__pExcelOptions.startRegex = getFieldByName(field, "Start Regex").value;
            }

            if (getFieldByName(field, "Other File")) {
                utField.__pExcelOptions.otherFile = getFieldByName(field, "Other File").value;
                utField.__pExcelOptions.otherFileName = getFieldByName(
                    field,
                    "Other File Name"
                ).value;
                utField.__pExcelOptions.otherFileTabName = getFieldByName(
                    field,
                    "Other File Tab Name"
                ).value;
            }

            if (getFieldByName(field, "End Special")?.value) {
                utField.__pExcelOptions.endSpecial = getFieldByName(field, "End Special").value;
                utField.__pExcelOptions.endTopOffset = getFieldByName(
                    field,
                    "End Top Offset"
                ).value;
                utField.__pExcelOptions.endLeftOffset = getFieldByName(
                    field,
                    "End Left Offset"
                ).value;
                utField.__pExcelOptions.endRegex = getFieldByName(field, "End Regex").value;
            }

            if (getFieldByName(field, "Reverse")) {
                utField.__pExcelOptions.reverse = getFieldByName(field, "Reverse").value;
            }

            if (getFieldByName(field, "Repeat Fixed")?.value) {
                utField.__pExcelOptions.repeatFixed = getFieldByName(field, "Repeat Fixed").value;
                const repeatFixedFields = getFieldByName(field, "Repeat Fixed Fields").fields.map(
                    (fs) => {
                        const repeatFixed = {
                            number: getFieldByName(fs, "Number").value,
                            topOffset: getFieldByName(fs, "Top Offset").value,
                            leftOffset: getFieldByName(fs, "Left Offset").value,
                            repeatHeight: getFieldByName(fs, "Repeat Height").value,
                            repeatWidth: getFieldByName(fs, "Repeat Width").value
                        };
                        if (getFieldByName(fs, "Update Offset")?.value) {
                            repeatFixed["updateOffsets"] = getFieldByName(
                                fs,
                                "Update Offset Fields"
                            ).fields.map((fs2) => {
                                return {
                                    name: getFieldByName(fs2, "Field Name").value,
                                    type: getFieldByName(fs2, "Type").value,
                                    topOffset: getFieldByName(fs2, "Top Offset").value,
                                    leftOffset: getFieldByName(fs2, "Left Offset").value
                                };
                            });
                        }
                        return repeatFixed;
                    }
                );
                utField.__pExcelOptions.repeatFixedFields = JSON.stringify(repeatFixedFields);
            }
            if (getFieldByName(field, "Allow Other Values")) {
                utField.__pExcelOptions.allowOtherValues = getFieldByName(
                    field,
                    "Allow Other Values"
                ).value;
                utField.__pExcelOptions.otherValuesRegex = getFieldByName(
                    field,
                    "Other Values Regex"
                ).value;
            }
            utField.__pExcelOptions.topOffset = getFieldByName(field, "top offset").value;
            utField.__pExcelOptions.leftOffset = getFieldByName(field, "left offset").value;
            utField.__pExcelOptions.repeat = getFieldByName(field, "repeat").value;
            utField.__pExcelOptions.repeatHeight =
                getFieldByName(field, "repeat height").value || 0;
            utField.__pExcelOptions.repeatWidth = getFieldByName(field, "repeat width").value || 0;
            utField.__pExcelOptions.dataHeight = getFieldByName(field, "data height").value;
            utField.__pExcelOptions.dataWidth = getFieldByName(field, "data width").value;
            utField.__pExcelOptions.hasHeaders = getFieldByName(field, "has headers").value;
            utField.__pDisplayOptions.decimalPlaces = getFieldByName(field, "decimal places").value;
            utField.__pDisplayOptions.scientificNotation = getFieldByName(
                field,
                "scientific notation"
            ).value;

            const newId = await createNewForm(utField);
            return { _dunderSource: newId };
        })
    );
    getFieldByName(formData, "JSON").value = JSON.stringify(allFieldsFormatted);
};

export const beforeSave = async (formData) => {
    if (formData.name === "Recipe") {
        await beforeSaveRecipe(formData);
    } else if (formData.name === "Upload Template") {
        await beforeSaveUt(formData);
    }
};

export const afterSave = async (formData, queryClient) => {
    if (formData.name === "Result Set") {
        await afterSaveResultSet(formData, queryClient);
    }
};

export const uploadTemplateLogic = (fields) => {
    const fieldType = getFieldByName(fields, "Field Type")?.value;
    if (!fieldType) {
        for (let field of fields) {
            if (field.name !== "Field Type") {
                setFieldVisibility(field, false);
            }
        }
    } else {
        for (let field of fields) {
            field.__pFieldOptions.hidden = false;
        }
        setFieldVisibility(getFieldByName(fields, "Name"), true);
    }
    if (["excel", "system", "aggregate"].includes(fieldType?.toLowerCase())) {
        setFieldVisibility(getFieldByName(fields, "Result Definition"), true);
        setFieldVisibility(getFieldByName(fields, "Data Width"), true);
        setFieldVisibility(getFieldByName(fields, "Data Height"), true);

        if (getFieldByName(fields, "Allow Other Values").value) {
            getFieldByName(fields, "Other Values Regex").__pFieldOptions.hidden = false;
        } else {
            setFieldVisibility(getFieldByName(fields, "Other Values Regex"), false);
        }
    } else {
        setFieldVisibility(getFieldByName(fields, "Result Definition"), false);
        setFieldVisibility(getFieldByName(fields, "Data Width"), false);
        setFieldVisibility(getFieldByName(fields, "Data Height"), false);
        setFieldVisibility(getFieldByName(fields, "Allow Other Values"), false);
        setFieldVisibility(getFieldByName(fields, "Other Values Regex"), false);
    }
    //for Excel field type
    if (fieldType?.toString().toLowerCase() === "excel") {
        setFieldVisibility(getFieldByName(fields, "Top Offset"), true);
        setFieldVisibility(getFieldByName(fields, "Left Offset"), true);
        getFieldByName(fields, "Other File").__pFieldOptions.hidden = false;
        getFieldByName(fields, "Reverse").__pFieldOptions.hidden = false;
        getFieldByName(fields, "Repeat").__pFieldOptions.hidden = false;
        getFieldByName(fields, "Repeat Fixed").__pFieldOptions.hidden = false;

        if (getFieldByName(fields, "Other File").value) {
            setFieldVisibility(getFieldByName(fields, "Other File Name"), true);
            setFieldVisibility(getFieldByName(fields, "Other File Tab Name"), true);
        } else {
            setFieldVisibility(getFieldByName(fields, "Other File Name"), false);
            setFieldVisibility(getFieldByName(fields, "Other File Tab Name"), false);
        }

        if (getFieldByName(fields, "Repeat").value) {
            setFieldVisibility(getFieldByName(fields, "Repeat Width"), true);
            setFieldVisibility(getFieldByName(fields, "Repeat Height"), true);
            getFieldByName(fields, "End Special").__pFieldOptions.hidden = false;
            if (getFieldByName(fields, "End Special").value) {
                setFieldVisibility(getFieldByName(fields, "End Top Offset"), true);
                setFieldVisibility(getFieldByName(fields, "End Left Offset"), true);
                getFieldByName(fields, "End Regex").__pFieldOptions.hidden = false;
            } else {
                setFieldVisibility(getFieldByName(fields, "End Top Offset"), false);
                setFieldVisibility(getFieldByName(fields, "End Left Offset"), false);
                setFieldVisibility(getFieldByName(fields, "End Regex"), false);
            }

            hideRepeatFixedFields(fields);
        } else {
            hideRepeatFields(fields);
        }

        if (getFieldByName(fields, "Repeat Fixed").value) {
            showRepeatFixedFields(fields);
        } else {
            hideRepeatFixedFields(fields);
        }

        getFieldByName(fields, "H Skips").__pFieldOptions.hidden = false;
        getFieldByName(fields, "W Skips").__pFieldOptions.hidden = false;

        getFieldByName(fields, "Start Special").__pFieldOptions.hidden = false;
        if (getFieldByName(fields, "Start Special").value) {
            setFieldVisibility(getFieldByName(fields, "Start Right Step"), true);
            setFieldVisibility(getFieldByName(fields, "Start Down Step"), true);
            setFieldVisibility(getFieldByName(fields, "Start Top Offset"), true);
            setFieldVisibility(getFieldByName(fields, "Start Left Offset"), true);
            getFieldByName(fields, "Start Regex").__pFieldOptions.hidden = false;
        } else {
            setFieldVisibility(getFieldByName(fields, "Start Right Step"), false);
            setFieldVisibility(getFieldByName(fields, "Start Down Step"), false);
            setFieldVisibility(getFieldByName(fields, "Start Top Offset"), false);
            setFieldVisibility(getFieldByName(fields, "Start Left Offset"), false);
            setFieldVisibility(getFieldByName(fields, "Start Regex"), false);
        }
        getFieldByName(fields, "Has Headers").__pFieldOptions.hidden = false;
    } else {
        setFieldVisibility(getFieldByName(fields, "Top Offset"), false);
        setFieldVisibility(getFieldByName(fields, "Left Offset"), false);
        setFieldVisibility(getFieldByName(fields, "Repeat"), false);
        setFieldVisibility(getFieldByName(fields, "Repeat Fixed"), false);
        setFieldVisibility(getFieldByName(fields, "Has Headers"), false);
        setFieldVisibility(getFieldByName(fields, "Repeat Width"), false);
        setFieldVisibility(getFieldByName(fields, "Repeat Height"), false);
        setFieldVisibility(getFieldByName(fields, "H Skips"), false);
        setFieldVisibility(getFieldByName(fields, "W Skips"), false);
        setFieldVisibility(getFieldByName(fields, "Start Special"), false);
        setFieldVisibility(getFieldByName(fields, "Start Right Step"), false);
        setFieldVisibility(getFieldByName(fields, "Start Down Step"), false);
        setFieldVisibility(getFieldByName(fields, "Start Top Offset"), false);
        setFieldVisibility(getFieldByName(fields, "Start Left Offset"), false);
        setFieldVisibility(getFieldByName(fields, "Start Regex"), false);
        setFieldVisibility(getFieldByName(fields, "End Special"), false);
        setFieldVisibility(getFieldByName(fields, "End Top Offset"), false);
        setFieldVisibility(getFieldByName(fields, "End Left Offset"), false);
        setFieldVisibility(getFieldByName(fields, "End Regex"), false);
        setFieldVisibility(getFieldByName(fields, "Other File"), false);
        setFieldVisibility(getFieldByName(fields, "Other File Name"), false);
        setFieldVisibility(getFieldByName(fields, "Other File Tab Name"), false);
        setFieldVisibility(getFieldByName(fields, "Reverse"), false);
        hideRepeatFixedFields(fields);
    }

    //for Aggregate field type
    if (fieldType?.toString().toLowerCase() === "aggregate") {
        setFieldVisibility(getFieldByName(fields, "Field Names"), true);
        setFieldVisibility(getFieldByName(fields, "Operation"), true);

        setFieldVisibility(getFieldByName(fields, "Data Width"), false);
        setFieldVisibility(getFieldByName(fields, "Data Height"), false);
        setFieldVisibility(getFieldByName(fields, "Decimal Places"), false);
        setFieldVisibility(getFieldByName(fields, "Scientific Notation"), false);
        setFieldVisibility(getFieldByName(fields, "Required"), false);
    } else {
        setFieldVisibility(getFieldByName(fields, "Field Names"), false);
        setFieldVisibility(getFieldByName(fields, "Operation"), false);
    }

    //for Display field type
    if (fieldType?.toString().toLowerCase() === "display") {
        setFieldVisibility(getFieldByName(fields, "Group Field Names"), true);
    } else {
        setFieldVisibility(getFieldByName(fields, "Group Field Names"), false);
    }

    if (getFieldByName(fields, "Show in FT").value) {
        setFieldVisibility(getFieldByName(fields, "FT Mode"), true);
    } else {
        setFieldVisibility(getFieldByName(fields, "FT Mode"), false);
    }
};

const setFieldVisibility = (field, isVisibleAndRequired) => {
    if (field) {
        field.__pFieldOptions.required = isVisibleAndRequired;
        field.__pFieldOptions.hidden = !isVisibleAndRequired;
    }
};

const hideRepeatFixedFields = (fields) => {
    //used for upload template logic
    const repeatFields = getFieldByName(fields, "Repeat Fixed Fields")?.fields;
    if (!repeatFields?.length) {
        return;
    }
    for (let field of repeatFields) {
        setFieldVisibility(getFieldByName(field, "Number"), false);
        setFieldVisibility(getFieldByName(field, "Top Offset"), false);
        setFieldVisibility(getFieldByName(field, "Left Offset"), false);
        setFieldVisibility(getFieldByName(field, "Repeat Height"), false);
        setFieldVisibility(getFieldByName(field, "Repeat Width"), false);

        if (getFieldByName(field, "Update Offset")) {
            const offsetFields = getFieldByName(fields, "Repeat Fixed Fields")?.fields || [];
            for (let field of offsetFields) {
                setFieldVisibility(getFieldByName(field, "Field Name"), false);
                setFieldVisibility(getFieldByName(field, "Type"), false);
                setFieldVisibility(getFieldByName(field, "Top Offset"), false);
                setFieldVisibility(getFieldByName(field, "Left Offset"), false);
            }
            setFieldVisibility(getFieldByName(field, "Update Offset Fields"), false);
        }
    }
    setFieldVisibility(getFieldByName(fields, "Repeat Fixed Fields"), false);
};

const hideRepeatFields = (fields) => {
    //used for upload template logic
    setFieldVisibility(getFieldByName(fields, "Repeat Width"), false);
    setFieldVisibility(getFieldByName(fields, "Repeat Height"), false);

    try {
        getFieldByName(fields, "End Special").value = false;
    } catch (err) {}

    setFieldVisibility(getFieldByName(fields, "End Special"), false);
    setFieldVisibility(getFieldByName(fields, "End Top Offset"), false);
    setFieldVisibility(getFieldByName(fields, "End Left Offset"), false);
    setFieldVisibility(getFieldByName(fields, "End Regex"), false);
};

const showRepeatFixedFields = (fields) => {
    //used for upload template logic
    const repeatFields = getFieldByName(fields, "Repeat Fixed Fields")?.fields;
    if (!repeatFields?.length) {
        return;
    }
    for (let field of repeatFields) {
        setFieldVisibility(getFieldByName(field, "Number"), true);
        setFieldVisibility(getFieldByName(field, "Top Offset"), true);
        setFieldVisibility(getFieldByName(field, "Left Offset"), true);
        setFieldVisibility(getFieldByName(field, "Repeat Height"), true);
        setFieldVisibility(getFieldByName(field, "Repeat Width"), true);

        if (getFieldByName(field, "Update Offset")) {
            const show = !!getFieldByName(field, "Update Offset").value;
            const offsetFields = getFieldByName(fields, "Update Offset Fields")?.fields || [];
            for (let field of offsetFields) {
                setFieldVisibility(getFieldByName(field, "Field Name"), show);
                setFieldVisibility(getFieldByName(field, "Type"), show);
                setFieldVisibility(getFieldByName(field, "Top Offset"), show);
                setFieldVisibility(getFieldByName(field, "Left Offset"), show);
            }
            if (show) {
                getFieldByName(field, "Update Offset Fields").__pFieldOptions.hidden = false;
            } else {
                setFieldVisibility(getFieldByName(field, "Update Offset Fields"), false);
            }
        }
    }
    getFieldByName(fields, "Repeat Fixed Fields").__pFieldOptions.hidden = false;
};

const uncheckCheckboxField = (field) => {
    if (field) {
        field.value = false;
        if (field.theO) {
            field.theO.prop("checked", false);
        }
    }
};

const fieldNamesWithUTLogic = [
    "Field Type",
    "Other File",
    "Start Special",
    "Repeat",
    "Repeat Fixed",
    "Update Offset",
    "Show in FT",
    "Allow Other Values",
    "End Special"
];

export const uploadTemplateLogicInteractive = (changedField) => {
    if (
        changedField.parentField &&
        Array.isArray(changedField.parentField) &&
        fieldNamesWithUTLogic.includes(changedField.__pFieldOptions.name)
    ) {
        const parentField =
            changedField.__pFieldOptions.name === "Update Offset"
                ? changedField.parentField.parentField.parentField
                : changedField.parentField;

        const repeatName = "Repeat";
        const repeatFixedName = "Repeat Fixed";

        const name = changedField.__pFieldOptions?.name;

        if (name === repeatName) {
            const repeatFixedField = getFieldByName(parentField, repeatFixedName);
            uncheckCheckboxField(repeatFixedField);
        } else if (name === repeatFixedName) {
            const repeatField = getFieldByName(parentField, repeatName);
            uncheckCheckboxField(repeatField);
        }

        uploadTemplateLogic(parentField);
    }
};
