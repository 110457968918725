import React, { useEffect, useMemo, useState } from "react";
import { debounce, TextField, TextFieldProps } from "@material-ui/core";

export const DebouncedTextField = ({
    onChangeValue,
    ...rest
}: Omit<TextFieldProps, "defaultValue, onChange"> & {
    onChangeValue: (value: string) => void;
}): React.ReactElement => {
    const value = rest.value as string;
    const [localValue, setLocalValue] = useState<string>(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const debouncedOnChange = useMemo(() => debounce(onChangeValue, 500), [onChangeValue]);

    return (
        <TextField
            {...rest}
            value={localValue}
            onChange={(event) => {
                setLocalValue(event.target.value);
                debouncedOnChange(event.target.value);
            }}
        />
    );
};
