import { useState, useEffect } from "react";

type Position = {
    clientX: number;
    clientY: number;
};

export const useMousePosition = (): Position => {
    const [position, setPosition] = useState({
        clientX: 0,
        clientY: 0
    });

    const updatePosition = (event: MouseEvent) => {
        const { clientX, clientY } = event;

        setPosition({
            clientX,
            clientY
        });
    };

    useEffect(() => {
        window.addEventListener("mousemove", updatePosition, false);
        window.addEventListener("mouseenter", updatePosition, false);

        return () => {
            window.removeEventListener("mousemove", updatePosition);
            window.removeEventListener("mouseenter", updatePosition);
        };
    }, []); // eslint-disable-line

    return position;
};
