import React, { useState } from "react";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { makeStyles, OutlinedIconButton, theme } from "@assay/ui-kit";
import { Accordion, AccordionSummary, Button, Divider, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { v4 as uuidv4 } from "uuid";
import { NOT_EXIST_COORDINATE } from "../values";
import { AddParameterButton } from "./add-parameter-button";
import { getTranslation } from "@assay/shared";

const buttonIconSize = 30;

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(1)
        }
    },
    summaryName: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "290px"
    },
    titleRow: {
        "display": "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        },
        "alignItems": "center",
        "minHeight": theme.spacing(4.5)
    },
    deleteButtonWrapper: {
        position: "absolute",
        right: theme.spacing(1),
        top: `calc(50% - ${buttonIconSize / 2}px)`
    },
    confirmButtonWrapper: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: theme.spacing(3)
    },
    text: {
        paddingBottom: theme.spacing(1)
    }
}));

const selectState = (state: UploadTemplateStore) => ({
    headers: state.tempHeaders,
    hasFields: state.fields.length > 0,
    editButtonEnable: state.fields.length > 0 && state.tempHeaders.length === 0,
    sheets: state.sheets,
    setTempHeaders: state.actions.setTempHeaders,
    handleConfirmHeaders: state.actions.handleConfirmHeaders,
    handleEditHeaders: state.actions.handleEditHeaders
});

export const HeadersEditor = (): React.ReactElement => {
    const classes = useStyles();
    const {
        headers,
        setTempHeaders,
        handleConfirmHeaders,
        editButtonEnable,
        hasFields,
        handleEditHeaders,
        sheets
    } = useShallowUploadTemplateStore(selectState);
    const [currentHeaderIndex, setCurrentHeaderIndex] = useState(Number);

    const deleteHeader = () => {
        const newHeaders = [...headers];
        newHeaders.splice(currentHeaderIndex, 1);
        setTempHeaders(newHeaders);
    };

    const addHeader = () => {
        const [sheet] = sheets;
        const newHeaders = [...headers];
        newHeaders.push({
            id: uuidv4(),
            name: "",
            sheet: sheet,
            column: NOT_EXIST_COORDINATE,
            row: NOT_EXIST_COORDINATE
        });
        setTempHeaders(newHeaders);
    };

    return (
        <>
            <div className={classes.root}>
                <div className={classes.titleRow}>
                    <Typography variant={"subtitle2"}>Headers</Typography>
                    {editButtonEnable && (
                        <Button color={"primary"} onClick={handleEditHeaders}>
                            Edit
                        </Button>
                    )}
                </div>
                {!hasFields && headers.length === 0 && (
                    <Typography variant={"body2"} className={classes.text}>
                        {getTranslation("choose-or-drag-cell-in-the-table")}
                    </Typography>
                )}

                {headers.map((header, headerIndex) => (
                    <Accordion key={header.id} expanded={false} variant={"outlined"}>
                        <AccordionSummary>
                            {header.name ? (
                                <Typography className={classes.summaryName}>
                                    {header.name}
                                </Typography>
                            ) : (
                                <Typography color="textSecondary">Parameter name</Typography>
                            )}
                            <div className={classes.deleteButtonWrapper}>
                                <OutlinedIconButton
                                    size={buttonIconSize}
                                    type="secondary"
                                    onClick={() => {
                                        setCurrentHeaderIndex(headerIndex);
                                        deleteHeader();
                                    }}
                                    iconColor={theme.palette.warning.main}
                                    startIcon={<ClearIcon />}
                                />
                            </div>
                        </AccordionSummary>
                    </Accordion>
                ))}
                {headers.length > 0 && (
                    <div className={classes.confirmButtonWrapper}>
                        <Button variant="outlined" color="secondary" onClick={handleConfirmHeaders}>
                            {getTranslation("confirm-all-and-continue")}
                        </Button>
                    </div>
                )}
                {!hasFields && (
                    <>
                        <Divider />
                        <AddParameterButton onClick={addHeader} />
                    </>
                )}
            </div>
        </>
    );
};
