import PropTypes from "prop-types";
import { makeStyles } from "@assay/ui-kit";

const useStyles = makeStyles({
    textWrapper: {
        paddingRight: "8px"
    },
    text: {
        whiteSpace: "pre-wrap",
        lineBreak: "anywhere"
    }
});

const Textarea = ({ value, mainOptions, onChange }) => {
    const classes = useStyles();
    if (mainOptions.viewState === "view") {
        return (
            <div className={classes.textWrapper}>
                <span className={classes.text}>{value}</span>
            </div>
        );
    }
    return <textarea value={value} onChange={(e) => onChange(e.target.value)} />;
};

Textarea.propTypes = {
    value: PropTypes.string,
    mainOptions: PropTypes.object
};

export { Textarea };
