import { Overrides } from "@material-ui/core/styles/overrides";
import { grey } from "../colors";

export const MuiAccordion: Overrides["MuiAccordion"] = {
    root: {
        boxShadow: "none",
        border: `1px solid ${grey[500]}`
    },
    rounded: {
        "borderRadius": 0,
        "borderTopLeftRadius": 0,
        "borderBottomLeftRadius": 0,
        "borderBottomRightRadius": 0,
        "borderTopRightRadius": 0,
        "&:last-child": {
            borderRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0
        },
        "&:first-child": {
            borderRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0
        }
    }
};
