import { AssayProps, Locales } from "./types";

export type ServerConfig = {
    [key: string]: unknown;
    resultTypeId: number;
    resultSetTypeId: number;
};

type AppConfig = AssayProps & ServerConfig;

const appConfig: AppConfig = {
    assayConfigHeader: {
        permissions: {
            canAdd: false,
            canEdit: false,
            canDelete: false
        },
        userName: "",
        userId: 0,
        whichClient: "",
        usersICanSee: "",
        isSupport: false,
        isDev: true
    },
    mainAppPath: "http://52.188.57.124:8892/arxlab",
    hasFTLiteAssay: true,
    proxyUrl: "",
    useProxy: false,
    apiUrl: "",
    uploadTemplatesApiUrl: "",
    dotnetApiUrl: "",
    connectionId: "",
    authTokenProvider: () => "",
    resultTypeId: 2113,
    resultSetTypeId: 1927,
    currentLanguage: "",
    defaultLangCode: "",
    useLongDate: false
};

export const saveObjectToAppConfig = (object: AssayProps | ServerConfig): void => {
    Object.keys(object).forEach((key) => {
        appConfig[key] = object[key];
    });

    if (!(Object.values(Locales) as Array<string>).includes(appConfig.defaultLangCode)) {
        appConfig.defaultLangCode = Locales.EnUs;
    }
};

export const getAppConfig = (): AppConfig => ({ ...appConfig });
