import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import { SignIn, SignOut, useAuthContext } from "./auth";
import { useTabs } from "./hooks";
import { AppContent } from "./app-content";
import { config } from "./config";
import { testId } from "@assay/shared";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        minHeight: "100vh"
    },
    appBar: {
        top: 0,
        position: "sticky",
        display: "flex",
        flexDirection: "row",
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
    },
    assayWrapper: {
        position: "relative",
        height: "calc(100vh - 48px)" // 100vh - header height
    }
}));

export const AppRaw: React.FC = () => {
    const classes = useStyles();
    const { accessToken } = useAuthContext();
    const { tabIndex, changeTabIndex } = useTabs();

    const isSignedIn = accessToken || !config.IS_AUTH_ENABLED;

    return (
        <Box className={classes.root}>
            <AppBar className={classes.appBar}>
                <Tabs value={tabIndex} onChange={changeTabIndex} aria-labelledby="app-bar-tabs">
                    <Tab label="Assay" {...testId("assays-tab-header")} />
                    <Tab label="Upload templates" {...testId("upload-templates-tab-header")} />
                    <Tab label="Heatmap" {...testId("heatmap-tab-header")} />
                    <Tab label="Curve" {...testId("curves-tab-header")} />
                </Tabs>

                <SignOut />
            </AppBar>

            {isSignedIn ? <AppContent selectedTabIndex={tabIndex} /> : <SignIn />}
        </Box>
    );
};
