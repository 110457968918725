import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const DeleteIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="m17.21411,7.92863l-1.0667,-1.07429l-4.26665,4.25905l-4.25143,-4.25905l-1.0819,1.07429l4.26666,4.25905l-4.26666,4.25906l1.0819,1.0743l4.25143,-4.25908l4.26665,4.25908l1.0667,-1.0743l-4.25145,-4.25906l4.25145,-4.25905z"
                fill={props.color}
            />
        </SvgIcon>
    );
};
