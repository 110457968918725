import { makeStyles } from "@material-ui/core";
import { sheetSizes } from "./values";
import { ExtendedTheme } from "@assay/ui-kit";

export const useTableCommonStyles = makeStyles<ExtendedTheme, { width: string; height: string }>(
    (theme) => ({
        tableRoot: ({ width, height }) => ({
            background: "#fff",
            height: height,
            width: width,
            overflow: "auto"
        }),
        tableRelativeWrapper: {
            position: "relative"
        },
        stickyRowWrapper: {
            position: "sticky",
            zIndex: 1,
            left: 0,
            width: "max-content"
        },
        stickyColumnWrapper: {
            position: "sticky",
            zIndex: 2,
            top: 0
        },
        crossItemsWrapper: {
            position: "sticky",
            width: 0,
            height: 0,
            left: 0,
            top: 0,
            zIndex: 4,
            overflow: "visible"
        },
        crossItemsPlaceholder: {
            position: "absolute",
            boxSizing: "border-box",
            width: sheetSizes.width.rowTitle,
            height: sheetSizes.height.base,
            left: 0,
            top: 0,
            background: theme.palette.grey[200]
        }
    })
);

//TODO: reorganize theme colors so change colors this to themes colors
const useCellsStyles = makeStyles((theme) => {
    return {
        cell: {
            "user-select": "none",
            "position": "absolute",
            "top": 0,
            "left": 0,
            "overflow": "hidden",
            "boxSizing": "border-box",
            "padding": theme.spacing(1),
            "height": sheetSizes.height.base
        },
        title: {
            border: `1px solid ${theme.palette.grey[200]}`,
            background: theme.palette.grey[200],
            textAlign: "center",
            verticalAlign: "middle"
        },
        content: {
            color: "#000000",
            border: `1px solid ${theme.palette.grey[400]}`,
            overflow: "hidden",
            width: sheetSizes.width.base
        },
        contentEditMode: {
            cursor: "pointer"
        },
        activeSelected: {
            border: `1px solid #3AC1EB`,
            background: "rgba(170, 225, 242, 0.3)"
        },
        disabledPrimarySelected: {
            border: `1px solid ${theme.palette.grey[400]}`,
            background: "rgba(195, 208, 217, 0.4)"
        },
        disabledSecondarySelected: {
            border: `1px solid ${theme.palette.grey[400]}`,
            background: "rgba(195, 208, 217, 0.8)",
            color: "#808080"
        },
        skippedSelected: {
            border: `1px solid ${theme.palette.grey[400]}`,
            background: "#F7F7F7",
            color: "#B0B0B0"
        },
        regExp: {
            border: `1px solid ${theme.palette.grey[400]}`,
            background: "rgba(77, 109, 243, 0.3)",
            color: theme.palette.common.black
        },
        disabled: {
            opacity: 0.6
        },
        sheetSelect: {
            width: 300
        }
    };
});

export const useTableContentStyles = (editMode: boolean) => {
    const cellsStyles = useCellsStyles();
    const titleCellStyle = [cellsStyles.cell, cellsStyles.title].join(" ");
    const contentCellStyle = [
        cellsStyles.cell,
        cellsStyles.content,
        editMode ? cellsStyles.contentEditMode : ""
    ].join(" ");

    const contentCellActiveSelectedStyle = [contentCellStyle, cellsStyles.activeSelected].join(" ");

    const contentCellDisabledPrimarySelectedStyle = [
        contentCellStyle,
        cellsStyles.disabledPrimarySelected
    ].join(" ");

    const contentCellDisabledSecondarySelectedStyle = [
        contentCellStyle,
        cellsStyles.disabledSecondarySelected
    ].join(" ");

    const contentCellSkippedSelectedStyle = [contentCellStyle, cellsStyles.skippedSelected].join(
        " "
    );

    const contentCellDisabledStyle = [contentCellStyle, cellsStyles.disabled].join(" ");

    const contentCellRegexpStyle = [contentCellStyle, cellsStyles.regExp].join(" ");

    return {
        ...cellsStyles,
        titleCellStyle,
        contentCellStyle,
        contentCellActiveSelectedStyle,
        contentCellDisabledPrimarySelectedStyle,
        contentCellDisabledSecondarySelectedStyle,
        contentCellSkippedSelectedStyle,
        contentCellDisabledStyle,
        contentCellRegexpStyle
    };
};
