import React from "react";

type Props = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    isInteractive: boolean;
};

const boolToStr = (value?: boolean) => (value ? "true" : "false");

export const TableInput: React.FC<Props> = ({ isInteractive, ...rest }) => {
    if (isInteractive) {
        return <input {...rest} />;
    }
    return <p>{rest.type === "checkbox" ? boolToStr(rest.checked) : rest.value}</p>;
};