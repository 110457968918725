import { getTranslation } from "@assay/shared";

export function boolToYesNoString(value?: boolean): string {
    return value ? getTranslation("yes") : getTranslation("no");
}

export const downloadFileByLink = (name: string, url: string): void => {
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.click();
};

export const normalizeStringToNumber = (value: string): number | undefined => {
    if (value === "" || isNaN(Number(value))) {
        return undefined;
    }
    return +value;
};
