import React from "react";

import { makeStyles } from "../theme";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
    spinnerPlaceholder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    }
});

export const SpinnerPlaceholder: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.spinnerPlaceholder}>
            <CircularProgress />
        </div>
    );
};
