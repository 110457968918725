import { ensureArray } from ".";
import { TreeNode } from "../types";

const sortingFn = (itemA: TreeNode, itemB: TreeNode): 1 | -1 => {
    const titleA = itemA.title.trim().toLowerCase();
    const titleB = itemB.title.trim().toLowerCase();
    return titleA > titleB ? 1 : -1;
};

export const sortTreeItems = (items: TreeNode[] = []): TreeNode[] => {
    const arr = ensureArray(items);
    arr.sort(sortingFn);

    const folders = arr.filter((item) => item.icon === "folder.gif");
    const permittedFolders = arr.filter((item) => item.icon === "lock.gif");
    const rest = arr.filter((item) => item.icon !== "folder.gif" && item.icon !== "lock.gif");

    return [...folders, ...permittedFolders, ...rest];
};
