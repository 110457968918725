import { Fragment } from "react";
import { TreeView } from "../../tree-view";
import { ContextMenu } from "../context-menu";
import { rootTreeQuery } from "../../../api-v2";
import { useContextMenu } from "./use-context-menu";
import { useTreeView } from "../../../store";
import { useInitTreeState } from "../../../hooks";

export const TreeViewContainer = () => {
    const { expandedItems, selectedItem, onItemClick } = useTreeView();
    const { menuOptions, menuCoords, onContextMenu, closeContextMenu } = useContextMenu();

    useInitTreeState();

    return (
        <Fragment>
            <TreeView
                expandedItems={expandedItems}
                selectedItem={selectedItem}
                onItemClick={(_, item) => onItemClick(item)}
                onContextMenu={onContextMenu}
                query={rootTreeQuery}
            />

            <ContextMenu coords={menuCoords} menuOptions={menuOptions} onClose={closeContextMenu} />
        </Fragment>
    );
};
