import React from "react";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { AlertMessage } from "../components/alert-message";

const selectState = ({ errorMessage, succeedMessage }: UploadTemplateStore) => ({
    isOpen: !!(errorMessage || succeedMessage),
    errorMessage,
    succeedMessage
});

export const RequestsResultAlert = () => {
    const { isOpen, errorMessage, succeedMessage } = useShallowUploadTemplateStore(selectState);

    if (!isOpen) return null;

    return (
        <AlertMessage
            severity={errorMessage ? "error" : "success"}
            message={errorMessage ?? succeedMessage}
        />
    );
};
