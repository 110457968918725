import React from "react";
import { StyledDialog } from "./styled-dialog";
import { makeStyles } from "../../theme";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles((theme) => ({
    root: {
        "minWidth": "200px",
        "display": "flex",
        "flexDirection": "column",
        "alignContent": "center",
        "alignItems": "center",
        "justifyContent": "center",
        "marginTop": theme.spacing(9),
        "marginBottom": theme.spacing(12),
        "marginRight": theme.spacing(13),
        "marginLeft": theme.spacing(13),
        "& > svg": {
            marginBottom: theme.spacing(1)
        }
    }
}));

type Props = {
    message: string;
    open: boolean;
    onClose: () => void;
};

export const ErrorDialog: React.FC<Props> = ({ message, open, onClose }) => {
    const classes = useStyles();
    return (
        <StyledDialog
            isOpen={open}
            onClose={onClose}
            content={
                <div className={classes.root}>
                    <ErrorOutlineIcon color={"error"} fontSize={"large"} />
                    {message}
                </div>
            }
        />
    );
};
