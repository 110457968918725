import { alternativeShadow, ExtendedTheme, green, makeStyles } from "@assay/ui-kit";

import { Alert, Color } from "@material-ui/lab";
import React from "react";

type StylesProps = {
    error: boolean;
};

const useStyles = makeStyles<ExtendedTheme, StylesProps>((theme) => ({
    alert: ({ error }) => ({
        "position": "absolute",
        "top": theme.spacing(1),
        "width": "500px",
        "left": "calc(50% - 250px)",
        "zIndex": 2000,
        "background": theme.palette.common.white,
        "boxShadow": alternativeShadow,
        "color": theme.palette.common.black,
        "& svg": {
            color: error ? theme.palette.error.main : green
        },
        "& button": {
            color: theme.palette.primary.main
        }
    })
}));

type Props = {
    action?: React.ReactNode;
    message: string | null;
    severity: Color;
};

export const AlertMessage: React.FC<Props> = ({ message, action, severity }) => {
    const classes = useStyles({ error: severity === "error" });

    return (
        <Alert severity={severity} action={action} className={classes.alert}>
            {message ?? ""}
        </Alert>
    );
};
