import React, { useState } from "react";
import { Box, Typography, Button, Dialog } from "@material-ui/core";
import { makeStyles } from "@assay/ui-kit";
import { SignInForm } from "./sign-in-form";

const useStyles = makeStyles((theme) => ({
    box: {
        position: "relative",
        flexGrow: 1
    },
    text: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    button: {
        marginLeft: "auto",
        fontSize: "inherit",
        verticalAlign: "baseline",
        textTransform: "none",
        color: theme.palette.primary.main
    }
}));

export const SignIn: React.FC = () => {
    const classes = useStyles();
    const [isFormOpen, setIsFormOpen] = useState(false);

    return (
        <Box className={classes.box}>
            <Typography variant="h6" color="inherit" className={classes.text}>
                Please,
                <Button
                    variant="text"
                    disableRipple
                    className={classes.button}
                    onClick={() => setIsFormOpen(true)}
                >
                    Sign in
                </Button>
            </Typography>

            <Dialog open={isFormOpen} onClose={() => setIsFormOpen(false)} maxWidth="sm" fullWidth>
                <SignInForm onSuccess={() => setIsFormOpen(false)} />
            </Dialog>
        </Box>
    );
};
