import { useQuery } from "react-query";
import { Box, CircularProgress, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { GroupItem } from "./group-item";
import { getUserGroups } from "../../../api-v2";

const useStyles = makeStyles({
    wrapper: {
        height: "220px",
        width: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "space-around",
        overflow: "auto"
    },

    list: {
        width: "100%"
    },

    spinner: {
        marginTop: "80px"
    }
});

export const GroupsList = ({ selected, onGroupChange, onUserChange }) => {
    const classes = useStyles();

    const { data: groups, isLoading } = useQuery("userGroups", getUserGroups);

    return (
        <Box className={classes.wrapper}>
            {isLoading ? (
                <CircularProgress className={classes.spinner} />
            ) : (
                <List className={classes.list}>
                    {groups.map((group) => (
                        <GroupItem
                            key={group.id}
                            group={group}
                            selected={selected}
                            onGroupChange={onGroupChange}
                            onUserChange={onUserChange}
                        />
                    ))}
                </List>
            )}
        </Box>
    );
};
