export const getRangeNumbers = (start: number, end: number, step = 1): number[] => {
    const result = [];
    for (let i = start; i <= end; i += step) {
        result.push(i);
    }
    return result;
};

export const round = (value: number, step = 1): number => {
    const inverseStep = 1.0 / step;
    return Math.round(value * inverseStep) / inverseStep;
};
