export const assayConfigHeader = {
    permissions: { canAdd: true, canEdit: true, canDelete: true },
    userName: "Arxspan Support",
    userId: 1227,
    whichClient: "ASSAY_TEST",
    usersICanSee:
        "1226,1227,1229,1230,1233,1313,1316,1317,1341,1343,1351,1364,1365,1366,1367,1372,1393,1413,1422,1451",
    isSupport: true,
    isDev: true
};

export const connectionId = "122755rld0sva768mbn4";
