import {
    createTheme,
    darken,
    duration,
    alpha,
    makeStyles as makeStylesMui,
    ThemeProvider,
    useTheme as useThemeMui
} from "@material-ui/core/styles";
import { ClassNameMap, Styles, WithStylesOptions } from "@material-ui/core/styles/withStyles";

import {
    blueGray,
    cloningHighlightColors,
    disabledButtonColor,
    insertionFragmentsColor,
    mapColors,
    palette,
    searchResultColors
} from "./colors";
import { config } from "./config";
import { overrides } from "./overrides";
import { alternativeElevatedShadow, alternativeShadow } from "./shadows";
import { Spacing } from "./spacing";
import { monospaceFontFamily, quicksandFontFamily, typography } from "./typography";

const extensions = {
    monospaceFontFamily,
    quicksandFontFamily,
    blueGray,
    cloningHighlightColors,
    insertionFragmentsColor,
    searchResultColors,
    borderRadius: config.borderRadius,
    alternativeElevatedShadow,
    alternativeShadow,
    spacing: Spacing,
    disabledButtonColor,
    mapColors
};

export const theme = createTheme(
    { palette, typography, overrides, zIndex: { tooltip: 1150 } },
    { extensions }
);

const fontUrls = [
    "https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap",
    "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap",
    "https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&display=swap"
];

export const appendFontsToDOM = () => {
    fontUrls.forEach((fontUrl: string) => {
        const link = document.createElement("link");

        link.setAttribute("rel", "stylesheet");
        link.setAttribute("type", "text/css");
        link.setAttribute("crossorigin", "anonymous");
        link.setAttribute("href", fontUrl);

        document.head.append(link);
    });
};

type Theme = typeof theme;
type Extensions = typeof extensions;
export type ExtendedTheme = Theme & { extensions: Extensions };

export { ThemeProvider, alpha, darken, duration };

export const useTheme = useThemeMui as <T = ExtendedTheme>() => T;

export const makeStyles = <
    Theme extends ExtendedTheme,
    Props extends Record<string, unknown>,
    ClassKey extends string = string
>(
    styles: Styles<Theme, Props, ClassKey>,
    options?: Omit<WithStylesOptions<Theme>, "withTheme">
) => makeStylesMui<Theme, Props>(styles, options) as (props?: Props) => ClassNameMap<ClassKey>;
