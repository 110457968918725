import { Overrides } from "@material-ui/core/styles/overrides";

export const MuiMenu: Overrides["MuiMenu"] = {
    list: {
        borderRadius: 8
    },
    paper: {
        borderRadius: 8
    }
};
