import React from "react";
import { makeStyles, StyledDialog } from "@assay/ui-kit";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { Button, Typography } from "@material-ui/core";

const useStyles = makeStyles({
    dialogRoot: {
        minWidth: "350px"
    }
});

const selectState = ({ fileApiErrors, actions, sendingDataInProcess }: UploadTemplateStore) => ({
    loading: sendingDataInProcess,
    errors: fileApiErrors,
    isErrorExist: !!fileApiErrors && fileApiErrors.length > 0,
    retry: actions.retrySaveFiles,
    retryLater: actions.deleteFilesErrors
});

export const FilesRetryDialog: React.FC = () => {
    const classes = useStyles();

    const { retry, retryLater, isErrorExist, errors, loading } =
        useShallowUploadTemplateStore(selectState);

    return (
        <StyledDialog
            title={"Some files are failed to process"}
            isOpen={isErrorExist}
            onClose={retryLater}
            content={
                <div className={classes.dialogRoot}>
                    {errors?.map((item, index) => (
                        <Typography variant="subtitle2" key={index}>
                            {`${item.fileName}, action: ${item.action}`}
                        </Typography>
                    ))}
                </div>
            }
            actions={
                <>
                    <Button color="secondary" variant="outlined" onClick={retry} disabled={loading}>
                        {loading ? "Sending..." : "Try again"}
                    </Button>
                    <Button color="primary" onClick={retryLater} disabled={loading}>
                        Close (try later)
                    </Button>
                </>
            }
        />
    );
};
