import { ExternalCurveParameters, round, SelectedCellsRow } from "@assay/shared";
import { AnyObj, FormData } from "../types";
import { getFieldByName, getFieldsByType } from "../form/form-utils";
import produce from "immer";
import { StoreCurveParameters } from "../store";
import { isNumeric } from "./common";

enum parameters {
    MinAsymptote = "minAsymptote",
    HillSlope = "hillSlope",
    InflectionPoint = "inflectionPoint",
    MaxAsymptote = "maxAsymptote"
}

type FitOption = {
    name:
        | parameters.MinAsymptote
        | parameters.HillSlope
        | parameters.InflectionPoint
        | parameters.MaxAsymptote;
    fixed?: "true" | "false";
    maxVal?: number;
    minVal?: number;
};

type CurveRow = {
    fixed: boolean | undefined;
    initialValue: number | undefined;
    minValue: number | undefined;
    maxValue: number | undefined;
};

type CurveTable = {
    [parameters.MinAsymptote]: CurveRow;
    [parameters.HillSlope]: CurveRow;
    [parameters.InflectionPoint]: CurveRow;
    [parameters.MaxAsymptote]: CurveRow;
};

type FitType = {
    parameterDisplayConfig: AnyObj;
    curveParameterNames: parameters[];
};
type CurveValue = {
    fitType: FitType;
    pointData: [number, number][];
    fitOptions: FitOption[];
    params: number[];
};

const findCurveTableParams = (value: CurveValue, formData: FormData): CurveTable => {
    const result = {} as CurveTable;
    const paramNames = value.fitType.curveParameterNames;
    for (const paramName of paramNames) {
        const fieldNames = value.fitType.parameterDisplayConfig[paramName].conditions;
        const fixed = getFieldByName(formData, fieldNames[1]).value;
        const initialValue = getFieldByName(formData, fieldNames[0]).value;
        const minValue = getFieldByName(formData, fieldNames[2]).value;
        const maxValue = getFieldByName(formData, fieldNames[3]).value;
        result[paramName] = {
            fixed: fixed !== undefined ? fixed === "true" : undefined,
            initialValue: isNumeric(initialValue) ? +initialValue : undefined,
            minValue: isNumeric(minValue) ? +minValue : undefined,
            maxValue: isNumeric(maxValue) ? +maxValue : undefined
        };
    }
    return result;
};

const setCurveTableParamsToFields = (
    value: CurveValue,
    formData: FormData,
    params: ExternalCurveParameters
) => {
    const paramNames = value.fitType.curveParameterNames;
    for (const paramName of paramNames) {
        const fieldNames = value.fitType.parameterDisplayConfig[paramName].conditions;
        const storeParamName = (
            paramName === "inflectionPoint" ? "EC50" : paramName
        ) as keyof typeof params;
        //TODO figure out why different types
        getFieldByName(formData, fieldNames[1]).value =
            params[storeParamName].fixed === true ? "true" : false;
        getFieldByName(formData, fieldNames[0]).value = params[storeParamName].initialValue;
        getFieldByName(formData, fieldNames[2]).value = params[storeParamName].minValue;
        getFieldByName(formData, fieldNames[3]).value = params[storeParamName].maxValue;
    }
};

export const extractCurveParameters = (
    value: CurveValue,
    selectedCells: SelectedCellsRow,
    formData: FormData
) => {
    const xDomain = [
        value.pointData.reduce((prev, [x]) => Math.min(prev, x), Number.MAX_SAFE_INTEGER),
        value.pointData.reduce((prev, [x]) => Math.max(prev, x), -Number.MAX_SAFE_INTEGER)
    ] as [number, number];

    xDomain[0] = round(xDomain[0] - 0.5, 0.5);
    xDomain[1] = round(xDomain[1] + 0.5, 0.5);

    const dots = value.pointData.map(([x, y], index) => ({
        x,
        y,
        isActive: selectedCells?.[index] ?? true
    }));

    const { minAsymptote, hillSlope, inflectionPoint, maxAsymptote } = findCurveTableParams(
        value,
        formData
    );

    const curveParameters: ExternalCurveParameters = {
        minAsymptote: {
            fixed: !!minAsymptote.fixed,
            initialValue: minAsymptote?.initialValue,
            minValue: minAsymptote?.minValue,
            maxValue: minAsymptote?.maxValue
        },
        hillSlope: {
            fixed: !!hillSlope.fixed,
            initialValue: hillSlope?.initialValue,
            minValue: hillSlope?.minValue,
            maxValue: hillSlope?.maxValue
        },
        EC50: {
            fixed: !!inflectionPoint.fixed,
            initialValue: inflectionPoint?.initialValue,
            minValue: inflectionPoint?.minValue,
            maxValue: inflectionPoint?.maxValue
        },
        maxAsymptote: {
            fixed: !!maxAsymptote.fixed,
            initialValue: maxAsymptote?.initialValue,
            minValue: maxAsymptote?.minValue,
            maxValue: maxAsymptote?.maxValue
        }
    };
    return { xDomain, dots, curveParameters };
};

export const convertFormsToHeatmap = (forms: FormData[]) => {
    //from first result
    const { columnLabels, readOnlyWells, displayOnlyWells, curveDataField } = JSON.parse(
        getFieldByName(forms[0], "heatMapProperties")?.value
    );

    const selectedCells = forms.map(
        (form) => JSON.parse(getFieldByName(form, curveDataField)?.value).enabled
    );

    const curveParameters = forms.map((form, index) => {
        const curveParamsStr = getFieldsByType(form, "curve")?.[0]?.value ?? "{}";
        const compoundId = getFieldByName(form, "Compound ID")?.value ?? "";
        const cellLine = getFieldByName(form, "Cell Line")?.value ?? "";
        const registrationId = getFieldByName(form, "Registration ID")?.value ?? "";

        return {
            ...extractCurveParameters(JSON.parse(curveParamsStr), selectedCells[index], form),
            compoundId,
            cellLine,
            registrationId
        };
    });

    const topEmptyWellReads = getFieldByName(forms[0], readOnlyWells[0].displayValueField)?.value;
    const emptyLeftColumnReads = getFieldByName(
        forms[0],
        readOnlyWells[1].displayValueField
    )?.value;
    const emptyRightColumnReads = getFieldByName(
        forms[0],
        readOnlyWells[2].displayValueField
    )?.value;
    const emptyBottomRowReads = getFieldByName(forms[0], readOnlyWells[3].displayValueField)?.value;

    const noTrtIndex = displayOnlyWells[0].wellColumn - 2;
    const DMSOIndex = noTrtIndex + 1;

    const emptyBorderCells = {
        top: topEmptyWellReads,
        bottom: emptyBottomRowReads,
        left: emptyLeftColumnReads,
        right: emptyRightColumnReads
    };

    const DMSOPercentActivity = forms.map(
        (form) => getFieldByName(form, "DMSO Percent Activity")?.value
    );
    const percentActivityFields = forms.map((form) => {
        return getFieldByName(form, "Percent Activity").value;
        const controlValue = getFieldByName(form, "Control Signal")?.value;
        const signalValue = getFieldByName(form, "Compound Signal")?.value;
        return signalValue?.map((item: number) => (item / controlValue) * 100);
    });

    return {
        curveParameters,
        columnLabels,
        noTrtIndex,
        DMSOIndex,
        emptyBorderCells,
        DMSOPercentActivity,
        percentActivityFields,
        selectedCells
    };
};

export const convertHeatmapToForms = (
    initForms: FormData[],
    selectedCells: SelectedCellsRow[],
    curvesParams: StoreCurveParameters[]
): FormData[] => {
    return produce(initForms, (draft) => {
        const { curveDataField } = JSON.parse(
            getFieldByName(initForms[0], "heatMapProperties")?.value
        );
        draft.forEach((form, index) => {
            const selectedCellsField = getFieldByName(form, curveDataField);
            const value = JSON.parse(selectedCellsField.value);
            value.enabled = selectedCells[index];
            selectedCellsField.value = JSON.stringify(value);

            const rIdField = getFieldByName(form, "Registration ID");
            rIdField.value = curvesParams[index].registrationId;

            const [curveField] = getFieldsByType(form, "curve");
            const curveStr = curveField.value;
            const curveValue = JSON.parse(curveStr) as CurveValue;

            const { curveParameters } = curvesParams[index];
            setCurveTableParamsToFields(curveValue, form, curveParameters);

            curveField.value = JSON.stringify(curveValue);
        });
    });
};