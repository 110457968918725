import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { ErrorFileLoadingDialog, UploadTemplateEditor, UploadTemplateNew } from "../containers";

const stateSelector = (state: UploadTemplateStore) => ({
    fileParsingInProcess: state.fileParsingInProcess,
    sheetsExist: Boolean(state.sheets.length)
});

export const UploadTemplateEditScreen = () => {
    const { sheetsExist, fileParsingInProcess } = useShallowUploadTemplateStore(stateSelector);

    const newTemplate = !sheetsExist && !fileParsingInProcess;

    return (
        <>
            {newTemplate && <UploadTemplateNew />}
            {!newTemplate && <UploadTemplateEditor />}
            <ErrorFileLoadingDialog />
        </>
    );
};
