import React from "react";
import { DotProps } from "./types";
import { curve, theme } from "@assay/ui-kit";

export const InactiveHoveredDot: React.FC<DotProps> = ({ x, y }) => (
    <svg
        x={(x ?? 0) - 10}
        y={(y ?? 0) - 10}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="10" cy="10" r="10" fill={theme.palette.common.black} fillOpacity="0.3" />
        <path
            d="M15.3539 6.36038L15.7055 6.00808L15.3548 5.65485L14.3548 4.64771L14.0016 4.29195L13.6468 4.64613L10.0006 8.28575L6.36816 4.64676L6.01585 4.29383L5.66198 4.6452L4.6477 5.65234L4.29132 6.00621L4.64676 6.36101L8.29226 10L4.64676 13.639L4.29132 13.9938L4.6477 14.3477L5.66198 15.3548L6.01585 15.7062L6.36816 15.3532L10.0006 11.7143L13.6468 15.3539L14.0016 15.708L14.3548 15.3523L15.3548 14.3452L15.7055 13.9919L15.3539 13.6396L11.7208 10L15.3539 6.36038Z"
            fill={curve.inActiveDot}
            stroke={theme.palette.common.white}
        />
    </svg>
);
