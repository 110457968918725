import {
    FileApiError,
    FileSaved,
    Header,
    Option,
    ResultDefinitionsQueryParams,
    UploadTemplate,
    UploadTemplateActions,
    UploadTemplateCallbacks,
    UploadTemplateField,
    UploadTemplatePayload
} from "./types";

import {
    DEFAULT_DECIMAL_PLACES,
    EMPTY_FIELD_TOUCHED,
    EMPTY_UPLOAD_TEMPLATE,
    FILES_TAB_INDEX,
    FT_MODE_OPTIONS,
    NOT_EXIST_COORDINATE
} from "./values";

import create, { StateSelector } from "zustand";
import { ResultDefinition } from "@assay/shared";

import {
    convertPayloadToUploadTemplate,
    convertUploadTemplateToPayload,
    createSheet,
    deleteFileExtension,
    errorToString,
    isDecimalPlacesDisabled,
    isSameResultDefinition
} from "./helpers";

import { getValidatedFields, validateSheets, validateUploadTemplateName } from "./validate";

import shallow from "zustand/shallow";

import { v4 as uuidv4 } from "uuid";
import {
    createUploadTemplate,
    deleteUploadTemplate,
    getUploadTemplate,
    updateUploadTemplate
} from "./api";

import { handleFilesRequests } from "./store-helpers";
import { FileWithPath } from "react-dropzone";

type UploadTemplateConditions = {
    isModified: boolean;
    fileParsingInProcess: boolean;
    fileParsingError: string | null;
    settingsTabIndex: number;
    editableFieldIndex: number | null;
    customTemplateModeActive: boolean;
    editMode: boolean;
    tempHeaders: Header[];
    ftModeOptions: Option[];
    sendingDataInProcess: boolean;
    loadingDataInProcess: boolean;
    errorMessage: string | null;
    succeedMessage: string | null;
    fileApiErrors: FileApiError[] | null;
    currentSheetIndex: number;
};

const emptyCallbacks: UploadTemplateCallbacks = {
    onDelete: () => null,
    onSave: () => null,
    onChangeMode: () => null,
    onInitCopy: () => null,
    onCancel: () => null
};

export type UploadTemplateStore = UploadTemplate &
    UploadTemplateConditions & {
        resultDefinitions: ResultDefinition[];
        resultDefinitionsQueryParams: ResultDefinitionsQueryParams;
        actions: UploadTemplateActions;
        callbacks: UploadTemplateCallbacks;
    };

const createFieldByHeaderHelper = (
    { sheet, name, row, column }: Header,
    resultDefinitions: ResultDefinition[]
): UploadTemplateField => {
    const rd = resultDefinitions.find((rd) => isSameResultDefinition(rd.name, name));
    return {
        id: uuidv4(),
        sheet: {
            id: (sheet?.id as number) ?? 0,
            name: sheet?.name ?? "",
            number: (sheet?.number as number) ?? 0
        },
        name,
        headerPosition: { row, column },
        dataRange: {
            range: null,
            simpleRule: {
                down: row >= 0 && column >= 0,
                right: false,
                diagonal: false
            }
        },
        mergeType: null,
        resultDefinitionId: rd?.id,
        hideInResult: false,
        decimalPlaces: rd && !isDecimalPlacesDisabled(rd) ? DEFAULT_DECIMAL_PLACES : null,
        required: true,
        scientificNotation: false,
        showInFT: false,

        aggregationFields: null
    };
};

const initialStoreConditions: UploadTemplateConditions = {
    isModified: false,
    fileParsingInProcess: false,
    fileParsingError: null,
    editMode: true,
    editableFieldIndex: null,
    customTemplateModeActive: false,
    settingsTabIndex: FILES_TAB_INDEX,
    tempHeaders: [],
    ftModeOptions: FT_MODE_OPTIONS,
    sendingDataInProcess: false,
    loadingDataInProcess: false,
    errorMessage: null,
    succeedMessage: null,
    fileApiErrors: null,
    currentSheetIndex: 0
};

const useUploadTemplateStore = create<UploadTemplateStore>((set, get) => ({
    ...EMPTY_UPLOAD_TEMPLATE,
    ...initialStoreConditions,
    resultDefinitions: [],
    resultDefinitionsQueryParams: {
        queryFn: () => new Promise<ResultDefinition[]>((resolve) => resolve([])),
        queryKey: ""
    },
    callbacks: {
        ...emptyCallbacks
    },
    actions: {
        setMessages: ({ succeedMessage, errorMessage }) => {
            if (succeedMessage) {
                set({ succeedMessage });
                setTimeout(() => set({ succeedMessage: null }), 2000);
            }
            if (errorMessage) {
                set({ errorMessage });
                setTimeout(() => set({ errorMessage: null }), 7000);
            }
        },

        loadTemplate: async (id, editMode, replaceConditions = true) => {
            set({ loadingDataInProcess: true });
            try {
                const response = await getUploadTemplate(id);
                if (!response.ok) {
                    get().actions.setMessages({ errorMessage: "Error" });
                    return;
                }
                const payload = (await response.json()) as UploadTemplatePayload;

                const template = convertPayloadToUploadTemplate(payload);

                set({
                    ...(replaceConditions ? initialStoreConditions : get()),
                    ...template,
                    editMode
                });

                // for debugging
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.downloadTemplateData = (data: UploadTemplate) =>
                    set({ ...data, ...initialStoreConditions, editMode: true });
            } catch (e) {
                get().actions.setMessages({ errorMessage: errorToString(e) });
            }

            set({ loadingDataInProcess: false });
        },

        initMainFile: (sheets, mainFile) => {
            get().callbacks.onChangeMode(true);
            set({
                isModified: true,
                sheets,
                mainFile,
                fileName: deleteFileExtension(mainFile.name),
                currentSheetIndex: 0
            });
        },
        changeSheets: (sheets) => {
            const sheetsErrors = validateSheets(sheets);
            set({ sheets, sheetsErrors, isModified: true, currentSheetIndex: 0 });
        },

        removeMainFile: () =>
            set({ mainFile: null, isModified: true, isDisplayOnEmptyCells: true }),

        setResultDefinitions: (resultDefinitions) => set({ resultDefinitions }),

        removeRow: (fieldIndex) => {
            const fields = [...get().fields];
            fields.splice(fieldIndex, 1);
            set({ fields: getValidatedFields(fields), isModified: true });
        },

        changeField: (fieldIndex, field) => {
            let fields = [...get().fields];
            if (fieldIndex < fields.length) {
                fields[fieldIndex] = {
                    ...field,
                    touched: {
                        ...(fields[fieldIndex].touched ?? EMPTY_FIELD_TOUCHED)
                    }
                };
            } else {
                fields.push(field);
            }

            fields = fields.map((item) => {
                if (!item.aggregationFields) {
                    return item;
                }

                return {
                    ...item,
                    aggregationFields: item.aggregationFields.map((aggItem) => {
                        if (aggItem.id === field.id) {
                            return { ...aggItem, name: field.name };
                        }
                        return aggItem;
                    })
                };
            });

            set({ fields: getValidatedFields(fields), isModified: true });
        },

        addField: () => {
            const { currentSheetIndex, fields, sheets } = get();
            const newIndex = fields.length;

            const sheet = sheets[currentSheetIndex];
            const { name, id, number } = sheet;

            const newField = createFieldByHeaderHelper(
                {
                    sheet: { name, id, number },
                    row: NOT_EXIST_COORDINATE,
                    column: NOT_EXIST_COORDINATE,
                    id: uuidv4(),
                    name: ""
                },
                get().resultDefinitions
            );

            get().actions.changeField(newIndex, newField);
            set({ editableFieldIndex: newIndex, isModified: true });
        },

        replaceFields: (fields) => set({ fields: getValidatedFields(fields), isModified: true }),

        touchField: (fieldIndex, subField) => {
            const fields = [...get().fields];
            if (fieldIndex >= fields.length) return;
            fields[fieldIndex] = {
                ...fields[fieldIndex],
                touched: {
                    ...(fields[fieldIndex].touched ?? EMPTY_FIELD_TOUCHED),
                    [subField]: true
                }
            };
            set({ fields });
        },

        handleConfirmHeaders: () => {
            const fields = get().tempHeaders.map(
                (value) =>
                    value.savedField ?? createFieldByHeaderHelper(value, get().resultDefinitions)
            );

            set({ fields: getValidatedFields(fields), tempHeaders: [], isModified: true });
        },

        handleEditHeaders: () =>
            set({
                fields: [],
                tempHeaders: get().fields.map((field) => ({
                    name: field.name,
                    sheet: field.sheet,
                    column: field.headerPosition?.column ?? NOT_EXIST_COORDINATE,
                    row: field.headerPosition?.row ?? NOT_EXIST_COORDINATE,
                    id: uuidv4(),
                    savedField: field
                })),
                editableFieldIndex: null,
                isModified: true
            }),

        changeName: (name) =>
            set({
                name: validateUploadTemplateName({
                    ...get().name,
                    value: name
                }),
                isModified: true
            }),
        changeFileUseRegularExpression: (useFileNameRegexp) =>
            set({ useFileNameRegexp, isModified: true }),

        changeFileName: (fileName) =>
            set({
                fileName,
                isModified: true
            }),

        changeSheetName: (index, name) => {
            const sheets = [...get().sheets];
            if (index >= sheets.length) return;

            const sheet = { ...sheets[index] };
            sheet.name = name;
            sheets[index] = sheet;

            const sheetsErrors = validateSheets(sheets);

            const fields = get().fields.map((field) => {
                if (field.sheet.id === sheet.id) {
                    return { ...field, sheet: { ...field.sheet, name: sheet.name } };
                }
                return field;
            });

            set({ sheets, fields, sheetsErrors, isModified: true });
        },
        changeSheetUseRegularExpression: (index, isUse) => {
            const sheets = [...get().sheets];
            if (index >= sheets.length) return;

            const sheet = { ...sheets[index] };
            sheet.useRegexp = isUse;
            sheets[index] = sheet;

            set({ sheets, isModified: true });
        },

        touchName: () =>
            set({
                name: {
                    ...get().name,
                    touched: true
                }
            }),

        addAttachment: (file) =>
            set({ attachments: [...get().attachments, file], isModified: true }),

        removeAttachment: (index) => {
            const attachments = [...get().attachments];
            const file = attachments[index];
            if (file instanceof File) {
                attachments.splice(index, 1);
            } else {
                attachments[index] = { ...(file as FileSaved), willBeDeleted: true };
            }

            set({ attachments, isModified: true });
        },

        reset: async (hard) => {
            const { id, callbacks, actions, isModified } = get();
            const exist = !!id;
            if (exist && !hard) {
                callbacks.onChangeMode(false);
                if (isModified && id) {
                    await actions.loadTemplate(id, false);
                }
                set({ editMode: false });
            } else {
                set({ ...EMPTY_UPLOAD_TEMPLATE, ...initialStoreConditions });
            }
        },

        changeEditMode: (editMode) => {
            set({ ...initialStoreConditions, editMode });
            const { callbacks } = get();
            callbacks.onChangeMode(editMode);
        },

        setSettingsTabIndex: (settingsTabIndex) =>
            set({
                settingsTabIndex,
                editableFieldIndex:
                    settingsTabIndex === FILES_TAB_INDEX ? null : get().editableFieldIndex
            }),

        setTempHeaders: (tempHeaders) => set({ tempHeaders }),

        setEditableFieldIndex: (editableFieldIndex) => {
            const { fields, currentSheetIndex } = get();

            const sheetIndex =
                editableFieldIndex !== null
                    ? fields[editableFieldIndex]?.sheet.number
                    : currentSheetIndex;
            set({ editableFieldIndex, currentSheetIndex: sheetIndex });
        },

        changeCurrentSheetIndex: (currentSheetIndex) => set({ currentSheetIndex }),

        setCustomTemplateModeActive: (customTemplateModeActive) =>
            set({ customTemplateModeActive }),
        saveUploadTemplate: async () => {
            set({ sendingDataInProcess: true });
            const { id: existId, actions, callbacks, mainFile, attachments } = get();
            const { setMessages } = actions;

            try {
                const payload = convertUploadTemplateToPayload(get());
                const response = existId
                    ? await updateUploadTemplate(existId, payload)
                    : await createUploadTemplate(payload);

                if (!response.ok) {
                    setMessages({ errorMessage: "Error" });
                    set({ sendingDataInProcess: false });
                    return;
                }

                setMessages({ succeedMessage: "Successfully saved" });

                const newId = await response.text();

                const templateId = existId ?? newId;

                const fileApiErrors = await handleFilesRequests(templateId, mainFile, attachments);

                set({
                    id: templateId,
                    sendingDataInProcess: false,
                    editMode: false,
                    fileApiErrors
                });

                callbacks.onSave(templateId);

                await actions.loadTemplate(templateId, false, false);
            } catch (err) {
                get().actions.setMessages({ errorMessage: errorToString(err) });

                set({ sendingDataInProcess: false });
            }
        },

        removeUploadTemplate: async () => {
            set({ sendingDataInProcess: true });
            try {
                const { id, actions, callbacks } = get();
                if (id) {
                    const response = await deleteUploadTemplate(id);

                    if (response.ok) {
                        actions.setMessages({ succeedMessage: "Successfully removed" });

                        callbacks.onDelete(id);

                        return set({ ...EMPTY_UPLOAD_TEMPLATE, ...initialStoreConditions });
                    }

                    set({ sendingDataInProcess: false });
                    throw new Error("Failed request");
                }
                throw new Error("Empty id");
            } catch (err) {
                get().actions.setMessages({ errorMessage: errorToString(err) });

                set({ sendingDataInProcess: false });
            }
        },

        retrySaveFiles: async () => {
            set({ sendingDataInProcess: true });
            const { fileApiErrors, id, actions, mainFile: existMainFile } = get();

            if (!id || !fileApiErrors) {
                return;
            }

            const mainFileError = fileApiErrors.find((item) => item.isMainFile);

            const getMainFilePayload = () => {
                if (!mainFileError && existMainFile) {
                    return existMainFile;
                }
                if (mainFileError && mainFileError.action === "create" && mainFileError.file) {
                    return mainFileError.file;
                }
                return null;
            };

            const attachments: Array<FileWithPath | FileSaved> = fileApiErrors
                .filter((item) => !item.isMainFile)
                .map((item) => {
                    if (item.action === "create" && item.file) {
                        return item.file;
                    }
                    return {
                        fileId: item.fileId ?? "",
                        fileName: item.fileName,
                        isMainFile: false,
                        willBeDeleted: true
                    };
                });

            const newFileApiError = await handleFilesRequests(
                id,
                getMainFilePayload(),
                attachments
            );

            if (newFileApiError.length) {
                set({ fileApiErrors: newFileApiError });
            } else {
                set({ fileApiErrors: null });
            }

            set({ sendingDataInProcess: false });

            await actions.loadTemplate(id, false, false);
        },

        deleteFilesErrors: () => set({ fileApiErrors: null }),

        setFileParsingInProgress: (fileParsingInProcess) => set({ fileParsingInProcess }),

        setFileParsingError: (fileParsingError) => set({ fileParsingError }),

        initExternals: ({ resultDefinitionsQueryParams, created, callbacks }) =>
            set({ callbacks, created, resultDefinitionsQueryParams }),
        initWithoutFile: () =>
            set({
                editMode: true,
                sheets: [createSheet(0)],
                isModified: true,
                isDisplayOnEmptyCells: true
            }),
        addSheet: () => {
            const sheets = [...get().sheets];

            const newSheet = createSheet(sheets.length);
            sheets.push(newSheet);

            const sheetsErrors = validateSheets(sheets);

            set({ sheets, isModified: true, sheetsErrors });
        },

        touchSheetName: (id) => {
            const newMap = new Map(get().sheetsTouched);
            newMap.set(id, true);
            set({ sheetsTouched: newMap });
        },

        changeIsDisplayOnEmptyCells: (isDisplayOnEmptyCells) =>
            set({ isDisplayOnEmptyCells, isModified: true })
    }
}));

export const useShallowUploadTemplateStore = <T>(selector: StateSelector<UploadTemplateStore, T>) =>
    useUploadTemplateStore(selector, shallow);

// for debugging
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.getUploadTemplateStore = () => useUploadTemplateStore.getState();
