export interface DropZoneExtensionOption {
    accept: string;
    name: string;
}

export const DROPZONE_EXTENSIONS_OPTIONS: {
    [key: string]: DropZoneExtensionOption;
} = {
    table: {
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv, .tsv, .xlsm",
        name: "XLS, XLSX, XLSM, CSV, TSV"
    },
    image: {
        accept: ".png, .jpg, .jpeg, .pdf",
        name: "PNG, JPG, PDF"
    }
};
