import { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { EditPerms } from "./edit-perms";
import { isObject } from "../../../helpers";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles({
    title: {
        fontWeight: "bold"
    },
    listItem: {
        padding: "0 10px 0"
    },
    text: {
        margin: 0
    }
});

const PermGroupList = ({ items, title }) => {
    const classes = useStyles();

    if (!items?.length) {
        return null;
    }

    return (
        <Fragment>
            <Typography className={classes.title}>{title}</Typography>
            <List>
                {items.map((item) => (
                    <ListItem key={item} className={classes.listItem}>
                        <ListItemText primary={item} className={classes.text} />
                    </ListItem>
                ))}
            </List>
        </Fragment>
    );
};

const DEFAULT_FIELD_VALUE = {
    userIds: [],
    userNames: [],
    groupIds: [],
    groupNames: []
};

const PermGroup = ({ value, mainOptions, onChange }) => {
    const { viewState } = mainOptions;

    const fieldValue = isObject(value) ? value : DEFAULT_FIELD_VALUE;
    const { groupNames, userNames } = fieldValue;

    return (
        <Fragment>
            {(viewState === "edit" || viewState === "create") && (
                <EditPerms fieldValue={fieldValue} onSubmit={onChange} />
            )}
            <PermGroupList items={groupNames} title={getTranslation("groups")} />
            <PermGroupList items={userNames} title={getTranslation("users")} />
        </Fragment>
    );
};

PermGroup.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    mainOptions: PropTypes.object,
    onChange: PropTypes.func
};

export { PermGroup };
