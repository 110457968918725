import React from "react";
import { Typography } from "@material-ui/core";
import { getTranslation } from "@assay/shared";

export const TemplateSettingsEditTitle = () => (
    <div>
        <Typography variant={"body1"}>
            {getTranslation("click-on-item-it-to-select-and-include-in-pattern-template")}
        </Typography>
    </div>
);
