import { TypographyOptions } from "@material-ui/core/styles/createTypography";

export const quicksandFontFamily = ["Quicksand", "sans-serif"].join(", ");
const robotoFontFamily = ["Roboto", "sans-serif"].join(", ");

export const monospaceFontFamily = "Roboto Mono";

export const typography: TypographyOptions = {
    h1: {
        fontSize: "48px",
        fontWeight: 500
    },
    h2: {
        fontSize: "34px",
        fontWeight: 500,
        letterSpacing: "0.25px"
    },
    h3: {
        fontSize: "24px",
        fontWeight: 500
    },
    h4: {
        fontSize: "20px",
        fontWeight: 500,
        letterSpacing: "0.15px"
    },
    body1: {
        fontFamily: "Arial, sans-serif",
        fontSize: "14px",
        fontWeight: "normal",
        letterSpacing: "0.44px"
    },
    body2: {
        fontSize: "14px",
        fontWeight: "normal",
        letterSpacing: "0.25px"
    },
    subtitle2: {
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: "0.1px"
    },
    caption: {
        fontSize: "12px",
        fontWeight: "normal",
        letterSpacing: "0.4px"
    },
    overline: {
        fontSize: "10px",
        fontWeight: 500,
        letterSpacing: "1.5px"
    },

    fontFamily: robotoFontFamily
};
