import React from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";

import { UploadTemplateField } from "../types";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ArrowDiagonal } from "@assay/ui-kit";
import { CustomTemplatePreview } from "./index";
import { boolToYesNoString } from "../utils";

type Props = {
    field: UploadTemplateField;
    resultDefinitionName?: string;
};

export const UploadTemplateTableRow: React.FC<Props> = ({ field, resultDefinitionName }) => {
    const {
        dataRange,
        aggregationFields,
        name,
        id,
        required,
        decimalPlaces,
        scientificNotation,
        showInFT,
        FTMode,
        hideInResult
    } = field;

    const isAggregation = !!aggregationFields?.length;
    const down = !!dataRange.simpleRule?.down;
    const right = !!dataRange.simpleRule?.right;
    const diagonal = !!dataRange.simpleRule?.diagonal;

    return (
        <TableRow key={id}>
            <TableCell align="left">{name}</TableCell>
            <TableCell align="left">
                {isAggregation && <Typography color={"textSecondary"}>Concatenation</Typography>}

                {!isAggregation && <Typography>{resultDefinitionName}</Typography>}
            </TableCell>
            <TableCell align="left">
                {down && <ArrowDownwardIcon color={"primary"} />}
                {right && <ArrowForwardIcon color={"primary"} />}
                {diagonal && <ArrowDiagonal color={"primary"} />}
                {!!dataRange.range && <CustomTemplatePreview range={dataRange.range} />}
            </TableCell>
            <TableCell align="left">{boolToYesNoString(required)}</TableCell>
            <TableCell align="left">{decimalPlaces}</TableCell>
            <TableCell align="left">{boolToYesNoString(scientificNotation ?? false)}</TableCell>
            <TableCell align="left">{boolToYesNoString(showInFT)}</TableCell>
            <TableCell align="left">{FTMode?.name ?? ""}</TableCell>
            <TableCell align="left">{boolToYesNoString(hideInResult)}</TableCell>
        </TableRow>
    );
};
