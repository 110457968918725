import React, { forwardRef } from "react";
import { Checkbox, ClickAwayListener, PaperProps } from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { Option } from "@assay/shared";
import { PaperComponent } from "./paper";
import { TextInput } from "./text-input";
import { makeStyles } from "../../theme";

const useStyles = makeStyles((theme) => ({
    checkbox: {
        marginRight: theme.spacing(1),
        padding: 0,
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0
    },
    multiListBox: {
        "& > li": {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        }
    }
}));

type Props<SingleOption> = {
    multi: boolean;
    setMulti: (value: boolean) => void;
    label: string;
    toggleLabel: string;
    error?: boolean;
    helperText?: string | null;
    singleOptions: SingleOption[];
    singleValue: SingleOption | null;
    multiOptions: Option[];
    multiValue: Option[] | null;
    onChangeSingle: (value: SingleOption | null) => void;
    onChangeMulti: (value: Option[] | null) => void;
    className?: string;
    multiComponentAdditionField?: React.ReactNode;
    multiHelperText?: string;
    onBlur?: () => void;
} & Omit<
    AutocompleteProps<Option | SingleOption, boolean, boolean, boolean>,
    | "disableCloseOnSelect"
    | "PaperComponent"
    | "ListboxComponent"
    | "options"
    | "filterOptions"
    | "renderOption"
    | "renderInput"
    | "defaultValue"
    | "onBlur"
>;

export const ComboAutocomplete = <SingleOption extends Option>({
    multi,
    setMulti,
    label,
    toggleLabel,
    singleOptions,
    multiOptions,
    error,
    helperText,
    singleValue,
    onChangeSingle,
    multiValue,
    onChangeMulti,
    className,
    multiComponentAdditionField,
    multiHelperText,
    onBlur,
    ...rest
}: Props<SingleOption>): React.ReactElement => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (isOpen) {
                    onBlur?.();
                }
                setIsOpen(false);
            }}
        >
            <div className={className}>
                {multi && (
                    <Autocomplete<Option, boolean, boolean, boolean>
                        {...rest}
                        getOptionSelected={(option, value) => option.id === value.id}
                        multiple
                        value={multiValue}
                        onChange={(_, value) => onChangeMulti(value ? (value as Option[]) : null)}
                        disableCloseOnSelect
                        open={isOpen}
                        ListboxComponent={
                            // eslint-disable-next-line react/display-name
                            forwardRef<HTMLDivElement>((props: unknown, ref) => (
                                <div ref={ref} {...props} className={classes.multiListBox} />
                            )) as React.ComponentType<React.HTMLAttributes<HTMLElement>>
                        }
                        PaperComponent={(props: PaperProps) => (
                            <PaperComponent
                                {...props}
                                additionField={multiComponentAdditionField}
                                helperText={multiHelperText}
                                toggleLabel={toggleLabel}
                                checked={multi}
                                onToggle={(checked) => {
                                    setMulti(checked);
                                }}
                                onClose={() => {
                                    setIsOpen(false);
                                    onBlur?.();
                                }}
                                onClear={() => onChangeMulti([])}
                            />
                        )}
                        options={multiOptions}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <div>
                                <Checkbox className={classes.checkbox} checked={selected} />
                                {option.name}
                            </div>
                        )}
                        renderInput={(params) => (
                            <TextInput
                                {...params}
                                label={label}
                                onClick={() => setIsOpen((prevState) => !prevState)}
                                helperText={helperText}
                                error={error}
                            />
                        )}
                        className={className}
                        onBlur={onBlur}
                    />
                )}
                {!multi && (
                    <Autocomplete<SingleOption, boolean, boolean, boolean>
                        {...rest}
                        multiple={false}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={singleValue}
                        onChange={(_, value) => onChangeSingle(value as SingleOption)}
                        open={isOpen}
                        PaperComponent={(props: PaperProps) => (
                            <PaperComponent
                                {...props}
                                toggleLabel={toggleLabel}
                                checked={multi}
                                onToggle={(checked) => {
                                    setMulti(checked);
                                }}
                            />
                        )}
                        options={singleOptions}
                        getOptionLabel={(option) => option.name}
                        ListboxComponent={
                            // eslint-disable-next-line react/display-name
                            forwardRef<HTMLDivElement>((props: unknown, ref) => (
                                <div ref={ref} {...props} onClick={() => setIsOpen(false)} />
                            )) as React.ComponentType<React.HTMLAttributes<HTMLElement>>
                        }
                        renderOption={(option) => (
                            <div onClick={() => setIsOpen(false)}>{option.name}</div>
                        )}
                        renderInput={(params) => (
                            <TextInput
                                {...params}
                                label={label}
                                onClick={() => setIsOpen((prevState) => !prevState)}
                                helperText={helperText}
                                error={error}
                            />
                        )}
                        className={className}
                        onBlur={onBlur}
                    />
                )}
            </div>
        </ClickAwayListener>
    );
};
