import React, { useState } from "react";
import {
    Curve,
    DotParams,
    ExternalCurveParameters,
    getTranslation,
    Heatmap,
    SelectedCellsRow
} from "@assay/shared";

import { useHeatMapStore } from "../store";
import { makeStyles, theme } from "@assay/ui-kit";
import produce from "immer";
import { CircularProgress, debounce, Divider } from "@material-ui/core";
import { sendSaveFormRequest } from "../api-v2";
import { convertHeatmapToForms } from "../helpers";
import { useQueryClient } from "react-query";
import { getFormQueryKey } from "../api-v2/form";
import NewWindow from "react-new-window";

const useStyles = makeStyles({
    heatmapWrapper: {
        position: "sticky",
        top: 0,
        background: theme.palette.common.white
    },
    boldText: {
        fontWeight: "bold"
    },
    input: {
        padding: "4px 5px",
        fontSize: "12px",
        width: "126px !important",
        margin: 0
    },
    buttons: {
        display: "flex",
        gap: theme.spacing(1)
    },
    button: {
        "border": `1px solid ${theme.palette.common.black}`,
        "padding": "4px 10px",
        "fontSize": "14px",
        "borderRadius": "2px",
        "marginTop": theme.spacing(1),
        "marginBottom": theme.spacing(1),
        "cursor": "pointer",
        "background": theme.palette.common.white,
        "&:hover": {
            background: theme.palette.grey[200]
        }
    }
});

export const HeatMap: React.FC = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const queryClient = useQueryClient();
    const isHeatMapShown = useHeatMapStore((state) => state.isHeatMapShown);
    const [isOutOfPage, setIsOutOfPage] = useState(false);
    const heatMapProps = useHeatMapStore((state) => state.heatMapProps);
    const setSelectedCells = useHeatMapStore((state) => state.setSelectedCells);
    const selectedCells = useHeatMapStore((state) => state.selectedCells);
    const hideHeatmap = useHeatMapStore((state) => state.reset);
    const curvesParams = useHeatMapStore((state) => state.curvesParams);
    const setCurveParameters = useHeatMapStore((state) => state.setCurveParameters);
    const forms = useHeatMapStore((state) => state.forms);

    const handleChangeCurveParameters = (index: number, params: ExternalCurveParameters): void => {
        const newCurvesParams = produce(curvesParams, (draft) => {
            draft[index] = { ...draft[index], curveParameters: params };
        });
        setCurveParameters(newCurvesParams);
    };

    const handleChangeDots = (index: number, dots: DotParams[]): void => {
        const newCurvesParams = produce(curvesParams, (draft) => {
            draft[index] = { ...draft[index], dots };
        });
        setCurveParameters(newCurvesParams);
        const newSelectedCells = produce(selectedCells, (draft) => {
            draft[index] = dots.map((item) => item.isActive);
        });
        setSelectedCells(newSelectedCells);
    };

    const handleChangeHeatmap = (rows: SelectedCellsRow[]): void => {
        setSelectedCells(rows);
        const newCurvesParams = produce(curvesParams, (draft) => {
            draft.forEach((params, paramsIndex) => {
                params.dots.forEach((dot, dotIndex) => {
                    dot.isActive = rows[paramsIndex]?.[dotIndex] ?? true;
                });
            });
        });
        setCurveParameters(newCurvesParams);
    };

    const handleChangeRegistrationId = debounce((value: string, index: number): void => {
        const newCurvesParams = produce(curvesParams, (draft) => {
            draft[index].registrationId = value;
        });
        setCurveParameters(newCurvesParams);
    }, 700);

    const handleSaveResults = async () => {
        setIsLoading(true);
        await Promise.all(
            convertHeatmapToForms(forms, selectedCells, curvesParams).map(async (form) => {
                await sendSaveFormRequest(form);
                queryClient.refetchQueries(getFormQueryKey(form.id));
            })
        );
        setIsLoading(false);
        hideHeatmap();
        window.scrollTo({ top: 0 });
    };

    if (!isHeatMapShown || !heatMapProps) {
        return null;
    }
    return (
        <>
            <div className={classes.heatmapWrapper}>
                <div className={classes.buttons}>
                    {isOutOfPage ? (
                        <>
                            <NewWindow
                                title="Heatmap"
                                features={{ width: 700, height: 400 }}
                                onUnload={() => setIsOutOfPage(false)}
                            >
                                <Heatmap
                                    {...heatMapProps}
                                    selectedCells={selectedCells}
                                    onChangeSelectedCells={handleChangeHeatmap}
                                />
                            </NewWindow>
                            <button
                                className={classes.button}
                                onClick={() => setIsOutOfPage(false)}
                            >
                                {getTranslation("show-heat-map")}
                            </button>
                        </>
                    ) : (
                        <button className={classes.button} onClick={() => setIsOutOfPage(true)}>
                            {getTranslation("pop-heat-map-out-of-page")}
                        </button>
                    )}

                    <button className={classes.button} onClick={() => hideHeatmap()}>
                        {getTranslation("hide-heat-map")}
                    </button>
                </div>
                {!isOutOfPage && (
                    <Heatmap
                        {...heatMapProps}
                        selectedCells={selectedCells}
                        onChangeSelectedCells={handleChangeHeatmap}
                    />
                )}
            </div>
            <Divider />

            {curvesParams.map((params, index) => (
                <React.Fragment key={index}>
                    <div className={classes.boldText}>
                        {`Compound ID: ${params.compoundId}`}
                    </div>
                    <div className={classes.boldText}>{`Cell Line: ${params.cellLine}`}</div>
                    <div>Registration ID*</div>
                    <input
                        className={classes.input}
                        defaultValue={params.registrationId}
                        onChange={(event) =>
                            handleChangeRegistrationId(event.target.value, index)
                        }
                    />
                    <Curve
                        isInteractive={true}
                        height={350}
                        width={750}
                        yDomain={[0, 120]}
                        onDotsChange={(dots) => handleChangeDots(index, dots)}
                        onChangeCurveParameters={(parameters) =>
                            handleChangeCurveParameters(index, parameters)
                        }
                        {...params}
                    />
                    <Divider />
                </React.Fragment>
            ))}
            {isLoading ? (
                <CircularProgress />
            ) : (
                <button className={classes.button} onClick={handleSaveResults}>
                    {getTranslation("save-results")}
                </button>
            )}
        </>
    );
};

