import { TableCell as MuiTableCell, TableRow as MuiTableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { ResultTableCell } from "./types";
import { useUrl } from "../use-url";

const useStyles = makeStyles({
    row: {
        "borderBottom": "1px solid #D3D3D3",
        "&:hover": {
            backgroundColor: "#E9E9E9"
        }
    },
    cell: {
        padding: "11px 5px",
        fontSize: "13px",
        lineHeight: "15px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    link: {
        color: "#666"
    }
});

type TableCellProps = {
    classes: Record<string, string>;
    cell: ResultTableCell;
};

const TableCell: React.FC<TableCellProps> = ({ cell, classes }) => {
    const { openItem } = useUrl();
    const { id, label, type } = cell;

    if (!id) {
        return <MuiTableCell className={classes.cell}>{label}</MuiTableCell>;
    }

    return (
        <MuiTableCell className={classes.cell}>
            <a
                href="#"
                className={classes.link}
                onClick={(e) => {
                    e.preventDefault();
                    openItem({ id, type, viewState: "view" });
                }}
            >
                {label}
            </a>
        </MuiTableCell>
    );
};

type TableRowProps = {
    row: ResultTableCell[];
};

export const TableRow: React.FC<TableRowProps> = ({ row }) => {
    const classes = useStyles();
    const cells = row.filter((cell) => !!cell);

    return (
        <MuiTableRow className={classes.row}>
            {cells.map((cell, i) => (
                <TableCell key={i} cell={cell} classes={classes} />
            ))}
        </MuiTableRow>
    );
};
