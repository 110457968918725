import { useState } from "react";

const concatUnique = (arr, newArr) => [...new Set([...arr, ...newArr])];

const filterOutIntersections = (targetArr, intersectionsArr) =>
    targetArr.filter((item) => !intersectionsArr.some((inersection) => inersection === item));

export const useSelected = (initialState) => {
    const [groupIds, setGroupIds] = useState(initialState.groupIds);
    const [groupNames, setGroupNames] = useState(initialState.groupNames);
    const [userIds, setUserIds] = useState(initialState.userIds);
    const [userNames, setUserNames] = useState(initialState.userNames);

    const selectGroup = (group) => {
        const { id, name, users } = group;

        setGroupIds((groupIds) => [...groupIds, id]);
        setGroupNames((groupNames) => [...groupNames, name]);
        setUserIds((userIds) =>
            concatUnique(
                userIds,
                users.map((u) => u.id)
            )
        );
        setUserNames((userNames) =>
            concatUnique(
                userNames,
                users.map((u) => u.name)
            )
        );
    };

    const unselectGroup = (group) => {
        const { name, id, users } = group;

        setGroupIds((groupIds) => groupIds.filter((group) => group !== id));
        setGroupNames((groupNames) => groupNames.filter((group) => group !== name));
        setUserIds((userIds) =>
            filterOutIntersections(
                userIds,
                users.map((u) => u.id)
            )
        );
        setUserNames((userNames) =>
            filterOutIntersections(
                userNames,
                users.map((u) => u.name)
            )
        );
    };

    const selectUser = (id, name, group) => {
        const newUserIds = [...userIds, id];
        const newUserNames = [...userNames, name];

        // if all users within a group are selected, select the group
        const areAllUsersSelected = group.users.every(
            (u) => newUserIds.includes(u.id) && newUserNames.includes(u.name)
        );

        const newGroupIds = areAllUsersSelected ? [...groupIds, group.id] : groupIds;
        const newGroupNames = areAllUsersSelected ? [...groupNames, group.name] : groupNames;

        setUserIds(newUserIds);
        setUserNames(newUserNames);
        setGroupIds(newGroupIds);
        setGroupNames(newGroupNames);
    };

    const unselectUser = (id, name, group) => {
        setGroupIds((groupIds) => groupIds.filter((id) => id !== group.id));
        setGroupNames((groupNames) => groupNames.filter((name) => name !== group.name));
        setUserIds((userIds) => userIds.filter((user) => user !== id));
        setUserNames((userNames) => userNames.filter((user) => user !== name));
    };

    const onSelectedGroupsChange = (group) => {
        if (groupIds.includes(group.id)) {
            unselectGroup(group);
        } else {
            selectGroup(group);
        }
    };

    const onSelectedUsersChange = (id, name, group) => {
        if (userIds.includes(id)) {
            unselectUser(id, name, group);
        } else {
            selectUser(id, name, group);
        }
    };

    return {
        selected: { groupIds, groupNames, userIds, userNames },
        onSelectedGroupsChange,
        onSelectedUsersChange
    };
};
