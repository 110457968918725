import React from "react";
import { makeStyles } from "@assay/ui-kit";
import { SettingsPanel } from "./settings-panel";
import { SheetEditorWorkplace } from "./sheet-editor-workplace";
import { CustomRuleAlert } from "./custom-rule-alert";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex"
    },
    leftSide: {
        position: "relative",
        flexGrow: 1,
        height: "100%",
        background: theme.palette.grey[200]
    },
    rightSide: {
        background: theme.palette.common.white,
        position: "relative",
        height: "100%",
        width: "368px",
        boxShadow: "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)"
    }
}));

export const UploadTemplateEditor = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.leftSide}>
                <SheetEditorWorkplace />
                <CustomRuleAlert />
            </div>
            <div className={classes.rightSide}>
                <SettingsPanel />
            </div>
        </div>
    );
};
