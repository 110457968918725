import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useAuthContext } from "./auth-context";
import { authActions } from "./auth-actions";

const useStyles = makeStyles({
    button: {
        marginLeft: "auto",
        textTransform: "none"
    }
});

export const SignOut: React.FC = () => {
    const classes = useStyles();
    const { accessToken, isAuthEnabled } = useAuthContext();

    if (!accessToken || !isAuthEnabled) {
        return null;
    }

    return (
        <Button
            variant="text"
            color="inherit"
            className={classes.button}
            onClick={authActions.signOut}
        >
            Sign out
        </Button>
    );
};
