import React, { useState } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {
    AttachmentIcon,
    green,
    OutlinedIconButton,
    outlinedIconButtonDefaultSize,
    theme
} from "@assay/ui-kit";
import { FileSaved } from "../types";
import { errorToString, getFileName, getFileUrl } from "../helpers";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { getUploadTemplateAttachmentFile, getUploadTemplateMainFile } from "../api";
import { downloadFileByLink } from "../utils";

const useStyles = makeStyles((theme) =>
    createStyles({
        fileContainer: {
            "display": "grid",
            "gridTemplateColumns": "auto 1fr auto auto",
            "& > *:not(:last-child)": {
                marginRight: theme.spacing(1)
            },
            "alignItems": "center",
            "paddingBottom": theme.spacing(2),
            "paddingRight": theme.spacing(1)
        }
    })
);

interface Props {
    file: File | FileSaved;
    onDelete?: (() => void) | null;
}

const selectState = ({ id, actions }: UploadTemplateStore) => ({
    id,
    setError: (errorMessage: string) => actions.setMessages({ errorMessage })
});

export const FileBar = ({ file, onDelete }: Props) => {
    const [downloadingInProcess, setDownloadingInProcess] = useState(false);

    const { id, setError } = useShallowUploadTemplateStore(selectState);

    const classes = useStyles();

    const name = getFileName(file);

    const saveAs = async (): Promise<void> => {
        if (file instanceof File) {
            downloadFileByLink(name, getFileUrl(file));
            return;
        }

        if (!id) {
            return;
        }

        setDownloadingInProcess(true);

        try {
            const response = file.isMainFile
                ? await getUploadTemplateMainFile(id)
                : await getUploadTemplateAttachmentFile(id, file.fileId);

            const blob = await response.blob();

            downloadFileByLink(name, getFileUrl(blob));
        } catch (e) {
            setError(errorToString(e));
        }
        setDownloadingInProcess(false);
    };

    return (
        <div className={classes.fileContainer}>
            <AttachmentIcon fileName={name} />
            <Typography>{name}</Typography>
            {!downloadingInProcess && (
                <OutlinedIconButton
                    type="secondary"
                    onClick={saveAs}
                    startIcon={<CloudDownloadIcon />}
                    iconColor={green}
                />
            )}
            {downloadingInProcess && <CircularProgress size={outlinedIconButtonDefaultSize} />}

            {onDelete && (
                <OutlinedIconButton
                    type="secondary"
                    onClick={onDelete}
                    startIcon={<ClearIcon />}
                    iconColor={theme.palette.warning.main}
                />
            )}
        </div>
    );
};
