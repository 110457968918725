import React from "react";
import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

type Props<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> = { label?: string; error?: boolean; helperText?: string | null } & Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    "renderInput"
>;

export const OutlinedAutocomplete = <
    T,
    Multiple extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>({
    label,
    error,
    helperText,
    ...rest
}: Props<T, Multiple, boolean, FreeSolo>): React.ReactElement => {
    return (
        <Autocomplete
            renderInput={(params: AutocompleteRenderInputParams): React.ReactElement => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    helperText={helperText}
                    error={error}
                />
            )}
            {...rest}
        />
    );
};
