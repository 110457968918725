import {
    DataMatrix,
    DataRange,
    DocumentSheet,
    Header,
    OptionWithNumber,
    Position,
    SelectedMatrix,
    SelectedSheetMatrix,
    SelectType,
    TemplateRange
} from "../../types";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../../store";
import { useMemo } from "react";

import produce from "immer";
import { FILES_TAB_INDEX, NOT_EXIST_COORDINATE, PROPERTIES_TAB_INDEX } from "../../values";
import {
    convertSimplyRuleToMatrix,
    convertTemplateToMatrix,
    createEmptySheetMatrix,
    findColumnsLength,
    headersLength
} from "../../utils";
import { v4 as uuidv4 } from "uuid";

type CommonSelectorResult = {
    isFileExist: boolean;
    fileParsingInProcess: boolean;
    filePreview: boolean;
    sheets: DocumentSheet[];
    isDisplayOnEmptyCells: boolean;
    changeIsDisplayOnEmptyCells: (checked: boolean) => void;
    changeCurrentSheetIndex: (index: number) => void;
    currentSheetIndex: number;
};

const selectCommonEditorData = ({
    mainFile,
    settingsTabIndex,
    sheets,
    fileParsingInProcess,
    isDisplayOnEmptyCells,
    currentSheetIndex,
    actions: { changeIsDisplayOnEmptyCells, changeCurrentSheetIndex }
}: UploadTemplateStore): CommonSelectorResult => ({
    isFileExist: !!mainFile,
    fileParsingInProcess,
    filePreview: settingsTabIndex === FILES_TAB_INDEX,
    sheets,
    isDisplayOnEmptyCells,
    changeIsDisplayOnEmptyCells,
    changeCurrentSheetIndex,
    currentSheetIndex
});

type HeadersPosition = {
    sheet: number;
    row: number;
    column: number;
};

type FieldsEditorData = {
    editFieldMode: boolean;
    fieldsHeadersPositions: HeadersPosition[];
    currentFieldHeaderPosition: Position | null;
    isAggregation: boolean;
    fieldDataRange: DataRange | null;
    fieldSheetNumber: number;
    onChangeFieldSheet: (sheet: OptionWithNumber) => void;
    onChangeFieldTemplate: (range: TemplateRange) => void;
    startRegexpValue?: string;
    endRegexpValue?: string;
    startRegexPositions?: Position;
    endRegexPositions?: Position;
    rightRegexpStep?: number;
    downRegexpStep?: number;
    isDisplayOnEmptyCells: boolean;
    isTemplateError?: boolean;
};

const selectFieldsEditorData = ({
    settingsTabIndex,
    editableFieldIndex,
    tempHeaders,
    customTemplateModeActive,
    fields,
    actions,
    isDisplayOnEmptyCells,
    currentSheetIndex
}: UploadTemplateStore): FieldsEditorData => {
    const editableFieldIndexDependedParams =
        editableFieldIndex !== null
            ? {
                  currentFieldHeaderPosition: fields[editableFieldIndex]?.headerPosition,
                  isAggregation: !!fields[editableFieldIndex]?.aggregationFields,
                  fieldDataRange: fields[editableFieldIndex]?.dataRange,
                  startRegexpValue: fields[editableFieldIndex]?.startRegexpValue,
                  endRegexpValue: fields[editableFieldIndex]?.endRegexpValue,
                  startRegexPositions: fields[editableFieldIndex]?.startRegexPositions,
                  endRegexPositions: fields[editableFieldIndex]?.endRegexPositions,
                  rightRegexpStep: fields[editableFieldIndex]?.startRegexStepRight,
                  downRegexpStep: fields[editableFieldIndex]?.startRegexStepDown,
                  fieldSheetNumber: fields[editableFieldIndex]?.sheet?.number,
                  isTemplateError: !!(
                      fields[editableFieldIndex]?.errors?.startRegexPositions ||
                      fields[editableFieldIndex]?.errors?.endRegexPositions ||
                      fields[editableFieldIndex]?.errors?.startRegexpValue ||
                      fields[editableFieldIndex]?.errors?.endRegexpValue ||
                      fields[editableFieldIndex]?.errors?.startRegexStepRight ||
                      fields[editableFieldIndex]?.errors?.startRegexStepDown
                  )
              }
            : {
                  currentFieldHeaderPosition: null,
                  isAggregation: false,
                  fieldDataRange: null,
                  fieldSheetNumber: currentSheetIndex
              };
    return {
        editFieldMode:
            settingsTabIndex === PROPERTIES_TAB_INDEX &&
            editableFieldIndex !== null &&
            tempHeaders.length === 0 &&
            customTemplateModeActive,
        isDisplayOnEmptyCells,
        fieldsHeadersPositions: fields.reduce((prev, field) => {
            const isHeaderPositionInvalid =
                !field.headerPosition ||
                field.headerPosition.row < 0 ||
                field.headerPosition.column < 0;
            return isHeaderPositionInvalid
                ? prev
                : [
                      ...prev,
                      {
                          sheet: field.sheet.number,
                          row: field.headerPosition?.row ?? -1,
                          column: field.headerPosition?.column ?? -1
                      }
                  ];
        }, new Array<HeadersPosition>()),
        ...editableFieldIndexDependedParams,
        onChangeFieldSheet: (sheet: OptionWithNumber) => {
            if (editableFieldIndex === null) return;
            actions.changeField(editableFieldIndex, {
                ...fields[editableFieldIndex],
                sheet
            });
        },
        onChangeFieldTemplate: (range: TemplateRange) => {
            if (editableFieldIndex === null) return;
            actions.changeField(editableFieldIndex, {
                ...fields[editableFieldIndex],
                dataRange: {
                    range,
                    simpleRule: {
                        down: false,
                        diagonal: false,
                        right: false
                    }
                }
            });
            actions.setCustomTemplateModeActive(false);
        }
    };
};

type HeadersEditorData = {
    tempHeaders: Header[];
    setTempHeaders: (headers: Header[]) => void;
    editHeadersMode: boolean;
    currentSheetIndex: number;
};

const selectHeadersEditorData = ({
    tempHeaders,
    actions,
    settingsTabIndex,
    fields,
    currentSheetIndex
}: UploadTemplateStore): HeadersEditorData => ({
    tempHeaders: tempHeaders,
    setTempHeaders: actions.setTempHeaders,
    currentSheetIndex,
    editHeadersMode:
        settingsTabIndex === PROPERTIES_TAB_INDEX && (fields.length === 0 || tempHeaders.length > 0)
});

type RegexpEditorData = {
    setRegexpPosition?: (position: Position) => void;
};

const selectRegexpEditorData = ({
    editableFieldIndex,
    fields,
    actions: { changeField }
}: UploadTemplateStore): RegexpEditorData => {
    if (editableFieldIndex === null) {
        return {};
    }
    const field = fields[editableFieldIndex];
    if (field.isStartRegexPositionsFocused) {
        return {
            setRegexpPosition: (position) =>
                changeField(editableFieldIndex, {
                    ...field,
                    startRegexPositions: position
                })
        };
    }
    if (field.isEndRegexPositionsFocused) {
        return {
            setRegexpPosition: (position) =>
                changeField(editableFieldIndex, {
                    ...field,
                    endRegexPositions: position
                })
        };
    }
    return {};
};

export const useCommonEditorData = () => useShallowUploadTemplateStore(selectCommonEditorData);

export const useFieldsEditorData = (sheets: DocumentSheet[]) => {
    const {
        editFieldMode,
        fieldsHeadersPositions,
        fieldSheetNumber,
        fieldDataRange,
        currentFieldHeaderPosition,
        onChangeFieldTemplate,
        onChangeFieldSheet,
        startRegexpValue,
        endRegexpValue,
        startRegexPositions,
        endRegexPositions,
        downRegexpStep,
        rightRegexpStep,
        isDisplayOnEmptyCells,
        isTemplateError
    } = useShallowUploadTemplateStore(selectFieldsEditorData);

    const emptySelectedMatrix: SelectedMatrix = useMemo(
        () =>
            sheets.map((sheet) =>
                createEmptySheetMatrix(
                    sheet.rawData.length + headersLength,
                    findColumnsLength(sheet.rawData) + headersLength
                )
            ),
        [sheets]
    );

    const matrixWithHeaders: SelectedMatrix = useMemo(
        () =>
            produce(emptySelectedMatrix, (draft) => {
                fieldsHeadersPositions.forEach((headerPosition) => {
                    const dataNotExist =
                        !draft[headerPosition.sheet]?.[headerPosition.row + headersLength]?.[
                            headerPosition.column + headersLength
                        ];
                    if (
                        headerPosition.row === null ||
                        headerPosition.column === null ||
                        dataNotExist
                    ) {
                        return;
                    }

                    draft[headerPosition.sheet][headerPosition.row + headersLength][
                        headerPosition.column + headersLength
                    ] = SelectType.DisabledSecondarySelected;
                });
            }),
        [emptySelectedMatrix, fieldsHeadersPositions]
    );

    const matrixWithTemplate: SelectedMatrix = useMemo(() => {
        if (currentFieldHeaderPosition === null) {
            return matrixWithHeaders;
        }
        if (fieldDataRange?.simpleRule?.down && !isTemplateError) {
            return convertSimplyRuleToMatrix({
                matrix: matrixWithHeaders,
                startColumn: currentFieldHeaderPosition.column,
                isMoveDown: true,
                isMoveRight: false,
                sheets,
                isDisplayOnEmptyCells,
                sheet: fieldSheetNumber,
                startRow: currentFieldHeaderPosition.row + 1,
                startRegexpValue,
                endRegexpValue,
                startRegexPositions,
                endRegexPositions,
                downRegexpStep,
                rightRegexpStep
            });
        }
        if (fieldDataRange?.simpleRule?.right && !isTemplateError) {
            return convertSimplyRuleToMatrix({
                matrix: matrixWithHeaders,
                startColumn: currentFieldHeaderPosition.column + 1,
                startRow: currentFieldHeaderPosition.row,
                sheets,
                sheet: fieldSheetNumber,
                startRegexpValue,
                endRegexpValue,
                isMoveDown: false,
                isMoveRight: true,
                isDisplayOnEmptyCells,
                startRegexPositions,
                endRegexPositions,
                downRegexpStep,
                rightRegexpStep
            });
        }
        if (fieldDataRange?.simpleRule?.diagonal && !isTemplateError) {
            return convertSimplyRuleToMatrix({
                matrix: matrixWithHeaders,
                startColumn: currentFieldHeaderPosition.column + 1,
                sheets,
                isMoveDown: true,
                sheet: fieldSheetNumber,
                isMoveRight: true,
                startRow: currentFieldHeaderPosition.row + 1,
                startRegexpValue,
                endRegexpValue,
                isDisplayOnEmptyCells,
                startRegexPositions,
                endRegexPositions,
                downRegexpStep,
                rightRegexpStep
            });
        }
        if (fieldDataRange?.range && !isTemplateError) {
            return produce(matrixWithHeaders, (draft) => {
                if (!fieldDataRange?.range) return;
                draft[fieldSheetNumber] = convertTemplateToMatrix({
                    matrixRowsLength: draft[fieldSheetNumber].length,
                    existMatrix: draft[fieldSheetNumber],
                    templateRange: fieldDataRange.range,
                    matrixColumnLength: findColumnsLength(draft[fieldSheetNumber]),
                    withHeaders: true,
                    dataMatrix: sheets[fieldSheetNumber].rawData,
                    startRegexpValue,
                    endRegexpValue,
                    startRegexPositions,
                    endRegexPositions,
                    isDisplayOnEmptyCells,
                    downRegexpStep,
                    rightRegexpStep
                });
            });
        }
        return matrixWithHeaders;
    }, [
        fieldSheetNumber,
        matrixWithHeaders,
        fieldDataRange,
        currentFieldHeaderPosition,
        isDisplayOnEmptyCells,
        endRegexpValue
    ]);

    return {
        editFieldMode,
        fieldsMatrix: matrixWithTemplate,
        fieldSheetNumber,
        onChangeFieldTemplate,
        onChangeFieldSheet
    };
};

export const useHeadersEditorData = (sheets: DocumentSheet[], tableData: DataMatrix) => {
    const { tempHeaders, editHeadersMode, setTempHeaders, currentSheetIndex } =
        useShallowUploadTemplateStore(selectHeadersEditorData);

    const currentHeadersSheet =
        currentSheetIndex < sheets.length ? sheets[currentSheetIndex] : null;

    const emptySelectedMatrix: SelectedMatrix = useMemo(
        () =>
            sheets.map((sheet) =>
                createEmptySheetMatrix(
                    sheet.rawData.length + headersLength,
                    findColumnsLength(sheet.rawData) + headersLength
                )
            ),
        [sheets]
    );

    const headersMatrix: SelectedMatrix = useMemo(
        () =>
            produce(emptySelectedMatrix, (draft) => {
                tempHeaders.forEach((header) => {
                    draft[header.sheet?.number as number][header.row + headersLength][
                        header.column + headersLength
                    ] = SelectType.ActiveSelected;
                });
            }),
        [emptySelectedMatrix, tempHeaders]
    );

    const handleChangeHeaders = (newSheetMatrix: SelectedSheetMatrix) => {
        const anotherTabHeaders = tempHeaders.filter(
            (header) =>
                header.sheet?.name !== currentHeadersSheet?.name &&
                header.column !== NOT_EXIST_COORDINATE &&
                header.row !== NOT_EXIST_COORDINATE
        );
        const customFieldsHeaders = tempHeaders.filter(
            (header) =>
                header.column === NOT_EXIST_COORDINATE || header.row === NOT_EXIST_COORDINATE
        );
        const newHeaders: Header[] = [];
        newSheetMatrix.forEach((row, rowIndex) => {
            row.forEach((column, columnIndex) => {
                if (column !== SelectType.ActiveSelected) return;

                const newRowIndex = rowIndex - headersLength;
                const newColumnIndex = columnIndex - headersLength;

                if (
                    newRowIndex === NOT_EXIST_COORDINATE ||
                    newColumnIndex === NOT_EXIST_COORDINATE
                ) {
                    return;
                }

                const existHeader = tempHeaders.find(
                    (header) =>
                        header.row === newRowIndex &&
                        header.column === newColumnIndex &&
                        header.sheet?.name === currentHeadersSheet?.name
                );

                newHeaders.push({
                    row: newRowIndex,
                    column: newColumnIndex,
                    sheet: currentHeadersSheet,
                    name:
                        existHeader?.name ??
                        tableData[currentSheetIndex]?.[newRowIndex]?.[newColumnIndex],
                    id: uuidv4(),
                    savedField: existHeader?.savedField
                });
            });
        });
        setTempHeaders([...anotherTabHeaders, ...newHeaders, ...customFieldsHeaders]);
    };

    return {
        headersMatrix,
        handleChangeHeaders,
        editHeadersMode,
        currentHeadersSheet
    };
};

export const useRegexpEditorData = () => {
    const { setRegexpPosition } = useShallowUploadTemplateStore(selectRegexpEditorData);
    return { setRegexpPosition };
};