import {
    AssayProps,
    getAppConfig,
    ServerConfig,
    saveObjectToAppConfig,
    initFeatureService
} from "@assay/shared";
import { apiCall } from "./common";
import { localesIds } from "../constants";

const loadTranslationsDictionary = async (): Promise<void> => {
    const localeId = localesIds[getAppConfig().currentLanguage];
    const dictionary = await apiCall("Translations", "GET", {}, { "Accept-Language": localeId });
    window.localStorage.setItem("dictionary", JSON.stringify(dictionary));
};

const getServerConfig = async (): Promise<ServerConfig> => {
    const { assayConfigHeader, connectionId } = getAppConfig();
    const { userId, whichClient, usersICanSee } = assayConfigHeader;

    return await apiCall<ServerConfig>("elnConnection", "POST", {
        userId,
        whichClient,
        usersICanSee,
        connectionId
    });
};

export const initApp = async (appProps: AssayProps): Promise<void> => {
    saveObjectToAppConfig(appProps);
    const serverConfig = await getServerConfig();
    saveObjectToAppConfig(serverConfig);

    await Promise.all([initFeatureService(), loadTranslationsDictionary()]);
};
