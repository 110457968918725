import React, { useEffect, useState } from "react";
import { getTranslation } from "@assay/shared";
import { makeStyles } from "@assay/ui-kit";
import { Box } from "@material-ui/core";
import { useFormQuery, useResultsIdsQuery } from "../api-v2";
import { noop } from "../helpers";
import { beforeShow, retreiveProps } from "./form-utils";
import { ResultsHeader } from "./results-header";
import { FieldWrapper } from "./field-wrapper.js";
import { FormField } from "../types";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: theme.spacing(3)
    }
}));

type Props = {
    resultSetId: number;
    dateUpdated: string;
};

export const Results: React.FC<Props> = ({ resultSetId }) => {
    const classes = useStyles();
    const [pageIndex, setPageIndex] = useState(0);

    const { data: resultsIds = [], isLoading } = useResultsIdsQuery(resultSetId);
    const { data: result } = useFormQuery(resultsIds[pageIndex]);
    const [fields, setFields] = useState<FormField[]>([]);
    useEffect(() => {
        if (result) {
            beforeShow(result);
            setFields(result.fields);
        }
    }, [result])
    if (!resultsIds.length && !isLoading) {
        return (
            <Box className={classes.wrapper}>
                <span>{getTranslation("no-result-data")}</span>
            </Box>
        );
    }

    return (
        <Box className={classes.wrapper}>
            <ResultsHeader
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                totalPages={resultsIds.length}
            />

            {fields.map((field, i) => (
                <FieldWrapper
                    key={i}
                    {...retreiveProps(field, "view")}
                    setFields={noop}
                    formData={result}
                    indexes={[i]}
                />
            ))}
        </Box>
    );
};
