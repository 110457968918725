import { UploadTemplateField } from "../types";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { UploadTemplateTableRow } from "../components";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles({
    container: {
        maxHeight: "65vh"
    },
    table: {
        minWidth: 650
    }
});

const selectState = (state: UploadTemplateStore) => ({
    fields: state.fields,
    resultDefinitions: state.resultDefinitions
});

export const UploadTemplateTable = () => {
    const classes = useStyles();
    const { fields, resultDefinitions } = useShallowUploadTemplateStore(selectState);

    const findRdName = (field: UploadTemplateField): string | undefined =>
        resultDefinitions.find((rd) => rd.id === field.resultDefinitionId)?.name;

    return (
        <TableContainer component={Paper} className={classes.container}>
            <Table className={classes.table} stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">{getTranslation("name")}</TableCell>
                        <TableCell align="left">{getTranslation("result-definition")}</TableCell>
                        <TableCell align="left">{getTranslation("range")}</TableCell>
                        <TableCell align="left">{getTranslation("required")}</TableCell>
                        <TableCell align="left">{getTranslation("decimal-places")}</TableCell>
                        <TableCell align="left">{getTranslation("scientific-notation")}</TableCell>
                        <TableCell align="left">{getTranslation("show-in-ft")}</TableCell>
                        <TableCell align="left">{getTranslation("ft-mode")}</TableCell>
                        <TableCell align="left">{getTranslation("hide-in-result")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field) => (
                        <UploadTemplateTableRow
                            key={field.id}
                            field={field}
                            resultDefinitionName={findRdName(field)}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
