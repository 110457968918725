import React, { useState } from "react";
import { Dropzone, DROPZONE_EXTENSIONS_OPTIONS } from "@assay/ui-kit";
import { initSheets, readSheets } from "../helpers";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { prepareSheetsReplacement, PrepareSheetsReplacementResult } from "../utils";
import { DocumentSheet } from "../types";
import { InconsistentFieldsDialog } from "./inconsistent-fields-dialog";

const selectState = ({
    fields,
    sheets,
    actions: { replaceFields, initMainFile, setFileParsingError, setFileParsingInProgress }
}: UploadTemplateStore) => ({
    fields,
    sheets,
    replaceFields,
    initMainFile,
    setFileParsingInProgress,
    setFileParsingError
});

type PrevFileResult = {
    fieldsPreparedResult: PrepareSheetsReplacementResult[];
    file: File;
    documentSheets: DocumentSheet[];
};

export const MainFileDropzone: React.FC = () => {
    const {
        setFileParsingError,
        setFileParsingInProgress,
        initMainFile,
        replaceFields,
        fields,
        sheets: existSheets
    } = useShallowUploadTemplateStore(selectState);

    const [preparedResult, setPreparedResult] = useState<PrevFileResult | null>(null);

    const handleFileChoose = async ([file]: File[]) => {
        setFileParsingInProgress(true);
        const { error, sheets } = await readSheets(file);
        if (error) {
            setFileParsingError(error);
            setFileParsingInProgress(false);
            return;
        }

        const documentSheets = initSheets(sheets);
        const preparedFields = prepareSheetsReplacement(fields, existSheets, documentSheets);
        const hasInconsistent = preparedFields.some((item) => !item.isConsistent);

        if (hasInconsistent) {
            setPreparedResult({ fieldsPreparedResult: preparedFields, file, documentSheets });
        } else {
            initMainFile(documentSheets, file);
            replaceFields(preparedFields.map((item) => item.field));
        }

        setFileParsingInProgress(false);
    };

    const handleEraseOnlyInconsistent = () => {
        if (!preparedResult) {
            return;
        }
        initMainFile(preparedResult.documentSheets, preparedResult.file);
        replaceFields(
            preparedResult.fieldsPreparedResult
                .filter((item) => item.isConsistent)
                .map((item) => item.field)
        );
        setPreparedResult(null);
    };

    const handleEraseAll = () => {
        if (!preparedResult) {
            return;
        }
        initMainFile(preparedResult.documentSheets, preparedResult.file);
        replaceFields([]);
        setPreparedResult(null);
    };

    const isDialogOpen = !!preparedResult;

    const inconsistentFields =
        preparedResult?.fieldsPreparedResult.filter((item) => !item.isConsistent) ?? [];

    return (
        <>
            <Dropzone
                sizeLimitMb={25}
                onFilesChoose={(files) => handleFileChoose(files as File[])}
                extensionOption={DROPZONE_EXTENSIONS_OPTIONS.table}
            />

            <InconsistentFieldsDialog
                isOpen={isDialogOpen}
                onClose={() => setPreparedResult(null)}
                fields={inconsistentFields}
                onEraseInconsistent={handleEraseOnlyInconsistent}
                onEraseAll={handleEraseAll}
            />
        </>
    );
};
