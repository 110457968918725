import React from "react";
import { Button } from "@material-ui/core";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { AlertMessage } from "../components/alert-message";
import { getTranslation } from "@assay/shared";

const selectState = (state: UploadTemplateStore) => ({
    isOpen: state.customTemplateModeActive,
    onClose: () => state.actions.setCustomTemplateModeActive(false)
});

export const CustomRuleAlert = () => {
    const { isOpen, onClose } = useShallowUploadTemplateStore(selectState);

    if (!isOpen) return null;

    return (
        <AlertMessage
            severity="info"
            action={
                <Button color="inherit" size="small" onClick={onClose}>
                    {getTranslation("cancel")}
                </Button>
            }
            message={getTranslation("click-on-cell-to-select-and-include-in-pattern-template")}
        />
    );
};
