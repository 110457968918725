import { AnyObj, FileData, FormData } from "../../../types";
import {
    attachDunderAsync,
    getForm,
    getNextId,
    getNumResults,
    getParsedResults,
    getTabNames
} from "../../../api-v2";
import { getFieldByName, getOriginFieldByName } from "../common";
import { attachDunders } from "../dunders";
import { attachOptions } from "../../../form-helpers";
import { isObject, FormError, FormErrorTypes } from "../../../helpers";
import { validateExcel } from "./validate-excel";
import { getAppConfig } from "@assay/shared";

type AllFiles = Record<string, FileData>;
type FilesFromUt = { allFiles: AllFiles; repeatFiles: FileData[] };

const retrieveFilesFromUt = async (ut: FormData, resultSet: FormData): Promise<FilesFromUt> => {
    const allFiles = {} as AllFiles;
    const repeatFiles = [] as FileData[];
    const utFileFields = getOriginFieldByName(ut, "File Fields");

    if (utFileFields) {
        for (const fieldsArray of utFileFields.fields) {
            const fileField = getFieldByName(
                resultSet,
                getOriginFieldByName(fieldsArray, "File Name").value
            );

            if (!fileField) {
                continue;
            }

            const file = {} as FileData;
            file.fileName = fileField.__pFieldOptions.name as string;
            file.fileId = fileField.value;
            file.tabName = getOriginFieldByName(fieldsArray, "tab name").value;
            file.isRegEx = !!getOriginFieldByName(fieldsArray, "regular expression")?.value;
            file.tabNames = await getTabNames(file);

            if (getOriginFieldByName(fieldsArray, "Parse Repeats").value) {
                repeatFiles.push(file);
            }

            allFiles[file.fileName] = file;
        }
    } else {
        const file = {} as FileData;
        file.fileId = getFieldByName(resultSet, "file").value;
        file.tabName = getFieldByName(ut, "tab name").value;
        file.isRegEx = !!getFieldByName(ut, "regular expression")?.value;
        file.tabNames = await getTabNames(file);

        repeatFiles.push(file);
        allFiles.default = file;
    }

    return { allFiles, repeatFiles };
};

/**
 * Create an object _sort at the root of the form
 * _sort: { fieldName: "fieldVal", fieldName2: "fieldVal2" ... }
 * The purpose of this is to make a sort of view that is easier to sort than the more
 * complicated queries of drilling down into the actual fields would be
 * key names have spaces replaced with _ and are lower cased.
 */
const fixSort = (result: FormData): void => {
    result._sort = {} as AnyObj;

    result.fields.forEach((field) => {
        if (field.__pFieldOptions?.type === "fieldSet") {
            return;
        }

        const key = field.__pFieldOptions?.name
            .toLowerCase()
            .replace(/\s+/gi, "_")
            .replace(/\./gi, "");

        result._sort[key] = field.value;
    });
};

/**
 * Recursively converts form's field values into numbers.
 * I.e. "333" -> 333.
 */
const fixNumbers = (object: any): void => {
    for (const key in object) {
        if (isObject(object[key])) {
            fixNumbers(object[key]);
        }
    }

    if (
        !object.value ||
        object.constructor.name !== "Object" ||
        !object.__pFieldOptions?.validation.contains("isNumber") ||
        !object.__pFieldOptions?.validation.contains("isInteger")
    ) {
        return;
    }

    if (Array.isArray(object.value)) {
        if (Array.isArray(object.value[0])) {
            // TODO: refactor
            for (let i = 0; i < object.value.length; i++) {
                for (let j = 0; j < object.value[i].length; j++) {
                    if (!object.__pExcelOptions?.hasHeaders || i !== 0) {
                        object.value[i][j] = Number(object.value[i][j]);
                    }
                }
            }
        } else {
            for (let i = 0; i < object.value.length; i++) {
                if (!object.__pExcelOptions?.hasHeaders || i !== 0) {
                    object.value[i] = Number(object.value[i]);
                }
            }
        }
    } else {
        object.value = parseFloat(object);
    }
};

const createResultForm = async (
    resultSet: FormData,
    uploadTemplate: FormData
): Promise<Partial<FormData>> => {
    const { resultTypeId } = getAppConfig();
    const preForm = await getForm(Number(resultTypeId));
    const resultForm = JSON.parse(getFieldByName(preForm, "JSON").value);
    resultForm.typeId = resultTypeId;
    resultForm.parentId = resultSet.id;

    resultSet.fields.forEach((field) => {
        if (
            field.__pFieldOptions?.name.toLowerCase() !== "name" &&
            field.__pFieldOptions?.name.toLowerCase() !== "file" &&
            field.__pFieldOptions?.type !== "file"
        ) {
            resultForm.fields.push(field);
        }
    });

    const utFields = JSON.parse(getOriginFieldByName(uploadTemplate, "JSON").value);
    utFields.forEach((field: any) => resultForm.fields.push(field));
    attachOptions(resultForm);
    await attachDunderAsync(resultForm);

    return resultForm;
};

export const createResults = async (
    resultSet: FormData,
    uploadTemplate: FormData
): Promise<FormData[]> => {
    const results = [] as FormData[];

    const resultForm = await createResultForm(resultSet, uploadTemplate);
    const resultsNumberLimit = getFieldByName(resultSet, "Cell Lines Tested")?.value.length;

    const { repeatFiles, allFiles } = await retrieveFilesFromUt(uploadTemplate, resultSet);

    for (const file of repeatFiles) {
        for (const tabName of file.tabNames) {
            const numResultsPayload = { fileId: file.fileId, rs: resultForm, tabName };
            const { num: resultsNumber, errors } = await getNumResults(numResultsPayload);

            if (errors) {
                throw new FormError(FormErrorTypes.ResultSetFile, errors);
            }

            const resultsNumberToAdd =
                resultsNumberLimit !== undefined
                    ? resultsNumberLimit - results.length
                    : resultsNumber;

            if (resultsNumberToAdd <= 0) {
                return results;
            }

            const parseResultsPayload = {
                forms: new Array(resultsNumberToAdd).fill(resultForm),
                fileId: file.fileId,
                isRegEx: file.isRegEx,
                tabName,
                allFiles
            };

            const { forms: parsedResults } = await getParsedResults(parseResultsPayload);

            const ids = (await Promise.all(parsedResults.map(getNextId))).sort();
            await Promise.all(
                parsedResults.map(async (result, index) => {
                    await attachDunders(result);
                    result.sheet = tabName;

                    const excelErrors = validateExcel(index, result);
                    if (excelErrors.length) {
                        throw new FormError(FormErrorTypes.ResultSetFile, excelErrors);
                    }

                    result.id = ids[index];
                    result.parentId = resultSet.id;
                    fixNumbers(result);
                    fixSort(result);
                    results.push(result);
                })
            );
            results.sort((res1, res2) => res1.id - res2.id);
        }
    }
    return results;
};
