import { Box } from "@material-ui/core";

export const CopyFormLayout = ({ title, fields, actions }) => {
    return (
        <Box display="flex" flexDirection="column">
            {title}
            <Box marginTop={2}>{fields}</Box>

            <Box display="flex" justifyContent="flex-end" marginTop={4}>
                {actions}
            </Box>
        </Box>
    );
};
