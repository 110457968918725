import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { ReactQueryDevtools } from "react-query/devtools";
import { App as Assay, AssayProps } from "@assay/core";
import { TabPanel, theme } from "@assay/ui-kit";
import { UploadTemplateTab } from "./upload-template-tab";
import { HeatmapTab } from "./heatmap-tab";
import { CurveTab } from "./curve-tab";
import { useAuthTokenProvider } from "./hooks";
import { getEnv, assayConfigHeader, connectionId, QueryProvider } from "@assay/shared";

const useStyles = makeStyles({
    assayWrapper: {
        position: "relative",
        height: "calc(100vh - 48px)" // 100vh - header height
    }
});

type Props = {
    selectedTabIndex: number;
};

export const AppContent: React.FC<Props> = ({ selectedTabIndex }) => {
    const classes = useStyles();
    const authTokenProvider = useAuthTokenProvider();

    const assayProps: AssayProps = {
        proxyUrl: getEnv("REACT_APP_PROXY_URL"),
        useProxy: !!getEnv("REACT_APP_USE_PROXY"),
        apiUrl: getEnv("REACT_APP_API_URL"),
        uploadTemplatesApiUrl: getEnv("REACT_APP_UPLOAD_TEMPLATES_API_URL"),
        dotnetApiUrl: getEnv("REACT_APP_DOTNET_API_URL"),
        currentLanguage: getEnv("REACT_APP_CURRENT_LANGUAGE"),
        defaultLangCode: getEnv("REACT_APP_DEFAULT_LANGUAGE_CODE"),
        useLongDate: getEnv("REACT_APP_USE_LONG_DATE") === "true",
        hasFTLiteAssay: true,
        mainAppPath: "http://52.188.57.124:8892/arxlab",
        assayConfigHeader,
        connectionId,
        authTokenProvider
    };

    return (
        <>
            <TabPanel selectedIndex={selectedTabIndex} currentIndex={0}>
                <div className={classes.assayWrapper}>
                    <Assay {...assayProps} />
                </div>
            </TabPanel>
            <TabPanel selectedIndex={selectedTabIndex} currentIndex={1}>
                {/* TODO: Move theme and query providers inside deliverable UT component,
                because it should only require a number of props, not providers to operate */}
                <ThemeProvider theme={theme}>
                    <QueryProvider>
                        <UploadTemplateTab />

                        {/* https://react-query.tanstack.com/devtools#options */}
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryProvider>
                </ThemeProvider>
            </TabPanel>
            <TabPanel selectedIndex={selectedTabIndex} currentIndex={2}>
                <HeatmapTab />
            </TabPanel>
            <TabPanel selectedIndex={selectedTabIndex} currentIndex={3}>
                <CurveTab />
            </TabPanel>
        </>
    );
};
