import { jsfit } from "./jsfit";
import * as numeric from "./numeric";

export function fitCurve(xVals, yVals, params, parInfo) {
    if (xVals.length <= 0 || xVals.length !== yVals.length)
        return { error: "xVals and yVals arrays must be of the same nonzero length", params: [] };

    var fitObj = {};
    var fitterOptions = { parInfo: parInfo, maxIterations: 1000 };
    var passParams = calculateInitialFitParams(xVals, yVals, params, parInfo);

    var retVal = {
        initialParams: passParams,
        fitOptions: fitterOptions,
        params: [],
        error: undefined
    };
    fitObj = jsfit.fit(curveFunction, [xVals, yVals], passParams, fitterOptions);

    if (fitObj["r2"]) retVal["r2"] = fitObj["r2"];

    if (fitObj["params"]) retVal["params"] = fitObj["params"];

    if (Object.keys(retVal).length === 0) retVal["error"] = "There was an unknown error";

    return retVal;
}

export function curveFunction(x, params) {
    var minAsymptote = params[0];
    var hillSlope = params[1];
    var inflectionPoint = params[2];
    var maxAsymptote = params[3];
    return (
        minAsymptote +
        (maxAsymptote - minAsymptote) / (1 + Math.pow(10, (inflectionPoint - x) * hillSlope))
    );
}

// The initial guesses for the fit parameters if they have not been provided by the user
function calculateInitialFitParams(xVals, yVals, params, parInfo) {
    var maxY = Math.max(...yVals);
    var minY = Math.min(...yVals);
    var avgX = numeric.sum(xVals) / xVals.length;
    var slope = (maxY - minY) / (xVals[yVals.indexOf(maxY)] - xVals[yVals.indexOf(minY)]);

    var passParams = [minY, slope, avgX, maxY];
    for (var param in params) if (params[param] !== undefined) passParams[param] = params[param];

    return passParams;
}