import { colors } from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";

import { common, grey } from "../colors";
import { config } from "../config";

export const MuiMenuItem: Overrides["MuiMenuItem"] = {
    root: {
        fontSize: 14,
        paddingTop: 8,
        paddingBottom: 8,

        [config.selectedSelector]: {
            color: colors.grey.A700,
            backgroundColor: common.white
        },

        [config.hoverSelector]: {
            color: grey[900],
            backgroundColor: colors.grey[100]
        }
    }
};
