import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const DashIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="m8.9053,11.48232l6.25,0c0.3438,0 0.625,0.28125 0.625,0.625c0,0.34375 -0.2812,0.625 -0.625,0.625l-6.25,0c-0.34375,0 -0.625,-0.28125 -0.625,-0.625c0,-0.34375 0.28125,-0.625 0.625,-0.625z"
                fill={props.color}
            />
        </SvgIcon>
    );
};
