import { getAppConfig } from "./appConfig";

export enum Features {
    IsNewUploadTemplatesOn = "IsNewUploadTemplatesOn"
}

type FeatureService = {
    [key in Features]: boolean;
};

export let featureService: FeatureService;

type ResponseData = Array<{
    name: Features;
    enabled: boolean;
}>;

export const initFeatureService = async (): Promise<void> => {
    const { dotnetApiUrl, authTokenProvider, connectionId } = getAppConfig();
    const token = await authTokenProvider();

    const headers = {
        "Authorization": `Bearer ${token}`,
        "Custom-ArxLab-ConnectionId": connectionId
    };

    const response = await fetch(`${dotnetApiUrl}/features`, { headers });
    const responseData: ResponseData = await response.json();

    const features = {} as FeatureService;
    responseData.forEach((item) => {
        features[item.name] = item.enabled;
    });

    featureService = Object.freeze(features);
};
