import { makeStyles } from "@assay/ui-kit";
import { CircularProgress } from "@material-ui/core";
import { getFormattedDate, getTranslation } from "@assay/shared";

const useStyles = makeStyles(() => ({
    button: {
        "width": "110px",
        "marginRight": "10px",
        "boxShadow": "0px 10px 14px -7px #276873",
        "backgroundColor": "#7cbed6",
        "display": "inline-block",
        "borderRadius": "8px",
        "cursor": "pointer",
        "color": "#ffffff",
        "fontFamily": "Arial",
        "fontSize": "13px",
        "fontWeight": "bold",
        "padding": "13px 32px",
        "textDecoration": "none",
        "textShadow": "0px 1px 0px #3d768a",
        "&:hover": {
            backgroundColor: "#408c99"
        }
    }
}));

export const Buttons = ({ handlers, formData }) => {
    const classes = useStyles();

    const {
        isLoading,
        showSaveButton,
        showCopyButton,
        showEditButton,
        showDeleteButton,
        onSave,
        onDelete,
        onCopy,
        onEdit
    } = handlers;

    const dateAdded = getFormattedDate(formData.dateAdded);

    const isDisplayAuditTrail = formData.userAdded && formData.id;

    return (
        <div id="buttonHolder">
            {isDisplayAuditTrail && (
                <div className="datebox">
                    <div>
                        <label>{getTranslation("user-added")}</label>
                        <div>{formData.userAdded?.userName}</div>
                    </div>

                    <div>
                        <label>{getTranslation("date-added")}</label>
                        <div>{dateAdded}</div>
                    </div>
                </div>
            )}

            {showSaveButton && (
                <button className={classes.button} onClick={onSave} disabled={isLoading}>
                    {getTranslation("save")}
                </button>
            )}

            {showCopyButton && (
                <button className={classes.button} onClick={onCopy}>
                    {getTranslation("copy")}
                </button>
            )}

            {showEditButton && (
                <button className={classes.button} onClick={onEdit}>
                    {getTranslation("edit")}
                </button>
            )}

            {showDeleteButton && (
                <button className={classes.button} onClick={onDelete}>
                    {getTranslation("delete")}
                </button>
            )}

            {isLoading && <CircularProgress size={26} />}
        </div>
    );
};
