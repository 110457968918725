import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { getAppConfig } from "@assay/shared";

const useStyles = makeStyles({
    box: {
        padding: "10px",
        textAlign: "center"
    }
});

export const Search = () => {
    const classes = useStyles();
    const { mainAppPath } = getAppConfig()
    return <Box className={classes.box}
        onClick={() => window.location.assign(`${mainAppPath}/goToFt.asp?lite=assay`)}>
        search
    </Box>;
};
