import { useMemo } from "react";
import { useQuery } from "react-query";
import { getFormIdsAndCursorData, getForms } from "./form";
import { ResultDefinition, ResultDefinitionTypes } from "@assay/shared";
import { Entity } from "../types";

const getResultDefinitions = async (): Promise<ResultDefinition[]> => {
    const query = { parentId: 351 };
    const ids = await getFormIdsAndCursorData({ query });
    const forms = await getForms(ids);

    return forms
        .filter((form) => form.name === "Result Definition")
        .map((form) => ({
            id: String(form.id),
            name: form.fields[0].value as string,
            type: form.fields[1].value as ResultDefinitionTypes,
            options: form.fields[2].value as string[]
        }));
};

export const getResultDefinitionsQueryParams = () => ({
    queryKey: "resultDefinitions",
    queryFn: getResultDefinitions
});

export const useGetRdsAsEntities = () => {
    const { data = [], ...rest } = useQuery(getResultDefinitionsQueryParams());
    const rdsAsEntities = useMemo(
        () => data.map((item: ResultDefinition): Entity => [Number(item.id), item.name]),
        [data]
    );

    return {
        data: rdsAsEntities,
        ...rest
    };
};
