import { useQuery, UseQueryResult } from "react-query";
import { apiCall } from "./common";
import { getForms } from "./form"
import { AnyObj, FormData, FileData } from "../types";

export const saveResults = async (form: FormData): Promise<void> => {
    await apiCall("saveResultSetForm", "POST", { form });
};

export const getTabNames = async (fileObj: Omit<FileData, "tabNames">): Promise<string[]> => {
    return await apiCall("getTabNames/", "POST", fileObj);
};

type GetNumResultsPayload = {
    fileId: number;
    rs: AnyObj; // result set's form data
    tabName: string;
};

type NumResults = {
    errors?: any;
    num: number;
};

export const getNumResults = async (payload: GetNumResultsPayload): Promise<NumResults> => {
    return await apiCall("getNumResults/", "POST", payload);
};

type GetParsedResultsPayload = {
    forms: FormData[]; // result set's results
    fileId: number;
    isRegEx: boolean;
    tabName: string;
    allFiles: Record<string, FileData>;
};

type ParsedResults = {
    forms: FormData[];
};

export const getParsedResults = async (
    payload: GetParsedResultsPayload
): Promise<ParsedResults> => {
    return await apiCall("parseResults/", "POST", payload);
};

type ChildIds = {
    childIds: number[];
};

const getChildIds = async (id: number): Promise<number[]> => {
    const response = await apiCall<ChildIds>("getChildIds/", "POST", { id });
    return response.childIds;
};

export const getResults = async (resultSetId: number): Promise<FormData[]> => {
    const resultsIds = await getChildIds(resultSetId);
    return await getForms(resultsIds);
};

export const deleteResult = async (id: number): Promise<void> => {
    await apiCall("deleteResult", "POST", { id });
};

export const removeResults = async (resultSetId: number): Promise<void> => {
    const resultIds = await getChildIds(resultSetId);
    await Promise.all(resultIds.map((id) => deleteResult(id)));
};

export const getResultsQueryKey = (resultSetId: number): string => {
    return `results-${resultSetId}`;
};

export const useResultsIdsQuery = (resultSetId: number): UseQueryResult<number[]> => {
    return useQuery<number[]>({
        queryKey: getResultsQueryKey(resultSetId),
        queryFn: () => getChildIds(resultSetId)
    });
};
