import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    FormControlLabel,
    Typography
} from "@material-ui/core";
import React, { useCallback } from "react";
import { useShallowUploadTemplateStore } from "../store";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Option, UploadTemplateField, UploadTemplateFieldTouched } from "../types";
import { getTranslation, ResultDefinition } from "@assay/shared";
import { DEFAULT_DECIMAL_PLACES } from "../values";

import {
    ArrowDiagonal,
    ComboAutocomplete,
    DebouncedTextField,
    ExtendedTheme,
    makeStyles,
    OutlinedAutocomplete,
    OutlinedIconButton
} from "@assay/ui-kit";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useFieldsOptions } from "../hooks";
import { ConcatenationMergeType } from "./concatenation-merge-type";
import { FieldRegexp } from "./field-regexp";
import {
    isDecimalPlacesDisabled,
    isSameResultDefinition,
    isScientificNotationDisabled
} from "../helpers";

const useStyles = makeStyles<ExtendedTheme, { isOpen?: boolean }>((theme) => ({
    root: {
        "display": "flex",
        "flexDirection": "column",
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(2)
        }
    },
    summaryName: ({ isOpen }) => ({
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "280px",
        fontWeight: isOpen ? "bold" : "normal"
    }),
    fieldRow: {
        "display": "flex",
        "width": "100%",
        "alignItems": "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    },
    fieldTitle: {
        width: "115px",
        flexShrink: 0
    },
    fieldInput: {
        flexGrow: 1
    },
    customButton: {
        flexGrow: 1
    },
    checkBoxes: {
        display: "grid",
        gridTemplateColumns: "1.2fr 1fr"
    }
}));

type Props = {
    fieldIndex: number;
};

export const Field: React.FC<Props> = ({ fieldIndex }) => {
    const {
        field,
        isOpen,
        onOpen,
        onChange,
        onTouch,
        resultDefinitions,
        setCustomTemplateModeActive,
        ftModeOptions
    } = useShallowUploadTemplateStore(
        useCallback(
            (state) => ({
                field: state.fields[fieldIndex],
                isOpen: state.editableFieldIndex === fieldIndex,
                onChange: (field: Partial<UploadTemplateField>) =>
                    state.actions.changeField(fieldIndex, {
                        ...state.fields[fieldIndex],
                        ...field
                    }),
                onTouch: (subField: keyof UploadTemplateFieldTouched) =>
                    state.actions.touchField(fieldIndex, subField),
                onOpen: () =>
                    state.actions.setEditableFieldIndex(
                        fieldIndex === state.editableFieldIndex ? null : fieldIndex
                    ),
                resultDefinitions: state.resultDefinitions,
                setCustomTemplateModeActive: state.actions.setCustomTemplateModeActive,
                ftModeOptions: state.ftModeOptions
            }),
            [fieldIndex]
        )
    );

    const classes = useStyles({ isOpen });

    const isLegacyField = !!(
        field.dataRange.additionalRanges ||
        field.dataRange.range?.relatedField ||
        field.dataRange.range?.leftOffset ||
        field.dataRange.range?.topOffset
    );

    const hasFieldNameError = !!(field.errors?.name && field.touched?.name);
    const fieldNameError = hasFieldNameError ? field.errors?.name : "";

    const hasResultDefinitionError = !!(
        (field.errors?.resultDefinition || field.errors?.aggregationFields) &&
        field.touched?.resultDefinition
    );
    const resultDefinitionError = hasResultDefinitionError
        ? field.errors?.resultDefinition || field.errors?.aggregationFields
        : "";

    const isDisabledSimpleRule =
        !field.headerPosition ||
        field.headerPosition.row < 0 ||
        field.headerPosition.column < 0 ||
        isLegacyField;

    const isRangeError = !!field.errors?.dataRange;
    const isDisplaySummaryError =
        (hasResultDefinitionError || hasFieldNameError || isRangeError) && !open;

    const handleChangeAggregation = (value: boolean): void => {
        if (value) {
            onChange({
                resultDefinitionId: undefined,
                aggregationFields: [],
                mergeType: "empty",
                dataRange: {
                    simpleRule: {
                        down: false,
                        right: false,
                        diagonal: false
                    },
                    range: null
                }
            });
        } else {
            onChange({
                aggregationFields: null,
                mergeType: null
            });
        }
    };

    const fieldsOptions = useFieldsOptions();
    const hasAggregation = !!field.aggregationFields;

    const resultDefinition =
        resultDefinitions.find((rd) => rd.id === field.resultDefinitionId) ?? null;

    const onResultDefinitionChange = (newResultDefinition: ResultDefinition | null): void => {
        const isPrevDecimalPlacesDisabled = isDecimalPlacesDisabled(resultDefinition);
        const isCurrentDecimalPlacesDisabled = isDecimalPlacesDisabled(newResultDefinition);

        let { decimalPlaces } = field;

        if (isPrevDecimalPlacesDisabled && !isCurrentDecimalPlacesDisabled) {
            decimalPlaces = DEFAULT_DECIMAL_PLACES;
        } else if (isCurrentDecimalPlacesDisabled) {
            decimalPlaces = null;
        }

        const scientificNotationDisabled = isScientificNotationDisabled(newResultDefinition);
        const scientificNotation = !scientificNotationDisabled && field.scientificNotation;
        onChange({
            resultDefinitionId: newResultDefinition?.id,
            decimalPlaces,
            scientificNotation
        });
    };

    const handleChangeAggregationFields = (aggregationFields: Option[] | null) => {
        if (!aggregationFields || aggregationFields.length <= 2) {
            onChange({ aggregationFields });
        }
    };

    const handleNameUnfocused = () => {
        onTouch("name");
        if (!hasAggregation && !resultDefinition) {
            const resultDefinitionId = resultDefinitions.find((item) =>
                isSameResultDefinition(item.name, field.name)
            )?.id;
            if (resultDefinitionId) {
                onChange({ resultDefinitionId });
            }
        }
    };

    return (
        <Accordion expanded={isOpen} onChange={onOpen} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {field.name ? (
                    <Typography
                        className={classes.summaryName}
                        color={isDisplaySummaryError ? "error" : "textPrimary"}
                    >
                        {field.name}
                    </Typography>
                ) : (
                    <Typography color="textSecondary">Parameter name</Typography>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.root}>
                    <div className={classes.fieldRow}>
                        <Typography className={classes.fieldTitle}>
                            {getTranslation("field-name")}
                        </Typography>
                        <DebouncedTextField
                            variant={"outlined"}
                            value={field.name}
                            onBlur={handleNameUnfocused}
                            error={hasFieldNameError}
                            helperText={fieldNameError}
                            className={classes.fieldInput}
                            onChangeValue={(name) => onChange({ name })}
                        />
                    </div>
                    <div className={classes.fieldRow}>
                        <Typography className={classes.fieldTitle}>
                            {hasAggregation
                                ? getTranslation("concatenate")
                                : getTranslation("result-definition")}
                        </Typography>
                        <ComboAutocomplete<ResultDefinition>
                            size={"medium"}
                            error={hasResultDefinitionError}
                            helperText={resultDefinitionError}
                            singleOptions={resultDefinitions}
                            singleValue={resultDefinition}
                            multi={hasAggregation}
                            multiOptions={fieldsOptions}
                            multiValue={field.aggregationFields}
                            onBlur={() => onTouch("resultDefinition")}
                            onChangeSingle={onResultDefinitionChange}
                            onChangeMulti={handleChangeAggregationFields}
                            label=""
                            toggleLabel={getTranslation("concatenate")}
                            setMulti={handleChangeAggregation}
                            className={classes.fieldInput}
                            multiComponentAdditionField={
                                <ConcatenationMergeType fieldIndex={fieldIndex} />
                            }
                            multiHelperText={getTranslation("choose-two-results-definitions")}
                        />
                    </div>

                    {!hasAggregation && (
                        <div className={classes.fieldRow}>
                            <Button
                                disabled={isLegacyField}
                                color={field.dataRange.range ? "primary" : "secondary"}
                                variant="outlined"
                                className={classes.customButton}
                                onClick={() => setCustomTemplateModeActive(true)}
                            >
                                {getTranslation("custom")}
                            </Button>
                            <OutlinedIconButton
                                disabled={isDisabledSimpleRule}
                                onClick={() =>
                                    onChange({
                                        dataRange: {
                                            simpleRule: {
                                                down: true,
                                                right: false,
                                                diagonal: false
                                            },
                                            range: null
                                        }
                                    })
                                }
                                startIcon={<ArrowDownwardIcon />}
                                type={field.dataRange.simpleRule?.down ? "primary" : "secondary"}
                            />

                            <OutlinedIconButton
                                disabled={isDisabledSimpleRule}
                                onClick={() =>
                                    onChange({
                                        dataRange: {
                                            simpleRule: {
                                                down: false,
                                                right: true,
                                                diagonal: false
                                            },
                                            range: null
                                        }
                                    })
                                }
                                startIcon={<ArrowForwardIcon />}
                                type={field.dataRange.simpleRule?.right ? "primary" : "secondary"}
                            />
                            <OutlinedIconButton
                                disabled={isDisabledSimpleRule}
                                onClick={() =>
                                    onChange({
                                        dataRange: {
                                            simpleRule: {
                                                down: false,
                                                right: false,
                                                diagonal: true
                                            },
                                            range: null
                                        }
                                    })
                                }
                                startIcon={<ArrowDiagonal />}
                                type={
                                    field.dataRange.simpleRule?.diagonal ? "primary" : "secondary"
                                }
                            />
                        </div>
                    )}
                    <div className={classes.fieldRow}>
                        <FieldRegexp field={field} onChange={onChange} onTouch={onTouch} />
                    </div>
                    <div className={classes.fieldRow}>
                        <Typography className={classes.fieldTitle}>
                            {getTranslation("decimal-places")}
                        </Typography>
                        <DebouncedTextField
                            type={"number"}
                            variant={"outlined"}
                            value={field.decimalPlaces ?? ""}
                            disabled={isDecimalPlacesDisabled(resultDefinition)}
                            className={classes.fieldInput}
                            onChangeValue={(decimalPlaces) =>
                                onChange({ decimalPlaces: +decimalPlaces })
                            }
                        />
                    </div>
                    <div className={classes.checkBoxes}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={"primary"}
                                    checked={field.required}
                                    onChange={(_, required) => onChange({ required })}
                                    name="required"
                                />
                            }
                            label={getTranslation("required")}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={"primary"}
                                    checked={field.hideInResult}
                                    onChange={(_, hideInResult) => onChange({ hideInResult })}
                                    name="hideInResult"
                                />
                            }
                            label={getTranslation("hide-in-result")}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={"primary"}
                                    checked={field.scientificNotation}
                                    disabled={isScientificNotationDisabled(resultDefinition)}
                                    onChange={(_, scientificNotation) =>
                                        onChange({ scientificNotation })
                                    }
                                    name="scientificNotation"
                                />
                            }
                            label={getTranslation("scientific-notation")}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={"primary"}
                                    checked={field.showInFT}
                                    onChange={(_, showInFT) =>
                                        onChange({
                                            showInFT,
                                            FTMode: showInFT ? ftModeOptions[0] : undefined
                                        })
                                    }
                                    name="showInFT"
                                />
                            }
                            label={getTranslation("show-in-ft")}
                        />
                    </div>
                    {field.showInFT && (
                        <div className={classes.fieldRow}>
                            <Typography className={classes.fieldTitle}>FT Mode</Typography>
                            <OutlinedAutocomplete<Option>
                                disableClearable
                                size={"medium"}
                                options={ftModeOptions}
                                value={field.FTMode}
                                onChange={(_, FTMode) => {
                                    if (!FTMode) return;
                                    onChange({
                                        FTMode
                                    });
                                }}
                                getOptionLabel={(option) => option.name}
                                className={classes.fieldInput}
                            />
                        </div>
                    )}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};
