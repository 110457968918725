import React, { useState } from "react";
import { makeStyles } from "@assay/ui-kit";
import { Button } from "@material-ui/core";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { ConfirmDialog } from "../components";
import { getTranslation } from "@assay/shared";
import { UploadTemplateFieldErrors } from "../types";

const useStyles = makeStyles((theme) => ({
    root: {
        "display": "flex",
        "width": "100%",
        "alignItems": "center",
        "flexDirection": "column",
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(1)
        }
    },
    saveButton: {
        minWidth: "308px"
    }
}));

const selectState = ({
    fileParsingInProcess,
    actions,
    fields,
    name,
    sheets,
    sendingDataInProcess,
    isModified,
    sheetsErrors
}: UploadTemplateStore) => ({
    isModified,
    cancelDisabled: fileParsingInProcess || sendingDataInProcess,
    saveDisabled: !!(
        fileParsingInProcess ||
        !fields.length ||
        !sheets.length ||
        name.error ||
        sheetsErrors.size > 0 ||
        fields.some(
            (field) =>
                field.errors &&
                Object.keys(field.errors).some(
                    (key) => !!field.errors?.[key as keyof UploadTemplateFieldErrors]
                )
        )
    ),
    reset: actions.reset,
    isLoading: sendingDataInProcess,
    onSave: actions.saveUploadTemplate
});

export const SaveButton = (): React.ReactElement => {
    const classes = useStyles();
    const { saveDisabled, onSave, reset, cancelDisabled, isLoading, isModified } =
        useShallowUploadTemplateStore(selectState);

    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

    const handleCancel = isModified ? () => setIsCancelDialogOpen(true) : () => reset(false);

    return (
        <div className={classes.root}>
            <Button
                onClick={onSave}
                disabled={saveDisabled || isLoading}
                variant="outlined"
                color={"secondary"}
                className={classes.saveButton}
            >
                {isLoading ? getTranslation("saving") : getTranslation("save-template")}
            </Button>
            <Button color={"primary"} onClick={handleCancel} disabled={cancelDisabled}>
                {getTranslation("cancel")}
            </Button>

            {isCancelDialogOpen && (
                <ConfirmDialog
                    onClose={() => setIsCancelDialogOpen(false)}
                    isOpen={isCancelDialogOpen}
                    onSubmit={() => reset(false)}
                    title={getTranslation("cancel-changes")}
                />
            )}
        </div>
    );
};
