import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useStyles } from "./styles";
import { TemplateRange } from "../../../types";
import { boolToYesNoString } from "../../../utils";
import { ArrowDiagonal } from "@assay/ui-kit";
import { getTranslation } from "@assay/shared";

type Props = {
    repeatLabel: string;
    directionLabel: string;
    repeatCountLabel: string;
    range?: TemplateRange | null;
};

export const TemplateSettingsView: FC<Props> = ({
    repeatLabel,
    range,
    directionLabel,
    repeatCountLabel
}) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.row}>
                <Typography className={classes.title}>{repeatLabel}</Typography>
                <Typography>{boolToYesNoString(range?.repeat.down || range?.repeat.right)}</Typography>
            </div>
            <div className={classes.row}>
                <Typography className={classes.title}>{directionLabel}</Typography>
                {range?.repeat.down && <ArrowDownwardIcon color={"inherit"} />}
                {range?.repeat.right && <ArrowForwardIcon color={"inherit"} />}
                {range?.repeat.diagonal && <ArrowDiagonal color={"inherit"} />}
            </div>
            <div className={classes.row}>
                <Typography className={classes.title}>{repeatCountLabel}</Typography>
                <Typography>{range?.repeatAll ? "All" : range?.repeatCount}</Typography>
            </div>
            <div className={classes.row}>
                <Typography className={classes.title}>
                    {getTranslation("display-as-one-value-in-result")}
                </Typography>
                <Typography>
                    {range?.isMulti ? getTranslation("yes") : getTranslation("no")}
                </Typography>
            </div>
            {range?.isMulti && (
                <div className={classes.row}>
                    <Typography className={classes.title}>
                        {getTranslation("reverse-data")}
                    </Typography>
                    <Typography>
                        {range?.reverse ? getTranslation("yes") : getTranslation("no")}
                    </Typography>
                </div>
            )}
        </>
    );
};
