import React, { FC } from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Switch,
    TextField,
    Typography
} from "@material-ui/core";
import { ArrowDiagonal, OutlinedIconButton } from "@assay/ui-kit";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useStyles } from "./styles";
import { SelectedSheetMatrix, SelectType, TemplateDirection, TemplateRange } from "../../../types";
import { getTranslation } from "@assay/shared";

type Props = {
    repeatLabel: string;
    directionLabel: string;
    repeatCountLabel: string;
    onClose: () => void;
    onSave: () => void;
    range: TemplateRange;
    setRange: (range: TemplateRange) => void;
    setMatrix: (value: SelectedSheetMatrix) => void;
    initMatrix: SelectedSheetMatrix;
    matrix: SelectedSheetMatrix;
};

export const TemplateSettingsEdit: FC<Props> = ({
    repeatCountLabel,
    repeatLabel,
    directionLabel,
    range,
    setRange,
    onSave,
    onClose,
    initMatrix,
    setMatrix,
    matrix
}) => {
    const classes = useStyles();

    const isRepeated = !!(range?.repeat.right || range?.repeat.down || range?.repeat.diagonal);

    const isApplyDisabled = matrix.every((row) =>
        row.every((column) => column === SelectType.NotSelected)
    );

    const handleClearAll = () => {
        setRange({
            repeatCount: 0,
            repeatAll: true,
            repeat: { down: true, right: false, diagonal: false },
            template: [],
            isMulti: false,
            reverse: false
        });
        setMatrix(initMatrix);
    };

    const setPartial = (value: Partial<TemplateRange>) => {
        setRange({
            ...range,
            ...value
        });
    };

    const toggleRepeat = (checked: boolean) =>
        setPartial({
            repeat: {
                down: checked,
                right: false,
                diagonal: false
            }
        });

    const toggleDirection = (direction: keyof TemplateDirection) => {
        setPartial({
            repeat: {
                down: false,
                right: false,
                diagonal: false,
                [direction]: true
            }
        });
    };

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={range.isMulti}
                        onChange={(_, isMulti) =>
                            setPartial({ isMulti, reverse: isMulti ? range.reverse : false })
                        }
                        color="primary"
                    />
                }
                label={getTranslation("display-as-one-value-in-result")}
            />
            <FormControlLabel
                disabled={!range.isMulti}
                control={
                    <Checkbox
                        checked={range.reverse}
                        onChange={(_, reverse) => setPartial({ reverse })}
                        color="primary"
                    />
                }
                label={getTranslation("reverse-data")}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isRepeated}
                        onChange={(_, checked) => {
                            toggleRepeat(checked);
                        }}
                        color="primary"
                    />
                }
                label={repeatLabel}
            />
            <div className={classes.directionRow}>
                {isRepeated && (
                    <>
                        <Typography className={classes.title}>{directionLabel}</Typography>
                        <OutlinedIconButton
                            onClick={() => toggleDirection("down")}
                            startIcon={<ArrowDownwardIcon />}
                            type={range?.repeat.down ? "primary" : "secondary"}
                        />
                        <OutlinedIconButton
                            onClick={() => toggleDirection("right")}
                            startIcon={<ArrowForwardIcon />}
                            type={range?.repeat.right ? "primary" : "secondary"}
                        />
                        <OutlinedIconButton
                            onClick={() => toggleDirection("diagonal")}
                            startIcon={<ArrowDiagonal />}
                            type={range?.repeat.diagonal ? "primary" : "secondary"}
                        />
                    </>
                )}
            </div>

            {isRepeated && (
                <div className={classes.directionRow}>
                    <Typography className={classes.title}>{repeatCountLabel}</Typography>
                    <TextField
                        className={classes.count}
                        disabled={range.repeatAll}
                        type="number"
                        label=""
                        variant="outlined"
                        size="small"
                        value={range.repeatCount.toString()} //for fix '01' instead of '1'
                        onChange={(event) =>
                            setPartial({ repeatCount: Math.max(+event.target.value, 0) })
                        }
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={range.repeatAll}
                                onChange={(_, repeatAll) =>
                                    setPartial({
                                        repeatAll
                                    })
                                }
                                color="primary"
                            />
                        }
                        label="All"
                    />
                </div>
            )}

            <div className={classes.actions}>
                <Button
                    variant="text"
                    color="primary"
                    className={classes.clearAction}
                    onClick={handleClearAll}
                >
                    {getTranslation("clear-all")}
                </Button>
                <div className={classes.mainActions}>
                    <Button
                        disabled={isApplyDisabled}
                        variant="outlined"
                        color="secondary"
                        onClick={onSave}
                    >
                        {getTranslation("apply")}
                    </Button>
                    <Button variant="text" color="primary" onClick={onClose}>
                        {getTranslation("cancel")}
                    </Button>
                </div>
            </div>
        </>
    );
};
