import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    tab: {
        position: "relative",
        flex: "1 1 100%",
        height: "100%"
    }
});

type Props = {
    children?: React.ReactNode;
    selectedIndex: number;
    currentIndex: number;
};

export const TabPanel: React.FC<Props> = ({ selectedIndex, currentIndex, children }) => {
    const classes = useStyles();
    return (
        <div className={classes.tab} hidden={currentIndex !== selectedIndex}>
            {currentIndex === selectedIndex && children}
        </div>
    );
};
