import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import { getTranslation } from "@assay/shared";

type Props = {
    onClear: () => void;
    onSave: () => void;
    onCancel: () => void;
};

const useStyles = makeStyles({
    flexRow: {
        "display": "flex",
        "width": "100%",
        "alignItems": "center",
        "alignContent": "center",
        "& > *:not(:last-child)": {
            marginRight: "1.5rem"
        }
    },
    editActionsButtons: {
        "display": "flex",
        "marginRight": 0,
        "marginLeft": "auto",
        "& > *:not(:last-child)": {
            marginRight: "1.5rem"
        }
    },
    clearDialogAction: {
        marginLeft: 0,
        marginRight: "auto"
    }
});

export const SheetDialogActions = ({ onClear, onCancel, onSave }: Props) => {
    const classes = useStyles();
    return (
        <div className={classes.flexRow}>
            <Button
                className={classes.clearDialogAction}
                variant="text"
                color="primary"
                onClick={onClear}
            >
                {getTranslation("clear-all")}
            </Button>
            <div className={classes.editActionsButtons}>
                <Button variant="outlined" color="primary" onClick={onSave}>
                    {getTranslation("save")}
                </Button>
                <Button variant="text" color="primary" onClick={onCancel}>
                    {getTranslation("cancel")}
                </Button>
            </div>
        </div>
    );
};
