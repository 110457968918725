import { useState } from "react";
import { calcPermissions } from "./calc-permissions";
import { useUrl } from "../../../use-url";
import { renderPopup } from "../../../popup";
import { AddDialog } from "../../../add-dialog";
import { ContentTypes } from "../../../types";
import { useGlobalStore, useTreeHandlers } from "../../../store";
import { getTranslation } from "@assay/shared";
import { UT_FOLDER_ID } from "../../../constants";
import { Features, featureService } from "@assay/shared";

const fixType = (item) => {
    const isNewUtsOn = featureService[Features.IsNewUploadTemplatesOn];
    const id = Number(item.key);

    return id === UT_FOLDER_ID && isNewUtsOn ? ContentTypes.Ut : ContentTypes.Form;
};

const initialMenuState = {
    item: null,
    coords: null
};

export const useContextMenu = () => {
    const [menuState, setMenuState] = useState(initialMenuState);
    const { item, coords } = menuState;
    const { openItem } = useUrl();
    const setUserAndDate = useGlobalStore((state) => state.setUserAndDate);
    const { toggleSelect: selectTreeNode } = useTreeHandlers();

    const closeContextMenu = (e) => {
        e.preventDefault();
        setMenuState(initialMenuState);
    };

    const onContextMenu = (e, item) => {
        e.preventDefault();
        setMenuState({
            item,
            coords: {
                left: e.clientX,
                top: e.clientY
            }
        });
    };

    const onAdd = () => {
        const id = Number(item.key);
        const onSubmit = async ({ addType, date, user } = {}) => {
            setUserAndDate(user, date);

            openItem({
                viewState: "create",
                type: fixType(item),
                typeId: addType,
                parentId: id
            });
        };

        renderPopup({
            id: "addPopup",
            component: AddDialog,
            entityId: id,
            type: item.type,
            formProps: {
                onSubmit: onSubmit
            }
        });
    };

    const onEdit = () => {
        openItem({
            id: item.key,
            viewState: "edit",
            type: fixType(item)
        });
        selectTreeNode(item.key);
    };

    const onView = () => {
        openItem({
            id: item.key,
            viewState: "view",
            type: item.type === ContentTypes.Ut ? ContentTypes.Ut : ContentTypes.Form
        });
        selectTreeNode(item.key);
    };

    const permissions = calcPermissions(item);

    const menuOptions = [
        {
            text: getTranslation("add"),
            disabled: permissions.isAddDisabled,
            onClick: onAdd
        },
        {
            text: getTranslation("edit"),
            disabled: permissions.isEditDisabled,
            onClick: onEdit
        },
        {
            text: getTranslation("view"),
            disabled: permissions.isViewDisabled,
            onClick: onView
        }
    ];

    return {
        menuOptions,
        menuCoords: coords,
        closeContextMenu,
        onContextMenu
    };
};
