import React, { useEffect, useMemo, useState } from "react";
import { IconButton, Typography } from "@material-ui/core";
import { convertTemplateToMatrix, createEmptySheetMatrix } from "../../../utils";
import produce from "immer";
import { SelectedSheetMatrix, SelectType, TemplateRange } from "../../../types";
import CloseIcon from "@material-ui/icons/Close";
import { TemplateSettingsMatrix } from "../template-settings-matrix";
import { TemplateSettingsEditTitle } from "./template-settings-edit-title";
import { useStyles } from "./styles";
import { TemplateSettingsView } from "./template-settings-view";
import { TemplateSettingsEdit } from "./template-settings-edit";
import { getTranslation } from "@assay/shared";
import { EMPTY_TEMPLATE_RANGE } from "../../../values";

type Props = {
    editMode?: boolean;
    rowsCount: number;
    columnsCount: number;
    onClose: () => void;
    onSave?: (value: { range?: TemplateRange | null; matrix: SelectedSheetMatrix }) => void;
    templateRange?: TemplateRange | null;
};


export const TemplateSettings = ({
                                     editMode = true,
                                     rowsCount,
                                     columnsCount,
                                     onClose,
                                     onSave,
                                     templateRange
                                 }: Props): React.ReactElement => {

    const classes = useStyles();

    const repeatLabel = getTranslation("repeat-pattern");
    const directionLabel = getTranslation("direction");
    const repeatCountLabel = getTranslation("repetition-number");

    const initMatrix = useMemo(
        () =>
            templateRange
                ? convertTemplateToMatrix({
                    templateRange,
                    withHeaders: false,
                    collapse: true,
                    isDisplayOnEmptyCells: false
                })
                : createEmptySheetMatrix(rowsCount, columnsCount),
        []
    );

    const [matrix, setMatrix] = useState(initMatrix);
    const [range, setRange] = useState(templateRange ?? EMPTY_TEMPLATE_RANGE);

    useEffect(() => {
        setRange(templateRange ?? EMPTY_TEMPLATE_RANGE);
    }, [templateRange]);

    const changeMatrixElement = (rowIndex: number, columnIndex: number) =>
        setMatrix((prevState) =>
            produce(prevState, (draftState) => {
                draftState[rowIndex][columnIndex] =
                    draftState[rowIndex][columnIndex] === SelectType.ActiveSelected
                        ? SelectType.NotSelected
                        : SelectType.ActiveSelected;
            })
        );

    return (
        <div className={classes.root}>
            <IconButton aria-label="close" className={classes.closeIcon} onClick={onClose}>
                <CloseIcon style={{ fontSize: "12px" }} />
            </IconButton>
            <Typography variant={"h6"}>{getTranslation("mapping")}</Typography>
            {editMode && <TemplateSettingsEditTitle />}

            <div className={classes.tableWrapper}>
                <TemplateSettingsMatrix
                    editMode={editMode}
                    matrix={matrix}
                    onChange={(rowIndex, columnIndex) => {
                        if (!editMode) return;
                        changeMatrixElement(rowIndex, columnIndex);
                    }}
                />
            </div>

            {!editMode && (
                <TemplateSettingsView
                    range={range}
                    directionLabel={directionLabel}
                    repeatLabel={repeatLabel}
                    repeatCountLabel={repeatCountLabel}
                />
            )}

            {editMode && (
                <TemplateSettingsEdit
                    repeatLabel={repeatLabel}
                    directionLabel={directionLabel}
                    repeatCountLabel={repeatCountLabel}
                    range={range}
                    setRange={setRange}
                    onClose={onClose}
                    setMatrix={setMatrix}
                    initMatrix={initMatrix}
                    matrix={matrix}
                    onSave={() => onSave?.({ range, matrix })}
                />
            )}
        </div>
    );
};
