import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const CommaIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="m10.56022,15.39652l-0.58179,-1.38785c0.69586,-0.2056 1.2149,-0.47801 1.55712,-0.81724c0.35364,-0.33925 0.52476,-0.69391 0.51335,-1.064l-0.11978,0c-0.4449,0 -0.84416,-0.12336 -1.19779,-0.37009c-0.34224,-0.25701 -0.51335,-0.61681 -0.51335,-1.07942c0,-0.43177 0.17111,-0.78129 0.51335,-1.04858c0.34222,-0.26728 0.7643,-0.40092 1.26623,-0.40092c0.63882,0 1.12364,0.21074 1.45446,0.63223c0.33082,0.42148 0.49623,1.01259 0.49623,1.77332c0,0.91493 -0.28517,1.70137 -0.85556,2.35925c-0.57038,0.65793 -1.41454,1.12569 -2.53247,1.40329z"
                fill={props.color}
            />
        </SvgIcon>
    );
};
