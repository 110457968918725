import {
    getForm,
    sendSaveFormRequest,
    saveResults,
    getResults,
    removeResults,
    getResultsQueryKey
} from "../../../api-v2";
import { FormData, FormField } from "../../../types";
import { FormError, FormErrorTypes } from "../../../helpers";
import { createResults } from "./results-actions";
import { getOriginFieldByName } from "../common";
import { arxFit, arxFitSigmoidal } from "./legacy";
import { KnockoutPlot } from "./knockout";
import { Features, featureService } from "@assay/shared";
import { QueryClient } from "react-query";

const getAnalysisFunctions = async (ids: number[]): Promise<string[]> => {
    return await Promise.all<string>(
        ids.map(async (id: number) => {
            const form = await getForm(id);
            return getOriginFieldByName(form, "function").value;
        })
    );
};

/**
 *  Adds some legacy props and methods to make a Result Set compatible with some AFs
 */
const attachLegacyProps = (form: FormData) => {
    const formWithLegacyProps = { ...form };
    formWithLegacyProps.fd = { ...formWithLegacyProps };

    Object.defineProperty(formWithLegacyProps, "getFieldByName", {
        value: function (name: string): FormField {
            const found = this.fields.find(
                (field: FormField) =>
                    name.toLowerCase() === field.__pFieldOptions?.name.toLowerCase()
            );
            return found || false;
        },
        enumerable: false
    });

    return formWithLegacyProps;
};

const executeAnalysisFunctions = (
    analysisFunctions: string[],
    resultSet: FormData,
    results: FormData[]
): void => {
    const rsWithLegacyProps = attachLegacyProps(resultSet);
    const resultsWithLegacyProps = results.map(attachLegacyProps);

    resultsWithLegacyProps.forEach((result) => {
        analysisFunctions.forEach((af) => {
            const func = new Function(
                "allResults",
                "thisResult",
                "resultSet",
                "arxFitSigmoidal",
                "arxFit",
                "KnockoutPlot",
                af
            );

            try {
                const errors = func(
                    resultsWithLegacyProps,
                    result,
                    rsWithLegacyProps,
                    arxFitSigmoidal,
                    arxFit,
                    KnockoutPlot
                );
                if (Array.isArray(errors) && errors.length) {
                    throw new FormError(FormErrorTypes.AnalysisFunction);
                }
            } catch (err) {
                throw new FormError(FormErrorTypes.AnalysisFunction);
            }
        });
    });
};

export const afterSaveResultSet = async (resultSet: FormData, queryClient: QueryClient): Promise<void> => {
    const protocol = await getForm(resultSet.parentId);
    const uploadTemplateId = getOriginFieldByName(protocol, "upload template").value;

    const isNewUtsOn = featureService[Features.IsNewUploadTemplatesOn];
    let results: FormData[];

    const key = getResultsQueryKey(resultSet.id);
    queryClient.invalidateQueries(key);

    if (isNewUtsOn) {
        await saveResults(resultSet);
        results = await getResults(resultSet.id);
    } else {
        const uploadTemplate = await getForm(uploadTemplateId);
        await removeResults(resultSet.id);
        results = await createResults(resultSet, uploadTemplate);
    }

    const analysisFunctionsIds = getOriginFieldByName(protocol, "Analysis Functions").value ?? [];
    const analysisFunctions = await getAnalysisFunctions(analysisFunctionsIds);
    executeAnalysisFunctions(analysisFunctions, resultSet, results);
    await Promise.all(results.map(sendSaveFormRequest));
};
