import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import React from "react";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 15 * 60 * 1000
        }
    }
});

export const QueryProvider: React.FC = ({ children }) => (
    <QueryClientProvider client={queryClient}>
        {children}
        {/* https://react-query.tanstack.com/devtools#options */}
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
);
