import React, { useState } from "react";
import { ConfirmDialog, FileBar } from "../components";
import { Typography } from "@material-ui/core";
import { UploadTemplateStore, useShallowUploadTemplateStore } from "../store";
import { makeStyles } from "@assay/ui-kit";
import { getTranslation } from "@assay/shared";

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.grey[600],
        padding: theme.spacing(1, 0, 0),
        display: "block"
    }
}));

const selectState = ({ mainFile, actions }: UploadTemplateStore) => ({
    mainFile: mainFile,
    onDeleteMainFile: actions.removeMainFile
});

export const MainFileViewBar: React.FC = () => {
    const classes = useStyles();
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const { mainFile, onDeleteMainFile } = useShallowUploadTemplateStore(selectState);

    if (!mainFile) {
        return null;
    }

    const confirmDelete = () => {
        setIsDeletePopupOpen(false);
        onDeleteMainFile();
    };

    const onDeleteFileHandler = () => setIsDeletePopupOpen(true);

    return (
        <>
            <Typography variant={"caption"} className={classes.text}>
                {getTranslation("file-is-been-using-for-creating-template")}
            </Typography>
            <FileBar file={mainFile} onDelete={onDeleteFileHandler} />

            {isDeletePopupOpen && (
                <ConfirmDialog
                    onClose={() => setIsDeletePopupOpen(false)}
                    isOpen={isDeletePopupOpen}
                    onSubmit={confirmDelete}
                    title={getTranslation("delete-file")}
                />
            )}
        </>
    );
};
