export const RESULTS_PER_PAGE = 10;

export const paginateRows = (rows: never[]): never[][] => {
    const pages = Math.ceil(rows.length / RESULTS_PER_PAGE);
    const result = [];
    let start = 0;
    let end = RESULTS_PER_PAGE;

    for (let i = 0; i < pages; i++) {
        const page = rows.slice(start, end);
        result.push(page);

        start = end;
        end += RESULTS_PER_PAGE;
    }

    return result;
};
