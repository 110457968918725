import React from "react";
import { Cell } from "./cell";
import produce from "immer";

export type SelectedCellsRow = boolean[] | undefined;

type ContentCell = {
    isSelected: boolean;
    isClickable: boolean;
    value: number;
};

type Props = {
    noTrtIndex: number;
    DMSOIndex: number;
    columnLabels: string[];
    emptyBorderCells: {
        top: number[];
        bottom: number[];
        left: number[];
        right: number[];
    };
    DMSOPercentActivity: number[];
    percentActivityFields: number[][];
    selectedCells: SelectedCellsRow[];
    onChangeSelectedCells: (value: SelectedCellsRow[]) => void;
};

export const Heatmap: React.FC<Props> = ({
    percentActivityFields,
    DMSOPercentActivity,
    columnLabels,
    emptyBorderCells,
    selectedCells,
    onChangeSelectedCells,
    noTrtIndex,
    DMSOIndex
}) => {
    const rows: ContentCell[][] = new Array(
        Math.max(
            percentActivityFields.length,
            emptyBorderCells.left.length,
            emptyBorderCells.right.length
        )
    )
        .fill("")
        .map((_, rowIndex) =>
            new Array(columnLabels.length - 2).fill("").map((_, columnIndex) => {
                if (columnIndex < noTrtIndex) {
                    const value = percentActivityFields[rowIndex]?.[columnIndex] ?? -1;
                    return {
                        isSelected: selectedCells[rowIndex]?.[columnIndex] ?? !!value,
                        isClickable: !!value,
                        value
                    };
                }

                if (columnIndex === noTrtIndex) {
                    return {
                        isSelected: true,
                        isClickable: false,
                        value: 100
                    };
                }

                if (columnIndex === DMSOIndex) {
                    return {
                        isSelected: true,
                        isClickable: false,
                        value: DMSOPercentActivity[rowIndex] ?? -1
                    };
                }

                const value = percentActivityFields[rowIndex]?.[columnIndex - 2] ?? -1;

                return {
                    isSelected: selectedCells[rowIndex]?.[columnIndex - 2] ?? !!value,
                    isClickable: !!value,
                    value
                };
            })
        );

    const handleClickCell = (rowIndex: number, columnIndex: number) => {
        const newSelected = produce(selectedCells, (draft) => {
            if (draft[rowIndex] === undefined) {
                draft[rowIndex] = new Array(columnLabels.length).fill(true);
            }
            if (columnIndex < noTrtIndex) {
                (draft[rowIndex] as boolean[])[columnIndex] = !(draft[rowIndex] as boolean[])[
                    columnIndex
                ];
            }
            if (columnIndex > DMSOIndex) {
                (draft[rowIndex] as boolean[])[columnIndex - 2] = !(draft[rowIndex] as boolean[])[
                    columnIndex - 2
                ];
            }
        });
        onChangeSelectedCells(newSelected);
    };

    const heatMapMin = Math.min(
        ...rows.map((row) =>
            Math.min(...row.filter((item) => item.value !== -1).map((column) => column.value))
        )
    );
    const heatMapMax = Math.max(
        ...rows.map((row) =>
            Math.max(...row.filter((item) => item.value !== -1).map((column) => column.value))
        )
    );

    return (
        <table cellSpacing={2}>
            <tbody>
                <tr>
                    {columnLabels.map((content, index) => (
                        <td key={index}>
                            <Cell content={content} isBold={true} />
                        </td>
                    ))}
                </tr>
                <tr>
                    {emptyBorderCells.top.map((content, index) => (
                        <td key={index}>
                            <Cell content={content} backgroundColor="white" />
                        </td>
                    ))}
                </tr>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <td>
                            <Cell
                                content={emptyBorderCells.left[rowIndex]}
                                backgroundColor="white"
                            />
                        </td>

                        {row.map((column, columnIndex) => (
                            <td key={columnIndex}>
                                <Cell
                                    content={column.value !== -1 ? column.value : ""}
                                    isCalculateColor={column.isSelected && column.value !== -1}
                                    backgroundColor={
                                        !column.isSelected || column.value === -1 ? "white" : ""
                                    }
                                    heatMapMin={heatMapMin}
                                    heatMapMax={heatMapMax}
                                    onClick={() => handleClickCell(rowIndex, columnIndex)}
                                />
                            </td>
                        ))}

                        <td>
                            <Cell
                                content={emptyBorderCells.right[rowIndex]}
                                backgroundColor={"white"}
                            />
                        </td>
                    </tr>
                ))}
                <tr>
                    {emptyBorderCells.bottom.map((content, index) => (
                        <td key={index}>
                            <Cell content={content} backgroundColor={"white"} />
                        </td>
                    ))}
                </tr>
            </tbody>
        </table>
    );
};
