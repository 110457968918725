import { Button, Divider, Paper, PaperProps, Typography } from "@material-ui/core";
import { makeStyles } from "../../theme";
import React, { MouseEvent, useEffect, useRef } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getTranslation } from "../get-translation";

type PaperComponentProps = PaperProps & {
    onToggle: (value: boolean) => void;
    checked: boolean;
    toggleLabel: string;
    onClose?: () => void;
    onClear?: () => void;
    additionField?: React.ReactNode;
    helperText?: string;
};

const usePaperStyles = makeStyles((theme) => ({
    buttons: {
        "display": "flex",
        "padding": theme.spacing(1),
        "justifyContent": "flex-start",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    },
    helperText: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const willChangeDeleteHelper = (el: HTMLElement) => {
    requestAnimationFrame(() => {
        if (el) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            el.style["will-change"] = null;
        }
    });
};

export const PaperComponent = ({
    onToggle,
    checked,
    toggleLabel,
    onClose,
    onClear,
    additionField,
    helperText,
    ...paperProps
}: PaperComponentProps) => {
    const classes = usePaperStyles();

    const handleMouseDown = (ev: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
        ev.stopPropagation();
        onToggle(!checked);
    };

    const ref = useRef<HTMLDivElement>();

    useEffect(() => {
        const presentationElement = ref?.current?.parentElement;

        if (presentationElement) {
            //this hack for avoid blurred text into div with role=presentation
            willChangeDeleteHelper(presentationElement);

            //sometimes hack from previous row doesn't work for unknown reason
            //and it is double hack
            presentationElement.addEventListener(
                "mousemove",
                () => willChangeDeleteHelper(presentationElement),
                { once: true }
            );
        }
    }, [ref]);

    return (
        <Paper innerRef={ref}>
            <Button
                color="primary"
                onMouseDown={handleMouseDown}
                startIcon={checked ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            >
                {toggleLabel}
            </Button>

            <Divider />
            {additionField}

            <Typography className={classes.helperText} color="textSecondary" variant="body2">
                {helperText}
            </Typography>

            <Paper elevation={0} {...paperProps} />
            {checked && (
                <div className={classes.buttons}>
                    <Button variant="outlined" color="secondary" onMouseDown={onClose}>
                        {getTranslation("apply")}
                    </Button>
                    <Button color="primary" onMouseDown={onClear}>
                        {getTranslation("clear")}
                    </Button>
                </div>
            )}
        </Paper>
    );
};
